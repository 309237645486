<template>
  <HeaderFooterExport v-if="config.public.IS_HEADER_FOOTER_EXPORT" />
  <NuxtPage v-else />
  <BaseConsent v-if="config.public.CONSENT_ENABLED" />
</template>

<script setup lang="ts">
import { NuxtPage } from '#components';
import { useCookie, useRuntimeConfig } from '#imports';
import { onBeforeUnmount, onMounted } from 'vue';
import BaseConsent from './components/base/BaseConsent.vue';
import HeaderFooterExport from './components/HeaderFooterExport.vue';
import { useBreakpoint } from './composables/useBreakpoint';
import { useScroll } from './composables/useScroll';

const { setScroll, state: scrollState } = useScroll();
const { init: breakpointInit } = useBreakpoint();
const config = useRuntimeConfig();
const shopStoreCookie = useCookie('store', {
  httpOnly: false,
  domain: '.wmf-coffeemachines.com',
  path: '/',
  maxAge: 60 * 60 * 24 * 365,
});

/**
 * Setup scroll state listener
 */
let scrollStateDebounce: NodeJS.Timeout;
function onManualScroll() {
  setScroll(window.scrollY);
  scrollState.status = 'scroll';

  if (scrollStateDebounce) {
    clearTimeout(scrollStateDebounce);
  }
  scrollStateDebounce = setTimeout(() => {
    scrollState.status = 'none';
  }, 30);
}

onBeforeUnmount(() => {
  clearTimeout(scrollStateDebounce);
  window.removeEventListener('scroll', onManualScroll);
});

onMounted(() => {
  window.addEventListener('scroll', onManualScroll);
  breakpointInit();

  shopStoreCookie.value = 'default';
});
</script>

<style>
body,
html {
  padding: 0;
  margin: 0;
}
</style>
