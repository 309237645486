<template>
  <picture v-if="src">
    <source
      v-for="sourceTag in sourceTags"
      :key="sourceTag.breakpoint"
      :media="sourceTag.media"
      :srcset="sourceTag.srcset" />
    <img
      :style="
        objectCover && {
          height: '100%',
          width: '100%',
          objectFit: 'cover',
        }
      "
      :src="defaultSrc['2x']"
      :alt="alt"
      :width="!isSVG ? widestWidth : undefined"
      :height="defaultSrcHeightRatio"
      :loading="lazy ? 'lazy' : undefined"
      @load="emit('load')" />
  </picture>
</template>

<script setup lang="ts">
import { useHead } from '#imports';
import { computed, ref, watch } from 'vue';
import { useLocale } from '~/composables/useLocale';
import { getAltText, getHeightByRatio, getSrcResized } from '~/utils/image';
import type { AssetStoryblok } from '~/types/storyblok-generated';

const props = withDefaults(
  defineProps<{
    src?: string;
    alt?: string;
    image?: AssetStoryblok;
    breakpointsWidthMap: Record<string, number>;
    heightRatio?: number[];
    lazy?: boolean;
    objectCover?: boolean;
    /**
     * Crop image to fit (contain) in the given dimensions
     */
    cropFitIn?: boolean;
    preload?: boolean;
  }>(),
  { lazy: true }
);

const emit = defineEmits<{
  (e: 'load'): void;
}>();

const { localeLanguage } = useLocale();

const breakpoints = Object.keys(props.breakpointsWidthMap).reverse();

const widestWidth: number = Object.values(props.breakpointsWidthMap)[
  Object.values(props.breakpointsWidthMap)?.length - 1
];

const src = computed(() => props.image?.filename ?? props.src ?? '');
const alt = computed(() => getAltText(localeLanguage.value, props.image, props.src, props.alt));
const isSVG = computed(() => props.src?.endsWith('.svg'));
const defaultSrc = ref(
  getSrcResized({
    src: src.value,
    width: widestWidth,
    heightRatio: props.heightRatio,
    cropFitIn: props.cropFitIn,
    isSVG: isSVG.value,
  })
);
const defaultSrcHeightRatio = ref(getHeightByRatio(widestWidth, props.heightRatio));

function getSourceTags() {
  return breakpoints.map(breakpoint => {
    const sources = getSrcResized({
      src: src.value,
      width: props.breakpointsWidthMap[breakpoint],
      heightRatio: props.heightRatio,
      cropFitIn: props.cropFitIn,
      isSVG: isSVG.value,
    });
    return {
      breakpoint: breakpoint,
      media: `(min-width: ${breakpoint}px)`,
      srcset: `${sources['1x']} 1x, ${sources['2x']} 2x`,
    };
  });
}
const sourceTags = ref(getSourceTags());

/**
 * Preload the smallest image
 */
if (props.preload) {
  useHead({
    link: [
      {
        rel: 'preload',
        as: 'image',
        href: sourceTags.value[0].srcset.split(' ')[0],
      },
    ],
  });
}

watch(
  () => [props.image?.id, props.src],
  () => {
    defaultSrc.value = getSrcResized({
      src: src.value,
      width: widestWidth,
      heightRatio: props.heightRatio,
      cropFitIn: props.cropFitIn,
      isSVG: isSVG.value,
    });
    sourceTags.value = getSourceTags();
  }
);
</script>
