import { sendEvent } from '../utils/showroomEvents';
import { ShowroomEvent, type ShowroomEventsMap } from '../webGlApp.types';
import type { AppStateInstance } from './appState';

export type SwipeControlsInstance = InstanceType<typeof SwipeControls>;

export class SwipeControls {
  appState: AppStateInstance;
  initialPointerX = 0;
  cumulativeSwipeDistance = 0;
  swipeThreshold = 50;
  treshholdIsReached = false;
  currentPointerMovementX = 0;
  isPointerDown = false;
  swipeInProgress = false;
  swipeDirection = 0;
  machinesAreMoving = false;
  pointerMoveX: number | null = null;
  distanceX: number | null = null;

  constructor(appState: AppStateInstance) {
    this.appState = appState;
    this.appState.webGlCanvas.addEventListener(ShowroomEvent.MachinesMoveEnd, () => this.onMachinesMovingEnd());
    this.appState.webGlCanvas.addEventListener(ShowroomEvent.MachinesRecenterEnd, () => this.onMachinesRecenterEnd());
    this.appState.webGlCanvas.addEventListener(ShowroomEvent.PointerDown, this.onPointerDown.bind(this));
    this.appState.webGlCanvas.addEventListener(ShowroomEvent.PointerMove, this.onPointerMove.bind(this));
    this.appState.webGlCanvas.addEventListener(ShowroomEvent.PointerUp, this.onPointerUp.bind(this));
  }

  private onPointerDown(event: ShowroomEventsMap[ShowroomEvent.PointerDown]) {
    this.isPointerDown = true;
    this.initialPointerX = event.detail.event.clientX;
  }

  private onPointerUp(_event: ShowroomEventsMap[ShowroomEvent.PointerUp]) {
    if (!this.treshholdIsReached && this.swipeInProgress && Math.abs(this.cumulativeSwipeDistance) > 0) {
      sendEvent(this.appState.webGlCanvas, ShowroomEvent.SwipeCancelled, null);
    }
    this.treshholdIsReached = false;
    this.isPointerDown = false;
    this.swipeInProgress = false;
    this.cumulativeSwipeDistance = 0;
    this.initialPointerX = 0;
  }

  private onPointerMove(event: ShowroomEventsMap[ShowroomEvent.PointerMove]) {
    if (this.isPointerDown && this.machinesAreMoving === false) {
      this.swipeInProgress = true;
      this.pointerMoveX = event.detail.event.clientX;
      this.distanceX = event.detail.event.movementX;
      this.handleSwipe(this.initialPointerX, this.pointerMoveX);
      this.initialPointerX = this.pointerMoveX;
    }
  }

  private handleSwipe(startX: number, currentX: number) {
    const diffX = currentX - startX;
    this.swipeDirection = 0;
    this.cumulativeSwipeDistance += diffX;

    if (diffX > 0) {
      this.swipeDirection = 1;
    } else if (diffX < 0) {
      this.swipeDirection = -1;
    }

    if (Math.abs(this.cumulativeSwipeDistance) > this.swipeThreshold) {
      this.treshholdIsReached = true;
      this.machinesAreMoving = true;
      if (this.swipeDirection === -1) {
        sendEvent(this.appState.webGlCanvas, ShowroomEvent.NextMachine, { isSwiped: true });
      } else {
        sendEvent(this.appState.webGlCanvas, ShowroomEvent.PreviousMachine, { isSwiped: true });
      }
    }
    sendEvent(
      this.appState.webGlCanvas,
      ShowroomEvent.SwipeProgress,
      0.001 * Math.min(Math.abs(this.distanceX ?? 0), 50) * this.swipeDirection
    );
  }

  private onMachinesMovingEnd() {
    this.machinesAreMoving = false;
  }

  private onMachinesRecenterEnd() {
    this.machinesAreMoving = false;
  }
}
