import { gsap } from 'gsap';
import * as THREE from 'three';
import utils from '../utils/utils';
import { ShowroomEvent, type Dimensions } from '../webGlApp.types';
import type { AppStateInstance } from './appState';
import { sendEvent } from '../utils/showroomEvents';

export type EngineInstance = InstanceType<typeof Engine>;

export class Engine {
  appState: AppStateInstance;
  renderer: THREE.WebGLRenderer;
  scene: THREE.Scene;
  camera: THREE.PerspectiveCamera;
  cameraSetup: { position: THREE.Vector3; lookAtPosition: THREE.Vector3 };
  cameraPosition = new THREE.Vector3(0, 0.4, 3);
  cameraLookAtPosition = new THREE.Vector3(0, 0.3, 0);
  zoomValue = 1;
  zoomTargetPositionZ: number;

  constructor(appState: AppStateInstance) {
    this.appState = appState;
    this.renderer = new THREE.WebGLRenderer({
      canvas: appState.webGlCanvas as HTMLCanvasElement,
      powerPreference: 'high-performance',
      antialias: true,
      stencil: false,
      depth: false,
    });
    this.renderer.setSize(appState.renderSize.width, appState.renderSize.height);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    this.camera = new THREE.PerspectiveCamera(30, 1, 0.1, 100);
    this.cameraSetup = utils.getCameraSetupByViewportWidth(appState.viewport.width);
    this.zoomTargetPositionZ = this.cameraSetup.position.z;
    this.cameraPosition.copy(this.cameraSetup.position);
    this.cameraLookAtPosition.copy(this.cameraSetup.lookAtPosition);
    this.camera.position.copy(this.cameraPosition);
    this.camera.lookAt(this.cameraLookAtPosition);
    this.camera.aspect = appState.viewport.width / appState.viewport.height;
    this.camera.updateProjectionMatrix();

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color('#000000');
    this.scene.add(this.camera);

    this.appState.visibleWidthOfCamera = this.getVisibleWidthOfCamera();

    appState.webGlCanvas.addEventListener(ShowroomEvent.Zoom, e => this.zoom(e.detail));
    appState.webGlCanvas.addEventListener(ShowroomEvent.ZoomTween, e => this.zoomTween(e.detail));
  }

  resize({ viewport, renderSize }: { viewport: Dimensions; renderSize: Dimensions }) {
    if (!this.appState.webGlCanvas || !this.renderer || !this.camera) {
      return;
    }
    this.camera.aspect = viewport.width / viewport.height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(renderSize.width, renderSize.height);
    this.appState.webGlCanvas.style.width = `${viewport.width}px`;
    this.appState.webGlCanvas.style.height = `${viewport.height}px`;
    this.cameraSetup = utils.getCameraSetupByViewportWidth(viewport.width);
  }

  getVisibleHeightAtZDepth(targetZ?: number) {
    if (!this.camera) {
      return 0;
    }
    const cameraPosZ = targetZ || this.zoomTargetPositionZ;

    // Certical fov in radians
    const vFOV = (this.camera.fov * Math.PI) / 180;

    // Math.abs to ensure the result is always positive
    // * By depth = camera pos z
    return 2 * Math.tan(vFOV / 2) * Math.abs(cameraPosZ);
  }

  getVisibleWidthOfCamera(targetZ?: number) {
    if (!this.camera) {
      return 0;
    }
    const height = this.getVisibleHeightAtZDepth(targetZ);
    return height * this.camera.aspect;
  }

  destroy() {
    this.renderer.forceContextLoss();
    this.renderer.dispose();
  }

  zoom(value: number) {
    if (!this.cameraSetup || !this.camera) {
      return;
    }
    this.zoomTargetPositionZ = this.cameraSetup.position.z * value;
    this.zoomValue = value;
    this.cameraPosition.set(
      this.cameraSetup.position.x,
      this.cameraSetup.position.y * this.zoomValue,
      this.cameraSetup.position.z * this.zoomValue
    );
    this.camera.position.copy(this.cameraPosition);
  }

  private zoomTween(value: number) {
    if (!this.cameraSetup || !this.camera) {
      return;
    }
    this.zoomTargetPositionZ = this.cameraSetup.position.z * value;
    const duration = window.innerWidth <= 480 ? 0.4 : 0.7;
    gsap.to(this, {
      zoomValue: value,
      duration,
      ease: 'power2.out',
      onUpdate: () => {
        this.camera.position.copy(this.cameraPosition);
      },
      onComplete: () => {
        sendEvent(this.appState.webGlCanvas, ShowroomEvent.ZoomTweenEnd, null);
      },
    });
  }

  update() {
    if (!this.renderer || !this.camera || !this.cameraSetup) {
      return;
    }
    this.appState.visibleWidthOfCamera = this.getVisibleWidthOfCamera();
    if (this.cameraSetup) {
      this.cameraPosition.set(
        this.cameraSetup.position.x,
        this.cameraSetup.position.y * this.zoomValue,
        this.cameraSetup.position.z * this.zoomValue
      );
      this.cameraLookAtPosition.copy(this.cameraSetup.lookAtPosition);
    }
  }
}
