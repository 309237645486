<template>
  <div
    ref="$category"
    v-editable="blok"
    class="category"
    :class="[isDark ? 'is-dark' : 'is-light', { 'has-bottom-line': hasBottomLine }]">
    <!-- Titles -->
    <div ref="$head" class="head" @click="handleClick">
      <div class="category-title">
        <div class="text-medium-regular">
          {{ blok.overline }}
        </div>
        <div class="heading-sub-small">
          {{ blok.headline }}
        </div>
      </div>
      <BaseIcon
        class="head-icon"
        :name="itemIsOpen ? 'fi_chevron-top' : 'fi_chevron-bottom'"
        :size="16"
        :color="isDark ? 'white' : 'black'" />
    </div>

    <!-- Items -->
    <ul v-if="blok.data_products?.length" class="items" :class="`has-${columns}-cols`">
      <li v-for="(item, index) in blok.data_products" :key="index">
        <BaseLink
          v-if="typeof item !== 'string' && item.content?.page"
          :link="item.content.page"
          class="item"
          :class="{ 'is-active': item.id === productDataActiveId }"
          @click="() => trackEvent({ event: 'flyoutProductClick', product: item.content.name })">
          <!-- Image -->
          <div class="category-image-container">
            <BaseImage
              v-if="item?.content?.image_quick_link?.filename"
              :image="item?.content.image_quick_link"
              :breakpointsWidthMap="{ 0: 80 }" />
          </div>

          <!-- Text -->
          <div class="item-container">
            <div class="item-text-container">
              <div class="action-medium-bold">
                {{ item?.content.name }}
              </div>
              <div class="item-text action-small-regular">
                {{ item?.content.text }}
              </div>
            </div>
            <UtilTag
              v-if="!!item?.content.type"
              :text="item?.content.serving_power"
              icon="fi_coffee"
              theme="soft"
              :isBackgroundDark="true" />
          </div>
        </BaseLink>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { gsap } from 'gsap';
import { onMounted, onUnmounted, ref } from 'vue';
import { BaseIcon, BaseImage, BaseLink } from '~/components/base';
import UtilTag from '~/components/storyblok/utils/UtilTag/UtilTag.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useGtmTracking } from '~/composables/useGtmTracking';
import type { BmHeaderRichQuickLinksStoryblok } from '~/types/storyblok-generated';

const props = withDefaults(
  defineProps<{
    blok: BmHeaderRichQuickLinksStoryblok;
    productDataActiveId?: number;
    isDark?: boolean;
    isOpen?: boolean;
    hasBottomLine?: boolean;
    columns?: number;
  }>(),
  { columns: 2 }
);

const { state: stateBreakpoint } = useBreakpoint();
const { trackEvent } = useGtmTracking();

const $category = ref<HTMLLIElement | null>(null);
const $head = ref<HTMLLIElement | null>(null);
const itemIsOpen = ref<boolean>(props.isOpen);

function handleClick() {
  if (stateBreakpoint.isTabletLarge) {
    return;
  }
  itemIsOpen.value = !itemIsOpen.value;
  if (itemIsOpen.value) {
    openItem();
  } else {
    closeItem();
  }
}

function openItem(isAnimated: boolean = true) {
  if (!$category.value) {
    return;
  }
  gsap.to($category.value, {
    height: 'auto',
    duration: isAnimated ? 0.3 : 0,
    ease: 'sine.out',
  });
}

function closeItem(isAnimated: boolean = true) {
  if (!$head.value) {
    return;
  }
  gsap.to($category.value as HTMLLIElement, {
    height: $head.value.clientHeight || 60,
    duration: isAnimated ? 0.3 : 0,
  });
}

function handleResize() {
  if (stateBreakpoint.isTabletLarge) {
    openItem(false);
    return;
  }
  if (props.isOpen) {
    openItem(false);
  } else {
    closeItem(false);
  }
}

onMounted(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style src="./BMHeaderRichQuickLinksCategory.scss" lang="scss" scoped />
