<template>
  <div
    class="util-tag action-xsmall-bold"
    :class="[theme === 'hard' ? 'is-hard' : 'is-soft', isBackgroundDark ? 'is-dark' : 'is-light']">
    <BaseIcon v-if="icon" :name="icon" :size="16" :color="iconColor" />
    <span v-if="text" class="text">
      {{ text }}
    </span>
  </div>
</template>

<script setup lang="ts">
import BaseIcon from '@/components/base/BaseIcon.vue';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    isBackgroundDark?: boolean;
    text: string;
    icon?: string;
    theme?: 'soft' | 'hard'; // Default is soft
  }>(),
  { theme: 'soft' }
);

const iconColor = computed(() =>
  (props.theme === 'hard' && props.isBackgroundDark) || (props.theme === 'soft' && !props.isBackgroundDark)
    ? 'black'
    : 'white'
);
</script>

<style src="./UtilTag.scss" lang="scss" scoped />
