<template>
  <div class="cm-media-card-wide">
    <!-- Main Card -->
    <CMMediaCard
      :item="item"
      mediaView="wide"
      :isDark="isDark"
      :shouldContentFadeIn="shouldCountentFadeIn"
      :youtubePrivacyWarning="youtubePrivacyWarning"
      class="card" />

    <!-- Desktop media only -->
    <div class="wrapper">
      <CMMediaCardBase
        :item="item"
        :youtubePrivacyWarning="youtubePrivacyWarning"
        mediaView="wide"
        class="card-media-21-9"
        @contentFadeIn="handleContentFadeIn" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import CMMediaCardBase from './CMMediaCardBase.vue';
import CMMediaCard from './CMMediaCard.vue';
import type { CmMediaItemStoryblok, RichtextStoryblok } from '~/types/storyblok-generated';
import { ref } from 'vue';

defineProps<{
  item: CmMediaItemStoryblok;
  isDark?: boolean;
  youtubePrivacyWarning: RichtextStoryblok;
}>();

const shouldCountentFadeIn = ref<boolean>(false);

function handleContentFadeIn() {
  shouldCountentFadeIn.value = true;
}
</script>

<style src="./CMMediaCardWide.scss" lang="scss" scoped />
