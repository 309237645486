uniform float u_intensity;
uniform sampler2D u_texture;
uniform sampler2D u_blueNoiseTexture;
uniform vec2 u_blueNoiseTextureSize;
uniform vec2 u_blueNoiseTextureOffset;

varying vec2 v_uv;



#include "../../../glsl/dithering.glsl"


void main()	{
    float shadow = texture2D(u_texture, v_uv).r;
    shadow *= u_intensity;
  
    vec3 shadowColor = shadow * vec3(0.0);

    float blueNoise = texture2D(u_blueNoiseTexture, gl_FragCoord.xy * u_blueNoiseTextureSize + u_blueNoiseTextureOffset ).b;
    shadowColor += blueNoise * 0.001;


    gl_FragColor =  vec4( shadowColor, shadow);
}
