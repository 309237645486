<template>
  <div ref="$el" v-editable="blok" class="lm-grid container-full">
    <!-- Top -->
    <div v-if="blok.head?.length" class="top">
      <ItemContentHead
        :blok="blok.head[0]"
        :isDark="blok.is_dark"
        :isVisibleOnLoad="isStorybookView"
        :headSize="blok.head_size"
        :staggerLines="true"
        class="head" />
    </div>

    <!-- Filters -->
    <LMGridFilters
      v-if="blok.filters_enabled"
      :machines="machines"
      :addOns="addOns"
      :isDark="blok.is_dark"
      class="filters"
      @onMachineSelect="onMachineSelect"
      @onAddonCategorySelect="onAddonCategorySelect"
      @scrollToTop="scrollToTop" />

    <!-- Grid -->
    <div ref="$grid" class="items">
      <ItemCardTeaser
        v-for="(item, index) in itemsShown"
        :key="getUniqueKey(item.headline, index)"
        class="item"
        :class="{ 'is-transparent': !isStorybookView }"
        :blok="item"
        :isDark="blok.is_dark" />
    </div>

    <!-- Load more button -->
    <p class="button-container" :class="{ 'is-hidden': !itemsLoadMoreShown }">
      <UtilButton
        :blok="itemsLoadMoreButton"
        :isBackgroundDark="blok.is_dark"
        class="load-more-button"
        @click="itemsLoadMore" />
    </p>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import type { ISbStoryData } from 'storyblok-js-client';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import ItemCardTeaser from '~/components/storyblok/item/ItemCardTeaser/ItemCardTeaser.vue';
import ItemContentHead from '~/components/storyblok/item/ItemContentHead/ItemContentHead.vue';
import LMGridFilters from '~/components/storyblok/list/LMGrid/LMGridFilters.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useLocale } from '~/composables/useLocale';
import { useProduct, type ItemCardTeaserProduct } from '~/composables/useProduct';
import { useScroll } from '~/composables/useScroll';
import { useStoryblokClient } from '~/composables/useStoryblokClient';
import { useTransition } from '~/composables/useTransition';
import type {
  DataAddOnStoryblok,
  DataProductStoryblok,
  LmGridStoryblok,
  UtilButtonStoryblok,
} from '~/types/storyblok-generated';

function unique(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index;
}

const props = defineProps<{ blok: LmGridStoryblok; isStorybookView?: boolean }>();

const $el = ref<HTMLDivElement | null>(null);
const $grid = ref<HTMLDivElement | null>(null);

const { getStories } = await useStoryblokClient();
const { transformMachineToItemCardTeaser, transformAddOnToItemCardTeaser } = useProduct();
const { state: stateBreakpoint } = useBreakpoint();
const { state: scrollState } = useScroll();
const { checkViewPortElements, addViewPortElements, removeViewPortElements, getUniqueKey } = useTransition();
const { locale } = useLocale();
const { t } = useI18n();

async function fetchMachines() {
  const machines = await getStories<DataProductStoryblok>({
    starts_with: locale || 'en_com',
    content_type: 'data_product',
  });
  return machines.value || [];
}

async function fetchAddOns() {
  const addOns = await getStories<DataAddOnStoryblok>({
    starts_with: locale || 'en_com',
    content_type: 'data_add-on',
  });
  return addOns.value || [];
}

const itemsLoadMoreButton = computed<UtilButtonStoryblok>(() => ({
  component: 'util_button',
  text: t('global.loadMore'),
  theme: 'secondary',
  size: 'large',
  _uid: 'items-load-more-button',
}));

const itemsLoadMoreShown = computed(() => {
  if (!props.blok.load_more_button_shown && !props.blok.filters_enabled) {
    return false;
  }
  return itemsShownCount.value < items.value?.length;
});

const itemsRowsShown = ref(props.blok.rows_shown ? parseInt(props.blok.rows_shown) || 2 : 2);
const itemsShownCount = computed(() => itemsInRow.value * itemsRowsShown.value);
const itemsShown = computed(() => items.value.slice(0, itemsShownCount.value));

const itemsInRow = computed(() => {
  if (stateBreakpoint.isTabletLarge) {
    return 4;
  }
  if (stateBreakpoint.isTablet) {
    return 3;
  }
  return 2;
});

function itemsLoadMore() {
  itemsRowsShown.value += 1;
}

const machines: ISbStoryData<DataProductStoryblok>[] = await fetchMachines();
const machinesCombined = [
  // Combine highlighted and fetched machines
  ...(props.blok.highlighted_machines?.map(id => machines.find(machine => machine.uuid === id)) || []),
  ...machines,
]
  .filter(machine => !!machine)
  .filter(unique);

const addOns: ISbStoryData<DataAddOnStoryblok>[] = await fetchAddOns();
const addOnsCombined = [
  // Combine highlighted and fetched add-ons
  ...(props.blok.highlighted_add_ons?.map(id =>
    addOns.find(addOn => (addOn).uuid === id)
  ) || []),
  ...addOns,
]
  .filter(addOn => !!addOn)
  .filter(unique);

/**
 * Filters the addons by the selected machine and add-on categories
 */
function filterAddons(addonsToFilter: ISbStoryData<DataAddOnStoryblok>[]) {
  let _filteredAddons = addonsToFilter;

  // Filter all add-ons by selected machine's add-ons
  if (!!machineSelected.value && !!machineSelected.value.content.add_ons) {
    _filteredAddons = machineSelected.value.content.add_ons
      ?.map(addOnId => {
        return _filteredAddons.find(addOn => addOn.uuid === addOnId) as ISbStoryData<DataAddOnStoryblok>;
      })
      .filter(addOn => !!addOn);
  }

  // Filter all add-ons by selected add-on categories
  if (addOnCategoriesSelectedIds.value?.length > 0) {
    _filteredAddons = _filteredAddons.filter(item =>
      addOnCategoriesSelectedIds.value.includes(item.content.category as string)
    );
  }

  return _filteredAddons;
}

/**
 * Filters the machines based on the selected add-on categories and machine filter
 */
function filterMachines(machinesToFilter: ISbStoryData<DataProductStoryblok>[]): ISbStoryData<DataProductStoryblok>[] {
  if (machineSelected.value) {
    // A specific machine is selected, so we return only that machine
    return [machineSelected.value];
  }

  let _filteredMachines = machinesToFilter;

  if (addOnCategoriesSelectedIds.value?.length > 0) {
    // We are filtering by addons, so we also filter the machines to only show the ones that have addons with the selected categories
    _filteredMachines = _filteredMachines.filter(machine => {
      return machine.content.add_ons?.some(addOnId => {
        const addOn = addOnsCombined.find(addOn => addOn.uuid === addOnId);
        return addOnCategoriesSelectedIds.value.includes(addOn?.content.category as string);
      });
    });
  }

  return _filteredMachines;
}

/**
 * The items with all their filters applied
 */
const items = computed<ItemCardTeaserProduct[]>(() => {
  let filteredAddons: ISbStoryData<DataAddOnStoryblok>[] = [];
  let filteredMachines: ISbStoryData<DataProductStoryblok>[] = [];
  const cardsPreset = props.blok.type === 'planner-data' ? 'planner-data' : undefined;

  // Filter the addons
  if (props.blok.type === 'planner-data' || props.blok.type === 'add-ons') {
    filteredAddons = filterAddons(addOnsCombined);
  }

  // Filter the machines
  if (props.blok.type === 'planner-data' || props.blok.type === 'machines') {
    filteredMachines = filterMachines(machinesCombined);
  }

  if (!machineSelected.value && addOnCategoriesSelectedIds.value?.length > 0) {
    // We show addons first if the user filters by add-ons only
    return [
      ...filteredAddons.map(a => transformAddOnToItemCardTeaser(a, cardsPreset)),
      ...filteredMachines.map(m => transformMachineToItemCardTeaser(m, cardsPreset)),
    ];
  } else {
    // Otherweise we show the machines first
    return [
      ...filteredMachines.map(m => transformMachineToItemCardTeaser(m, cardsPreset)),
      ...filteredAddons.map(a => transformAddOnToItemCardTeaser(a, cardsPreset)),
    ];
  }
});

const machineSelectedId = ref<string | null>(null);
const machineSelected = computed(() => {
  return machines.find(machine => machine.uuid === machineSelectedId.value);
});
function onMachineSelect(id: string | null) {
  machineSelectedId.value = id;
}

const addOnCategoriesSelectedIds = ref<string[]>([]);
function onAddonCategorySelect(ids: string[]) {
  addOnCategoriesSelectedIds.value = ids;
}

async function scrollToTop() {
  // Scroll to the top of $grid.value
  if ($grid.value) {
    window.scrollTo({
      top: $grid.value.getBoundingClientRect().top + window.scrollY - 110,
      behavior: 'smooth',
    });
  }
}

function watchPageScroll() {
  if ($el.value) {
    checkViewPortElements($el.value);
  }
}

function parseAnimatedItems() {
  if (!$el.value) {
    return;
  }

  /* Card items which can change */
  addViewPortElements($el.value.querySelectorAll('.filters'));
  addViewPortElements($el.value.querySelectorAll('.item'));
  if (itemsLoadMoreShown.value) {
    addViewPortElements($el.value.querySelectorAll('.button-container'));
  }
  watchPageScroll();
}

watch(
  () => itemsShown.value,
  () => {
    nextTick(parseAnimatedItems);
  }
);

watch(() => scrollState.top, watchPageScroll);

onBeforeUnmount(() => {
  if ($el.value) {
    removeViewPortElements($el.value);
  }
});

onMounted(() => {
  nextTick(parseAnimatedItems);
});
</script>

<style src="./LMGrid.scss" lang="scss" scoped />
