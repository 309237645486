<template>
  <BaseLink
    :link="{
      id: article.uuid,
      url: article.full_slug,
      cached_url: article.full_slug,
      fieldtype: 'multilink',
      linktype: 'story',
      story: article as any,
    }"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave">
    <div class="card">
      <div class="cover-image">
        <BaseImage :image="image" :breakpointsWidthMap="breakpointsWidthMap" :heightRatio="imageRatio" />
      </div>
      <div class="text-container">
        <div>
          <p class="heading-sub-small">{{ article.content.card_title }}</p>
          <div v-if="(article.content.tags ?? []).length > 0" class="tags-container">
            <UtilTag
              v-for="(tag, index) in article.content.tags"
              :key="index"
              :text="t(`dataSources.lm-grid-industries-tags.${tag}`)"
              :isBackgroundDark="isDark" />
          </div>
        </div>
        <UtilButton
          :blok="{
            component: 'util_button',
            size: 'large',
            theme: 'secondary',
            _uid: 'cta',
            icon: 'fi_arrow-right',
          }"
          isFakeButton
          :isHovered="isHovered"
          :isBackgroundDark="false" />
      </div>
    </div>
  </BaseLink>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import { computed, ref } from 'vue';
import { BaseImage } from '~/components/base';
import BaseLink from '~/components/base/BaseLink.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import UtilTag from '~/components/storyblok/utils/UtilTag/UtilTag.vue';
import type { LMGridIndustriesCardProps } from './LMGridIndustries.types';

const props = defineProps<LMGridIndustriesCardProps>();

const isHovered = ref(false);
const { t } = useI18n();

const image = computed(() => {
  if (props.imageRatio[0] === 16 && props.imageRatio[1] === 9 && props.article.content.card_image_16_9?.filename) {
    return props.article.content.card_image_16_9;
  }
  if (props.imageRatio[0] === 3 && props.imageRatio[1] === 2 && props.article.content.card_image_3_2?.filename) {
    return props.article.content.card_image_3_2;
  }
  if (props.imageRatio[0] === 1 && props.imageRatio[1] === 1 && props.article.content.card_image_1_1?.filename) {
    return props.article.content.card_image_1_1;
  }
  if (props.imageRatio[0] === 3 && props.imageRatio[1] === 4 && props.article.content.card_image_3_4?.filename) {
    return props.article.content.card_image_3_4;
  }
  return props.article.content.card_image;
});

function handleMouseEnter() {
  isHovered.value = true;
}

function handleMouseLeave() {
  isHovered.value = false;
}
</script>

<style src="./LMGridIndustriesCard.scss" lang="scss" scoped />
