<template>
  <div
    v-editable="blok"
    class="cm-highlights"
    :data-cursor="
      JSON.stringify({
        cursor: 'pointer',
        button: buttonBlok,
      })
    ">
    <div v-if="blok.add_fade" class="mobile-fade"></div>
    <div class="items">
      <div ref="$wrapper" class="items-wrapper">
        <div ref="$itemContainer" class="items-container" :class="{ 'is-moved-down': textIsMovedDown }">
          <div
            v-for="(item, index) in blok.items"
            :key="index"
            ref="$items"
            class="item"
            :style="{
              top: stateBreakpoint.isTabletLarge ? `${index * 50}%` : 0,
            }"
            :class="{
              'item--visible': activeIndex === index || stateBreakpoint.isTabletLarge,
            }">
            <h2 class="item-headline" :class="blok.is_dark ? 'is-dark' : 'is-light'">{{ item.headline }}</h2>
            <p class="item-text" :class="blok.is_dark ? 'is-dark' : 'is-light'">{{ item.text }}</p>
            <div class="item-rect"></div>
            <UtilButton
              v-if="(!stateBreakpoint.isTabletLarge || isTouch) && buttonBlok"
              class="item-button"
              :blok="buttonBlok"
              @click="onVideoClick(item.video)" />
          </div>
        </div>
        <div class="overlay" @click="onVideoClick(blok.items?.[activeIndex].video)"></div>
      </div>
    </div>
    <div ref="$media" class="media">
      <template v-if="isImageEnabled">
        <div v-for="(item, index) in blok.items" ref="$images" :key="index" class="images">
          <BaseImage
            v-if="item.image?.filename && item.image_mobile?.filename"
            class="image"
            :src="stateBreakpoint.isTabletLarge ? item.image?.filename : item.image_mobile.filename"
            :alt="stateBreakpoint.isTabletLarge ? (item.image?.alt ?? '') : (item.image_mobile?.alt ?? '')"
            :breakpointsWidthMap="{ 0: 2000 }"
            :objectCover="true" />
        </div>
      </template>
      <UtilVideoScrub
        v-if="blok.video_scrub?.length"
        :blok="blok.video_scrub[0]"
        :progress="state.progress"
        :canBeInitialized="scrollState.activeModuleIndex >= state.index - 2" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap';
import { computed, ref, watch } from 'vue';
import BaseImage from '~/components/base/BaseImage.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import UtilVideoScrub from '~/components/storyblok/utils/UtilVideoScrub/UtilVideoScrub.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useModalVideo } from '~/composables/useModalVideo';
import { useScroll } from '~/composables/useScroll';
import type { AssetStoryblok, CmHighlightsStoryblok, UtilButtonStoryblok } from '~/types/storyblok-generated';
import type { ModuleWrapperState } from '~/types/utils';

const props = defineProps<{
  state: ModuleWrapperState;
  blok: CmHighlightsStoryblok;
}>();

// In case the video is not enabled, show the images from the items
const isImageEnabled = computed(() => !props.blok.video_scrub?.length);

const { state: stateBreakpoint, isTouch } = useBreakpoint();
const { state: stateModalVideo } = useModalVideo();
const { state: scrollState } = useScroll();

const $wrapper = ref<HTMLElement>();
const $media = ref<HTMLElement>();
const $images = ref<HTMLElement[]>([]);
const $items = ref<HTMLElement[]>([]);
const $itemContainer = ref<HTMLElement[]>([]);

const buttonBlok = computed((): UtilButtonStoryblok | null => {
  const buttonText = props.blok.items?.[activeIndex.value]?.button_text;
  if (!buttonText) {
    return null;
  }
  return {
    icon: 'fi_play',
    size: 'large',
    text: buttonText,
    theme: 'primary',
    component: 'util_button',
    _uid: 'button',
  };
});

const appearPercentages = computed(() => props.blok.items?.map(item => parseInt(item.appear_percentage)));

function onVideoClick(video?: AssetStoryblok) {
  if (video?.filename) {
    stateModalVideo.value = { type: 'media', filename: video?.filename };
  }
}

const activeIndex = computed(() => {
  const _progress = props.state.progress * 100;

  let activeIndex = 0;
  if (!props.blok.items) {
    return activeIndex;
  }

  // In case of images are shown instead of video, calculate the active index based on the progress divided by the number of items
  if (isImageEnabled.value) {
    return Math.floor(_progress / (100 / props.blok.items?.length));
  }

  // Find the active index based on the appear percentage
  if (appearPercentages.value) {
    for (let i = 0; i < appearPercentages.value?.length; i++) {
      if (_progress >= appearPercentages.value[i]) {
        activeIndex = i;
      }
    }
  }

  return activeIndex;
});

watch(
  () => props.state.inViewport,
  () => {
    // Scroll up
    if (props.state.inViewport && $wrapper.value && $media.value) {
      // Animate video and items in when active
      gsap.to($wrapper.value, { autoAlpha: 1, ease: 'expo.out' });
      gsap.to($media.value, { autoAlpha: 1 });
      if (isImageEnabled.value && $images.value[activeIndex.value]) {
        gsap.to($images.value[activeIndex.value], {
          autoAlpha: 1,
        });
      }
    }
  }
);

watch(
  () => props.state.active,
  () => {
    if (props.state.active) {
      // Animate video and items in when active
      if ($wrapper.value && $media.value) {
        gsap.to($wrapper.value, {
          autoAlpha: 1,
          y: 0,
          duration: 2,
          ease: 'expo.out',
        });
        gsap.to($media.value, { autoAlpha: 1 });
      }
      if (isImageEnabled.value && $images.value[activeIndex.value]) {
        gsap.to($images.value[activeIndex.value], {
          autoAlpha: 1,
        });
      }
      void animateItem(true);
    } else {
      // Animate video and items out when not active
      if ($wrapper.value && $media.value) {
        gsap.to($media.value, { autoAlpha: 0.5 }); //Should stay visible
        gsap.to($items.value, {
          overwrite: true,
          duration: 1,
          ease: 'quint.out',
          x: 0,
          filter: 'blur(20px)',
          autoAlpha: 0,
        });
      }
      if (isImageEnabled.value) {
        gsap.to($images.value[0], {
          duration: 1,
          autoAlpha: 0,
        });
      }
    }
  }
);

watch(
  () => activeIndex.value,
  () => {
    void animateItem();
  }
);

watch(
  () => props.state.progress,
  () => {
    if (!isImageEnabled.value) {
      return;
    }

    const $image = $images.value[activeIndex.value];

    // Calculate the relative progress of the active item between 0 and 1
    const _activeIndex = activeIndex.value;
    const _progress = props.state.progress * 100;
    const _itemsLength = props.blok.items?.length;
    const relativeProgressActive = _itemsLength ? _progress / (100 / _itemsLength) - _activeIndex : 0;

    const scale = relativeProgressActive * 0.2 + 1;

    // Zoom while progress is changing
    if (isImageEnabled.value) {
      gsap.to($image, {
        scale,
        duration: 1,
        ease: 'quint.out',
      });
    }
  }
);

const animateImage = async (initial?: boolean) => {
  // Images
  const $image = $images.value[activeIndex.value];

  if (initial) {
    gsap.to($image, {
      overwrite: true,
      x: 0,
      duration: 1,
      ease: 'quint.out',
      autoAlpha: 1,
    });
  } else {
    await gsap.to($images.value, {
      overwrite: true,
      duration: 0.7,
      ease: 'sine.inOut',
      y: 0,
      x: 0,
      autoAlpha: 0,
    });

    // Show the active point
    gsap.to($image, {
      overwrite: true,
      x: 0,
      duration: 1,
      ease: 'quint.out',
      autoAlpha: 1,
    });
  }
};

const animateItem = async (initial?: boolean) => {
  const $item = $items.value[activeIndex.value];

  if (isImageEnabled.value) {
    void animateImage(initial);
  }

  // Animate for mobile
  if (!stateBreakpoint.isTabletLarge) {
    gsap.set($itemContainer.value, { yPercent: 0 });

    // If it's the first time, just show the active point
    if (initial) {
      gsap.to($item, {
        overwrite: true,
        x: 0,
        duration: 1,
        ease: 'quint.out',
        filter: 'blur(0px)',
        autoAlpha: 1,
      });
    } else {
      // Animate the items wrapping container
      await gsap.to($items.value, {
        overwrite: true,
        duration: 0.7,
        ease: 'sine.inOut',
        y: 0,
        x: 0,
        filter: 'blur(20px)',
        autoAlpha: 0,
      });

      // Show the active point
      gsap.to($item, {
        overwrite: true,
        x: 0,
        duration: 1,
        ease: 'quint.out',
        filter: 'blur(0px)',
        autoAlpha: 1,
      });
    }
  } else {
    // Animate for desktop
    const MINUS_FIVTY_PERCENT = -50;
    const targetPos = MINUS_FIVTY_PERCENT * (activeIndex.value - 1);
    gsap.to($itemContainer.value, {
      duration: initial ? 0 : 1.2,
      ease: 'quint.out',
      yPercent: targetPos,
    });
    gsap.to($items.value, {
      overwrite: true,
      duration: initial ? 0 : 2,
      y: `${MINUS_FIVTY_PERCENT}%`,
      ease: 'quint.out',
      filter: 'blur(10px)',
      autoAlpha: 0,
    });
    gsap.to($item, {
      duration: 2,
      ease: 'quint.out',
      filter: 'blur(0px)',
      z: 0,
      autoAlpha: 1,
    });
  }
};

/**
 * Move the text down on mobile when scrolling up
 */
const textIsMovedDown = ref(false);
watch(
  () => scrollState.velocity < 0,
  () => (textIsMovedDown.value = scrollState.velocity < 0)
);
</script>

<style src="./CMHighlights.scss" lang="scss" scoped />
