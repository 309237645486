import { useTransition } from '~/composables/useTransition';

export function useIconAnimation() {
  const { morphIcon } = useTransition();

  interface IconData {
    iconName: string | null | undefined;
    iconElement: HTMLElement | null;
  }

  function getIcon(target: HTMLElement | null): IconData | null {
    if (!target) {
      return null;
    }

    const iconElement: HTMLElement | null = target.querySelector('.nuxt-icon');
    const iconName: string | null | undefined = iconElement?.getAttribute('iconname');

    return {
      iconName,
      iconElement,
    };
  }

  function onIconMouseOver(e: PointerEvent | MouseEvent): void {
    const target = e.target as HTMLElement | null;
    const icon = getIcon(target);

    if (icon?.iconName && icon.iconElement) {
      morphIcon(icon.iconName, icon.iconElement, true);
    }
  }

  function onIconMouseOut(e: PointerEvent | MouseEvent): void {
    const target = e.target as HTMLElement | null;
    const icon = getIcon(target);

    if (icon?.iconName && icon.iconElement) {
      morphIcon(icon.iconName, icon.iconElement, false);
    }
  }

  return {
    onIconMouseOver,
    onIconMouseOut,
  };
}
