/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import type { MetaObject } from '@nuxt/schema';
import { useRuntimeConfig } from 'nuxt/app';
import type { ISbStoryData } from 'storyblok-js-client';
import { computed } from 'vue';
import { normalizeUrl } from '~/utils/url';
import { LOCALE_TO_HREFLANG_MAP, type Locale, type LocaleHreflang } from '~/types/locales';
import type { CorePageStoryblok } from '~/types/storyblok-generated';

/**
 * Creates SEO meta tags for the given story.
 * @param story The current story
 * @returns The according SEO meta tags
 */
export function createSeoMetaTags(locale: Locale, story: ISbStoryData<CorePageStoryblok>): MetaObject {
  const config = useRuntimeConfig();
  if (config.public.IS_HEADER_FOOTER_EXPORT) {
    return {};
  }

  const localeHreflang = LOCALE_TO_HREFLANG_MAP[locale];
  const url = config.public.HOST_URL + normalizeUrl(story.full_slug);

  const getAlternateLinks = computed(() => {
    const links: {
      rel: string;
      href: string;
      hreflang: LocaleHreflang | 'x-default';
    }[] = story.alternates?.length
      ? [
          {
            rel: 'alternate',
            href: url,
            hreflang: localeHreflang,
          },
          ...story.alternates
            .filter(item => !!item.name)
            .map(item => {
              const slugLocale = item.full_slug.split('/')[0];
              const locale = config.public.LOCALES.includes(slugLocale) ? (slugLocale as Locale) : null;

              if (!locale) {
                return null;
              }

              const isLocaleEnabled = config.public.LOCALES_ENABLED.includes(locale);
              if (isLocaleEnabled === false) {
                return;
              }

              return {
                rel: 'alternate',
                href: `${config.public.HOST_URL}${normalizeUrl(item.full_slug)}`,
                hreflang: LOCALE_TO_HREFLANG_MAP[locale],
              };
            })
            .filter(item => !!item),
        ]
      : [];

    links.push({
      rel: 'alternate',
      href: config.public.HOST_URL,
      hreflang: 'x-default',
    });

    return links.filter(item => !!item.href);
  });

  const image = story?.content.seo_meta?.og_image || `${config.public.HOST_URL}/brand/open-graph-image.png`;
  const title = story?.content.seo_meta?.title || `${story.name} | ${config.public.META_TITLE_FALLBACK}`;

  return {
    htmlAttrs: {
      lang: localeHreflang,
    },
    title,
    meta: [
      story.content.seo_nofollow || story.content.seo_noindex
        ? {
            name: 'robots',
            content: [story.content.seo_nofollow ? 'nofollow' : '', story.content.seo_noindex ? 'noindex' : '']
              .filter(item => !!item)
              .join(', '),
          }
        : {},
      {
        hid: 'og:url',
        name: 'og:url',
        property: 'og:url',
        content: url,
      },
      {
        hid: 'description',
        name: 'description',
        property: 'description',
        content: story?.content.seo_meta?.description,
      },
      {
        hid: 'og:image',
        name: 'og:image',
        property: 'og:image',
        content: image,
      },
      {
        hid: 'og:description',
        name: 'og:description',
        property: 'og:description',
        content: story?.content.seo_meta?.og_description || story?.content.seo_meta?.description,
      },
      {
        hid: 'og:title',
        name: 'og:title',
        property: 'og:title',
        content: story?.content.seo_meta?.og_title || story?.content.seo_meta?.title,
      },
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        property: 'twitter:title',
        content: story?.content.seo_meta?.twitter_title || story?.content.seo_meta?.title,
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        property: 'twitter:description',
        content: story?.content.seo_meta?.twitter_description || story?.content.seo_meta?.description,
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        property: 'twitter:image',
        content: story?.content.seo_meta?.twitter_image || image,
      },
      {
        hid: 'twitter:card',
        name: 'twitter:card',
        property: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        name: 'theme-color',
        content: '#0D0D0D',
      },
    ].filter(item => !!item?.content),
    // If the story is set to noindex, we don't want to add any canonical and alternate links
    link: story.content.seo_noindex
      ? []
      : [
          {
            rel: 'canonical',
            href: story.content.seo_canonical || url,
          },
          ...getAlternateLinks.value,
        ],
  };
}
