<template>
  <video
    ref="$video"
    class="base-video-scrub is-video"
    muted
    playsinline
    :poster="poster ?? undefined"
    :src="scrubMode === 'video' && src ? src : undefined"
    :preload="!props.canBeInitialized || scrubMode !== 'video' ? 'metadata' : 'auto'"
    :style="{ display: scrubMode === 'webCodecsApi' ? 'none' : undefined }"
    @timeupdate="onTimeUpdate"
    @canplaythrough="handleCanPlayThrough"></video>
  <canvas
    ref="$canvas"
    class="base-video-scrub is-canvas"
    :style="{ display: scrubMode === 'video' ? 'none' : undefined }">
  </canvas>
</template>

<script setup lang="ts">
import { gsap } from 'gsap';
import { nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { hasWebCodecsSupport, WebCodecsScrubber } from '~/utils/video-scrolling';

const props = withDefaults(
  defineProps<{
    progress?: number;
    src?: string | null;
    poster?: string | null;
    density?: number;
    smooth?: boolean;
    isDisabled?: boolean;
    canBeInitialized: boolean;
  }>(),
  {
    progress: 0,
    density: 1,
    smooth: false,
  }
);

const emit = defineEmits<{ (e: 'load'): void }>();

const { isTouch } = useBreakpoint();
const $video = ref<HTMLVideoElement | null>(null);
const $canvas = ref<HTMLCanvasElement | null>(null);
const webCodecsScrubberInstance = ref<WebCodecsScrubber | null>(null);
const videoProgress = ref(0);
const scrubMode = ref<'webCodecsApi' | 'video' | null>(null);
const isInitialized = ref(false);

function onTimeUpdate() {
  if (!$video.value) {
    return;
  }
  $video.value.pause();
}

function updateProgress() {
  const diff = videoProgress.value - props.progress;
  const baseSpeed = isTouch.value ? 0.5 : 1;

  let duration = diff > 0 ? diff * 1 : diff * -1;
  duration = props.smooth ? baseSpeed : duration / props.density;

  if (props.isDisabled) {
    duration = 0;
  }

  // Regular tween gets kidnapped by ease tween if ease prop is set
  gsap.to(videoProgress, {
    value: props.progress,
    duration,
    ease: props.smooth ? 'sine.out' : 'none',
  });
}

function initVideo() {
  if (isInitialized.value || !props.canBeInitialized || !$video.value || !$canvas.value || !props.src) {
    return;
  }
  isInitialized.value = true;
  if (scrubMode.value === 'webCodecsApi') {
    webCodecsScrubberInstance.value = new WebCodecsScrubber({
      src: props.src,
      videoEl: $video.value,
      canvasEl: $canvas.value,
      onReady: () => emit('load'),
    });
  }
  if (scrubMode.value === 'video') {
    $video.value.load();
  }
  $video.value.pause();
}

async function handleCanPlayThrough() {
  if (scrubMode.value === 'video' && $video.value) {
    await $video.value.play();
    requestAnimationFrame(() => {
      $video.value?.pause();
    });
    emit('load');
  }
}

watch(
  () => props.isDisabled,
  () => {
    if (props.isDisabled) {
      gsap.killTweensOf(videoProgress);
    }
  }
);

watch(
  () => props.progress,
  () => {
    void nextTick(updateProgress);
  }
);

watch(
  () => videoProgress.value,
  async () => {
    if (scrubMode.value === 'video' && $video.value) {
      if (isNaN($video.value.duration)) {
        return;
      }
      $video.value.currentTime = $video.value.duration * videoProgress.value;
      if (videoProgress.value !== 1) {
        await $video.value.play();
        $video.value.pause();
      }
    } else if (scrubMode.value === 'webCodecsApi' && webCodecsScrubberInstance.value) {
      webCodecsScrubberInstance.value.setProgress(props.progress);
    }
  }
);

watch(
  () => props.src,
  () => {
    if (scrubMode.value === 'webCodecsApi' && webCodecsScrubberInstance.value) {
      webCodecsScrubberInstance.value.destroy();
    }
    isInitialized.value = false;
    initVideo();
  }
);

watch(() => props.canBeInitialized, initVideo);

onUnmounted(() => {
  gsap.killTweensOf(videoProgress);
  webCodecsScrubberInstance.value?.destroy();
  webCodecsScrubberInstance.value = null;
});

onMounted(() => {
  if (import.meta.client) {
    scrubMode.value = hasWebCodecsSupport() ? 'webCodecsApi' : 'video';
    void nextTick(initVideo);
  }
});
</script>

<style src="./BaseVideoScrub.scss" lang="scss" scoped />
