<template>
  <div class="em-home-column">
    <EMHomeCard
      v-if="medium_cards[0]"
      :frontSideOverline="medium_cards[0].front_side_overline"
      :frontSideHeadline="medium_cards[0].front_side_headline"
      :frontSideSubline="medium_cards[0].front_side_subline"
      :frontSideImage="
        breakpointState.isPhoneLarge
          ? medium_cards[0].front_side_image_desktop
          : medium_cards[0].front_side_image_mobile
      "
      :frontSideVideo="
        breakpointState.isPhoneLarge
          ? medium_cards[0].front_side_video_desktop
          : medium_cards[0].front_side_video_mobile
      "
      :link="medium_cards[0].link"
      :backSideText="medium_cards[0].back_side_text"
      :backSideCta="medium_cards[0].back_side_cta?.[0]"
      horizontalTextAlign="center"
      :verticalTextAlign="medium_cards[0].vertical_text_align"
      :isDark="!!medium_cards[0].is_dark"
      :textLayout="medium_cards[0].text_layout"
      :interactivity="medium_cards[0].interactivity"
      cardType="medium"
      class="medium-card"
      :isFlipped="flippedCardUid === medium_cards[0]._uid"
      :uid="medium_cards[0]._uid"
      @flip="emit('flip', medium_cards[0]._uid)" />
    <EMHomeCard
      v-if="medium_cards[1]"
      :frontSideOverline="medium_cards[1].front_side_overline"
      :frontSideHeadline="medium_cards[1].front_side_headline"
      :frontSideSubline="medium_cards[1].front_side_subline"
      :frontSideImage="
        breakpointState.isPhoneLarge
          ? medium_cards[1].front_side_image_desktop
          : medium_cards[1].front_side_image_mobile
      "
      :frontSideVideo="
        breakpointState.isPhoneLarge
          ? medium_cards[1].front_side_video_desktop
          : medium_cards[1].front_side_video_mobile
      "
      :link="medium_cards[1].link"
      :backSideText="medium_cards[1].back_side_text"
      :backSideCta="medium_cards[1].back_side_cta?.[0]"
      horizontalTextAlign="center"
      :verticalTextAlign="medium_cards[1].vertical_text_align"
      :isDark="!!medium_cards[1].is_dark"
      :textLayout="medium_cards[1].text_layout"
      :interactivity="medium_cards[1].interactivity"
      cardType="medium"
      class="medium-card"
      :isFlipped="flippedCardUid === medium_cards[1]._uid"
      :uid="medium_cards[1]._uid"
      @flip="emit('flip', medium_cards[1]._uid)" />
  </div>
</template>

<script lang="ts" setup>
import { useBreakpoint } from '~/composables/useBreakpoint';
import type { EmHomeCol2Medium } from './EMHome.types';
import EMHomeCard from './EMHomeCard.vue';

defineProps<EmHomeCol2Medium>();

const emit = defineEmits<{ (e: 'flip', id: string): void }>();

const { state: breakpointState } = useBreakpoint();
</script>

<style lang="scss" scoped>
.em-home-column {
  display: grid;
  grid-gap: 8px;
  height: 100%;
  width: 100%;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
  @media (min-width: 768px) {
    gap: 16px;
  }
  @media (min-width: 1024px) {
    gap: 24px;
  }
}
</style>
