<template>
  <BaseModal :isDark="isDark" :isOpen="isOpen" class="quick-links-modal" animationType="custom">
    <div class="bm-header-rich-quick-links" :class="isDark ? 'is-dark' : 'is-light'">
      <div class="links-container links-container-header">
        <div class="header-content">
          <!-- logos -->
          <div class="logo">
            <RouterLink :to="`/${config.public.IS_HEADER_FOOTER_EXPORT ? '' : locale}/`" aria-label="home" class="link">
              <BaseImage :image="logo" :breakpointsWidthMap="{ 0: 26, 1024: 32 }" />
            </RouterLink>
          </div>

          <!-- Close icon -->
          <button
            class="link close-button"
            :class="isDark ? 'is-dark' : 'is-light'"
            :aria-label="t('global.closeModal')"
            @click="emit('close')">
            <BaseIcon name="fi_x" :size="16" color="inherit" />
          </button>
        </div>
      </div>

      <div class="content">
        <UtilScrollable>
          <div class="links-container">
            <div class="content-wrapper">
              <!--Categories -->
              <ul class="content-categories" :class="{ 'is-span-2': !cards?.length }">
                <li
                  v-for="(item, i) in quickLinkItems"
                  :key="i"
                  ref="$contentCategoriesLists"
                  class="content-categories-list">
                  <BMHeaderRichQuickLinksCategory
                    :blok="item"
                    :productDataActiveId="productDataActiveId"
                    :isDark="isDark"
                    :hasBottomLine="i !== (quickLinkItems?.length ?? 0) - 1"
                    :isOpen="
                      item.data_products?.some(
                        product => typeof product !== 'string' && product.id === productDataActiveId
                      ) ?? i === 0
                    "
                    :columns="cards?.length ? 2 : 4" />
                </li>
              </ul>

              <!-- Cards -->
              <div v-if="cards" class="content-cards">
                <ul class="content-cards-list">
                  <li v-for="(card, i) in cards" :key="i" ref="$contentCardsLists" class="content-cards-list-entry">
                    <BMHeaderRichQuickLinksCard :blok="card" :isDark="isDark" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </UtilScrollable>
      </div>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import { useI18n, useRuntimeConfig } from '#imports';
import { ref, watch } from 'vue';
import { RouterLink } from 'vue-router';
import { BaseIcon, BaseImage, BaseModal } from '~/components/base';
import BMHeaderRichQuickLinksCard from '~/components/storyblok/basic/BMHeaderRich/BMHeaderRichQuickLinksCard.vue';
import BMHeaderRichQuickLinksCategory from '~/components/storyblok/basic/BMHeaderRich/BMHeaderRichQuickLinksCategory.vue';
import UtilScrollable from '~/components/storyblok/utils/UtilScrollable/UtilScrollable.vue';
import { useLocale } from '~/composables/useLocale';
import { useTransition } from '~/composables/useTransition';
import type {
  AssetStoryblok,
  BmHeaderRichCardStoryblok,
  BmHeaderRichQuickLinksStoryblok,
} from '~/types/storyblok-generated';

const props = defineProps<{
  isDark?: boolean;
  logo: AssetStoryblok;
  quickLinkItems?: BmHeaderRichQuickLinksStoryblok[];
  productDataActiveId?: number;
  cards?: BmHeaderRichCardStoryblok[];
  isOpen: boolean;
}>();

const emit = defineEmits<{ (e: 'close'): void }>();

const { fadeSlide } = useTransition();
const { t } = useI18n();
const $contentCategoriesLists = ref<HTMLElement[]>([]);
const $contentCardsLists = ref<HTMLElement[]>([]);
const config = useRuntimeConfig();
const { locale } = useLocale();

watch(
  () => props.isOpen,
  () => {
    if (props.isOpen) {
      fadeSlide($contentCategoriesLists.value, {
        baseDelay: 0.5,
        staggerEach: 0.2,
        offsetMultiplier: 3,
      });
      fadeSlide($contentCardsLists.value, { baseDelay: 1, staggerEach: 0.2, offsetMultiplier: 3 });
    }
  }
);
</script>

<style src="./BMHeaderRichQuickLinks.scss" lang="scss" scoped />
