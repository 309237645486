<template>
  <div
    ref="$list"
    class="dropdown-list"
    :class="[
      isDark ? 'is-dark' : 'is-light',
      isSmall ? 'is-small' : 'is-large',
      {
        'is-open': isOpen,
        'is-floating': isFloating,
        'is-showroom-style': isShowroomStyle,
      },
    ]">
    <!-- machine with sections -->
    <template v-for="(option, index) in options" :key="option.name">
      <div
        v-if="option.type === 'categoryTitle'"
        class="dropdown-category-title action-xsmall-regular"
        :class="{ 'is-first': index === 0 }">
        {{ option.name }}
      </div>
      <button
        v-else-if="option.type === 'option'"
        class="dropdown-option action-medium-bold"
        :class="{ 'is-selected': selectedOption?.value === option.value, 'is-first': index === 0 }"
        @click="() => emit('select', option, uniqueId)">
        {{ option.name }}
      </button>
    </template>

    <div class="dropdown-reset-container">
      <span class="dropdown-reset-line"></span>
      <button class="dropdown-reset-button" :disabled="!selectedOption" @click="emit('reset', uniqueId)">
        <span>{{ t('forms.resetSelection') }}</span>
        <BaseIcon name="fi_rotate-ccw" :size="16" color="inherit" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import gsap from 'gsap';
import { ref, watch } from 'vue';
import { BaseIcon } from '~/components/base';
import { useGsap } from '~/composables/useGsap';
import type { InternalCategoryTitle, InternalOption, ItemDropdownOption } from './ItemDropdown.types';
import { useI18n } from '#imports';

const props = defineProps<{
  isDark: boolean;
  isSmall: boolean;
  isShowroomStyle: boolean;
  isOpen: boolean;
  selectedOption: ItemDropdownOption | null;
  uniqueId?: string;
  isFloating: boolean;
  options: (InternalOption | InternalCategoryTitle)[];
}>();

const emit = defineEmits<{
  (e: 'select', option: ItemDropdownOption, id?: string): void;
  (e: 'reset', id?: string): void;
  (e: 'tween'): void;
}>();

defineOptions({ inheritAttrs: false });

const $list = ref<HTMLElement>();

const { easeOut } = useGsap();
const { t } = useI18n();

watch(
  () => props.isOpen,
  isOpen => {
    if (!$list.value) {
      return;
    }

    if (isOpen) {
      $list.value.style.display = 'block';
      gsap.to($list.value, {
        height: 'auto',
        opacity: 1,
        duration: 0.3,
        ease: easeOut,
        delay: 0.1,
        overwrite: true,
        onUpdate: () => emit('tween'),
      });
    } else {
      gsap.to($list.value, {
        height: 0,
        opacity: 0,
        duration: 0.3,
        ease: easeOut,
        onUpdate: () => emit('tween'),
        onComplete: () => {
          if ($list.value) {
            $list.value.style.display = 'none';
          }
        },
      });
    }
  }
);
</script>

<style src="./ItemDropdownList.scss" lang="scss" scoped />
