import type { ISbStoryData } from 'storyblok';
import type { IndustriesArticlePageStoryblok } from '~/types/storyblok-generated';

export const excludedFields = [
  'alert',
  'body',
  'component',
  'data_product',
  'first_published_at',
  'header_alternative',
  'header_in_page',
  'is_dark',
  'seo_canonical',
  'seo_meta',
  'seo_nofollow',
  'seo_noindex',
] as const;

type ExcludedFields = (typeof excludedFields)[number];

export type ReducedArticle = Omit<IndustriesArticlePageStoryblok, ExcludedFields>;

export interface LMGridIndustriesCardProps {
  article: ISbStoryData<ReducedArticle>;
  isDark?: boolean;
  breakpointsWidthMap: Record<string, number>;
  imageRatio: [number, number];
}

export interface CardProps {
  breakpointsWidthMap: LMGridIndustriesCardProps['breakpointsWidthMap'];
  imageRatio: LMGridIndustriesCardProps['imageRatio'];
  columnStart: number;
  columnEnd: number;
  isSmallOnMobile: boolean;
  isVerticallyCentered: boolean;
}

export const cardsDefaultLayout: CardProps[] = [
  // Row 1
  {
    breakpointsWidthMap: { '0px': 425, '480px': 648, '1024px': 425, '1280px': 648 },
    imageRatio: [3, 2],
    columnStart: 1,
    columnEnd: 5,
    isSmallOnMobile: false,
    isVerticallyCentered: false,
  },
  {
    breakpointsWidthMap: { '0px': 425, '480px': 648, '1024px': 425, '1280px': 648 },
    imageRatio: [16, 9],
    columnStart: 5,
    columnEnd: 9,
    isSmallOnMobile: false,
    isVerticallyCentered: false,
  },
  {
    breakpointsWidthMap: { '0px': 425, '480px': 648, '1024px': 425, '1280px': 648 },
    imageRatio: [3, 2],
    columnStart: 9,
    columnEnd: 13,
    isSmallOnMobile: false,
    isVerticallyCentered: false,
  },
  // Row 2
  {
    breakpointsWidthMap: { '0px': 538, '480px': 648, '1024px': 538, '1280px': 648 },
    imageRatio: [3, 4],
    columnStart: 2,
    columnEnd: 7,
    isSmallOnMobile: true,
    isVerticallyCentered: false,
  },
  {
    breakpointsWidthMap: { '0px': 425, '480px': 648, '1024px': 425, '1280px': 648 },
    imageRatio: [3, 2],
    columnStart: 8,
    columnEnd: 12,
    isSmallOnMobile: true,
    isVerticallyCentered: true,
  },
  // Row 3
  {
    breakpointsWidthMap: { '0px': 425, '480px': 648, '1024px': 425, '1280px': 648 },
    imageRatio: [3, 4],
    columnStart: 1,
    columnEnd: 5,
    isSmallOnMobile: false,
    isVerticallyCentered: false,
  },
  {
    breakpointsWidthMap: { '0px': 425, '480px': 648, '1024px': 425, '1280px': 648 },
    imageRatio: [16, 9],
    columnStart: 5,
    columnEnd: 9,
    isSmallOnMobile: false,
    isVerticallyCentered: false,
  },
  {
    breakpointsWidthMap: { '0px': 425, '480px': 648, '1024px': 425, '1280px': 648 },
    imageRatio: [1, 1],
    columnStart: 9,
    columnEnd: 13,
    isSmallOnMobile: false,
    isVerticallyCentered: false,
  },
  // Row 4
  {
    breakpointsWidthMap: { '0px': 425, '480px': 648, '1024px': 425, '1280px': 648 },
    imageRatio: [16, 9],
    columnStart: 2,
    columnEnd: 6,
    isSmallOnMobile: true,
    isVerticallyCentered: true,
  },
  {
    breakpointsWidthMap: { '0px': 538, '480px': 820, '1024px': 538, '1280px': 820 },
    imageRatio: [1, 1],
    columnStart: 7,
    columnEnd: 12,
    isSmallOnMobile: true,
    isVerticallyCentered: false,
  },
];

export const cardsFilteredLayout: CardProps[] = [
  // Row 1
  {
    breakpointsWidthMap: { '0px': 425, '480px': 648, '1024px': 425, '1280px': 648 },
    imageRatio: [3, 2],
    columnStart: 1,
    columnEnd: 5,
    isSmallOnMobile: false,
    isVerticallyCentered: false,
  },
  {
    breakpointsWidthMap: { '0px': 425, '480px': 648, '1024px': 425, '1280px': 648 },
    imageRatio: [16, 9],
    columnStart: 5,
    columnEnd: 9,
    isSmallOnMobile: false,
    isVerticallyCentered: false,
  },
  {
    breakpointsWidthMap: { '0px': 425, '480px': 648, '1024px': 425, '1280px': 648 },
    imageRatio: [3, 2],
    columnStart: 9,
    columnEnd: 13,
    isSmallOnMobile: false,
    isVerticallyCentered: false,
  },
  // Row 2
  {
    breakpointsWidthMap: { '0px': 538 },
    imageRatio: [3, 4],
    columnStart: 1,
    columnEnd: 5,
    isSmallOnMobile: false,
    isVerticallyCentered: false,
  },
  {
    breakpointsWidthMap: { '0px': 425, '480px': 648, '1024px': 425, '1280px': 648 },
    imageRatio: [3, 2],
    columnStart: 5,
    columnEnd: 9,
    isSmallOnMobile: false,
    isVerticallyCentered: false,
  },
  {
    breakpointsWidthMap: { '0px': 538 },
    imageRatio: [3, 4],
    columnStart: 9,
    columnEnd: 13,
    isSmallOnMobile: false,
    isVerticallyCentered: false,
  },
  // Row 3
  {
    breakpointsWidthMap: { '0px': 425, '480px': 648, '1024px': 425, '1280px': 648 },
    imageRatio: [16, 9],
    columnStart: 1,
    columnEnd: 5,
    isSmallOnMobile: false,
    isVerticallyCentered: false,
  },
  {
    breakpointsWidthMap: { '0px': 425, '480px': 648, '1024px': 425, '1280px': 648 },
    imageRatio: [1, 1],
    columnStart: 5,
    columnEnd: 9,
    isSmallOnMobile: false,
    isVerticallyCentered: false,
  },
  {
    breakpointsWidthMap: { '0px': 425, '480px': 648, '1024px': 425, '1280px': 648 },
    imageRatio: [1, 1],
    columnStart: 9,
    columnEnd: 13,
    isSmallOnMobile: false,
    isVerticallyCentered: false,
  },
];
