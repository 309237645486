import { reactive } from 'vue';

const state = reactive({
  isOpen: false,
});

export function useModal() {
  return {
    state,
  };
}
