<template>
  <BaseLink
    v-editable="blok"
    :link="blok.link"
    class="util-link-item"
    :class="isDark ? 'is-dark' : 'is-light'"
    @click="trackClick && trackNavigationClick(blok.text)">
    {{ blok.text }}
  </BaseLink>
</template>

<script lang="ts" setup>
import BaseLink from '~/components/base/BaseLink.vue';
import { useGtmTracking } from '~/composables/useGtmTracking';
import type { UtilLinkItemStoryblok } from '~/types/storyblok-generated';

const { trackEvent } = useGtmTracking();

defineProps<{
  blok: UtilLinkItemStoryblok;
  isDark?: boolean;
  trackClick?: boolean;
}>();

function trackNavigationClick(label: string) {
  trackEvent({ event: 'navigationClick', navigationItem: label });
}
</script>

<style src="./UtilLinkItem.scss" lang="scss" scoped />
