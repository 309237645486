<template>
  <NuxtIcon
    v-if="name"
    :name="name"
    v-bind="{ iconname: name }"
    :filled="true"
    class="base-icon"
    :class="[`is-${color}`, { 'has-no-stroke': filled }]"
    :style="{
      width: `${size}px`,
      height: `${size}px`,
    }" />
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { NuxtIcon } from '#components';

const props = withDefaults(
  defineProps<{
    name: string;
    /**
     * Size in Pixels
     */
    size?: number;
    color?: 'black' | 'white' | 'grey-light-3' | 'grey-light-4' | 'grey-light-5' | 'inherit';
    filled?: boolean;
  }>(),
  {
    size: 16,
    color: 'black',
  }
);

onMounted(() => {
  // Log error if name is undefined
  if (!props.name) {
    console.error('No valid icon name provided');
  }
});
</script>

<style src="./BaseIcon.scss" lang="scss" scoped />
