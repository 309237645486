import type { ISbStoryData } from '@storyblok/vue';
import type { ShowroomPointerState } from '~/components/storyblok/extra/EMShowroom/EMShowroom.types';
import type { DataProductStoryblok } from '~/types/storyblok-generated';
import { CameraControls, type CameraControlsInstance } from './cameraControls/cameraControls';
import { AppState, type AppStateInstance } from './core/appState';
import { AssetManager, type AssetManagerInstance } from './core/assetManager';
import { Engine, type EngineInstance } from './core/engine';
import { SwipeControls } from './core/swipeControls';
import { PostProcess, type PostProcessInstance } from './postProcess/postProcess';
import { clamp } from './utils/mathUtils';
import { sendEvent } from './utils/showroomEvents';
import { Visuals, type VisualsInstance } from './visuals/visuals';
import { ShowroomEvent } from './webGlApp.types';

const POINTER_ACTIVE_RATIO_SPEED = 0.2;

export type WebGlAppInstance = InstanceType<typeof WebGlApp>;

export class WebGlApp {
  assetManager: AssetManagerInstance;
  appState: AppStateInstance;
  visuals: VisualsInstance;
  swipeControls: SwipeControls | null = null;
  cameraControls: CameraControlsInstance;
  engine: EngineInstance;
  postprocess: PostProcessInstance;
  /**
   * We only need to render new frames when the mouse moves on desktop or the machines
   * are moving on mobile. This flag is used to determine if the rendering loop should be active.
   */
  isInitialRender = true;
  isInitialRenderPhase = false;
  machinesAreMoving = false;
  pointerIsMoving = false;
  pointerMoveDebounceTimeout: number | null = null;

  constructor(
    canvas: HTMLCanvasElement,
    featuredMachinesData: ISbStoryData<DataProductStoryblok>[],
    initialMachinesData: ISbStoryData<DataProductStoryblok>[]
  ) {
    this.appState = new AppState(canvas);
    this.assetManager = new AssetManager(this.appState, this.appState.IS_DEBUG);
    this.engine = new Engine(this.appState);
    this.cameraControls = new CameraControls(this.appState);
    this.visuals = new Visuals({
      appState: this.appState,
      engine: this.engine,
      assetManager: this.assetManager,
      featuredMachinesData: featuredMachinesData,
      initialMachinesData: initialMachinesData,
    });
    void this.assetManager.loadAssets();
    this.postprocess = new PostProcess(this.appState, this.engine);
    if (this.appState.isMobileDevice) {
      this.swipeControls = new SwipeControls(this.appState);
    }
    this.appState.webGlCanvas.addEventListener(ShowroomEvent.MachinesMoveEnd, this.handleMachinesMovingEnd.bind(this));
  }

  show() {
    this.resize(window.innerWidth, window.innerHeight);
    this.visuals.show();
    this.postprocess.show();
    this.cameraControls.show();
  }

  hide() {
    this.visuals.hide();
    this.postprocess.hide();
    this.cameraControls.hide();
  }

  resize(width: number, height: number) {
    this.appState.resize(width, height);
    this.engine.resize({ viewport: this.appState.viewport, renderSize: this.appState.renderSize });
    this.visuals.resize(this.appState.viewport.width);
    this.postprocess.resize();
  }

  start() {
    this.show();
    if (this.isInitialRender) {
      // We use a very low frame rate for the initial render.
      // We need to update a few times to make sure everything is rendered correctly,
      // But also want to keep the first seconds as easy on the GPU as possible.
      this.isInitialRender = false;
      this.update(0.1, true);
      setTimeout(() => this.update(0.1, true), 100);
      setTimeout(() => this.update(0.1, true), 500);
      setTimeout(() => this.update(0.1, true), 1000);
      setTimeout(() => this.update(0.1, true), 1500);
    }
  }

  destroy() {
    this.visuals.destroy();
    this.engine.destroy();
    this.hide();
    this.update(1);
  }

  update(dt: number, forceUpdate = false) {
    this.appState.pointerActiveRatio = clamp(
      this.appState.pointerActiveRatio + (this.appState.isPointerActive ? -dt : dt) / POINTER_ACTIVE_RATIO_SPEED,
      0.0,
      1.0
    );
    if (!forceUpdate && !this.isInitialRenderPhase && !this.machinesAreMoving && !this.pointerIsMoving) {
      // This is a still image so we don't need to render new frames
      return;
    }
    this.engine.update();
    this.cameraControls.update(this.engine);
    this.visuals.update(this.engine);
    this.postprocess.update();
  }

  previousMachine() {
    this.machinesAreMoving = true;
    sendEvent(this.appState.webGlCanvas, ShowroomEvent.PreviousMachine, { isSwiped: false });
  }

  nextMachine() {
    this.machinesAreMoving = true;
    sendEvent(this.appState.webGlCanvas, ShowroomEvent.NextMachine, { isSwiped: false });
  }

  handleMachinesMovingEnd() {
    this.machinesAreMoving = false;
  }

  onPointerDown(pointerState: ShowroomPointerState) {
    sendEvent(this.appState.webGlCanvas, ShowroomEvent.PointerDown, pointerState);
  }

  onPointerMove(pointerState: ShowroomPointerState) {
    this.appState.normalizedPointerCoords.set(
      -1 + (pointerState.x / window.innerWidth) * 2,
      1 - (pointerState.y / window.innerHeight) * 2
    );
    this.appState.isPointerActive = pointerState.isOff;
    sendEvent(this.appState.webGlCanvas, ShowroomEvent.PointerMove, pointerState);

    // In case user leaves and re-enters with pressed pointerdown
    if (pointerState.isOff === true) {
      this.onPointerUp(pointerState);
    }

    // Set the pointerIsMoving flag to true and debounce it
    if (this.pointerMoveDebounceTimeout) {
      window.clearTimeout(this.pointerMoveDebounceTimeout);
    }
    this.pointerIsMoving = true;
    this.pointerMoveDebounceTimeout = window.setTimeout(() => {
      this.pointerIsMoving = false;
    }, 500);
  }

  onPointerUp(pointerState: ShowroomPointerState) {
    sendEvent(this.appState.webGlCanvas, ShowroomEvent.PointerUp, pointerState);
  }

  setMachines(data: ISbStoryData<DataProductStoryblok>[]) {
    this.visuals.setMachines(data);
  }
}
