import { printValue, type LocaleObject } from 'yup';

export const yupDe: LocaleObject = {
  mixed: {
    default: 'Dieses Feld ist ungültig.',
    required: 'Dieses Feld ist ein Pflichtfeld.',
    defined: 'Dieses Feld muss definiert werden.',
    notNull: 'Dieses Feld darf nicht leer sein.',
    oneOf: 'Dieses Feld muss einer der folgenden Werte sein: ${values}.',
    notOneOf: 'Dieses Feld darf nicht einer der folgenden Werte sein: ${values}.',
    notType: ({ _path, type, value, originalValue }) => {
      const isCast = originalValue != null && originalValue !== value;
      let msg =
        `Dieses Feld muss ein \`${type}\` Typ sein, ` +
        `Aber der Endwert war: \`${printValue(value, true)}\`` +
        (isCast ? ` (aus dem Wert \`${printValue(originalValue, true)}\`).` : '.');

      if (value === null) {
        msg += `\n Wenn "null" als leerer Wert gedacht ist, markieren Sie das Schema als` + ' `.nullable()`.';
      }

      return msg;
    },
  },

  string: {
    length: 'Dieses Feld muss genau ${length} Zeichen lang sein.',
    min: 'Dieses Feld muss mindestens ${min} Zeichen lang sein.',
    max: 'Dieses Feld darf höchstens ${max} Zeichen lang sein.',
    matches: 'Dieses Feld muss folgendes Muster haben: "${regex}".',
    email: 'Dieses Feld muss eine gültige E-Mail-Adresse sein.',
    url: 'Dieses Feld muss eine gültige URL sein.',
    uuid: 'Dieses Feld muss eine gültige UUID sein.',
    trim: 'Dieses Feld muss eine Zeichenfolge ohne Leerzeichen sein.',
    lowercase: 'Dieses Feld muss in Kleinbuchstaben geschrieben sein.',
    uppercase: 'Dieses Feld muss in Großbuchstaben geschrieben sein.',
  },

  number: {
    min: 'Dieses Feld muss größer oder gleich ${min} sein.',
    max: 'Dieses Feld muss geringer oder gleich ${max} sein.',
    lessThan: 'Dieses Feld muss kleiner als ${less} sein.',
    moreThan: 'Dieses Feld muss größer als ${more} sein.',
    positive: 'Dieses Feld muss eine positive Zahl sein.',
    negative: 'Dieses Feld muss eine negative Zahl sein.',
    integer: 'Dieses Feld muss eine ganze Zahl sein.',
  },

  date: {
    min: 'Dieses Feld muss nach ${min} sein.',
    max: 'Dieses Feld muss vor ${max} sein.',
  },

  boolean: {
    isValue: 'Dieses Feld muss ${value} sein.',
  },

  object: {
    noUnknown: 'Dieses Feld kann keine unbekannten Werte enthalten.',
  },

  array: {
    min: 'Dieses Feld muss mindestens ${min} Einträge haben.',
    max: 'Dieses Feld darf höchstens ${max} Einträge haben.',
    length: 'Dieses Feld muss ${length} Einträge haben.',
  },
};
