import { reactive, ref } from 'vue';
import { deviceToBreakpointMap, type Device } from '~/types/utils';

const state = reactive<Record<Device, boolean>>({
  isPhone: true,
  isPhoneLarge: true,
  isTablet: true,
  isTabletLarge: true,
  isLaptop: true,
  isLaptopLarge: true,
  isDesktop: true,
  isDesktopLarge: true,
  isCinema: true,
  isCinemaLarge: true,
});

const isTouch = ref(false);

export function useBreakpoint() {
  function setDevices(ref: Device, value: number) {
    if (value > 0) {
      state[ref] = window.matchMedia(`(min-width: ${value}px)`).matches;
      return;
    }
    state[ref] = window.matchMedia(`(max-width: ${value * -1}px)`).matches;
  }

  function setBreakpoints() {
    Object.keys(deviceToBreakpointMap).forEach(key => {
      setDevices(key as Device, deviceToBreakpointMap[key as Device]);
    });
  }

  function setIsTouch() {
    isTouch.value = window.matchMedia('(hover: none)').matches;
  }

  function onResize() {
    setBreakpoints();
    setIsTouch();
  }

  function init() {
    onResize();
    window.removeEventListener('resize', onResize);
    window.addEventListener('resize', onResize);
  }

  return {
    init,
    isTouch,
    state,
  };
}
