<template>
  <div
    v-show="isShown"
    ref="$container"
    class="util-modal"
    :class="[
      props.isDark ? 'is-dark' : 'is-light',
      {
        'is-custom': animationType === 'custom',
        'has-background': hasBackground,
      },
    ]"
    :style="{
      opacity: ['none', 'custom'].includes(animationType) ? 1 : 0,
    }">
    <div v-if="animationType === 'custom'" ref="$bgFade" class="bg-fade"></div>

    <div class="content">
      <slot></slot>
    </div>

    <!-- Hold optional navigation items -->
    <div v-if="isNavigation" class="navigation-container">
      <div class="navigation">
        <!--Logo-->
        <div v-if="logo" class="logo" :aria-label="t('global.closeModal')" @click="hideModal">
          <BaseImage v-if="logo" :image="logo" :breakpointsWidthMap="{ 0: 26, 1024: 32 }" />
        </div>

        <!-- Burger -->
        <button class="navigation-link burger" :aria-label="t('global.closeModal')" @click="hideModal">
          <BaseIcon class="burger-icon" name="fi_menu" :size="16" color="inherit" />
        </button>
      </div>
    </div>

    <div v-else-if="includeClose" class="navigation-container">
      <div class="navigation">
        <!-- Close -->
        <button class="navigation-link close" :aria-label="t('global.closeModal')" @click="hideModal">
          <BaseIcon class="close-icon" name="fi_x" :size="16" color="inherit" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '#imports';
import { gsap } from 'gsap';
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { BaseIcon, BaseImage } from '~/components/base';
import { useHeader } from '~/composables/useHeader';
import { useModal } from '~/composables/useModal';
import { useScroll } from '~/composables/useScroll';
import { useTransition } from '~/composables/useTransition';
import type { AssetStoryblok } from '~/types/storyblok-generated';
const KEY_CODE_ESC = 27;

type AnimationType = 'none' | 'fade' | 'custom';

const props = withDefaults(
  defineProps<{
    isDark?: boolean;
    isOpen?: boolean;
    isNavigation?: boolean;
    hasBackground?: boolean;
    animationType?: AnimationType;
    logo?: AssetStoryblok;
    includeClose?: boolean;
  }>(),
  { hasBackground: true, animationType: 'none' }
);

const emit = defineEmits<{
  (e: 'loaded'): void;
  (e: 'close'): void;
}>();

const $container = ref<HTMLElement>();
const $bgFade = ref<HTMLElement>();
const isShown = ref(false);
const themeFlipped = ref(false);

const { state: modalState } = useModal();
const { state: headerState } = useHeader();
const { morphIcon } = useTransition();
const { disableScroll, enableScroll } = useScroll();
const { t } = useI18n();

watch(
  () => props.isOpen,
  async () => {
    modalState.isOpen = props.isOpen;
    await nextTick();
    if (props.isOpen) {
      showModal();
    } else {
      hideModal();
    }
  }
);

onBeforeUnmount(() => {
  headerState.menuOpen = false;
  enableScroll();
});

function checkSlideProgress(progress: number) {
  if (progress > 0.2) {
    themeFlipped.value = true;
  } else {
    themeFlipped.value = false;
  }
}

function updateNavigation(value: boolean) {
  if (!props.isNavigation && !props.includeClose) {
    return;
  }

  if (value) {
    gsap.to($container.value!.querySelectorAll('.navigation'), {
      autoAlpha: 1,
      duration: 0.3,
      overwrite: true,
    });
  } else {
    gsap.to($container.value!.querySelectorAll('.navigation'), {
      overwrite: true,
      autoAlpha: 0,
      duration: 0.3,
      delay: props.includeClose ? 0 : 0.15,
    });
  }
}

watch(
  () => themeFlipped.value,
  () => {
    if (!props.isNavigation) {
      return;
    }
    morphIcon('menu', $container.value?.querySelector('.burger-icon svg') as HTMLElement, themeFlipped.value);
  }
);

async function showModal() {
  isShown.value = true;

  await nextTick();
  if (!$container.value) {
    return;
  }

  headerState.menuOpen = true;
  enableScroll();

  switch (props.animationType) {
    case 'fade': {
      const content = $container.value.querySelector('.content');
      gsap.set(content, { autoAlpha: 0 });
      gsap.to(content, { autoAlpha: 1, delay: 0.4 }).then(() => updateNavigation(true));

      gsap.to($container.value, { autoAlpha: 1, duration: 0.5 }).then(() => {
        disableScroll();
        emit('loaded');
      });
      break;
    }
    case 'custom': {
      if (!$bgFade.value) {
        return;
      }
      gsap.to($bgFade.value, { overwrite: true, autoAlpha: 0.8, duration: 1 });

      const menu = $container.value.querySelector('.content');
      gsap
        .to(menu, {
          overwrite: true,
          ease: 'quint.out',
          y: 0,
          duration: 0.8,
          onUpdate: function () {
            checkSlideProgress(this.progress());
          },
        })
        .then(() => {
          disableScroll();
          emit('loaded');
        });

      /*Navigation*/
      updateNavigation(true);
      break;
    }
    default: {
      gsap.set($container.value, { autoAlpha: 1 });

      /*Navigation*/
      updateNavigation(true);

      nextTick(() => {
        disableScroll();
        emit('loaded');
      });
      break;
    }
  }
}

function hideModal() {
  if (!$container.value) {
    return;
  }

  enableScroll();

  switch (props.animationType) {
    case 'fade': {
      updateNavigation(false);
      gsap
        .to($container.value, {
          overwrite: true,
          duration: 0.25,
          delay: 0.15,
          autoAlpha: 0,
        })
        .then(() => {
          isShown.value = false;
        });
      break;
    }
    case 'custom': {
      const bgFade = $container.value.querySelector('.bg-fade');
      gsap.to(bgFade, { overwrite: true, autoAlpha: 0, duration: 0.5 });
      const menu = $container.value.querySelector('.content');
      gsap
        .to(menu, {
          overwrite: true,
          ease: 'quint.out',
          y: '-100%',
          duration: 0.5,
        })
        .then(() => {
          headerState.menuOpen = false;
          isShown.value = false;
        });

      /* Navigation */
      updateNavigation(false);
      themeFlipped.value = false;
      break;
    }
    default: {
      isShown.value = false;
      break;
    }
  }
  emit('close');
}

/**
 * Closes the modal when the ESC key is pressed
 */
function onKeyDown(e: { keyCode: number }) {
  if (e.keyCode === KEY_CODE_ESC && props.isOpen) {
    hideModal();
  }
}
onBeforeUnmount(() => {
  window.removeEventListener('keydown', onKeyDown);
});
onMounted(() => {
  window.addEventListener('keydown', onKeyDown);
});
</script>

<style src="./BaseModal.scss" lang="scss" scoped />
