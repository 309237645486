uniform mat4 u_textureMatrix;

varying vec2 v_uv;
varying vec4 v_uvReflection;


void main () {
	v_uv = uv;
	v_uvReflection = u_textureMatrix * vec4(position, 1.0);

	vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
	gl_Position = projectionMatrix * mvPosition;
}
