<template>
  <div v-editable="blok" class="bm-header-rich-quick-links-card" :class="isDark ? 'is-dark' : 'is-light'">
    <div v-if="hasMedia" class="media-container">
      <BaseVideo
        v-if="blok.media && isVideo(blok.media.filename)"
        :src="blok.media.filename"
        :autoplay="true"
        :loop="true" />
      <BaseImage v-else :image="blok.media" :breakpointsWidthMap="{ 0: 550 }" :heightRatio="[3, 2]" />
    </div>

    <!-- Text -->
    <div class="card-content" :class="hasMedia ? 'has-media' : 'has-no-media'">
      <div class="card-content-text" :class="hasMedia ? 'has-media' : 'has-no-media'">
        <span v-if="blok.overline" class="card-content-headline" :class="hasMedia ? 'has-media' : 'text-small-bold'">
          {{ blok.overline }}
        </span>
        <div :class="hasMedia ? 'heading-sub-large' : 'heading-sub-small'">
          {{ blok.headline }}
        </div>
        <p v-if="blok.text" class="text-medium-regular">{{ blok.text }}</p>
      </div>

      <!-- Button -->
      <UtilButton
        v-if="!!blok.link?.cached_url"
        :blok="buttonBlok"
        :isBackgroundDark="isDark"
        :isSmallOnMobile="true"
        class="card-button" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import BaseImage from '~/components/base/BaseImage.vue';
import BaseVideo from '~/components/base/BaseVideo.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import { isVideo } from '~/utils/media';
import type { BmHeaderRichCardStoryblok, UtilButtonStoryblok } from '~/types/storyblok-generated';

const props = defineProps<{
  blok: BmHeaderRichCardStoryblok;
  isDark?: boolean;
}>();

const hasMedia = computed(() => !!props.blok.media?.filename);

const buttonBlok: UtilButtonStoryblok = {
  component: 'util_button',
  text: '',
  theme: 'primary',
  size: 'large',
  link: props.blok.link,
  icon: 'fi_arrow-right',
  _uid: 'button-blok',
};
</script>

<style src="./BMHeaderRichQuickLinksCard.scss" lang="scss" scoped />
