<template>
  <BaseModal
    :isDark="isDark"
    :isOpen="isOpen"
    :isNavigation="true"
    :logo="logo"
    animationType="custom"
    @close="emit('close')">
    <div ref="$menu" class="bm-header-rich-menu" :class="isDark ? 'is-dark' : 'is-light'">
      <div class="container">
        <div class="header">
          <!-- Language menu button -->
          <button class="language-select" @click="onLanguageSelectClick">
            <BaseIcon
              v-if="isLanguageSelectActive"
              name="fi_arrow-left"
              :size="16"
              :color="isDark ? 'white' : 'black'"
              class="language-arrow" />

            <BaseFlag :marketLocale="localeMarket" />

            <div class="language-text action-medium-bold">
              <span>{{ localeLanguage }}</span>
              <span>({{ localeMarket }})</span>
            </div>
            <BaseIcon
              :name="isLanguageSelectActive ? 'fi_chevron-top' : 'fi_chevron-bottom'"
              :size="16"
              :color="isDark ? 'white' : 'black'"
              class="language-chevron" />
          </button>
        </div>

        <BmHeaderRichMarketSelect
          ref="$marketSelect"
          :isDark="isDark"
          :alternates="alternates"
          @hidden="onMarketSelectHidden" />

        <div class="line"></div>

        <!-- Search bar -->
        <div v-if="isSearchEnabled" class="search-bar" @submit="handleSearchSubmit">
          <BaseInput
            v-model="searchInputValue"
            name="search"
            class="search-input"
            :isDark="isDark"
            :isSearchBar="true"
            :placeholder="'What are you searching for?'"
            @focus="() => handleSearchFocus(true)"
            @blur="() => handleSearchFocus(false)" />

          <!-- Results -->
          <!-- @TODO add real results -->
          <div v-if="isSearchActive" class="search-results">
            <ul class="search-results-entry">
              <li v-for="i in 4" :key="i">
                <BaseLink
                  :link="{
                    fieldtype: 'multilink',
                    id: '/',
                    cached_url: '/',
                    url: '/',
                    linktype: 'url',
                  }"
                  class="bottom-link">
                  <span>Result mocked {{ i }}</span>
                </BaseLink>
              </li>
            </ul>
          </div>
        </div>

        <div class="content-container">
          <UtilScrollable class="content-scroll-container">
            <div class="content">
              <!-- Main links -->
              <div class="links">
                <ul class="top-links">
                  <li v-for="(item, index) in linksPrimary" :key="index" class="links-item">
                    <!-- Change link colour when hover occurs in one of the links, and the link is not the current one -->
                    <BaseLink
                      :link="item.link"
                      class="links-item-link statement-large"
                      :class="{ 'is-active': item.link && linkIsActive(item.link) }"
                      @mouseenter="handleHover(index)"
                      @mouseleave="handleHover(-1)"
                      @click="() => trackNavigationClick(item.text)">
                      {{ item.text }}
                    </BaseLink>
                  </li>
                </ul>
              </div>

              <div class="media">
                <!-- Media -->
                <div class="media-container" :class="{ 'is-active': hoveredEntry?.media?.filename }">
                  <div v-if="hoveredEntry?.media?.filename" class="media-wrapper">
                    <BaseVideo
                      v-if="isVideo(hoveredEntry.media.filename)"
                      :src="hoveredEntry.media.filename"
                      :autoplay="true"
                      :loop="true"
                      class="media-video"
                      :class="`is-${hoveredEntry.aspect_ratio}`" />
                    <BaseImage
                      v-else
                      :image="hoveredEntry?.media"
                      :breakpointsWidthMap="{ 0: 550 }"
                      :heightRatio="hoveredEntry.aspect_ratio.split('-').map(s => parseInt(s)) ?? [16, 9]"
                      class="media-image" />
                  </div>
                </div>

                <!-- Bottom links -->
                <div class="bottom-links">
                  <template v-for="(links, i) in [linksSecondary, linksTertiary]">
                    <ul v-if="links && links.length" :key="i" class="bottom-links-column">
                      <li v-for="item in links" :key="item._uid" class="bottom-link-container">
                        <BaseLink
                          :link="item.link"
                          class="bottom-link"
                          :class="{ 'is-active': item.link && linkIsActive(item.link) }"
                          @click="() => trackNavigationClick(item.text)">
                          <span>{{ item.text }} </span>

                          <BaseIcon
                            v-if="item.component === 'util_link-item-icon'"
                            :name="item.icon"
                            :size="16"
                            color="inherit"
                            class="bottom-link-icon" />
                        </BaseLink>
                      </li>
                    </ul>
                  </template>
                </div>
              </div>
            </div>
          </UtilScrollable>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import type { ISbAlternateObject } from 'storyblok-js-client';
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { BaseIcon, BaseImage, BaseInput, BaseLink, BaseModal, BaseVideo } from '~/components/base';
import UtilScrollable from '~/components/storyblok/utils/UtilScrollable/UtilScrollable.vue';
import BmHeaderRichMarketSelect from '~/components/storyblok/basic/BMHeaderRich/BmHeaderRichMarketSelect.vue';
import { useGtmTracking } from '~/composables/useGtmTracking';
import { useLocale } from '~/composables/useLocale';
import { useTransition } from '~/composables/useTransition';
import { isVideo } from '~/utils/media';
import { normalizeUrl, urlExtractFromLink } from '~/utils/url';
import type { AssetStoryblok, BmHeaderRichStoryblok, MultilinkStoryblok } from '~/types/storyblok-generated';
import BaseFlag from '~/components/base/BaseFlag.vue';

const props = defineProps<{
  isDark?: boolean;
  isOpen: boolean;
  logo: AssetStoryblok;
  linksPrimary?: BmHeaderRichStoryblok['menu_primary_links'];
  linksSecondary?: BmHeaderRichStoryblok['menu_secondary_links'];
  linksTertiary?: BmHeaderRichStoryblok['menu_tertiary_links'];
  alternates?: ISbAlternateObject[];
  isSearchEnabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const { localeMarket, localeLanguage } = useLocale();
const { fadeSlide } = useTransition();
const { currentRoute } = useRouter();

const isSearchActive = ref(false);
const isLanguageSelectActive = ref(false);
const hoverIndex = ref<number>(-1); // Index of the hovered link
const hoveredEntry = computed(() => props.linksPrimary?.[hoverIndex.value]);
const $marketSelect = ref<InstanceType<typeof BmHeaderRichMarketSelect>>();
const $menu = ref<HTMLElement>();
const searchInputValue = ref('');
const { trackEvent } = useGtmTracking();
let hoverTimeout: ReturnType<typeof setTimeout>;

function handleSearchFocus(value: boolean) {
  isSearchActive.value = value;
}

function handleHover(index: number) {
  // Reset hover index for transition effect
  clearTimeout(hoverTimeout);
  hoverIndex.value = -1;
  hoverTimeout = setTimeout(() => {
    hoverIndex.value = index;
  }, 200);
}

function onLanguageSelectClick() {
  isLanguageSelectActive.value = !isLanguageSelectActive.value;
  if (!isLanguageSelectActive.value) {
    $marketSelect.value?.hide();
  } else {
    // Wait for hidden
    $marketSelect.value?.show();
  }
}

function onMarketSelectHidden() {
  // Triggered by clickoutside
  isLanguageSelectActive.value = false;
}

function linkIsActive(link: MultilinkStoryblok) {
  if (!link) {
    return false;
  }
  const url = urlExtractFromLink(link) ?? '';
  const normalizedUrl = normalizeUrl(url);
  const currentUrl = normalizeUrl(currentRoute.value.fullPath);
  return currentUrl.startsWith(normalizedUrl);
}

watch(
  () => props.isOpen,
  () => {
    // Menu animation
    if (props.isOpen && $menu.value) {
      const line = $menu.value.querySelectorAll('.line');
      fadeSlide(line, { baseDelay: 0.25, offsetMultiplier: 4 });

      const mainItems = Array.from($menu.value.querySelectorAll('.links-item'));

      fadeSlide(mainItems, {
        baseDelay: 0.5,
        staggerEach: 0.1,
        offsetMultiplier: 1,
      });

      const bottomItems = Array.from($menu.value.querySelectorAll('.bottom-links-column li'));

      fadeSlide(bottomItems, {
        baseDelay: mainItems.length * 0.1 + 0.6,
        staggerEach: 0.05,
        offsetMultiplier: 1,
      });
    }
  }
);

function handleSearchSubmit() {
  trackEvent({ event: 'search', searchTerm: searchInputValue.value });
}

function trackNavigationClick(label?: string) {
  trackEvent({ event: 'navigationClick', navigationItem: label ?? '' });
}

onBeforeUnmount(() => {
  clearTimeout(hoverTimeout);
});
</script>

<style src="./BMHeaderRichMenu.scss" lang="scss" scoped />
