uniform mat4 u_textureMatrix;

varying vec2 v_uv;


void main () {
	v_uv = uv;

	vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
	gl_Position = projectionMatrix * mvPosition;
}
