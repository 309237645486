import { useDebounceFn } from '@vueuse/core';
import { nextTick, reactive } from 'vue';

const state = reactive({
  width: 0,
  height: 0,
});

const debouncedState = reactive({
  width: 0,
  height: 0,
});

let liveInterval: NodeJS.Timeout;

export function useWindow() {
  const debounceListener = useDebounceFn(onDebouncedResize, 30);

  function onResize() {
    state.width = window.innerWidth;
    state.height = window.innerHeight;
    debounceListener();
  }

  function onDebouncedResize() {
    debouncedState.width = window.innerWidth;
    debouncedState.height = window.innerHeight;
  }

  function init() {
    if (liveInterval) {
      return;
    }

    window.addEventListener('resize', onResize);
    nextTick(onResize);
  }

  return {
    init,
    state,
    debouncedState,
  };
}
