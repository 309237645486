<template>
  <div class="base-checkbox form-field" :class="[isDark ? 'is-dark' : 'is-light', { 'is-disabled': disabled }]">
    <label :for="uid" class="field-container">
      <input
        :id="uid"
        tabindex="0"
        type="checkbox"
        class="input"
        :class="{ 'has-error': errorMessage && isTouched }"
        :disabled="disabled"
        :checked="modelValue"
        @input="handleInput" />
      <div class="label">
        <BaseRichtext :text="label" :isDark="isDark" textSize="medium" :isProseParagraphsEnabled="false" />
      </div>
    </label>
    <div v-if="errorMessage && isTouched" class="error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ISbRichtext } from 'storyblok-js-client';
import { useId } from 'vue';
import { BaseRichtext } from '~/components/base';
import type { FormFieldProps } from '~/types/utils';

interface BaseCheckbox extends Omit<FormFieldProps, 'label'> {
  label: ISbRichtext;
  modelValue: boolean | undefined;
}

const props = defineProps<BaseCheckbox>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const uid = props.id ?? useId();

function handleInput(event: Event) {
  if (event.target instanceof HTMLInputElement) {
    emit('update:modelValue', event.target.checked);
  }
}
</script>

<style src="~/assets/scss/_form-field.scss" lang="scss" scoped />
<style src="./_BaseCheckbox.scss" lang="scss" scoped />
