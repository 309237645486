<template>
  <!-- Card -->
  <div ref="$card" v-editable="item" class="cm-media-card">
    <!-- Card Media -->
    <CMMediaCardBase
      :item="item"
      :mediaView="mediaView"
      :youtubePrivacyWarning="youtubePrivacyWarning"
      class="card-media"
      :class="`is-${mediaView}`"
      :breakpointsWidthMap="breakpointsWidthMap"
      @contentFadeIn="onContentFadeIn" />

    <!-- Card text -->
    <div ref="$content" class="content">
      <div class="text">
        <h3 v-if="item.headline" class="heading-sub-small">
          {{ item.headline }}
        </h3>
        <p
          v-if="item.text"
          class="text-paragraph text-medium-regular"
          :class="isDark ? 'is-dark' : 'is-light'"
          v-html="item.text"></p>
      </div>

      <!-- Card CTAs -->
      <div v-if="(item.call_to_actions?.length ?? 0) > 0" class="ctas-container">
        <template v-for="(action, index) in item.call_to_actions" :key="index">
          <UtilLinkItemIcon v-if="action.component === 'util_link-item-icon'" :blok="action" :isDark="isDark" />
          <UtilLinkItem v-if="action.component === 'util_link-item'" :blok="action" :isDark="isDark" />
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { gsap } from 'gsap';
import { ref, watch } from 'vue';
import UtilLinkItem from '~/components/storyblok/utils/UtilLinkItem/UtilLinkItem.vue';
import UtilLinkItemIcon from '~/components/storyblok/utils/UtilLinkItemIcon/UtilLinkItemIcon.vue';
import type { CmMediaItemStoryblok, RichtextStoryblok } from '~/types/storyblok-generated';
import CMMediaCardBase from './CMMediaCardBase.vue';

const props = withDefaults(
  defineProps<{
    item: CmMediaItemStoryblok;
    mediaView: 'normal' | 'wide' | 'align-right' | 'align-left';
    isDark?: boolean;
    shouldContentFadeIn?: boolean;
    youtubePrivacyWarning: RichtextStoryblok;
    breakpointsWidthMap?: Record<string, number>;
  }>(),
  { mediaView: 'normal' }
);

const $card = ref<HTMLElement>();
const $content = ref<HTMLElement>();

// Listen for changes from the Wide view card
watch(
  () => props.shouldContentFadeIn,
  () => onContentFadeIn()
);

// For card's content in wide view
const onContentFadeIn = () => {
  const delay = (($card.value?.offsetLeft ?? 0) / window.innerWidth) * 0.8;
  gsap.to($card.value as HTMLElement, { autoAlpha: 1, delay: delay });
};
</script>

<style src="./CMMediaCard.scss" lang="scss" scoped />
