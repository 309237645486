<template>
  <Component
    :is="isValidDownload ? BaseDownload : BaseLink"
    v-if="isValidDownload || isValidUrl || $attrs?.['onClick']"
    v-editable="blok"
    :link="isValidDownload ? undefined : blok.link"
    :asset="isValidDownload ? (asset ?? undefined) : undefined"
    :filename="blok.text"
    class="util-link-item-icon"
    :class="[isDark ? 'is-dark' : 'is-light', { 'is-disabled': isDisabled }]"
    @click="onButtonClick"
    @mouseenter="onIconMouseOver"
    @mouseleave="onIconMouseOut">
    <BaseIcon v-if="blok.icon_position === 'start'" :name="blok.icon" :size="16" color="inherit" class="icon-start" />
    <div v-if="hasText" class="text" :class="{ 'is-hidden-on-mobile': isTextHiddenOnMobile }">
      <span class="main-text">
        {{ blok.text }}
        <BaseIcon
          v-if="blok.component === 'util_link-item-icon' && blok.icon_position === 'end'"
          :name="blok.icon"
          :size="16"
          color="inherit"
          class="icon-end" />
      </span>
      <span v-if="subtext" class="subtext" :class="isDark ? 'is-dark' : 'is-light'">
        {{ subtext }}
      </span>
    </div>
  </Component>
</template>

<script lang="ts" setup>
import { urlExtractFromLink } from '#imports';
import { computed } from 'vue';
import BaseDownload from '~/components/base/BaseDownload.vue';
import BaseIcon from '~/components/base/BaseIcon.vue';
import BaseLink from '~/components/base/BaseLink.vue';
import { useIconAnimation } from '~/composables/useIconAnimation';
import { useLayer } from '~/composables/useLayer';
import type { AssetStoryblok, UtilLinkItemIconStoryblok } from '~/types/storyblok-generated';

const props = defineProps<{
  blok: UtilLinkItemIconStoryblok;
  isDark?: boolean;
  isDisabled?: boolean;
  isTextHiddenOnMobile?: boolean;
  subtext?: string;
  asset?: AssetStoryblok | null;
}>();

const { onIconMouseOut, onIconMouseOver } = useIconAnimation();
const hasText = computed(() => !!props.blok.text);
const isValidDownload = computed(() => !!props.asset?.filename);
const isValidUrl = computed(() => !!props.blok.link && !!urlExtractFromLink(props.blok.link));

function onButtonClick() {
  if (!props.blok.item_overlay) {
    return;
  }

  // Open overlay
  const { state } = useLayer();
  if (typeof props.blok.item_overlay === 'string') {
    state.corePageLayerId = null;
  } else {
    state.corePageLayerId = props.blok.item_overlay.uuid;
  }
}
</script>

<style src="./UtilLinkItemIcon.scss" lang="scss" scoped />
