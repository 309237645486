<template>
  <div ref="$el" v-editable="blok" class="cm-accordion container-full">
    <!-- Headings -->
    <div v-if="hasOverline || blok.headline" class="heading" :class="{ 'has-no-media': hasNoMedia }">
      <div v-if="hasOverline" :class="blok.head_size === 'small' ? 'heading-over-small' : 'heading-over-medium'">
        {{ blok.overline }}
      </div>
      <h2
        v-if="blok.headline"
        class="heading-main-large"
        :class="blok.head_size === 'small' ? 'heading-main-small' : 'heading-main-large'">
        {{ blok.headline }}
      </h2>
    </div>

    <!-- Content -->
    <div
      v-if="blok.body?.length"
      ref="$content"
      class="content"
      :class="{ 'has-media': !hasNoMedia, 'has-no-media': hasNoMedia, 'has-overline': hasOverline }">
      <!-- Accordions -->
      <div class="accordion" :class="{ 'has-tabs': hasTabs }">
        <UtilAccordion
          v-for="(accordion, i) in displayedAccordions"
          :key="getUniqueKey(accordion.title, i)"
          v-editable="accordion"
          :isDark="blok.is_dark"
          :index="i"
          :activeIndex="activeAccordion"
          :shouldScrollToTopOnDesktop="false"
          @accordionToggle="onAccordionToggle"
          @accordionEnter="onAccordionEnter"
          @accordionLeave="onAccordionLeave">
          <!-- Head -->
          <template #head>
            <h3 class="head-content" :class="blok.is_dark ? 'is-dark' : 'is-light'">
              {{ accordion.title }}
            </h3>
          </template>

          <!-- Body -->
          <template #body>
            <div class="body">
              <!-- Accordion Media -->
              <div
                v-if="hasMedia(accordion) && !hasTabs && accordion.component === 'item_accordion-media'"
                class="media-mobile">
                <div class="media-container">
                  <UtilYoutubeVideo
                    v-if="accordion.media_type === 'youtube-video' && accordion.youtube_video?.[0]"
                    :blok="accordion.youtube_video[0]"
                    :privacyWarningRichtext="settings?.youtube_privacy_warning" />
                  <UtilMediaResponsive
                    v-if="accordion.media_type === 'media'"
                    :image="accordion.media_image"
                    :video="accordion.media_video"
                    :autoplay="true"
                    aspectRatio="1/1"
                    :isDark="blok.is_dark" />
                </div>
              </div>

              <template v-for="(item, index) in accordion.body" :key="index">
                <UtilRichtext v-if="item.component === 'util_richtext-reduced'" :blok="item" :isDark="blok.is_dark" />
                <ItemQuote v-else-if="item.component === 'item_quote'" :blok="item" :isDark="blok.is_dark" />
              </template>
            </div>
          </template>
        </UtilAccordion>
      </div>

      <!-- Sidebar -->
      <div v-if="!hasNoMedia" class="sidebar" :class="hasTabs ? 'with-tabs' : 'with-media'">
        <!-- Tabs -->
        <ul
          v-if="accordionBody?.component === 'cm_accordion--tabs'"
          ref="$sidebarEl"
          class="tabs"
          :class="{ 'is-sticky': sidebarElIsSticky }">
          <li v-for="(tab, j) in accordionBody.tabs" :key="j" ref="$tab" class="tab" @click="onTabClick(j)">
            <BaseIcon
              name="fi_chevron-right"
              class="tab-icon"
              :size="16"
              :color="blok.is_dark ? 'white' : 'black'"
              :class="{ 'is-active': j === activeTab }" />

            <h4 class="tab-title" :class="[blok.is_dark ? 'is-dark' : 'is-light', { active: j === activeTab }]">
              {{ tab.title }}
            </h4>
          </li>
        </ul>

        <!-- Media -->
        <div
          v-else
          ref="$sidebarEl"
          class="media"
          :class="{ 'is-sticky': sidebarElIsSticky }"
          :style="{
            aspectRatio: mediaContent?.type === 'youtube-video' ? mediaContent?.content?.media_ratio || '16/9' : '1/1',
          }">
          <div ref="$mediaContainer" class="media-container">
            <UtilYoutubeVideo
              v-if="mediaContent?.type === 'youtube-video' && mediaContent?.content"
              :blok="mediaContent.content"
              :privacyWarningRichtext="settings?.youtube_privacy_warning" />
            <UtilMediaResponsive
              v-if="mediaContent?.type === 'image' || mediaContent?.type === 'video'"
              :image="mediaContent.content"
              :video="modalVideo || mediaContent.content"
              :autoplay="true"
              aspectRatio="1/1"
              :isDark="blok.is_dark" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDebounceFn, useElementSize } from '@vueuse/core';
import gsap from 'gsap';
import { computed, nextTick, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from 'vue';
import { BaseIcon } from '~/components/base';
import UtilAccordion from '~/components/storyblok/utils/UtilAccordion/UtilAccordion.vue';
import UtilMediaResponsive from '~/components/storyblok/utils/UtilMediaResponsive/UtilMediaResponsive.vue';
import UtilRichtext from '~/components/storyblok/utils/UtilRichtext/UtilRichtext.vue';
import UtilYoutubeVideo from '~/components/storyblok/utils/UtilYoutubeVideo/UtilYoutubeVideo.vue';
import ItemQuote from '~/components/storyblok/item/ItemQuote/ItemQuote.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useGsap } from '~/composables/useGsap';
import { useScroll } from '~/composables/useScroll';
import { useTransition } from '~/composables/useTransition';
import { isVideoWebm } from '~/utils/media';
import type {
  AssetStoryblok,
  CmAccordionStoryblok,
  CoreSettingsStoryblok,
  ItemAccordionMediaStoryblok,
  ItemAccordionStoryblok,
  ItemYoutubeVideoStoryblok,
} from '~/types/storyblok-generated';

interface MediaContentMedia {
  type: 'image' | 'video';
  content?: AssetStoryblok;
}

interface MediaContentYoutube {
  type: 'youtube-video';
  content?: ItemYoutubeVideoStoryblok;
}

type MediaContent = MediaContentMedia | MediaContentYoutube;

const props = defineProps<{
  blok: CmAccordionStoryblok;
  settings: CoreSettingsStoryblok;
}>();

const { easeOut } = useGsap();
const { getUniqueKey, hideElements, addViewPortElements, removeViewPortElements, checkViewPortElements } =
  useTransition();
const { state: scrollState } = useScroll();
const { state: stateBreakpoint } = useBreakpoint();

const $el = ref<HTMLElement>();
const $mediaContainer = ref<HTMLElement>();
const $tab = ref<HTMLElement[] | null>(null);

const mediaContent = ref<MediaContent | null>(null);
const modalVideo = ref<AssetStoryblok | null>(null);
const activeAccordion = ref<number>(-1);
const activeTab = ref<number>(0);
const lastActiveMedia = ref<number>(-2);
const $content = ref<HTMLElement>();
const $sidebarEl = ref<HTMLElement>();
const { height: contentElHeight } = useElementSize($content);
const sidebarElIsSticky = ref(false);
let timeout: ReturnType<typeof setTimeout>;

const accordionBody = computed(() => props.blok.body?.[0]);
const hasTabs = computed(() => accordionBody.value?.component === 'cm_accordion--tabs');
const hasNoMedia = computed(() => accordionBody.value?.component === 'cm_accordion--no-media');
const hasOverline = computed(() => !!props.blok.overline);
const isMobile = computed(() => !stateBreakpoint.isPhoneLarge);

const displayedAccordions = ref<(ItemAccordionStoryblok | ItemAccordionMediaStoryblok)[]>(
  accordionBody.value?.component === 'cm_accordion--tabs'
    ? accordionBody.value?.tabs?.[0]?.accordions
    : accordionBody.value?.accordions
);

function onAccordionEnter(index: number = -1) {
  if (isMobile.value) {
    return;
  }
  setActiveMedia(index);
}

function onAccordionLeave() {
  if (isMobile.value) {
    return;
  }
  setActiveMedia(activeAccordion.value);
}

function setActiveMedia(index: number = -1) {
  const targetAccordion = index < 0 ? accordionBody.value : displayedAccordions?.value?.[index];
  if (targetAccordion.component !== 'item_accordion-media' && targetAccordion.component !== 'cm_accordion--media') {
    return;
  }
  const { media_video: video, media_image: image, media_type, youtube_video } = targetAccordion;

  // Set active media based on the accordion index
  if ((image?.filename || media_type === 'youtube-video') && lastActiveMedia.value !== index && $mediaContainer.value) {
    // Fade out media
    gsap.to($mediaContainer.value, {
      opacity: 0,
      duration: 0.1,
      ease: easeOut,
      onComplete: () => {
        if (media_type === 'media') {
          const isVideo = isVideoWebm(video?.filename);
          mediaContent.value = {
            type: isVideo ? 'video' : 'image',
            content: isVideo ? video : image,
          };
        } else {
          mediaContent.value = {
            type: 'youtube-video',
            content: youtube_video?.[0],
          };
        }
        modalVideo.value = video?.filename && mediaContent.value?.type === 'image' ? video : null;
        // Fade in media
        if ($mediaContainer.value) {
          gsap.to($mediaContainer.value, {
            opacity: 1,
            duration: 0.3,
            ease: easeOut,
          });
        }
      },
    });

    lastActiveMedia.value = index;
  }
}

function hasMedia(accordion: ItemAccordionMediaStoryblok | ItemAccordionStoryblok) {
  if (accordion.component === 'item_accordion') {
    return false;
  }

  return accordion.youtube_video?.[0] || accordion.media_image?.name !== '' || accordion.media_video?.name !== ''
    ? true
    : false;
}

function onAccordionToggle(index: number) {
  if (index !== -1) {
    // The accordion was opened
    activeAccordion.value = index;
  }
}

function closeAllAccordions() {
  // -2 to force closing all accordions
  activeAccordion.value = -2;
}

function scrollToContentIfPastTop() {
  const content = $content.value;
  if (!content) {
    return;
  }

  const rect = content.getBoundingClientRect();

  if (rect.top < 0) {
    const scrollOptions = {
      top: window.scrollY + rect.top - 100,
      left: 0,
    };
    window.scrollTo(scrollOptions);
  }
}

function onTabClick(index: number) {
  if (index === activeTab.value || accordionBody.value.component !== 'cm_accordion--tabs') {
    return;
  }

  displayedAccordions.value = accordionBody.value.tabs[index].accordions;

  // Reset previously active tab
  const prevIconEl = $tab.value?.[activeTab.value]?.querySelector<HTMLElement>('.tab-icon');
  const prevTextEl = $tab.value?.[activeTab.value]?.querySelector<HTMLElement>('h4');

  if (prevIconEl) {
    gsap.to(prevIconEl, {
      opacity: 0,
      transform: 'translateX(-10px)',
      duration: 0.25,
      ease: easeOut,
    });
  }
  if (prevTextEl) {
    gsap.to(prevTextEl, { left: 0, duration: 0.25, ease: easeOut });
  }
  activeTab.value = index;
  closeAllAccordions();

  // Set active tab
  const nextIconEl = $tab.value?.[index]?.querySelector<HTMLElement>('.tab-icon');
  const nextTextEl = $tab.value?.[index]?.querySelector<HTMLElement>('h4');

  if (nextIconEl) {
    gsap.fromTo(
      nextIconEl,
      { opacity: 0, transform: 'translateX(-10px)' },
      {
        opacity: 1,
        transform: 'translateX(0)',
        duration: 0.5,
        ease: easeOut,
      }
    );
  }
  if (nextTextEl) {
    gsap.fromTo(nextTextEl, { left: 0 }, { left: 24, duration: 0.1, ease: easeOut });
  }

  // Scroll page to top of content
  scrollToContentIfPastTop();
}

const prevWindowWidth = ref(0);
function resizeHandler() {
  if (prevWindowWidth.value !== window.innerWidth) {
    prevWindowWidth.value = window.innerWidth;
    closeAllAccordions();
  }
  if ($el.value) {
    checkViewPortElements($el.value);
  }
}
const debouncedResizeHandler = useDebounceFn(resizeHandler, 100);

/**
 * We only want the sidebar element to be sticky if the content is longer than the viewport
 * If we switch the behavior, we need to animate the sidebar element to its new position
 */
function checkForStickyness() {
  if ($content.value && typeof window !== 'undefined') {
    const newValue = contentElHeight.value > window.innerHeight - 92;
    if (sidebarElIsSticky.value !== newValue && $sidebarEl.value) {
      const { top } = $sidebarEl.value.getBoundingClientRect();
      $sidebarEl.value.style.position = newValue ? 'sticky' : 'static';
      const { top: newTop } = $sidebarEl.value.getBoundingClientRect();
      gsap.from($sidebarEl.value, {
        transform: `translateY(${top - newTop}px)`,
        duration: 0.25,
        ease: easeOut,
        onComplete: () => {
          $sidebarEl.value?.removeAttribute('style');
          sidebarElIsSticky.value = newValue;
        },
      });
    } else {
      sidebarElIsSticky.value = newValue;
    }
  }
}
const debouncedCheckForStickyness = useDebounceFn(checkForStickyness, 250);
watch(contentElHeight, debouncedCheckForStickyness);

watch(
  () => scrollState.top,
  () => {
    if ($el.value) {
      checkViewPortElements($el.value);
    }
  }
);

onBeforeUnmount(() => {
  if ($el.value) {
    removeViewPortElements($el.value);
  }
});

onMounted(() => {
  setActiveMedia();
  prevWindowWidth.value = window.innerWidth;
  window.addEventListener('resize', debouncedResizeHandler);

  // Appear animations
  void nextTick(() => {
    if (!$el.value) {
      return;
    }
    addViewPortElements($el.value.querySelectorAll('.heading-over-medium'));
    addViewPortElements($el.value.querySelectorAll('.heading-main-large'));
    addViewPortElements($el.value.querySelectorAll('.sidebar'));
    addViewPortElements($el.value.querySelectorAll('.accordion'));
    addViewPortElements($el.value.querySelectorAll('.tabs > li'));

    hideElements($el.value);
    gsap.set($el.value, { autoAlpha: 1 });

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      // Wait for accordion items
      if (!$el.value) {
        return;
      }
      checkViewPortElements($el.value);
    }, 150);
  });
});

onUnmounted(() => {
  clearTimeout(timeout);
  window.removeEventListener('resize', debouncedResizeHandler);
});
</script>

<style src="./CMAccordion.scss" lang="scss" scoped />
