<template>
  <div
    v-editable="blok"
    class="cm-horizontal-scrolling"
    :class="[blok.is_dark ? 'is-dark' : 'is-light', { 'is-loaded': isLoaded }]">
    <div ref="$horizontalScrolling" class="inner-container container-full">
      <div class="hs-container">
        <!-- Head Mobile -->
        <template v-for="(item, index) in blok.head" :key="index">
          <ItemContentHead
            v-if="item.component === 'item_content-head'"
            :blok="item"
            :isDark="blok.is_dark"
            :headSize="blok.head_size"
            class="head-mobile" />
          <ItemQuote
            v-else-if="item.component === 'item_quote'"
            :blok="{
              _uid: item._uid,
              component: item.component,
              text: item.text,
              layout: 'full-size',
            }"
            :isDark="blok.is_dark"
            :isLeftIndented="false"
            class="head-mobile" />
        </template>

        <!-- hs-wrapper -->
        <div ref="$hsWrapper" class="hs-wrapper">
          <!-- Head -->
          <div
            v-if="blok.head?.length && !isMobile"
            class="card is-head"
            :style="{
              width: isHeadStatement || isHeadSmall ? `calc(425px + ${getWidth(2)})` : '565px',
              paddingLeft: isHeadStatement || isHeadSmall ? getWidth(2) : '0',
            }">
            <ItemContentHead
              v-if="blok.head[0]?.component === 'item_content-head'"
              :blok="blok.head[0]"
              :isDark="blok.is_dark"
              :headSize="blok.head_size"
              class="card-inner-head" />
            <ItemQuote
              v-else-if="blok.head[0]?.component === 'item_quote'"
              :blok="{
                _uid: blok.head[0]._uid,
                component: blok.head[0].component,
                text: blok.head[0].text,
                layout: 'full-size',
              }"
              :isDark="blok.is_dark"
              :isLeftIndented="false" />
          </div>

          <template v-for="(cluster, clusterIndex) in blok.items" :key="clusterIndex">
            <!-- Space -->
            <div v-if="!(!clusterIndex && isMobile)" class="card is-item" :style="{ width: getWidth(1) }"></div>

            <!-- Card -->
            <template v-for="(card, cardIndex) in [cluster.card_1, cluster.card_2, cluster.card_3]" :key="cardIndex">
              <div
                v-if="card?.length"
                class="card is-item"
                :style="{
                  alignSelf: [
                    cluster.card_1_alignment === 'bottom' ? 'flex-end' : 'flex-start',
                    cluster.card_2_alignment === 'bottom' ? 'flex-end' : 'flex-start',
                    cluster.card_3_alignment === 'bottom' ? 'flex-end' : 'flex-start',
                  ][cardIndex],
                  width: getCardWidth(clusterIndex, cardIndex),
                }">
                <ItemCardContent
                  :blok="card[0]"
                  class="card-item-card"
                  :breakpointsWidthMap="{ '0': getImageWidth(getCardWidth(clusterIndex, cardIndex)) }" />
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDebounceFn } from '@vueuse/core';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { computed, onMounted, ref, watch } from 'vue';
import ItemCardContent from '~/components/storyblok/item/ItemCardContent/ItemCardContent.vue';
import ItemContentHead from '~/components/storyblok/item/ItemContentHead/ItemContentHead.vue';
import ItemQuote from '~/components/storyblok/item/ItemQuote/ItemQuote.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import type { CmHorizontalScrollingStoryblok, ItemCardContentMediaStoryblok } from '~/types/storyblok-generated';
import type { ModuleWrapperState } from '~/types/utils';

gsap.registerPlugin(ScrollTrigger);

const props = defineProps<{
  blok: CmHorizontalScrollingStoryblok;
  state: ModuleWrapperState;
}>();

const { state: stateBreakpoint } = useBreakpoint();

const $horizontalScrolling = ref<HTMLElement>();
const $hsWrapper = ref<HTMLElement>();
const columnWidth = ref<number>(0);
const gapWidth = ref<number>(0);
const tween = ref<gsap.core.Tween>();
const isLoaded = ref(false);
const isHeadSmall = computed(() => props.blok.head_size === 'small');
const isHeadStatement = computed(() => props.blok.head?.[0]?.component === 'item_quote');
const isMobile = computed(() => !stateBreakpoint.isPhoneLarge);
const isLaptop = computed(() => stateBreakpoint.isLaptop);

function getWidth(size: number) {
  // 10 columns for mobile and tablet, X columns for laptop
  const columns = !isMobile.value || size === 1 ? size : 10;
  // Gaps between columns
  const gapsWidth = gapWidth.value * (columns - 1);
  const width = columnWidth.value * columns + gapsWidth;
  return `${width}px`;
}

function getColumnSize(format: ItemCardContentMediaStoryblok['format'] = 'square', isLarge: boolean): number {
  const SIZE_MAP = {
    square: [5, 4, 3], // [large, small_tablet, small]
    portrait: [4, 4, 3], // [large, small_tablet, small]
    landscape: [6, 4], // [large, small]
  };

  const _isTabletOnly = stateBreakpoint.isPhoneLarge && !stateBreakpoint.isTablet;

  switch (format) {
    case 'square':
      // On tablet, portrait is always large
      return isLarge ? SIZE_MAP['square'][0] : SIZE_MAP['square'][_isTabletOnly ? 1 : 2];
    case 'portrait':
      // On tablet, portrait is always large
      return isLarge ? SIZE_MAP['portrait'][0] : SIZE_MAP['portrait'][_isTabletOnly ? 1 : 2];
    case 'landscape':
      return isLarge ? SIZE_MAP['landscape'][0] : SIZE_MAP['landscape'][1];
    default:
      return 1;
  }
}

function getCardWidth(clusterIndex: number, itemIndex: number) {
  const cluster = props.blok.items?.[clusterIndex];
  let size = 1;

  if (cluster) {
    switch (itemIndex) {
      case 0: {
        const formatCard1 =
          cluster.card_1?.[0] && cluster.card_1[0].component !== 'item_card-content-svg'
            ? cluster.card_1[0].format || undefined
            : undefined;
        size = getColumnSize(formatCard1, cluster.card_1_is_large ?? false);
        break;
      }
      case 1: {
        const formatCard2 =
          cluster.card_2?.[0] && cluster.card_2[0].component !== 'item_card-content-svg'
            ? cluster.card_2[0].format || undefined
            : undefined;
        size = getColumnSize(formatCard2, cluster.card_2_is_large ?? false);
        break;
      }
      case 2: {
        const formatCard3 =
          cluster.card_3?.[0] && cluster.card_3[0].component !== 'item_card-content-svg'
            ? cluster.card_3[0].format || undefined
            : undefined;
        size = getColumnSize(formatCard3, cluster.card_3_is_large ?? false);
        break;
      }
      default:
        break;
    }
  }

  return getWidth(size);
}

/**
 * We don't want to create lots of assets, so we limit images sizes to steps of 20
 */
function getImageWidth(cardWidth: string) {
  const parsedCardWidth = parseInt(cardWidth.replace('px', ''));
  return Math.ceil(parsedCardWidth / 20) * 20;
}

function setColumnWidth() {
  const maxWidth = 1280; // Max width 1280px to avoid y overflow
  let containerWidth = $horizontalScrolling.value?.offsetWidth ?? 1;
  gapWidth.value = isLaptop.value ? 24 : !isMobile.value ? 16 : 8; // 24px gap for laptop, 16px for tablet, 8px for mobile
  containerWidth = containerWidth > maxWidth ? maxWidth : containerWidth;
  columnWidth.value = (containerWidth - gapWidth.value * 11) / 12; // 11 gaps, 12 columns without gaps
  isLoaded.value = true;
}

function updateCardPositions() {
  if (!$hsWrapper.value) {
    return;
  }

  if (isMobile.value) {
    // Remove tween if it exists
    if (tween.value) {
      tween.value = gsap.to($hsWrapper.value, {
        duration: 0,
        x: 0,
      });
      tween.value.kill();
    }
  } else {
    const useProgress = Math.max(Math.min(-0.1 + props.state.progress * 1.2, 1), 0);
    const maxX = $hsWrapper.value.scrollWidth - $hsWrapper.value.clientWidth + 200;
    tween.value = gsap.to($hsWrapper.value, {
      ease: 'expo.out',
      duration: 0.5,
      x: -useProgress * maxX,
    });
  }
}

watch(() => props.state.progress, updateCardPositions);

onMounted(() => {
  setColumnWidth();

   
  window.addEventListener('resize', useDebounceFn(setColumnWidth, 300));
});
</script>

<style src="./CMHorizontalScrolling.scss" lang="scss" scoped />
