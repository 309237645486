<template>
  <div
    :class="[
      'util-sticky-bar-scroll-button',
      isDark ? 'is-dark' : 'is-light',
      position === 'right' ? 'is-right' : 'is-left',
      { 'is-visible': isVisible },
    ]">
    <button
      :class="['icon-button', isDark ? 'is-dark' : 'is-light']"
      :aria-label="t(`UtilStickyBarScrollButton.scrollButtonLabel.${position}`)"
      @click="emit('click', $event)">
      <BaseIcon
        :name="position === 'right' ? 'fi_arrow-right' : 'fi_arrow-left'"
        :size="16"
        color="inherit"
        class="icon" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import { BaseIcon } from '~/components/base';

defineProps<{
  isDark?: boolean;
  isVisible: boolean;
  position: 'left' | 'right';
}>();

const emit = defineEmits<{
  (e: 'click', event: MouseEvent): void;
}>();

const { t } = useI18n();
</script>

<style src="./UtilStickyBarScrollButton.scss" lang="scss" scoped />
