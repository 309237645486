<template>
  <BaseModal
    :isOpen="isOpen"
    animationType="fade"
    :isDark="true"
    class="base-modal-video"
    @loaded="showModal"
    @close="hideModal">
    <BaseVideo
      v-if="modalVideoState?.type === 'media'"
      ref="$mediaVideo"
      :autoplay="true"
      :src="modalVideoState.filename"
      :playsinline="false"
      controls />
    <UtilYoutubeVideo
      v-else-if="modalVideoState?.type === 'youtubeVideo'"
      ref="$utilYoutubeVideo"
      :blok="{ ...modalVideoState.youtubeVideoBlok, play_in_fullscreen: false }"
      :privacyWarningRichtext="youtubePrivacyWarning"
      isShownInModal />
    <button class="close" @click="hideModal">
      <BaseIcon name="fi_x" color="white" />
    </button>
  </BaseModal>
</template>

<script lang="ts" setup>
import { gsap } from 'gsap';
import BaseVideo from '~/components/base/BaseVideo.vue';
import BaseIcon from '~/components/base/BaseIcon.vue';
import BaseModal from '~/components/base/BaseModal.vue';
import { useHeader } from '~/composables/useHeader';
import { useRouter } from 'vue-router';
import { useRoute } from 'nuxt/app';
import { useModalVideo } from '~/composables/useModalVideo';
import { computed, ref, watch } from 'vue';
import UtilYoutubeVideo from '~/components/storyblok/utils/UtilYoutubeVideo/UtilYoutubeVideo.vue';
import type { RichtextStoryblok } from '~/types/storyblok-generated';

defineProps<{
  youtubePrivacyWarning: RichtextStoryblok;
}>();

const router = useRouter();
const route = useRoute();

const { state: headerState } = useHeader();
const { state: modalVideoState } = useModalVideo();

const $mediaVideo = ref<InstanceType<typeof BaseVideo>>();
const $utilYoutubeVideo = ref<InstanceType<typeof UtilYoutubeVideo>>();
const isOpen = computed(() => !!modalVideoState.value);

function showModal() {
  if (modalVideoState.value?.type === 'media' && $mediaVideo.value) {
    gsap.to($mediaVideo.value.$el as HTMLVideoElement, { autoAlpha: 1, delay: 0.4 });
  }
  if (modalVideoState.value?.type === 'youtubeVideo' && $utilYoutubeVideo.value) {
    // @ts-expect-error ($player exists, but is not typed)
    gsap.to($utilYoutubeVideo.value.$player as HTMLDivElement, { autoAlpha: 1, delay: 0.4 });
    $utilYoutubeVideo.value.startVideo();
  }
  void router.push({ query: { video: 1 } });
}

async function hideModal() {
  if ($mediaVideo.value) {
    gsap.to($mediaVideo.value.$el as HTMLVideoElement, { volume: 0, duration: 0.4 });
    await gsap.to($mediaVideo.value.$el as HTMLVideoElement, { duration: 0.25, autoAlpha: 0 });
  }
  if ($utilYoutubeVideo.value && $mediaVideo.value) {
    await gsap.to($mediaVideo.value.$el as HTMLVideoElement, { duration: 0.25, autoAlpha: 0 });
    $utilYoutubeVideo.value.killPlayer();
  }
  headerState.menuOpen = false;
  modalVideoState.value = null;
  void router.push({ query: {} });
}

watch(
  () => route.query,
  () => {
    if (isOpen.value && !route.query.video) {
      void hideModal();
    }
  }
);
</script>

<style src="./BaseModalVideo.scss" lang="scss" scoped />
