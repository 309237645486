import { reactive } from 'vue';

interface FloatingElementsState {
  alertVisible: boolean;
  chatbaseVisible: boolean;
  hideAll: boolean;
}

const state = reactive<FloatingElementsState>({
  alertVisible: false,
  chatbaseVisible: false,
  hideAll: false,
});

export function useFloatingElements() {
  return {
    state,
  };
}
