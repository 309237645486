<template>
  <BaseVideoScrub
    v-if="!isInitialLoad && media"
    :src="media.video"
    :poster="media.poster"
    :progress="progress"
    :smooth="true"
    :canBeInitialized="props.canBeInitialized"
    :isDisabled="props.isDisabled"
    @load="emit('load')" />
</template>

<script lang="ts" setup>
import { useHead } from '#imports';
import { computed, onMounted, ref } from 'vue';
import BaseVideoScrub from '~/components/base/BaseVideoScrub.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { hasMp4ScrubSupport } from '~/utils/browser';
import { hasWebCodecsSupport } from '~/utils/video-scrolling';
import type { UtilVideoScrubStoryblok } from '~/types/storyblok-generated';

const props = withDefaults(
  defineProps<{
    blok: UtilVideoScrubStoryblok;
    progress: number;
    /**
     * Used to determine what video source to use.
     */
    mediaSource?: 'tablet' | 'desktop' | 'mobile';
    isDisabled?: boolean;
    canBeInitialized?: boolean;
    preloadPoster?: boolean;
  }>(),
  { progress: 0, mediaSource: 'desktop', canBeInitialized: true }
);

const emit = defineEmits<{ (e: 'load'): void }>();

const { state: stateBreakpoint } = useBreakpoint();

const isInitialLoad = ref(true);

const showTabletMedia = computed(() => {
  return props?.mediaSource !== undefined ? props.mediaSource === 'tablet' : stateBreakpoint.isTablet;
});
const showDesktopMedia = computed(() => {
  return props?.mediaSource !== undefined ? props.mediaSource === 'desktop' : stateBreakpoint.isLaptop;
});

const media = computed(() => {
  const useMp4 = hasMp4ScrubSupport() || hasWebCodecsSupport();
  if (showDesktopMedia.value) {
    return {
      video: useMp4 ? props.blok.video?.filename : props.blok.video_webm?.filename,
      poster: props.blok.video_poster?.filename,
    };
  }
  if (showTabletMedia.value) {
    // We fall back to desktop if no tablet media is provided to provide backwards compatibility
    return {
      video: useMp4
        ? // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          props.blok.video_tablet?.filename || props.blok.video?.filename
        : // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          props.blok.video_tablet_webm?.filename || props.blok.video_webm?.filename,
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      poster: props.blok.video_tablet_poster?.filename || props.blok.video_poster?.filename,
    };
  }
  return {
    video: useMp4 ? props.blok.video_mobile?.filename : props.blok.video_mobile_webm?.filename,
    poster: props.blok.video_mobile_poster?.filename,
  };
});

/**
 * Preload the poster image
 */
if (props.preloadPoster) {
  useHead({
    link: [
      {
        rel: 'preload',
        as: 'image',
        href: props.blok.video_poster?.filename,
      },
      {
        rel: 'preload',
        as: 'image',
        href: props.blok.video_tablet_poster?.filename,
      },
      {
        rel: 'preload',
        as: 'image',
        href: props.blok.video_mobile_poster?.filename,
      },
    ],
  });
}

onMounted(() => {
  isInitialLoad.value = false;
});
</script>
