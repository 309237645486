<template>
  <div class="result" :class="{ 'is-selected': isSelected }" tabindex="0" @click="emit('click', store)">
    <p class="name text-large-bold">{{ store.content.name }}</p>
    <p v-if="store.content.is_showroom" class="tags-container">
      <UtilTag text="Showroom" theme="soft" :isBackgroundDark="true" />
    </p>
    <p class="address text-medium-regular">
      <span>{{ store.content.location?.street }}</span>
      <br />
      <span>
        {{ store.content.location?.postal_code }}{{ ' ' }}
        {{ store.content.location?.city }}
      </span>
    </p>
    <div v-if="hasValidContactInfo" ref="$contactContainer" class="contact-container">
      <UtilButton
        v-if="storeContactType === 'button' && buttonLink"
        class="contact-button"
        :blok="{
          component: 'util_button',
          theme: 'primary',
          text: 'Use contact form',
          size: 'small',
          link: buttonLink,
          _uid: 'button-contact-form',
        }" />
      <template v-else-if="storeContactType === 'list'">
        <p>
          <a v-if="store.content.phone" class="contact-list-entry" :href="'tel:' + store.content.phone">
            <BaseIcon name="fi_phone" :size="16" color="inherit" />
            <span class="action-medium-bold">
              {{ store.content.phone }}
            </span>
          </a>
        </p>
        <p>
          <a v-if="store.content.email" class="contact-list-entry" :href="'mailto:' + store.content.email">
            <BaseIcon name="fi_mail" :size="16" color="inherit" />
            <span class="action-medium-bold">
              {{ store.content.email }}
            </span>
          </a>
        </p>
        <p>
          <a
            v-if="websiteLink"
            class="contact-list-entry"
            :href="websiteLink"
            target="_blank"
            rel="noopener noreferrer">
            <BaseIcon name="fi_globe" :size="16" color="inherit" />
            <span class="action-medium-bold">
              {{ websiteLink.replace(/((^\w+:|^)\/\/)?(www\.)?/, '') }}
            </span>
          </a>
        </p>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { gsap } from 'gsap';
import type { ISbStoryData } from 'storyblok-js-client';
import { ref, watch } from 'vue';
import BaseIcon from '~/components/base/BaseIcon.vue';
import type { EmMapStoryblok, StoreEntryStoryblok } from '~/types/storyblok-generated';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import UtilTag from '~/components/storyblok/utils/UtilTag/UtilTag.vue';

const props = defineProps<{
  store: ISbStoryData<StoreEntryStoryblok>;
  isSelected?: boolean;
  buttonLink?: EmMapStoryblok['button_link'];
  storeContactType: EmMapStoryblok['store_contact_type'];
}>();

const emit = defineEmits<{
  (e: 'click', result: ISbStoryData<StoreEntryStoryblok>): void;
}>();

const hasValidButton = props.storeContactType === 'button' && !!props.buttonLink;
const websiteLink =
  props.store.content.website?.linktype === 'url'
    ? props.store.content.website?.url
    : props.store.content.website?.linktype === 'story'
      ? props.store.content.website?.story?.full_slug
      : undefined;
const hasValidList =
  props.storeContactType === 'list' && (!!props.store.content.phone || !!props.store.content.email || websiteLink);
const hasValidContactInfo = hasValidButton || hasValidList;
const $contactContainer = ref<HTMLElement>();

watch(
  () => props.isSelected,
  isSelected => {
    // Use gsap to animate to auto height if the result is selected
    if (hasValidContactInfo && $contactContainer.value) {
      gsap.to($contactContainer.value, {
        height: isSelected ? 'auto' : 0,
        duration: 0.3,
        ease: 'power2.inOut',
      });
    }
  }
);
</script>

<style src="./EMMapResult.scss" lang="scss" scoped />
