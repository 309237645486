<template>
  <div
    ref="$scrollEl"
    class="util-scrollbar"
    :class="{
      'is-full-height': fullHeight,
      'has-overflow-visible': !state.maxScroll && !disabled,
    }">
    <div ref="$scrollContainerEl" class="scroll-container">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbars } from 'overlayscrollbars';
import { nextTick, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useWindow } from '~/composables/useWindow';

interface UtilScrollableState {
  topReached: boolean;
  bottomReached: boolean;
  scroll: number;
  maxScroll: number;
  contentHeight: number;
}

const emit = defineEmits<{
  (e: 'change', state: UtilScrollableState): void;
}>();

const $scrollEl = ref<HTMLElement>();
const $scrollContainerEl = ref<HTMLElement>();
const osInstance = ref<any>();

const { state: windowState } = useWindow();

const state = reactive<UtilScrollableState>({
  topReached: false,
  bottomReached: false,
  scroll: 0,
  maxScroll: 0,
  contentHeight: 0,
});

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  fullHeight: { type: Boolean, default: false },
});

function destroyScrollbar() {
  if (!osInstance.value) {
    return;
  }
  osInstance.value.destroy();
}

function initScrollbar() {
  if (!$scrollEl.value) {
    return;
  }
  osInstance.value = OverlayScrollbars($scrollEl.value, {
    scrollbars: {
      theme: 'os-theme-dark',
      visibility: 'auto',
      autoHide: 'scroll',
      autoHideDelay: 500,
      autoHideSuspend: false,
      dragScroll: true,
      clickScroll: false,
      pointers: ['mouse', 'touch', 'pen'],
    },
  });
  osInstance.value.on('scroll', () => {
    update();
  });
  void nextTick(update);
}

function update() {
  if (!osInstance.value || !$scrollEl.value || !$scrollContainerEl.value) {
    return;
  }
  const { overflowAmount } = osInstance.value.state();
  const { scrollOffsetElement } = osInstance.value.elements();
  const { scrollTop } = scrollOffsetElement;
  state.scroll = scrollTop;
  state.maxScroll = overflowAmount.y;
  state.bottomReached = state.maxScroll > 0 ? (Math.abs(state.scroll - state.maxScroll) < 1 ? true : false) : true;
  state.topReached = state.scroll === 0 ? true : false;
  state.contentHeight = $scrollContainerEl.value.offsetHeight ?? 0;
  emit('change', state);
}

defineExpose({ update });

watch(
  () => windowState.width,
  () => nextTick(update)
);

watch(
  () => props.disabled,
  () => {
    if (!props.disabled) initScrollbar();
    if (props.disabled) destroyScrollbar();
  }
);

onUnmounted(destroyScrollbar);

onMounted(() => {
  if (!props.disabled) initScrollbar();
});
</script>

<style src="./UtilScrollable.scss" lang="scss" scoped />
