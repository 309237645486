<template>
  <section
    :id="id"
    ref="$module"
    class="core-module"
    :class="[
      isDark ? 'is-dark' : 'is-light',
      {
        'is-wrapped': isWrapped,
        'has-wrapped-border-radius': isWrapped && (appearance !== 'slide' || !state?.atTopOfViewport),
        'is-clipped': props.isWrapped || props.appearance !== 'slide-desktop',
        'is-module-slide': appearance === 'slide',
        // This class is used by other components to detetect if the next module is sticky
        'next-blok-wrapped': nextBlokWrapped,
      },
    ]"
    :style="{ zIndex: baseZIndex }">
    <slot :state="state"></slot>
    <div
      v-if="state?.blenderAlpha"
      class="blender"
      :style="{ opacity: state.blenderAlpha, zIndex: baseZIndex + 1 }"></div>
  </section>
</template>

<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { computed, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useScroll } from '~/composables/useScroll';
import { useTheme } from '~/composables/useTheme';
import type { ModuleAppearance } from '~/utils/module-settings';
import type { ModuleWrapperState } from '~/types/utils';

const props = withDefaults(
  defineProps<{
    index: number;
    appearance?: ModuleAppearance;
    isWrapped: boolean;
    nextBlokWrapped?: boolean;
    buffer?: boolean;
    hideFooter?: boolean;
    scrollMultiplier?: number;
    isDark?: boolean;
    marginMultiplier?: number;
    nextModuleSelector?: string;
    inModal?: boolean;
  }>(),
  {
    appearance: 'normal',
    scrollMultiplier: 1,
  }
);

defineEmits<{ (e: 'snap'): void }>();

const id = computed(() => (props.inModal ? `module-modal-${props.index + 1}` : `module-${props.index + 1}`));
const { state: scrollState, setActiveModule } = useScroll();
const { state: themeState } = useTheme();
const { state: stateBreakpoint } = useBreakpoint();

const $module = ref<HTMLElement>();

const state = reactive<ModuleWrapperState>({
  index: props.index,
  progress: 0,
  active: false,
  visible: false,
  inViewport: false,
  atTopOfViewport: false,
  blenderAlpha: 0,
});

let context: gsap.Context;
let trigger: ScrollTrigger;
let stateTimeout: ReturnType<typeof setTimeout>;
let updateTimeout: ReturnType<typeof setTimeout>;

const isPhoneLarge = computed(() => stateBreakpoint.isPhoneLarge);
const appearance = computed(() => {
  let _appearance = props.appearance;
  // Show normal appearance on mobile
  if (props.appearance === 'slide-desktop') {
    _appearance = isPhoneLarge.value ? 'slide' : 'normal';
  }
  return _appearance;
});
const baseZIndex = computed(() => {
  let newBaseZIndex = (props.index + 1) * 2 - 1;
  if (props.inModal || appearance.value === 'widget-overlay') {
    // Increase z-index for modals and overlay widgets
    newBaseZIndex += 50;
  }
  return newBaseZIndex;
});

function onEnter() {
  state.visible = true;
  setActiveModule(id.value, props.index);
}

function onEnterBack() {
  state.visible = true;
}

function onLeave() {
  state.visible = false;
}

function onLeaveBack() {
  state.visible = false;
}

function onToggle(self: ScrollTrigger) {
  state.active = self.isActive;
}

function onUpdate(self: ScrollTrigger) {
  // Overflow id fixed/hidden. No need to update scrolltriggers
  if (!document.documentElement.offsetHeight) {
    return;
  }

  let progress = parseFloat(self.progress.toFixed(3));

  // Checking next module pos for content dimming
  if (props.nextBlokWrapped && props.nextModuleSelector) {
    const nextModule = document.querySelector(props.nextModuleSelector);
    const nextModulePosition = nextModule?.getBoundingClientRect()?.top;

    if (nextModulePosition) {
      const nextModulePercentInViewport = -(nextModulePosition - window.innerHeight) / window.innerHeight;
      const alpha = Math.max(Math.min(nextModulePercentInViewport, 1), 0);
      gsap.to(state, { blenderAlpha: alpha, duration: 0.25 });
    }
  }

  // Adds the missing pixel if the follow content is overlapping
  if (
    appearance.value === 'slide' &&
    props.nextBlokWrapped &&
    props.nextModuleSelector &&
    !!$module.value?.parentElement &&
    props.scrollMultiplier
  ) {
    const $nextEl = document.querySelector(props.nextModuleSelector);
    if ($nextEl) {
      // Overlap progress interpolation
      const spacerY = -$module.value?.parentElement.getBoundingClientRect().y;
      const overlapProgress = spacerY / (props.scrollMultiplier * window.innerHeight);
      progress = Math.min(Math.max(overlapProgress, 0), 1);
    }
  }

  // If buffer is enabled, we want to make the progress a bit more aggressive
  if (props.buffer) {
    progress = -0.1 + progress * 1.2;
    progress = progress < 0 ? 0 : progress;
    progress = progress > 1 ? 1 : progress;
  }

  state.progress = progress;
}

function observeModulePosition() {
  if (!$module.value) {
    return;
  }

  const moduleBounds: DOMRect = $module.value.getBoundingClientRect();
  const topBuffer = 72;
  if (moduleBounds.y < topBuffer && moduleBounds.y + moduleBounds.height > 0) {
    themeState.isDark = props.isDark;
  }
}

function initContext() {
  context = gsap.context(() => {
    const triggerConfig: ScrollTrigger.StaticVars = {
      pin: false,
      pinSpacing: false,
      trigger: $module.value,
      start: 'top top',
      fastScrollEnd: true,
      anticipatePin: 1,
      invalidateOnRefresh: true,
      preventOverlaps: true,
      onEnter,
      onEnterBack,
      onLeave,
      onLeaveBack,
      onUpdate,
      onToggle,
    };

    switch (appearance.value) {
      case 'sticky':
      case 'slide': {
        triggerConfig.pin = true;
        triggerConfig.pinSpacing = appearance.value === 'slide';
        const scrollRange = Math.round(150 * props.scrollMultiplier);

        triggerConfig.start = 'top top';
        triggerConfig.end = 'bottom top-=' + scrollRange + '%';

        // Manual pin spacing. Progress is not correct afterwards.
        if (props.nextBlokWrapped && !!$module.value && props.scrollMultiplier) {
          triggerConfig.pinSpacing = false;
          triggerConfig.endTrigger = props.nextModuleSelector;
          triggerConfig.end = 'top top';

          gsap.set($module.value, {
            marginBottom: props.scrollMultiplier * window.innerHeight,
          });
        }

        break;
      }
      default: {
        triggerConfig.start = 'top bottom';
        triggerConfig.end = 'bottom center';

        if (props.nextBlokWrapped) {
          triggerConfig.pin = true;
          triggerConfig.pinSpacing = false;

          triggerConfig.start = 'bottom bottom';
          triggerConfig.end = 'bottom bottom-=100%';
        }
        break;
      }
    }

    trigger = ScrollTrigger.create(triggerConfig);

    if (props.appearance === 'slide-desktop') {
      // Only way to change the background color of the spacer
      // @ts-expect-error - We need to access the private property
      const spacer = trigger.spacer;
      if (spacer) {
        spacer.style.backgroundColor = props.isWrapped ? 'transparent' : props.isDark ? '#0d0d0d' : '#fff';
      }
    }
  });
}

function calculateViewport() {
  if (!$module.value) {
    return;
  }

  const inViewport = ScrollTrigger.isInViewport($module.value, 0.1);

  // If not in viewport, we don't need to do anything
  if (!inViewport) {
    state.inViewport = false;
    state.atTopOfViewport = false;
    return;
  }

  // Check if the element reached the top of the viewport
  state.atTopOfViewport = $module.value.getBoundingClientRect().top <= 1;

  const positionInViewport = Number(ScrollTrigger.positionInViewport($module.value, 'center').toFixed(2));

  if (appearance.value !== 'slide' && props.nextBlokWrapped && positionInViewport > 0) {
    const sectionBounds = $module.value?.getBoundingClientRect();
    const position = window.innerHeight - sectionBounds.y;
    const bottomProgress = position / sectionBounds.height;
    if (bottomProgress >= 0 && bottomProgress <= 1) {
      state.progress = bottomProgress;
    }
  } else {
    state.inViewport = true;
  }
}

watch(
  () => scrollState.top,
  () => {
    // Wrapper observer for theme handling
    observeModulePosition();

    // Viewport calculation
    calculateViewport();
  }
);

/**
 * Sometimes the height of a module changes after the initial setup of this wrapper.
 * To prevent visual issues we disable the trigger for a short time and enable it afterwards.
 */
function handleResetEvent() {
  clearTimeout(updateTimeout);
  updateTimeout = setTimeout(() => {
    onUpdate(trigger);
  }, 500);
}

function handleResize() {
  if (trigger.refresh) {
    trigger.refresh();
  }
}
const debouncedHandleResize = useDebounceFn(handleResize, 50);

onBeforeUnmount(() => {
  clearTimeout(stateTimeout);
  clearTimeout(updateTimeout);
  document.removeEventListener('resetModuleWrapper', handleResetEvent);
  window.removeEventListener('resize', debouncedHandleResize);
  if (context) {
    context.revert();
  }
  if (trigger) {
    trigger.kill();
  }
});

onMounted(() => {
  gsap.registerPlugin(ScrollTrigger);
  document.addEventListener('resetModuleWrapper', handleResetEvent);
  window.addEventListener('resize', debouncedHandleResize);
  stateTimeout = setTimeout(() => {
    state.progress = 0;
    initContext();
    calculateViewport();
  }, 101);
});
</script>

<style src="./CorePageBlokModuleWrapper.scss" lang="scss" scoped />
