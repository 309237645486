<template>
  <div ref="$el" v-editable="blok" class="cm-copy-media container-full" :class="blok.is_dark ? 'is-dark' : 'is-light'">
    <div class="content">
      <!-- Mobile Head -->
      <ItemContentHead
        v-if="blok.head?.length"
        :blok="blok.head[0]"
        :isDark="blok.is_dark"
        :headSize="blok.head_size"
        class="head-mobile" />

      <!-- Media -->
      <div
        class="media"
        :class="[
          `is-${blok.media_card_size || 'medium'}`,
          {
            'is-right': !isMediaLeft,
            'is-card': blok.media?.[0]?.component !== 'item_copy-media-media',
          },
        ]">
        <template v-for="(media, index) in blok.media" :key="index">
          <!-- Card Content -->
          <ItemCardContent
            v-if="media.component !== 'item_copy-media-media' && media.component !== 'item_youtube-video'"
            v-editable="blok.media"
            :breakpointsWidthMap="breakpointsWidthMap"
            :blok="media" />

          <!-- Media Object (Image, video or Youtube video)-->
          <div v-else v-editable="blok.media" class="media-object">
            <UtilYoutubeVideo
              v-if="media.component === 'item_youtube-video'"
              :blok="media"
              :privacyWarningRichtext="settings?.youtube_privacy_warning"
              :breakpointsWidthMap="breakpointsWidthMap" />
            <template v-if="media.component === 'item_copy-media-media'">
              <UtilMediaResponsive
                :image="media.image"
                :video="media.video"
                :autoplay="true"
                :aspectRatio="media.media_ratio"
                :breakpointsWidthMap="breakpointsWidthMap"
                :isDark="media.is_play_button_dark" />
              <div v-if="media.caption" class="text-small-regular">
                {{ media.caption }}
              </div>
            </template>
          </div>
        </template>
      </div>

      <!-- Body -->
      <div v-if="blok.body?.length" class="body" :class="{ 'is-left': isMediaLeft }">
        <ItemContentHead
          v-if="blok.head?.length"
          :blok="blok.head[0]"
          :isDark="blok.is_dark"
          :headSize="blok.head_size"
          class="head-desktop" />
        <div class="body-text" data-transition-delay=".25">
          <template v-for="(item, index) in blok.body" :key="index">
            <UtilRichtext v-if="item.component === 'util_richtext'" :blok="item" :isDark="blok.is_dark" />
            <ItemQuote v-if="item.component === 'item_quote'" :blok="item" :isDark="blok.is_dark" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import gsap from 'gsap';
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import ItemCardContent from '~/components/storyblok/item/ItemCardContent/ItemCardContent.vue';
import ItemContentHead from '~/components/storyblok/item/ItemContentHead/ItemContentHead.vue';
import ItemQuote from '~/components/storyblok/item/ItemQuote/ItemQuote.vue';
import UtilMediaResponsive from '~/components/storyblok/utils/UtilMediaResponsive/UtilMediaResponsive.vue';
import UtilRichtext from '~/components/storyblok/utils/UtilRichtext/UtilRichtext.vue';
import UtilYoutubeVideo from '~/components/storyblok/utils/UtilYoutubeVideo/UtilYoutubeVideo.vue';
import { useTransition } from '~/composables/useTransition';
import type { CmCopyMediaStoryblok, CoreSettingsStoryblok } from '~/types/storyblok-generated';
import type { ModuleWrapperState } from '~/types/utils';

const props = defineProps<{
  blok: CmCopyMediaStoryblok;
  state: ModuleWrapperState;
  settings: CoreSettingsStoryblok;
}>();

const { hideElements, addViewPortElements, removeViewPortElements, checkViewPortElements } = useTransition();

const isMediaLeft = computed(() => props.blok.alignment === 'left');
const $el = ref<HTMLElement>();

const breakpointsWidthMap = computed(() => {
  switch (props.blok.media_card_size) {
    case 'small':
      return {
        '0': 529,
        '576': 706,
        '768': 942,
        '1024': 313,
        '1441': 424,
        '1921': 542,
        '2440': 462,
      };
    case 'large':
      return {
        '0': 529,
        '576': 706,
        '768': 942,
        '1024': 538,
        '1441': 722,
        '1921': 921,
        '2440': 786,
      };
    default:
      // medium
      return {
        '0': 529,
        '576': 706,
        '768': 942,
        '1024': 426,
        '1441': 573,
        '1921': 723,
        '2440': 624,
      };
  }
});

watch(
  () => props.state.progress,
  () => {
    if (!$el.value) {
      return;
    }
    checkViewPortElements($el.value);
  }
);

onUnmounted(() => {
  if (!$el.value) {
    return;
  }
  removeViewPortElements($el.value);
});

onMounted(() => {
  if (!$el.value) {
    return;
  }
  addViewPortElements($el.value.querySelectorAll('.body-text'));
  addViewPortElements($el.value.querySelectorAll('.media-object'));
  addViewPortElements($el.value.querySelectorAll('.media'));

  void nextTick(() => {
    if (!$el.value) {
      return;
    }
    hideElements($el.value);
    gsap.set($el.value, { autoAlpha: 1 });
  });
});
</script>

<style src="./CMCopyMedia.scss" lang="scss" scoped />
