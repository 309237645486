import type { Language } from '~/types/locales';
import type { AssetStoryblok } from '~/types/storyblok-generated';

export function getAltText(
  localeLanguage: Language,
  asset?: AssetStoryblok,
  customSrc?: string,
  customAlt?: string
): string {
  if (asset) {
    // @ts-expect-error - meta_data of assets is not typed
    const altLang = asset.meta_data && (asset.meta_data[`alt_${localeLanguage}`] as string);
    return altLang || asset.alt || '';
  }

  if (customAlt) {
    return customAlt;
  }

  return customSrc?.split('/').slice(-1)[0].split('.')[0] || '';
}

/**
 * Returns the height of an image based on the width and the height ratio.
 * 0 tells Storyblok that no height is set and instead a "auto" height is used.
 */
export function getHeightByRatio(width: number, heightRatio?: number[]): number {
  return heightRatio ? Math.round((width * heightRatio[1]) / heightRatio[0]) : 0;
}

interface GetSrcResizedParams {
  src: string;
  width: number;
  heightRatio?: number[];
  cropFitIn?: boolean;
  isSVG?: boolean;
}

export function getSrcResized({ src, width, heightRatio, cropFitIn, isSVG }: GetSrcResizedParams): {
  '1x': string;
  '2x': string;
} {
  // We limit the width to 3840px to prevent oversized images
  const adjustedWidth = Math.min(width, 3840);
  const height = getHeightByRatio(adjustedWidth, heightRatio);

  // Placeholder in case of empty src
  if (src === '') {
    return {
      '1x': `https://via.placeholder.com/${adjustedWidth}x${height}`,
      '2x': `https://via.placeholder.com/${adjustedWidth * 2}x${height * 2}`,
    };
  }

  // Enable resizing for Lorem Picsum in Storybook
  if (src.startsWith('https://picsum.photos')) {
    return {
      '1x': `${src}/${adjustedWidth}/${height}`,
      '2x': `${src}/${adjustedWidth * 2}/${height * 2}`,
    };
  }

  if (isSVG) {
    return {
      '1x': src,
      '2x': src,
    };
  }

  let baseSrc = `${src}/m/`;

  if (cropFitIn) {
    baseSrc += `fit-in/`;
  }

  return {
    '1x': `${baseSrc}${adjustedWidth}x${height}`,
    '2x': `${baseSrc}${adjustedWidth * 2}x${height * 2}`,
  };
}

export function getActiveBreakpointWidth(breakpointsWidthMap: Record<string, number>) {
  if (import.meta.server) {
    return breakpointsWidthMap[Object.keys(breakpointsWidthMap)[0]];
  }
  const activeBreakpoint = Object.entries(breakpointsWidthMap).find(([_, width]) => {
    return window.matchMedia(`(min-width: ${width}px)`).matches;
  });
  if (!activeBreakpoint) {
    return breakpointsWidthMap[Object.keys(breakpointsWidthMap)[0]];
  }
  return activeBreakpoint[1];
}
