<template>
  <div ref="$container" class="em-showroom-loader" :class="{ 'is-visible': loaderVisible }">
    <div ref="$content" class="content">
      <img
        v-if="animationDisplayed"
        class="loader-animation"
        src="/videos/showroom-loader_center.gif"
        alt="Line animation of WMF coffee machines" />
      <p
        v-for="(loaderText, index) in loaderTexts"
        :key="loaderText"
        class="loader-text"
        :class="{ 'is-visible': loaderTextIndex !== null && index === loaderTextIndex % loaderTexts.length }">
        {{ loaderText }}
      </p>
      <div class="loader-bar">
        <div class="loader-fill" :style="{ transform: `scaleX(${progress})` }"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nextTick } from 'process';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';

const props = defineProps<{
  progress: number;
  text?: string;
}>();

const emit = defineEmits<{ (e: 'remove'): void }>();

// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
const loaderTexts = computed(() => props.text?.split('\n') || []);
const loaderTextIndex = ref<number | null>(null);
const loaderTextInterval = ref<ReturnType<typeof setInterval>>();
const loaderVisible = ref(true);
const animationDisplayed = ref(true);
const $container = ref<HTMLElement>();
const $content = ref<HTMLElement>();

function updateText() {
  loaderTextIndex.value = ((loaderTextIndex.value ?? 0) + 1) % loaderTexts.value.length;
}

watch(
  () => props.progress,
  () => {
    if (props.progress === 1) {
      clearInterval(loaderTextInterval.value);
      $container.value?.addEventListener('transitionend', handleContainerTransitionEnd);
      $content.value?.addEventListener('transitionend', handleContentTransitionEnd);
      nextTick(() => {
        loaderVisible.value = false;
      });
    }
  }
);

function handleContainerTransitionEnd(e: TransitionEvent) {
  if (e.srcElement === $container.value && e.propertyName === 'opacity') {
    emit('remove');
  }
}

function handleContentTransitionEnd(e: TransitionEvent) {
  if (e.srcElement === $content.value && e.propertyName === 'opacity') {
    animationDisplayed.value = false;
  }
}

onMounted(() => {
  clearInterval(loaderTextInterval.value);
  loaderTextInterval.value = setInterval(updateText, 3500);
  updateText();
});

onBeforeUnmount(() => {
  clearInterval(loaderTextInterval.value);
  $container.value?.removeEventListener('transitionend', handleContainerTransitionEnd);
  $content.value?.removeEventListener('transitionend', handleContentTransitionEnd);
});
</script>

<style src="./EMShowroomLoader.scss" lang="scss" scoped />
