<template>
  <div ref="$el" class="em-showroom-filters" :class="{ 'is-visible': props.isVisible, 'is-displayed': isDisplayed }">
    <div ref="$blender" class="blender"></div>

    <div class="panel-container container-full">
      <div
        ref="$panel"
        class="panel"
        :class="{
          'is-open': state.opened,
          'has-scrollbar': contentBorderStyles.top || contentBorderStyles.bottom,
        }">
        <div class="close" @click="emit('close')">
          <BaseIcon name="fi_x" color="white" />
        </div>

        <div class="content">
          <div class="content-border" :style="{ opacity: contentBorderStyles.top ? 1 : 0 }"></div>
          <div class="content-border" :style="{ opacity: contentBorderStyles.bottom ? 1 : 0 }"></div>

          <UtilScrollable ref="$scrollbar" @change="onScrollbarChange">
            <div class="padding-wrapper is-header">
              <h3 class="heading-sub-medium fadeable">
                {{ blok.filter_headline }}
              </h3>

              <div v-if="blok.filter_dropdowns?.[0]" class="selectbox is-type fadeable">
                <div class="selectbox-label action-small-regular">
                  {{ blok.filter_dropdowns?.[0].dropdown_label }}
                </div>
                <ItemDropdown
                  :isDark="true"
                  :options="blok.filter_dropdowns ? filterDropDownItems(blok.filter_dropdowns[0].items ?? []) : []"
                  :selectedOption="dropDownOptions.type"
                  :isFloating="false"
                  :isShowroomStyle="true"
                  uniqueId="type"
                  @select="e => onDropDownSelect(e, 'type')"
                  @reset="() => onDropDownReset('type')"
                  @tween="onDropDownTween" />
              </div>

              <div v-if="blok.filter_dropdowns?.[1]" class="selectbox is-industry fadeable">
                <div class="selectbox-label action-small-regular">
                  {{ blok.filter_dropdowns?.[1].dropdown_label }}
                </div>
                <ItemDropdown
                  :isDark="true"
                  :options="filterDropDownItems(blok.filter_dropdowns[1].items ?? []) ?? []"
                  :selectedOption="dropDownOptions.industry"
                  :isFloating="false"
                  :isShowroomStyle="true"
                  uniqueId="industry"
                  @select="e => onDropDownSelect(e, 'industry')"
                  @reset="() => onDropDownReset('industry')"
                  @tween="onDropDownTween" />
              </div>
            </div>

            <div class="volume-animation fadeable">
              <EMShowroomVolumeAnimation v-if="state.animated" :step="state.volumeStep" />
            </div>
            <div class="padding-wrapper fadeable">
              <div v-if="currentVolume" class="volume-infos">
                <span class="action-medium-bold">
                  {{ currentVolume.label }}
                </span>
                <span class="action-small-regular">
                  {{ currentVolume.description?.split('[x]').join(currentVolume.volume?.toString()) }}
                </span>
              </div>

              <UtilButton
                class="pager is-minus"
                :blok="pagerButtonMinus"
                :isBackgroundDark="true"
                :isSmallOnMobile="true"
                :isDisabled="state.volumeStep === 0"
                @click="onVolumeStepClick(-1)" />
              <UtilButton
                class="pager is-plus"
                :blok="pagerButtonPlus"
                :isBackgroundDark="true"
                :isSmallOnMobile="true"
                :isDisabled="state.volumeStep === 2"
                @click="onVolumeStepClick(1)" />
            </div>

            <div class="scrollbar-bouncer" :class="{ 'is-active': state.hasActiveDropDown }"></div>
          </UtilScrollable>
        </div>

        <div class="actions padding-wrapper fadeable">
          <UtilLinkItemIcon
            :isDark="true"
            :isDisabled="filtered.length === allMachinesLength"
            :blok="resetButton"
            @click="resetFilters" />
          <UtilButton
            :blok="applyButton"
            :isBackgroundDark="true"
            :isDisabled="!filtered.length"
            @click="applyFilters" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from '#imports';
import { onClickOutside } from '@vueuse/core';
import gsap from 'gsap';
import { computed, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';
import { BaseIcon } from '~/components/base';
import type { ItemDropdownOption } from '~/components/storyblok/item/ItemDropdown/ItemDropdown.types';
import ItemDropdown from '~/components/storyblok/item/ItemDropdown/ItemDropdown.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import UtilLinkItemIcon from '~/components/storyblok/utils/UtilLinkItemIcon/UtilLinkItemIcon.vue';
import UtilScrollable from '~/components/storyblok/utils/UtilScrollable/UtilScrollable.vue';
import { useFloatingElements } from '~/composables/useFloatingElements';
import { useHeader } from '~/composables/useHeader';
import { useTransition } from '~/composables/useTransition';
import type {
  EmShowroomStoryblok,
  UtilButtonStoryblok,
  UtilDropdownItemStoryblok,
  UtilLinkItemIconStoryblok,
} from '~/types/storyblok-generated';
import type { ShowroomDropdownKeys, ShowroomFilters, ShowroomVolume } from './EMShowroom.types';
import EMShowroomVolumeAnimation from './EMShowroomVolumeAnimation.vue';

const props = defineProps<{
  isVisible: boolean;
  product: Record<string, any>;
  filtered: any[];
  allMachinesLength: number;
  blok: EmShowroomStoryblok;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'filtersChange', filter: ShowroomFilters): void;
  (e: 'applyFilters'): void;
}>();

const { fadeSlide } = useTransition();

const $el = ref<HTMLElement>();
const $blender = ref<HTMLElement>();
const $panel = ref<HTMLElement>();
const $scrollbar = ref<typeof UtilScrollable>();
const isDisplayed = ref(props.isVisible);
let visibleTimeout: ReturnType<typeof setTimeout>;

let clickOutsideDestroy: () => void;

const { state: headerState } = useHeader();
const { state: floatingElementsState } = useFloatingElements();
const { t } = useI18n();

const state = reactive<{
  opened: boolean;
  animated: boolean;
  volumeStep: ShowroomVolume;
  hasActiveDropDown: boolean;
}>({
  opened: false,
  animated: false,
  volumeStep: 0,
  hasActiveDropDown: false,
});

const contentBorderStyles = reactive({
  top: false,
  bottom: false,
});

function onDropDownTween() {
  $scrollbar.value?.update(false, true);
}

function onDropDownReset(dropdownId: ShowroomDropdownKeys) {
  dropDownOptions[dropdownId] = null;
  filters[dropdownId] = undefined;
}

function onDropDownSelect(option: ItemDropdownOption, dropdownId: ShowroomDropdownKeys) {
  dropDownOptions[dropdownId] = option;
  filters[dropdownId] = option.value;
}

function filterDropDownItems(items: UtilDropdownItemStoryblok[]): ItemDropdownOption[] {
  return items
    .map(item => {
      if (!item.name || !item.value) {
        return null;
      }
      return {
        name: item.name,
        value: item.value,
      };
    })
    .filter(item => item !== null);
}

const resetButton = computed<UtilLinkItemIconStoryblok>(() => ({
  text: t('forms.resetSelection'),
  icon: 'fi_rotate-ccw',
  icon_position: 'end',
  component: 'util_link-item-icon',
  _uid: 'reset-button',
}));

const applyButton = computed<UtilButtonStoryblok>(() => ({
  component: 'util_button',
  size: 'large',
  text: t('EMShowroom.showXResults', { count: props.filtered.length }),
  theme: 'primary',
  _uid: 'apply-button',
}));

const currentVolume = computed(() => props.blok.filter_volume?.[state.volumeStep]);

const dropDownOptions = reactive<Record<ShowroomDropdownKeys, ItemDropdownOption | null>>({
  industry: null,
  type: null,
});

const filters = reactive<ShowroomFilters>({
  industry: undefined,
  type: undefined,
  volume: undefined,
});

function resetFilters() {
  state.volumeStep = 0;
  filters.industry = undefined;
  filters.type = undefined;
  filters.volume = undefined;
  dropDownOptions.industry = null;
  dropDownOptions.type = null;
}

function applyFilters() {
  if (!props.filtered.length) {
    return;
  }
  emit('applyFilters');
}

function onVolumeStepClick(direction: number) {
  const newStep = state.volumeStep + direction;
  state.volumeStep = Math.min(Math.max(newStep, 0), 2) as ShowroomVolume;
}

watch(
  () => state.volumeStep,
  () => {
    if (!$el.value) {
      return;
    }
    filters.volume =
      currentVolume.value?.volume !== undefined ? (parseInt(currentVolume.value.volume) as ShowroomVolume) : 0;
    const items = $el.value.querySelectorAll('.volume-infos span');
    gsap.set(items, { y: 5, autoAlpha: 0, overwrite: true });
    gsap.to(items, { ease: 'expo.out', y: 0, stagger: 0.1 });
    gsap.to(items, { autoAlpha: 1, stagger: 0.1 });
  }
);

watch(
  () => filters,
  () => {
    emit('filtersChange', filters);
  },
  { deep: true }
);

function onScrollbarChange(e: { topReached: any; bottomReached: any }) {
  contentBorderStyles.top = !e.topReached;
  contentBorderStyles.bottom = !e.bottomReached;
}

const pagerButtonPlus: UtilButtonStoryblok = {
  component: 'util_button',
  text: '',
  theme: 'secondary',
  size: 'small',
  icon: 'fi_plus',
  _uid: 'pager-button-plus',
};

const pagerButtonMinus: UtilButtonStoryblok = {
  component: 'util_button',
  text: '',
  theme: 'secondary',
  size: 'small',
  icon: 'fi_minus',
  _uid: 'pager-button-minus',
};

function fadeContents() {
  if (!$el.value) {
    return;
  }
  state.animated = true;
  const elements = Array.from($el.value.querySelectorAll('.fadeable')); //.reverse();
  fadeSlide(elements, { staggerEach: 0.1, speedMultiplier: 0.5 });
}

watch(
  () => props.isVisible,
  () => {
    headerState.dimmed = props.isVisible;
    floatingElementsState.hideAll = props.isVisible;
    state.opened = props.isVisible;
    if (props.isVisible) {
      isDisplayed.value = true;
      fadeContents();
    } else {
      clearTimeout(visibleTimeout);
      visibleTimeout = setTimeout(() => {
        state.animated = false;
        isDisplayed.value = false;
      }, 400);
    }
  }
);

onBeforeUnmount(() => {
  clearTimeout(visibleTimeout);
  if (clickOutsideDestroy) {
    clickOutsideDestroy();
  }
});

onMounted(() => {
  clickOutsideDestroy = onClickOutside($panel.value, () => {
    if (!state.opened) {
      return;
    }
    setTimeout(() => {
      emit('close');
    }, 50);
  });
});
</script>

<style src="./EMShowroomFilters.scss" lang="scss" scoped />
