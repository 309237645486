<template>
  <div ref="$el" class="em-showroom-media">
    <!-- Button overlay -->
    <div class="pager-wrapper">
      <!-- Buttons -->
      <UtilButton class="pager is-left" :blok="pagerButton" :isBackgroundDark="true" @click="onPrevClick" />

      <UtilButton class="pager is-right" :blok="pagerButton" :isBackgroundDark="true" @click="onNextClick" />
    </div>

    <!-- Header and stuff -->
    <div class="container-full header-container">
      <div class="grid">
        <div class="header">
          <div class="tags">
            <UtilTag
              v-if="currentProduct.content.is_new"
              text="New"
              icon="fi_bell"
              theme="hard"
              :isBackgroundDark="true" />
            <UtilTag :text="getMachineTypeText(currentProduct.content.type)" :isBackgroundDark="true" />
          </div>

          <h2 class="headline heading-main-large">
            {{ currentProduct.name }}
          </h2>

          <UtilButton v-if="!buttonIsFollowing" :key="productButton._uid" :blok="productButton" class="product-cta" />
        </div>
      </div>
    </div>

    <!-- Cards -->
    <EMShowroomCards
      :product="currentProduct"
      :cursorMessages="cursorMessages"
      :layout="cardLayout"
      :cardsVisible="cardsVisible"
      :coffeeConnectImage="
        props.product.content.is_dark_variant ? blok.coffee_connect_image_dark : blok.coffee_connect_image_light
      "
      :servingPowerSubline="t(`EMShowroom.servingPowerSubline.${servingPowerUnit}`)"
      :offset="cardOffset"
      :darkVariant="props.product.content.is_dark_variant"
      @hero="onHeroHover" />

    <!-- Filter button -->
    <div class="filter-container">
      <div class="container-full">
        <div class="filter">
          <UtilButton
            :blok="filterButton"
            :isBackgroundDark="true"
            :isSmallOnMobile="true"
            class="filter-button"
            @click="emit('toggleFilters')" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from '#imports';
import gsap from 'gsap';
import type { ISbStoryData } from 'storyblok-js-client';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import UtilTag from '~/components/storyblok/utils/UtilTag/UtilTag.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useProduct } from '~/composables/useProduct';
import { useTransition } from '~/composables/useTransition';
import type { DataProductStoryblok, EmShowroomStoryblok, UtilButtonStoryblok } from '~/types/storyblok-generated';
import { ShowroomEvent, type ShowroomCanvas, type ShowroomEventsMap } from '~/webglApp/webGlApp.types';
import type { ShowroomPointerState } from './EMShowroom.types';
import EMShowroomCards from './EMShowroomCards.vue';

const props = withDefaults(
  defineProps<{
    blok: EmShowroomStoryblok;
    product: ISbStoryData<DataProductStoryblok>;
    productsLength?: number;
    filteredProductsLength?: number;
    pointer?: ShowroomPointerState | null;
    sensor?: string;
    filtersToggled?: boolean;
    cursorMessages?: string[];
    currentIndex: number;
  }>(),
  {
    currentIndex: 0,
    cursorMessages: () => ['Please add a cursor Text'],
  }
);

const emit = defineEmits<{
  (e: 'toggleFilters'): void;
  (e: 'prev'): void;
  (e: 'next'): void;
  (e: 'product', data: string): void;
  (e: 'hero', data: boolean): void;
}>();

defineExpose({ onPrevClick, onNextClick });

const { getMachineTypeText } = useProduct();
const { state: stateBreakpoint, isTouch } = useBreakpoint();
const { fadeSlide } = useTransition();
const { t } = useI18n();
const isDesktop = computed((): boolean => stateBreakpoint.isTabletLarge);
const buttonIsFollowing = computed(() => stateBreakpoint.isTablet && !isTouch.value);

const $el = ref<HTMLElement>();
const $webGlCanvas = ref<ShowroomCanvas>();
const currentProduct = ref<ISbStoryData<DataProductStoryblok>>(props.product);
const transitionDone = ref(true);
const buttonsEnabled = ref(true);
let timeout: ReturnType<typeof setTimeout>;

const servingPowerUnit = computed(() => {
  let servingPowerUnit: typeof props.product.content.serving_power_unit = props.product.content.serving_power_unit;
  if (!props.product.content.serving_power_unit) {
    servingPowerUnit = 'per-day';
  }
  return servingPowerUnit;
});

function onNextClick() {
  if (!transitionDone.value || !buttonsEnabled.value) {
    return;
  }
  _productChangedBySwipe = false;
  transitionDone.value = false;
  emit('next');
}

function onPrevClick() {
  if (!transitionDone.value || !buttonsEnabled.value) {
    return;
  }
  _productChangedBySwipe = false;
  transitionDone.value = false;
  emit('prev');
}

const productButton = computed<UtilButtonStoryblok>(() => ({
  component: 'util_button',
  text: props.cursorMessages[0],
  theme: 'primary',
  size: 'large',
  link: currentProduct.value.content.page,
  _uid: currentProduct.value.content._uid,
}));

const filterButton = ref<UtilButtonStoryblok>({
  component: 'util_button',
  text: t('EMShowroom.filtersToggleLabel'),
  theme: 'secondary',
  size: 'large',
  icon: 'fi_sliders',
  _uid: 'filter-button',
});

const pagerButton = computed<UtilButtonStoryblok>(() => ({
  component: 'util_button',
  text: '',
  theme: 'secondary',
  size: isDesktop.value ? 'large' : 'small',
  icon: 'fi_arrow-right',
  _uid: 'pager-button',
}));

const cardLayout = computed(() => {
  const flipped = Math.random() > 0.5;
  return currentProduct.value.content.is_wide ? 'landscape' : !flipped ? 'portrait' : 'portrait-flipped';
});

function hide() {
  if (_showTimeout) {
    clearTimeout(_showTimeout);
  }
  if (!$el.value) {
    return;
  }

  const tags = $el.value.querySelector('.tags')?.children;
  if (tags) {
    gsap.to(tags, { duration: 0.25, overwrite: true, autoAlpha: 0, y: 0 });
  }

  const headline = $el.value.querySelector('.heading-main-large');
  gsap.to(headline, { duration: 0.25, overwrite: true, autoAlpha: 0, y: 0 });

  const cta = $el.value.querySelector('.product-cta');
  if (cta) {
    if (cta) gsap.to(cta, { duration: 0.25, overwrite: true, autoAlpha: 0, y: 0 });
  }

  cardsVisible.value = false;
}

async function show() {
  if (!$el.value) {
    return;
  }
  currentProduct.value = props.product;
  await nextTick();
  _productChangePending = false;
  updateArrows();
  checkCardVisibility();

  // Tags
  const tagsContainer = $el.value.querySelector<HTMLElement>('.tags');
  const heading = $el.value.querySelector<HTMLElement>('.heading-main-large');
  if (!tagsContainer || !heading) {
    return;
  }
  const tagsTop = tagsContainer.offsetTop < heading.offsetTop;
  const tags = Array.from(tagsContainer.children);
  gsap.set(tags, { y: 40, overwrite: true, autoAlpha: 0 });
  gsap.to(tags, {
    duration: 1,
    y: 0,
    ease: 'expo.out',
    delay: tagsTop ? 0.2 : 0.4,
  });
  gsap
    .to(tags, {
      duration: 0.5,
      autoAlpha: 1,
      delay: tagsTop ? 0.2 : 0.4,
    })
    .then(() => {
      if (!isDesktop.value) {
        showCards(true);
      }
    })
    .catch(console.error);

  // Headline
  const headline = $el.value.querySelector('.heading-main-large');
  gsap.set(headline, { overwrite: true, autoAlpha: 0, y: 40 });
  gsap.to(headline, {
    duration: 0.5,
    autoAlpha: 1,
    delay: !tagsTop ? 0.2 : 0.4,
  });
  gsap.to(headline, {
    duration: 1,
    y: 0,
    ease: 'expo.out',
    delay: !tagsTop ? 0.2 : 0.4,
  });

  // CTA
  const cta = $el.value.querySelector('.product-cta');
  if (cta) {
    gsap.set(cta, {
      overwrite: true,
      autoAlpha: 0,
      y: isDesktop.value ? 0 : 40,
    });
    gsap.to(cta, {
      duration: 1,
      y: 0,
      delay: 0.5,
      ease: 'expo.out',
    });
    gsap.to(cta, {
      duration: 0.5,
      autoAlpha: 1,
      delay: 0.5,
    });
  }
}

const cardsVisible = ref(false);
const cardOffset = ref({ x: 0, y: 0 });

function showCards(force = false) {
  if (_productChangePending) {
    return;
  }
  if (!transitionDone.value && !force) {
    return;
  }
  if (props.filtersToggled) {
    return;
  }
  cardsVisible.value = true;
}

function hideCards() {
  cardsVisible.value = false;
}

function onHeroHover(value: boolean) {
  if (value && isDesktop.value) {
    showCards();
  } else if (isDesktop.value) {
    hideCards();
  }
}

let _showTimeout: string | number | NodeJS.Timeout | null | undefined = null;

watch(
  () => props.filtersToggled,
  () => {
    updateArrows();

    if (props.filtersToggled) hide();
    if (!props.filtersToggled) {
      if (!_productChangePending)
        _showTimeout = setTimeout(() => {
          void show();
        }, 50);
    }
  }
);

function updateArrows() {
  if (!$el.value) {
    return;
  }
  const arrowLeft = $el.value.querySelector('.is-left');
  const arrowRight = $el.value.querySelector('.is-right');

  if (!arrowLeft) {
    return;
  }

  gsap.to(arrowLeft, {
    autoAlpha: 0,
  });
  gsap.to(arrowRight, {
    autoAlpha: 0,
  });

  if (props.filteredProductsLength === 1 || props.filtersToggled) {
    return;
  }

  gsap.to(arrowLeft, {
    autoAlpha: 1,
    duration: 0.5,
  });
  gsap.to(arrowRight, {
    autoAlpha: 1,
    duration: 0.5,
  });
}

watch(
  () => props.pointer,
  () => {
    if (!props.pointer || props.pointer.isOff || isTouch.value) {
      cardOffset.value = { x: 0, y: 0 };
      return;
    }

    if (!$el.value) {
      return;
    }
    // Transforming pointer to -1-1
    const x = -1 + (props.pointer.x / window.innerWidth) * 2;
    const y = -1 + (props.pointer.y / window.innerHeight) * 2;

    cardOffset.value = { x, y };
  }
);

let _productChangePending = false;
let _productChangedBySwipe = false;

watch(
  () => props.product.name,
  () => {
    let $delay = 0;

    if (!_productChangedBySwipe) {
      hide();
      $delay = 250;
    }

    _productChangePending = true;

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      currentProduct.value = props.product;
      void show();
    }, $delay);
  }
);

watch(
  () => props.filteredProductsLength,
  () => {
    filterButton.value.text =
      props.filteredProductsLength === props.productsLength
        ? t('EMShowroom.filtersToggleLabel')
        : t('EMShowroom.filtersToggleLabelSelected', {
            found: props.filteredProductsLength,
            total: props.productsLength,
          });
  }
);

function checkCardVisibility() {
  if (props.sensor === 'main' && isDesktop.value) showCards(true);
}

function onTransitionDone() {
  transitionDone.value = true;
  checkCardVisibility();
}

function onMachineMoveStart(event: ShowroomEventsMap[ShowroomEvent.MachinesMoveStart]) {
  _productChangedBySwipe = event.detail.isSwiped;
  transitionDone.value = false;
  buttonsEnabled.value = false;
  hide();
}

function onMachineMoveEnd(_event: ShowroomEventsMap[ShowroomEvent.MachinesMoveEnd]) {
  buttonsEnabled.value = true;
}

function onMachineMoveProgress(event: ShowroomEventsMap[ShowroomEvent.MachinesMoveProgress]) {
  if (event.detail.progress >= 0.25 && !transitionDone.value) {
    onTransitionDone();
    const cleanedMachineName = event.detail.currentMachineName.replace('-copy', '');
    emit('product', cleanedMachineName);
  }
}

onMounted(() => {
  if (!$el.value) {
    return;
  }
  currentProduct.value = props.product;
  void show();
  fadeSlide($el.value.querySelectorAll('.filter-container'));

  const canvasEl = document.querySelector<HTMLCanvasElement>('#webGlCanvas');
  if (!canvasEl) {
    console.error('WebGL canvas element not found');
    return;
  }
  $webGlCanvas.value = canvasEl;
  $webGlCanvas.value.addEventListener(ShowroomEvent.MachinesMoveStart, onMachineMoveStart);
  $webGlCanvas.value.addEventListener(ShowroomEvent.MachinesMoveProgress, onMachineMoveProgress);
  $webGlCanvas.value.addEventListener(ShowroomEvent.MachinesMoveEnd, onMachineMoveEnd);
});

onBeforeUnmount(() => {
  clearTimeout(timeout);
  $webGlCanvas.value?.removeEventListener(ShowroomEvent.MachinesMoveStart, onMachineMoveStart);
  $webGlCanvas.value?.removeEventListener(ShowroomEvent.MachinesMoveProgress, onMachineMoveProgress);
  $webGlCanvas.value?.removeEventListener(ShowroomEvent.MachinesMoveEnd, onMachineMoveEnd);
});
</script>

<style src="./EMShowroomMedia.scss" lang="scss" scoped />
