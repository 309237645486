<template>
  <component
    :is="blok.link?.url || blok.link?.cached_url ? BaseLink : 'div'"
    v-editable="blok"
    :link="blok.link"
    class="item-card-teaser"
    :class="[isDark ? 'is-dark' : 'is-light', { 'is-touch': isTouch }]">
    <div class="hover-rect"></div>
    <div class="image-wrapper">
      <BaseImage
        v-if="hoverImage"
        :image="hoverImage"
        :breakpointsWidthMap="breakpointsWidthMap"
        :heightRatio="isProductCard ? [1, 1] : [3, 4]"
        cropFitIn
        :class="{ 'is-product-card': isProductCard }"
        class="image-hover" />
      <BaseImage
        v-if="blok.image"
        :image="blok.image"
        :breakpointsWidthMap="breakpointsWidthMap"
        :heightRatio="isProductCard ? [1, 1] : [3, 4]"
        cropFitIn
        :class="{ 'is-product-card': isProductCard }"
        class="image" />
    </div>
    <div class="bottom">
      <div class="headline" :class="{ 'is-product-headline': isProductCard }">
        {{ blok.headline }}
      </div>
      <div class="tags" :class="{ 'is-product-tags': isProductCard }">
        <UtilTag
          v-for="(tag, index) in blok.tags"
          :key="index"
          :text="tag.text"
          :icon="tag.icon"
          :isBackgroundDark="isDark" />
      </div>
    </div>
  </component>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import BaseImage from '~/components/base/BaseImage.vue';
import BaseLink from '~/components/base/BaseLink.vue';
import UtilTag from '~/components/storyblok/utils/UtilTag/UtilTag.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import type { ItemCardTeaserProduct } from '~/composables/useProduct';

const { isTouch } = useBreakpoint();

const props = defineProps<{
  blok: ItemCardTeaserProduct;
  isDark?: boolean;
}>();

const breakpointsWidthMap = {
  0: 169,
  394: 191,
  441: 253,
  576: 342,
  769: 459,
  1024: 384,
  1280: 322,
  1441: 433,
  1921: 552,
  2640: 471,
};

const isProductCard = computed(() => props.blok.component === 'item_card-teaser--product');
const hoverImage = computed(() => isProductCard.value && props.blok.image_hover);
</script>

<style src="./ItemCardTeaser.scss" lang="scss" scoped />
