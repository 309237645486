<template>
  <footer
    class="footer"
    :class="[isDark ? 'is-dark' : 'is-light', { 'is-hidden': state.hidden }]"
    :hidden="state.hidden">
    <div v-editable="blok" class="footer-grid">
      <div class="footer-container">
        <!-- Logo -->
        <div class="logo-container">
          <BaseImage v-if="logo" :image="logo" :breakpointsWidthMap="{ 0: 100 }" />
        </div>

        <!-- Richtext -->
        <div class="main-content">
          <UtilRichtext
            v-for="(item, index) in blok.texts"
            :key="index"
            :blok="item"
            :preventInlineIcons="true"
            :isDark="isDark"
            textSize="medium"
            class="richtext" />

          <div class="line" :class="isDark ? 'line-dark' : 'line-light'"></div>

          <!-- Section right -->
          <div class="columns-container">
            <!-- Spacer to align the columns right if less than 4 -->
            <div v-if="blok.columns?.length && blok.columns?.length <= 1" class="col-spacer"></div>
            <div v-if="blok.columns?.length && blok.columns?.length <= 2" class="col-spacer"></div>
            <div v-if="blok.columns?.length && blok.columns?.length <= 3" class="col-spacer"></div>
            <template v-for="(column, i) in blok.columns" :key="column._uid">
              <div
                v-if="
                  i !== 0 &&
                  blok.columns?.length &&
                  !(column.component === 'util_link-list' && blok.columns[i - 1].component === 'util_link-list')
                "
                class="line"
                :class="isDark ? 'line-dark' : 'line-light'"></div>
              <div v-editable="column" class="column">
                <UtilRichtext
                  v-if="column.component === 'util_richtext'"
                  :blok="column"
                  :isDark="isDark"
                  :preventInlineIcons="true"
                  class="text"
                  textSize="medium" />
                <ul v-else-if="column.component === 'util_link-list'" class="column-links">
                  <!-- Full links -->
                  <li v-for="(item, j) in column.items" :key="j" v-editable="item">
                    <UtilLinkItemIcon v-if="item.component === 'util_link-item-icon'" :blok="item" :isDark="isDark" />
                    <UtilLinkItem v-if="item.component === 'util_link-item'" :blok="item" :isDark="isDark" />
                  </li>

                  <!-- Hidden text links -->
                  <li v-if="column.items_social && column.items_social.length > 0">
                    <ul class="hidden-text-links">
                      <li v-for="(item, j) in column.items_social" :key="j" v-editable="item">
                        <UtilLinkItemIcon :blok="item" :isDark="isDark" :isTextHiddenOnMobile="true" />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </template>
          </div>
        </div>

        <!-- Section bottom -->
        <div class="bottom-container">
          <ul class="bottom-links">
            <li v-for="(item, index) in blok.legal_links" :key="index" v-editable="item">
              <BaseLink :link="item.link" class="action-medium-bold">
                {{ item.text }}
              </BaseLink>
            </li>
          </ul>
          <p class="bottom-text">
            {{ blok.legal_copyright_text }}
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import UtilLinkItemIcon from '~/components/storyblok/utils/UtilLinkItemIcon/UtilLinkItemIcon.vue';
import UtilLinkItem from '~/components/storyblok/utils/UtilLinkItem/UtilLinkItem.vue';
import UtilRichtext from '~/components/storyblok/utils/UtilRichtext/UtilRichtext.vue';
import BaseImage from '~/components/base/BaseImage.vue';
import BaseLink from '~/components/base/BaseLink.vue';
import { useFooter } from '~/composables/useFooter';
import { computed } from 'vue';

import type { BmFooterStoryblok } from '~/types/storyblok-generated';

const { state } = useFooter();

const props = defineProps<{
  blok: BmFooterStoryblok;
  isDark?: boolean;
}>();

const logo = computed(() => (props.isDark ? props.blok.logo_white : props.blok.logo_black));
</script>

<style src="./BMFooter.scss" lang="scss" scoped />
