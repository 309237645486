<template>
  <div ref="$el" v-editable="blok" class="cm-text-highlight" :class="blok.is_dark ? 'is-dark' : 'is-light'">
    <UtilImageResponsive
      v-if="blok.background_image && blok.background_image[0]"
      class="bg-image"
      :blok="blok.background_image[0]"
      breakpoint="tablet" />
    <div class="word-wrappper" :class="wordsClasses">
      <h2 class="text-overline">{{ blok.overline }}</h2>
      <template v-for="(item, index) in words" :key="index">
        <div
          v-if="isWord(item)"
          class="word"
          :class="{
            'is-video': isVideoItem(item),
            'is-video-mobile': isVideoMobile(item),
            'is-active': isVideoMobile(item) ? true : transformedProgress * (words?.length ?? 0) > index,
          }">
          <!-- template desktop -->
          <template v-if="item === '[v]' && blok.video_thumbnail?.filename">
            <button
              class="inline-video"
              :class="{ 'has-shadow': blok.has_drop_shadow }"
              :aria-label="t('global.playVideo')"
              @click="onVideoClick"
              @mouseenter="setHoverTrue"
              @mouseleave="setHoverFalse">
              <BaseVideo
                :autoplay="videoIsPlaying"
                :src="blok.video_thumbnail.filename"
                :muted="true"
                :hidden="!state.visible"
                :poster="blok.video_thumbnail_poster?.filename"
                loop />
            </button>
            <UtilButton
              :blok="playButtonBlok"
              :isBackgroundDark="true"
              :isHovered="isHovered"
              isFakeButton
              class="play-icon" />
          </template>

          <!-- template mobile -->
          <template v-else-if="item === '[vm]' && blok.video_thumbnail?.filename">
            <button
              class="inline-video is-mobile"
              :class="{ 'has-shadow': blok.has_drop_shadow }"
              :aria-label="t('global.playVideo')"
              @click="onVideoClick"
              @mouseenter="setHoverTrue"
              @mouseleave="setHoverFalse">
              <BaseVideo
                :src="blok.video_thumbnail.filename"
                :autoplay="true"
                :playsinline="true"
                :muted="true"
                :poster="blok.video_thumbnail_poster?.filename"
                loop />
              <UtilButton
                :blok="playButtonBlok"
                :isBackgroundDark="true"
                :isHovered="isHovered"
                isFakeButton
                class="play-icon-mobile" />
            </button>
          </template>

          <span v-else v-html="item"></span>
        </div>

        <br v-else />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import { computed, ref, watch } from 'vue';
import BaseVideo from '~/components/base/BaseVideo.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import UtilImageResponsive from '~/components/storyblok/utils/UtilImageResponsive/UtilImageResponsive.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useModalVideo } from '~/composables/useModalVideo';
import type { CmTextHighlightStoryblok, UtilButtonStoryblok } from '~/types/storyblok-generated';
import type { ModuleWrapperState } from '~/types/utils';

const TEXT_LENGTH_BREAKPOINT = 320;
const TEXT_LENGTH_BREAKPOINT_MOBILE = 240;

const props = defineProps<{
  blok: CmTextHighlightStoryblok;
  state: ModuleWrapperState;
}>();

const { state: stateModalVideo } = useModalVideo();
const { state: stateBreakpoint } = useBreakpoint();
const { t } = useI18n();

const isHovered = ref(false);
const setHoverTrue = () => (isHovered.value = true);
const setHoverFalse = () => (isHovered.value = false);

const transformedProgress = ref(0);
const $el = ref<HTMLDivElement | null>(null);
const videoIsPlaying = ref(false);

// Removing slots if no video is available and splitting text into words
const words = computed(() => {
  let wordsCleaned = !props.blok.video_thumbnail?.filename
    ? props.blok.text?.replace('[v]', '').replace('[vm]', '').replaceAll('  ', ' ')
    : props.blok.text;

  // Product names should not break
  wordsCleaned = (wordsCleaned ?? '').replaceAll(/(WMF) (\d{3,5}) ([A-z]+)/g, '$1&nbsp;$2&nbsp;$3');
  return wordsCleaned?.replace('][', '] [').split(' ');
});

const wordsClasses = computed(() => {
  const classes = [];

  if (props.blok.text?.length ?? 0 > TEXT_LENGTH_BREAKPOINT) {
    classes.push('statement-medium');
  } else {
    // Mobile text should be smaller earlier than desktop
    if (props.blok.text?.length ?? 0 > TEXT_LENGTH_BREAKPOINT_MOBILE) {
      classes.push('statement-medium bl-l:statement-large');
    } else {
      classes.push('statement-large');
    }
  }

  return classes;
});

const playButtonBlok: UtilButtonStoryblok = {
  component: 'util_button',
  size: 'small',
  icon: 'fi_play',
  theme: 'secondary',
  text: '',
  _uid: 'play-button',
};

function isVideoItem(word: string) {
  return word.includes('[v');
}

function isVideoMobile(word: string) {
  return word.includes('[vm]');
}

function isWord(word: string) {
  return word !== '<br>';
}

function onVideoClick() {
  if (props.blok.video?.filename) {
    stateModalVideo.value = { type: 'media', filename: props.blok.video.filename };
  }
}

watch(
  () => props.state.visible,
  () => {
    if (!props.state.visible) {
      videoIsPlaying.value = false;
    }
    if (props.state.visible && stateBreakpoint.isPhone) {
      videoIsPlaying.value = true;
    }
  }
);

watch(
  () => props.state.progress,
  () => {
    transformedProgress.value = props.state.progress * 1.2;
    if (stateBreakpoint.isPhone) {
      videoIsPlaying.value = true;
      return;
    }
    const videoTag = stateBreakpoint.isPhone ? '[vm]' : '[v]';
    const videoProgress = words.value ? words.value.indexOf(videoTag) / words.value?.length : 0;
    videoIsPlaying.value = transformedProgress.value >= videoProgress ? true : false;
  }
);

watch(
  () => videoIsPlaying.value,
  () => {
    const video = $el.value?.querySelector<HTMLVideoElement>(
      `.is-video${stateBreakpoint.isPhone ? '-mobile' : ''} video`
    );

    if (!video) {
      return;
    }

    if (videoIsPlaying.value) {
      void video.play();
    } else {
      video.pause();
    }
  }
);
</script>

<style src="./CMTextHighlight.scss" lang="scss" scoped />
