import * as THREE from 'three';

class Utils {
  v1 = new THREE.Vector3();
  v2 = new THREE.Vector3();

  screenCategories = [
    {
      name: 'Small Phone Screen',
      minWidth: 0,
      maxWidth: 559,
      machineOffset: 0.025,
      camera: {
        position: new THREE.Vector3(0, 0.6, 4.25),
        lookAtPosition: new THREE.Vector3(0, 0.525, 0),
        isZooming: true,
      },
    },
    {
      name: 'Phone Screen',
      minWidth: 560,
      maxWidth: 767,
      machineOffset: 0.025,
      camera: {
        position: new THREE.Vector3(0, 0.6, 4),
        lookAtPosition: new THREE.Vector3(0, 0.525, 0),
        isZooming: false,
      },
    },
    {
      name: 'Small Tablet Screen',
      minWidth: 768,
      maxWidth: 1023,
      machineOffset: 0.125,
      camera: {
        position: new THREE.Vector3(0, 0.575, 3.5),
        lookAtPosition: new THREE.Vector3(0, 0.575, 0),
        zoomMode: false,
      },
    },
    {
      name: 'Tablet Screen',
      minWidth: 1024,
      maxWidth: 1279,
      machineOffset: 0.125,
      camera: {
        position: new THREE.Vector3(0, 0.325, 2.75),
        lookAtPosition: new THREE.Vector3(0, 0.5, 0),
        isZooming: false,
      },
    },
    {
      name: 'Small Screen',
      minWidth: 1280,
      maxWidth: 1439,
      machineOffset: 0.325,
      camera: {
        position: new THREE.Vector3(0, 0.3, 2.8),
        lookAtPosition: new THREE.Vector3(0, 0.3, 0),
        isZooming: false,
      },
    },
    {
      name: 'Medium Screen',
      minWidth: 1440,
      maxWidth: 1919,
      machineOffset: 0.275,
      camera: {
        position: new THREE.Vector3(0, 0.3, 2.75),
        lookAtPosition: new THREE.Vector3(0, 0.3, 0),
        isZooming: false,
      },
    },
    {
      name: 'Large Screen',
      minWidth: 1920,
      maxWidth: 2439,
      machineOffset: 0.475,
      camera: {
        position: new THREE.Vector3(0, 0.3, 2.75),
        lookAtPosition: new THREE.Vector3(0, 0.3, 0),
        isZooming: false,
      },
    },
    {
      name: 'Huge Screen',
      minWidth: 2440,
      maxWidth: Infinity,
      machineOffset: 0.575,
      camera: {
        position: new THREE.Vector3(0, 0.3, 2.75),
        lookAtPosition: new THREE.Vector3(0, 0.3, 0),
        isZooming: false,
      },
    },
  ];

  getImageformat(
    preferedFormat: 'webp' | 'avif',
    fallbackFormat: 'webp' | 'avif',
    supportsAvif: boolean,
    supportsWebp: boolean
  ) {
    let format;

    if (preferedFormat === 'avif') {
      format = supportsAvif === true ? 'avif' : supportsWebp === true ? 'webp' : fallbackFormat;
    } else if (preferedFormat === 'webp') {
      format = supportsWebp === true ? 'webp' : fallbackFormat;
    } else {
      format = fallbackFormat;
    }

    return format;
  }

  getPosByScreenCoords(
    camera: THREE.PerspectiveCamera,
    screenCoordX: number,
    screenCoordY: number,
    targetZ: number,
    v1: THREE.Vector3,
    v2: THREE.Vector3
  ) {
    let distance;

    v1.set(screenCoordX, screenCoordY, 0.5);

    v1.unproject(camera);

    v1.sub(camera.position).normalize();
    if (targetZ) {
      distance = (targetZ - camera.position.z) / v1.z;
    } else {
      distance = -camera.position.z / v1.z;
    }

    return v2.copy(camera.position).add(v1.multiplyScalar(distance));
  }

  getScreenSizeCategoryByViewportWidth(width: number) {
    for (let i = 0; i < this.screenCategories.length; i++) {
      if (width >= this.screenCategories[i].minWidth && width <= this.screenCategories[i].maxWidth) {
        return this.screenCategories[i];
      }
    }
    return this.screenCategories[0];
  }

  getOffsetByViewportWidth(width: number) {
    const category = this.getScreenSizeCategoryByViewportWidth(width);
    return category.machineOffset;
  }

  getCameraSetupByViewportWidth(width: number) {
    const category = this.getScreenSizeCategoryByViewportWidth(width);
    return category.camera;
  }
}

export default new Utils();
