// This is a noise texture that tiles very well.
// @see https://blog.demofox.org/2018/08/07/tiled-blue-noise/
import * as THREE from 'three';
import type { AssetManagerInstance } from '~/webglApp/core/assetManager';

export type BlueNoiseInstance = InstanceType<typeof BlueNoise>;

export class BlueNoise {
  texture: THREE.Texture | null = null;
  textureSize = new THREE.Vector2();
  textureOffset = new THREE.Vector2(1, 1);

  constructor(assetManager: AssetManagerInstance) {
    assetManager.addToLoader('textures/bluenoise_128.png', loadedAsset => {
      if (!(loadedAsset instanceof THREE.Texture)) {
        console.error('Blue noise texture is not a texture');
        return;
      }
      this.texture = loadedAsset;
      this.texture.generateMipmaps = false;
      this.texture.wrapS = this.texture.wrapT = THREE.RepeatWrapping;
      this.texture.minFilter = this.texture.magFilter = THREE.NearestFilter;
      this.textureSize.set(1 / this.texture.image.width, 1 / this.texture.image.height);
    });
  }

  update() {
    this.textureOffset.set(Math.random(), Math.random());
  }
}
