import gsap from 'gsap';
import type { Ref } from 'vue';

interface Use3dCardParams {
  $card: Ref<HTMLElement | undefined>;
  $cardFront: Ref<HTMLElement | undefined>;
  $cardBack: Ref<HTMLElement | undefined>;
  $headline: Ref<HTMLElement | undefined>;
  $text: Ref<HTMLElement | undefined>;
  isFlipped: Ref<boolean>;
  isFlippable: boolean;
  tl: Ref<GSAPTimeline | undefined>;
  options?: {
    transformPerspective?: number;
  };
}

export function use3dCard({
  $card,
  $cardFront,
  $cardBack,
  $headline,
  $text,
  isFlipped,
  isFlippable,
  tl,
  options,
}: Use3dCardParams) {
  function tiltCard(event: MouseEvent) {
    if (!$card.value || !$headline.value) {
      return;
    }

    const card = $card.value;
    const headline = $headline.value;
    const text = $text.value;

    const rect = card.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;

    // Calculate tilt angles based on mouse position
    const tiltMultiplier = -15;
    const tiltX = (mouseX / rect.width - 0.5) * tiltMultiplier;
    const tiltY = (mouseY / rect.height - 0.5) * tiltMultiplier;

    gsap.to(card, {
      rotationX: -tiltY, // Around x axis
      rotationY: tiltX, // Around y axis
      ease: 'power2.out',
      duration: 0.5,
    });

    gsap.to([headline, text], {
      x: tiltX * 0.5,
      y: tiltY * 0.5,
      ease: 'power2.out',
      duration: 0.5,
    });
  }

  function resetTilt() {
    gsap.to([$card.value, $headline.value, $text.value], {
      rotationY: 0,
      rotationX: 0,
      x: 0,
      y: 0,
      ease: 'Power1.easeOut',
    });
  }

  function flipCard() {
    if (!isFlippable) {
      return;
    }

    if (isFlipped.value) {
      tl.value?.reverse();
    } else {
      tl.value?.play();
    }

    isFlipped.value = !isFlipped.value;
  }

  let setCardCounter = 0;

  function setCard() {
    if (!$card.value) {
      if (setCardCounter < 5) {
        setTimeout(setCard, 500);
        setCardCounter++;
      }
      return;
    }

    // Set card 3D perspective
    gsap.set($card.value, {
      transformStyle: 'preserve-3d',
      transformPerspective: options?.transformPerspective ?? 1500,
    });

    // Set card sides to enable flipping
    if (isFlippable && $cardFront.value && $cardBack.value) {
      gsap.set($cardFront.value, {
        backfaceVisibility: 'hidden',
        zIndex: 5,
        translateZ: 0.5,
      });
      gsap.set($cardBack.value, {
        backfaceVisibility: 'hidden',
        rotationY: -180,
        translateZ: 0.5,
      });

      // The timeline is paused by default, and the card is flipped when the timeline is played.
      tl.value = gsap
        .timeline({ paused: true })
        .to($cardFront.value, {
          duration: 1,
          rotationY: 180,
          zIndex: 0,
          ease: 'power2.inOut',
        })
        .to(
          $cardBack.value,
          {
            duration: 1,
            rotationY: 0,
            zIndex: 5,
            ease: 'power2.inOut',
          },
          0
        )
        .to($card.value, { z: 50 }, 0)
        .to($card.value, { z: 0 }, 0.5);
    }
  }

  return { tiltCard, resetTilt, flipCard, setCard };
}
