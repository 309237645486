<template>
  <div
    v-if="isMounted"
    class="util-media-responsive"
    :class="[isDark ? 'is-dark' : 'is-light', { 'has-modal': hasModalVideo }]"
    :style="{ aspectRatio: props.aspectRatio || undefined }"
    @click="handleVideoPlay"
    @mouseenter="setHoverTrue"
    @mouseleave="setHoverFalse">
    <!-- Video - only webm format  -->
    <template v-if="videoSource?.filename && isVideoWebm(videoSource.filename)">
      <BaseVideo
        v-if="isWebmSupported"
        :src="videoSource.filename"
        :autoplay="autoplay"
        :playsinline="true"
        :loop="true"
        :style="{ aspectRatio: props.aspectRatio || undefined }"
        @load="emit('load')" />

      <!-- Fallback MP4 video for webm video - -->
      <BaseVideo
        v-else-if="videoFallbackForWebm"
        :src="videoFallbackForWebm.filename"
        :autoplay="autoplay"
        :playsinline="true"
        :loop="true"
        :style="{ aspectRatio: props.aspectRatio || undefined }"
        @load="emit('load')" />
    </template>

    <!-- Image - shown only when video not set, or set with mp4 -->
    <BaseImage
      v-else-if="imageSource"
      :image="imageSource"
      :breakpointsWidthMap="breakpointsWidthMap!"
      @load="emit('load')" />

    <!-- Play button for mp4 video -->
    <UtilButton
      v-if="hasModalVideo"
      :blok="{
        component: 'util_button',
        size: 'large',
        icon: 'fi_play',
        theme: 'primary',
        text: '',
        _uid: 'play-button',
      }"
      :isBackgroundDark="true"
      :isHovered="isHovered"
      class="play-button"
      @click="handleVideoPlay" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import BaseImage from '~/components/base/BaseImage.vue';
import BaseVideo from '~/components/base/BaseVideo.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useModalVideo } from '~/composables/useModalVideo';
import { hasFullWebmSupport } from '~/utils/browser';
import { isVideo, isVideoWebm } from '~/utils/media';
import type { AssetStoryblok } from '~/types/storyblok-generated';
import type { Device } from '~/types/utils';

const props = withDefaults(
  defineProps<{
    image?: AssetStoryblok;
    imageMobile?: AssetStoryblok;
    video?: AssetStoryblok;
    videoMobile?: AssetStoryblok;
    videoFallbackForWebm?: AssetStoryblok;
    autoplay?: boolean;
    breakpoint?: Device;
    breakpointsWidthMap?: Record<string, number>;
    aspectRatio?: '1/1' | '3/4' | '3/2' | '16/9' | '';
    isDark?: boolean;
  }>(),
  {
    breakpointsWidthMap: () => ({
      '0': 768,
      '768': 1024,
      '1024': 1280,
      '1280': 1400,
    }),
    breakpoint: 'isPhoneLarge',
  }
);

const emit = defineEmits<{
  (e: 'load'): void;
}>();

const { state: stateModalVideo } = useModalVideo();
const { state: stateBreakpoint } = useBreakpoint();

const isHovered = ref(false);
const setHoverTrue = () => (isHovered.value = true);
const setHoverFalse = () => (isHovered.value = false);

const isMounted = ref(false);

const isMobile = computed((): boolean => !stateBreakpoint[props.breakpoint]);

const imageSource = computed(() => (isMobile.value ? props.imageMobile || props.image : props.image));
const videoSource = computed(() => (isMobile.value ? props.videoMobile || props.video : props.video));
const hasModalVideo = computed(() => isVideo(videoSource.value?.filename) && !isVideoWebm(videoSource.value?.filename));
const isWebmSupported = computed(() => hasFullWebmSupport());

function handleVideoPlay() {
  if (videoSource.value && videoSource.value.filename && hasModalVideo.value === true) {
    stateModalVideo.value = { type: 'media', filename: videoSource.value.filename };
  }
}

onMounted(() => {
  isMounted.value = true;
});
</script>

<style src="./UtilMediaResponsive.scss" lang="scss" scoped />
