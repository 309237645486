import * as THREE from 'three';
import type { AppStateInstance } from '~/webglApp/core/appState';
import type { EngineInstance } from '~/webglApp/core/engine';

export type LightingInstance = InstanceType<typeof Lighting>;

export class Lighting {
  appState: AppStateInstance;
  container = new THREE.Object3D();
  sceneLight: THREE.SpotLight;
  v1 = new THREE.Vector3();

  cursorLight;
  cursorLightTarget;

  isActive = false;

  constructor(appState: AppStateInstance) {
    this.appState = appState;
    this.cursorLight = new THREE.SpotLight('#ffffff', 75);
    this.cursorLight.shadow.intensity = 1.25;
    this.cursorLight.penumbra = 1;
    this.cursorLight.decay = 6;
    this.cursorLight.angle = 0.3;
    this.cursorLight.castShadow = true;

    const shadowResolution = appState.qualityLvl === 'low' ? 256 : 768;
    this.cursorLight.shadow.mapSize.width = shadowResolution;
    this.cursorLight.shadow.mapSize.height = shadowResolution;
    this.cursorLight.shadow.camera.near = 0.1;
    this.cursorLight.shadow.camera.far = 5;
    this.cursorLight.shadow.bias = -0.0004;

    this.container.add(this.cursorLight);

    this.cursorLightTarget = new THREE.Object3D();
    this.cursorLightTarget.position.copy(this.v1);
    this.container.add(this.cursorLightTarget);
    this.cursorLight.target = this.cursorLightTarget;

    this.sceneLight = new THREE.SpotLight('#ffffff', appState.isMobileDevice ? 5000000 : 2000000);
    this.sceneLight.position.set(0, 4, 2);
    this.sceneLight.penumbra = 1;
    this.sceneLight.decay = 10;
    this.sceneLight.angle = 0.1;

    this.container.add(this.sceneLight);
  }

  show() {
    this.isActive = true;
  }

  hide() {
    this.isActive = false;
  }

  update(engine: EngineInstance) {
    if (!this.isActive) {
      return;
    }
    if (this.appState.isMobileDevice) {
      this.v1.set(0, 0, 0.5);
    } else {
      this.v1.set(
        this.appState.normalizedPointerCoords.x *
          this.appState.visibleWidthOfCamera *
          this.appState.pointerActiveRatio *
          4,
        this.appState.normalizedPointerCoords.y *
          this.appState.visibleWidthOfCamera *
          this.appState.pointerActiveRatio *
          2,
        0.5
      );
    }

    this.v1.unproject(engine.camera);
    this.cursorLight.position.copy(this.v1);

    this.cursorLight.target.position.copy(engine.cameraLookAtPosition);
    this.cursorLight.target.updateMatrixWorld();
  }
}
