import { useStorage } from '@vueuse/core';
import { useCookie } from '#app';
import { reactive } from 'vue';
import type { ICartData } from '@/types/cart';

const cartState = reactive<ICartData>({
  customer: undefined,
  cart: undefined,
});

const cartStoreId = 'wmf-shop'; /*Tbd name by brand*/
const cartStore = useStorage(cartStoreId, cartState);

export function useShoppingCart() {
  const length = () => {
    return cartStore.value.cart?.summary_count || 0;
  };

  const sync = async () => {
    /* Sync routine */
    const COOKIE_KEY = cartStoreId;

    /* Cookie auslesen */
    const storeCookie = useCookie(COOKIE_KEY);
    let defaultCartItem: ICartData = {};

    if (storeCookie.value) {
      try {
        defaultCartItem = storeCookie.value as ICartData;
      } catch (error) {
        console.error('Error parsing cookie value', error);
      }
    }

    cartStore.value = defaultCartItem;
  };

  return {
    length,
    sync,
    cartStore,
  };
}
