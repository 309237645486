import { MarketLocale } from '~/types/locales';

export const MARKET_TO_CHATBOT_ID_MAP: Record<MarketLocale, string | undefined> = {
  [MarketLocale.COM]: 'mN_vNBwM8xJ0NPvzzBxvu',
  [MarketLocale.UK]: undefined,
  [MarketLocale.NL]: 'mN_vNBwM8xJ0NPvzzBxvu',
  [MarketLocale.US]: undefined,
  [MarketLocale.DE]: 'Itn5eAA7-gDpJ875_u1MB',
  [MarketLocale.FR]: undefined,
  [MarketLocale.BE]: undefined,
  [MarketLocale.ES]: undefined,
  [MarketLocale.JP]: undefined,
  [MarketLocale.CH]: undefined,
  [MarketLocale.AT]: undefined,
  [MarketLocale.CN]: undefined,
};

export function chatWindowIsOpen() {
  const trigger = document.getElementById('chatbase-bubble-button');
  return !!trigger?.querySelector('#closeIcon');
}

export function toggleChatbot() {
  // Chatbase does not provide an API for this so we have to do it with this caveman solution
  document.getElementById('chatbase-bubble-button')?.click();
}

export function openChatbot() {
  if (!chatWindowIsOpen()) {
    document.getElementById('chatbase-bubble-button')?.click();
  }
}

export function closeChatbot() {
  if (chatWindowIsOpen()) {
    document.getElementById('chatbase-bubble-button')?.click();
  }
}
