<template>
  <div v-editable="blok" class="fm-newsletter">
    <BaseForm ref="$baseForm" :blok="blok">
      <template #form>
        <!--
          Personal information
        -->
        <form @submit="handleSubmit">
          <div class="section">
            <div class="fields-container">
              <!-- First name -->
              <div class="field">
                <BaseInput
                  v-model="firstname"
                  v-bind="firstnameAttrs"
                  :isTouched="isFieldTouched('firstname')"
                  :errorMessage="errors['firstname']"
                  name="firstname"
                  autocomplete="given-name"
                  type="text"
                  :label="t('forms.firstname')"
                  :placeholder="t('forms.firstnamePlaceholder')"
                  :isDark="blok.is_dark"
                  :disabled="isSubmitted"
                  required />
              </div>

              <!-- Last name -->
              <div class="field">
                <BaseInput
                  v-model="lastname"
                  v-bind="lastnameAttrs"
                  :isTouched="isFieldTouched('lastname')"
                  :errorMessage="errors['lastname']"
                  name="lastname"
                  autocomplete="family-name"
                  type="text"
                  :label="t('forms.lastname')"
                  :placeholder="t('forms.lastnamePlaceholder')"
                  :isDark="blok.is_dark"
                  :disabled="isSubmitted"
                  required />
              </div>

              <!-- Company -->
              <div class="field">
                <BaseInput
                  v-model="company"
                  v-bind="companyAttrs"
                  :isTouched="isFieldTouched('company')"
                  :errorMessage="errors['company']"
                  name="company"
                  autocomplete="organization"
                  type="text"
                  :label="t('forms.company')"
                  :placeholder="t('forms.companyPlaceholder')"
                  :isDark="blok.is_dark"
                  :disabled="isSubmitted"
                  required />
              </div>

              <!-- Email -->
              <div class="field">
                <BaseInput
                  v-model="email"
                  :label="t('forms.email')"
                  :placeholder="t('forms.emailPlaceholder')"
                  type="email"
                  name="email"
                  autocomplete="email"
                  v-bind="emailAttrs"
                  :isTouched="isFieldTouched('email')"
                  :errorMessage="errors['email']"
                  :isDark="blok.is_dark"
                  :disabled="isSubmitted"
                  required />
              </div>
            </div>
          </div>

          <!--
            Terms and captcha
          -->
          <div class="section">
            <div class="fields-container">
              <div class="field is-full-width">
                <p class="required-info">
                  <BaseRichtext
                    :text="blok.terms_text || t('forms.privacyNote')"
                    :isDark="blok.is_dark"
                    :isProseEnabled="false" />
                </p>
              </div>
              <div class="field is-full-width">
                <BaseCaptcha
                  v-model="captcha"
                  :isTouched="isFieldTouched('captcha')"
                  :errorMessage="errors['captcha']"
                  :isDark="blok.is_dark"
                  :disabled="isSubmitted" />
              </div>
            </div>
          </div>

          <!--
            Submit button and error messages
          -->
          <div class="section">
            <div class="fields-container">
              <div class="field is-full-width">
                <p class="required-info">{{ t('forms.mandatoryInfo') }}</p>
              </div>

              <div v-if="errorMessage" class="field is-full-width api-response-message">
                {{ t(`forms.apiResponseMessage.${errorMessage}`) }}
              </div>

              <div class="field is-full-width">
                <UtilButton
                  :blok="{
                    component: 'util_button',
                    text: t('global.submit'),
                    size: 'large',
                    theme: 'primary',
                    _uid: 'submit-button',
                  }"
                  type="submit"
                  :isLoading="isSubmitting"
                  :isDisabled="isSubmitted && !isSubmitting"
                  :isBackgroundDark="blok.is_dark" />
              </div>
            </div>
          </div>
        </form>
      </template>
    </BaseForm>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import { toTypedSchema } from '@vee-validate/yup';
import { useForm } from 'vee-validate';
import { ref, watch } from 'vue';
import { BaseCaptcha, BaseForm, BaseInput } from '~/components/base';
import BaseRichtext from '~/components/base/BaseRichtext/BaseRichtext.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import { useGtmTracking } from '~/composables/useGtmTracking';
import { getNewsletterSchema, submitNewsletter, type NewsletterForm } from '~/utils/forms';
import type { Locale } from '~/types/locales';
import type { FmNewsletterStoryblok } from '~/types/storyblok-generated';
import { isOFetchApiError } from '~/types/utils';

defineProps<{ blok: FmNewsletterStoryblok }>();

const { trackEvent } = useGtmTracking();

const $baseForm = ref<typeof BaseForm | null>(null);
const hasBeenTracked = ref<boolean>(false);
const errorMessage = ref<string | null>(null);
const isSubmitted = ref<boolean>(false);

const { t, locale } = useI18n();
const {
  values,
  defineField,
  isSubmitting,
  handleSubmit: useFormSubmit,
  errors,
  isFieldTouched,
} = useForm({
  validationSchema: toTypedSchema(getNewsletterSchema(locale.value as Locale)),
});

const [firstname, firstnameAttrs] = defineField('firstname');
const [lastname, lastnameAttrs] = defineField('lastname');
const [company, companyAttrs] = defineField('company');
const [email, emailAttrs] = defineField('email');
const [captcha, _captchaAttrs] = defineField('captcha');

const handleSubmit = useFormSubmit(onSuccess);

async function onSuccess(values: NewsletterForm) {
  trackEvent({
    event: 'formSubmit',
    formType: 'newsletter',
  });
  try {
    isSubmitted.value = true;
    const res = await submitNewsletter(values);
    if (res.success) {
      $baseForm.value?.openSuccessModal();
    }
  } catch (error) {
    isSubmitted.value = false;
    if (isOFetchApiError(error)) {
      console.error(error.data);
      errorMessage.value = error.data.data.messageKey;
      return;
    }
  }
}

watch(values, () => {
  if (!hasBeenTracked.value && Object.keys(values).filter(k => k !== 'captcha').length > 0) {
    hasBeenTracked.value = true;
    trackEvent({
      event: 'formStart',
      formType: 'newsletter',
    });
  }
});
</script>

<style src="~/assets/scss/_form-layout.scss" lang="scss" scoped />
<style src="./FMNewsletter.scss" lang="scss" scoped />
