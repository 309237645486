<template>
  <UtilStickyBar class="sticky-bar" :class="{ 'is-not-mounted': !isMounted }">
    <template #fixedContent>
      <ItemDropdown
        :isDark="isDark"
        :isSmall="true"
        :selectedOption="selectedMachineOption"
        :options="machineOptions"
        :isFloating="true"
        @select="onMachineSelect"
        @reset="handleReset"
        @buttonClick="handleButtonClick" />
    </template>
    <template #arrowsContent>
      <div class="categories-container">
        <UtilFilterButton
          v-for="category in addOnCategories"
          :key="category"
          :value="category"
          :label="t(`dataSources.add-on-categories.${category}`)"
          :isDark="isDark"
          :isSelected="addOnCategoriesSelectedIds.includes(category)"
          :isDisabled="isAddonDisabled(category)"
          @click="onAddOnCategoryToggle(category)" />
      </div>
    </template>
  </UtilStickyBar>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import { useDebounceFn } from '@vueuse/core';
import type { ISbStoryData } from 'storyblok-js-client';
import { computed, onMounted, ref, watch } from 'vue';
import UtilStickyBar from '~/components/storyblok/utils/UtilStickyBar/UtilStickyBar.vue';
import ItemDropdown from '~/components/storyblok/item/ItemDropdown/ItemDropdown.vue';
import type {
  ItemDropdownOption,
  ItemDropdownOptions,
} from '~/components/storyblok/item/ItemDropdown/ItemDropdown.types';
import UtilFilterButton from '~/components/storyblok/utils/UtilFilterButton/UtilFilterButton.vue';
import { useGtmTracking } from '~/composables/useGtmTracking';
import { useProduct } from '~/composables/useProduct';
import { useScroll } from '~/composables/useScroll';
import { useStoryblokClient } from '~/composables/useStoryblokClient';
import type { DataAddOnStoryblok, DataProductStoryblok } from '~/types/storyblok-generated';

const props = defineProps<{
  machines: ISbStoryData<DataProductStoryblok>[];
  addOns: ISbStoryData<DataAddOnStoryblok>[];
  isDark?: boolean;
}>();

const emit = defineEmits<{
  (e: 'onMachineSelect', uuid: string | null): void;
  (e: 'onAddonCategorySelect', categories: string[]): void;
  (e: 'scrollToTop'): void;
}>();

const { getDatasourceEntries } = await useStoryblokClient();
const { state: scrollState } = useScroll();
const { trackEvent } = useGtmTracking();
const { getMachineTypeText } = useProduct();
const { t } = useI18n();

const addOnCategories = (await getDatasourceEntries('add-on-categories')).value?.map(entry => entry.value);

const isMounted = ref(false);
const isMachinesFilterActive = ref(false);
const addOnCategoriesSelectedIds = ref<string[]>([]);
const selectedMachineOption = ref<ItemDropdownOption | null>(null);

const selectedMachine = computed<ISbStoryData<DataProductStoryblok> | null>(
  () => props.machines.find(machine => machine.uuid === selectedMachineOption.value?.value) ?? null
);

const machineOptions = computed<ItemDropdownOptions>(() => {
  const machinesMap: Record<string, ISbStoryData<DataProductStoryblok>[]> = {};
  props.machines.forEach(machine => {
    const type = machine.content.type;
    if (!type) {
      return;
    }
    if (!machinesMap[type]) {
      machinesMap[type] = [];
    }
    machinesMap[type].push(machine);
  });

  return Object.entries(machinesMap).map(([type, machines]) => {
    return {
      name: getMachineTypeText(type as DataProductStoryblok['type']),
      value: type,
      options: machines.map(machine => ({
        name: machine.content.name,
        value: machine.uuid,
      })),
    };
  });
});

function handleReset() {
  selectedMachineOption.value = null;
  addOnCategoriesSelectedIds.value = [];
  isMachinesFilterActive.value = false;
}

function onMachineSelect(option: ItemDropdownOption) {
  isMachinesFilterActive.value = false;
  selectedMachineOption.value = option;
  addOnCategoriesSelectedIds.value = [];
}

function onAddOnCategoryToggle(category: string) {
  if (isAddonDisabled(category)) {
    return;
  }
  const index = addOnCategoriesSelectedIds.value.indexOf(category);
  if (index === -1) {
    addOnCategoriesSelectedIds.value.push(category);
  } else {
    addOnCategoriesSelectedIds.value.splice(index, 1);
  }
}

function getAddOnCategories(addOns: ISbStoryData<DataAddOnStoryblok>[]) {
  const categories = new Set<string>();
  addOns.forEach(addOn => {
    if (addOn.content.category) {
      categories.add(addOn.content.category.toString());
    }
  });
  return Array.from(categories);
}

function isAddonDisabled(category: string) {
  if (!selectedMachine.value || !selectedMachine.value.content.add_ons) {
    return false;
  }

  const remainingAddOns = selectedMachine.value.content.add_ons
    .map(addOnId => props.addOns.find(addOn => addOn.uuid === addOnId))
    .filter(addOn => !!addOn);
  const remainingAddOnsCategories = getAddOnCategories(remainingAddOns);

  return !remainingAddOnsCategories.includes(category);
}

function handleButtonClick() {
  isMachinesFilterActive.value = !isMachinesFilterActive.value;
  if (isMachinesFilterActive.value && !scrollState.isStickyActive) {
    emit('scrollToTop');
  }
}

/**
 * Tracks the selected filters in an array that should always be the values in the same order.
 * In our case that is machine first and then add-on categories in the order we got them from the datasource.
 */
function trackFilters() {
  if (!addOnCategories) {
    return;
  }
  const addonsArray = addOnCategories.map(addOn => (addOnCategoriesSelectedIds.value.includes(addOn) ? addOn : null));

  trackEvent({
    event: 'productFilter',
    filterValue: [selectedMachine.value?.name ?? null, ...addonsArray],
  });
}
// We debounce the tracking to avoid sending duplicate events when the machine makes an add-on disabled and
// Therefore triggers a filter change.
const debouncedTrackFilters = useDebounceFn(trackFilters, 100);

watch(
  () => addOnCategoriesSelectedIds.value,
  () => {
    void debouncedTrackFilters();
    emit('onAddonCategorySelect', addOnCategoriesSelectedIds.value);
    emit('scrollToTop');
  },
  { deep: true }
);

watch(
  () => selectedMachineOption.value,
  option => {
    void debouncedTrackFilters();
    emit('onMachineSelect', option?.value ?? null);
    emit('scrollToTop');
  }
);

onMounted(() => {
  isMounted.value = true;
});
</script>

<style src="./LMGridFilters.scss" lang="scss" scoped />
