//https://tsev.dev/posts/2020-06-19-colour-correction-with-webgl/
//https://www.shadertoy.com/view/XdcXzn
precision mediump float;


uniform float u_brightness;
uniform float u_contrast;
uniform float u_saturation;
uniform float u_tintIntensity;
uniform vec3 u_tintColor;

varying vec2 v_uv;


mat4 brightnessMatrix( float brightness ){
    return mat4( 1.0, 0.0, 0.0, 0.0,
                 0.0, 1.0, 0.0, 0.0,
                 0.0, 0.0, 1.0, 0.0,
                 brightness, brightness, brightness, 1 );
}


mat4 contrastMatrix( float contrast ){
	float t = ( 1.0 - contrast ) / 2.0;
    
    return mat4( contrast, 0.0, 0.0, 0.0,
                 0.0, contrast, 0.0, 0.0,
                 0.0, 0.0, contrast, 0.0,
                 t, t, t, 1 );

}


mat4 saturationMatrix( float saturation ){
    vec3 luminance = vec3( 0.3086, 0.6094, 0.0820 );
    
    float oneMinusSat = 1.0 - saturation;
    
    vec3 red = vec3( luminance.x * oneMinusSat );
    red+= vec3( saturation, 0.0, 0.0 );
    
    vec3 green = vec3( luminance.y * oneMinusSat );
    green += vec3( 0.0, saturation, 0.0 );
    
    vec3 blue = vec3( luminance.z * oneMinusSat );
    blue += vec3( 0.0, 0.0, saturation );
    
    return mat4( red,     0.0,
                 green,   0.0,
                 blue,    0.0,
                 0.0, 0.0, 0.0, 1.0 );
}




void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor ) {
    vec4 transformedColor = brightnessMatrix( u_brightness ) * contrastMatrix( u_contrast ) * saturationMatrix(u_saturation ) * inputColor;

    vec4 finalColor = mix(transformedColor, vec4(u_tintColor.rgb, 1.0), max(u_tintIntensity, 0.0));

    // Output the final color
    outputColor = vec4(finalColor.rgb, finalColor.a);
}
