<template>
  <div
    ref="$container"
    class="util-youtube-video"
    :style="{ aspectRatio: blok.media_ratio || '16/9' }"
    :class="{
      'has-ready-embed-behind': !youtubeEmbedIsVisible && hasConsent && !blok.play_in_fullscreen,
      'is-shown-in-modal': isShownInModal,
    }">
    <div v-if="!youtubeEmbedIsVisible || blok.play_in_fullscreen" class="placeholder">
      <img
        v-if="blok.poster_variant === 'automatic'"
        class="thumbnail"
        :src="youtubeThumbnailUrl"
        alt="YouTube Video Thumbnail" />
      <BaseImage
        v-else-if="blok.poster_variant === 'image'"
        :breakpointsWidthMap="breakpointsWidthMap!"
        :image="blok.poster_image"
        class="thumbnail" />
      <BaseVideo
        v-else-if="blok.poster_variant === 'video' && blok.poster_video?.filename"
        :src="blok.poster_video?.filename"
        class="thumbnail" />
      <div class="button-container" @click="startVideo" @mouseenter="setHoverTrue" @mouseleave="setHoverFalse">
        <UtilButton
          :blok="{
            component: 'util_button',
            size: 'large',
            icon: 'fi_play',
            theme: 'primary',
            _uid: 'play-button',
          }"
          :isHovered="isHovered"
          @click="startVideo" />
      </div>
      <div
        v-if="privacyWarningRichtext"
        class="info-text"
        :class="blok.text_color === 'black' ? 'has-dark-text' : 'has-light-text'">
        <BaseRichtext :text="privacyWarningRichtext" />
      </div>
    </div>
    <div
      v-if="!blok.play_in_fullscreen"
      ref="$player"
      class="plyr-player"
      data-plyr-provider="youtube"
      :data-plyr-embed-id="youtubeVideoId ?? ''"></div>
  </div>
</template>

<script lang="ts" setup>
import { useRuntimeConfig } from '#app/nuxt';
import Plyr from 'plyr';
import type { ISbRichtext } from 'storyblok-js-client';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { BaseImage, BaseRichtext, BaseVideo } from '~/components/base';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import { useConsent } from '~/composables/useConsent';
import { useModalVideo } from '~/composables/useModalVideo';
import { OneTrustGroup } from '@seb-platform/shared/types';
import type { ItemYoutubeVideoStoryblok } from '~/types/storyblok-generated';

const props = withDefaults(
  defineProps<{
    blok: ItemYoutubeVideoStoryblok;
    privacyWarningRichtext: ISbRichtext;
    breakpointsWidthMap?: Record<string, number>;
    autoplay?: boolean;
    isShownInModal?: boolean;
  }>(),
  {
    breakpointsWidthMap: () => ({
      '0': 768,
      '768': 1024,
      '1024': 1280,
      '1280': 1400,
    }),
    isShownInModal: false,
  }
);

defineExpose({ startVideo, killPlayer });

const { state: stateModalVideo } = useModalVideo();
const { state: consentState } = useConsent();
const config = useRuntimeConfig();

const $player = ref<HTMLDivElement | null>(null);
const $container = ref<HTMLDivElement | null>(null);
const playerInstance = ref<Plyr | null>(null);
const youtubeEmbedIsVisible = ref(false);
const waitingForConsent = ref(false);
const isHovered = ref(false);
const setHoverTrue = () => (isHovered.value = true);
const setHoverFalse = () => (isHovered.value = false);

const hasConsent = computed(() => consentState.groups.includes(OneTrustGroup.Tracking));
const youtubeVideoId = computed(() => getIdFromUrl(props.blok.youtube_url));
const youtubeThumbnailUrl = computed(() => `https://img.youtube.com/vi/${youtubeVideoId.value}/maxresdefault.jpg`);

function getIdFromUrl(url: string): string | undefined {
  let regExp: RegExp;
  if (url.includes('youtu.be/')) {
    regExp = /youtu\.be\/([\w\d-]+)\??/;
  } else {
    regExp = /youtube\.com\/watch\?v=([\w\d-]+)[&#/]?.*/;
  }
  return url.match(regExp)?.[1];
}

async function startVideo() {
  if (config.public.ENV !== 'development' && !consentState.groups.includes(OneTrustGroup.Tracking)) {
    consentState.visible = 'preferences';
    waitingForConsent.value = true;
    return;
  }
  if (props.blok.play_in_fullscreen) {
    // This video is a modal
    stateModalVideo.value = { type: 'youtubeVideo', youtubeVideoBlok: props.blok };
    return;
  }

  if (!playerInstance.value) {
    initPlayer();
    // Wait 500ms for the player to be ready
    await new Promise(resolve => setTimeout(resolve, 500));
  }

  if (playerInstance.value) {
    youtubeEmbedIsVisible.value = true;
    void playerInstance.value.play();
  }
}

function initPlayer() {
  if ($player.value) {
    playerInstance.value = new Plyr($player.value, {
      iconUrl: '/icons/plyr-icons.svg',
      youtube: { noCookie: true },
    });
    void nextTick(() => {
      $container.value
        ?.querySelector<HTMLElement>('.plyr')
        ?.addEventListener('click', () => (youtubeEmbedIsVisible.value = true));
    });
  }
}

function killPlayer() {
  playerInstance.value?.destroy();
}

onMounted(() => {
  if (consentState.groups.includes(OneTrustGroup.Tracking)) {
    initPlayer();
  }
});

watch(
  () => consentState.groups.includes(OneTrustGroup.Tracking),
  isEnabled => {
    if (isEnabled) {
      initPlayer();
      if (waitingForConsent.value) {
        // We were waiting for consent since this component triggered the consent modal
        youtubeEmbedIsVisible.value = true;
        void startVideo();
      }
    }
  }
);
</script>

<style src="./UtilYoutubeVideo.scss" lang="scss" scoped />
<style src="~/assets/scss/plyr.scss" lang="scss" />
