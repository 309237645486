import type { RichtextStoryblok } from '~/types/storyblok-generated';

export function useContent() {
  const hasRichtextContent = (richtext: RichtextStoryblok) => {
    if (!richtext) {
      return false;
    }
    if (!richtext.content) {
      return false;
    }
    if (!richtext.content[0]) {
      return false;
    }
    if (!richtext.content[0].content) {
      return false;
    }

    return true;
  };

  return {
    hasRichtextContent,
  };
}
