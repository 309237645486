<template>
  <div ref="$el" class="cart-list" :class="{ 'is-modal': inModal }">
    <div
      class="cart-list-header"
      :class="{
        'has-backdrop': inModal,
      }">
      <div v-if="!inModal" class="close" @click="emit('close')">
        <BaseIcon name="fi_x" color="white" />
      </div>

      <div class="cart-list-border" :class="{ 'is-faded': scrollerState.topReached }"></div>
    </div>

    <UtilScrollable
      ref="scrollable"
      class="cart-scrollbar"
      :fullHeight="inModal !== undefined && !cartItems?.length"
      @change="onScrollChange">
      <template v-if="cartItems && cartItems.length > 0">
        <div class="cart-list-intro">
          <h2 class="heading-sub-medium">{{ t('BMShoppingCartList.headline') }}</h2>
          <BaseRichtext
            class="text-medium-regular"
            :text="blok.copy"
            :isProseEnabled="false"
            :preventInlineIcons="true"
            :isDark="true" />
        </div>
        <ul>
          <li
            v-for="item in cartItems"
            :key="'cart-item-' + item.item_id"
            class="cart-list-item-fadeable"
            @click="openLink(item)">
            <div class="list-item-image">
              <img :src="item.product_image.src" :alt="item.product_image.alt" />
            </div>
            <div class="list-item-label">
              <span class="action-medium-bold">{{ item.product_name }}</span>
              <span class="action-medium-regular">{{ item.qty }}x</span>
            </div>
          </li>
        </ul>
      </template>
      <template v-else>
        <div class="cart-list-intro">
          <h2 class="heading-sub-medium">{{ t('BMShoppingCartList.headlineEmpty') }}</h2>
          <BaseRichtext
            class="text-medium-regular"
            :text="blok.copy ?? t('BMShoppingCartList.emptyMessage')"
            :isProseEnabled="false"
            :preventInlineIcons="true"
            :isDark="true" />
        </div>
        <div class="cart-list-payments">
          <h3 class="text-medium-bold">{{ t('BMShoppingCartList.paymentMethods') }}</h3>
          <div class="logo-group">
            <LogoPayPal />
            <LogoVisa />
            <LogoMastercard />
          </div>
        </div>
      </template>
    </UtilScrollable>

    <div
      class="cart-list-footer"
      :class="{
        'has-backdrop': inModal,
      }">
      <div v-if="!scrollerState.isScrollable" class="cart-list-border-solid"></div>
      <div class="cart-list-border" :class="{ 'is-faded': scrollerState.bottomReached }"></div>
      <UtilButton :blok="reviewOrderButton" :isBackgroundDark="true" skipUrlManipulation />
    </div>
  </div>
</template>
<script setup lang="ts">
import { navigateTo, useRuntimeConfig } from '#app';
import { computed, nextTick, onBeforeUnmount, reactive, ref, watch } from 'vue';
import { BaseIcon, BaseRichtext } from '~/components/base';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import UtilScrollable from '~/components/storyblok/utils/UtilScrollable/UtilScrollable.vue';
import { useShoppingCart } from '~/composables/useShoppingCart';
import { useTransition } from '~/composables/useTransition';
import type { ICartItem } from '~/types/cart';
import type { BmShoppingCartStoryblok, UtilButtonStoryblok } from '~/types/storyblok-generated';
import LogoPayPal from '~/assets/icons/logo_paypal.svg?component';
import LogoVisa from '~/assets/icons/logo_visa.svg?component';
import LogoMastercard from '~/assets/icons/logo_mastercard.svg?component';
import { useI18n } from '#imports';

const props = defineProps<{
  blok: BmShoppingCartStoryblok;
  inModal?: boolean;
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'update', height: number, contentHeight: number): void;
}>();

const scrollable = ref<InstanceType<typeof UtilScrollable>>();
const $el = ref<HTMLElement>();
const { t } = useI18n();
const config = useRuntimeConfig();

const scrollerState = reactive({
  topReached: true,
  bottomReached: false,
  isScrollable: false,
  maxScroll: 0,
});

const { cartStore } = useShoppingCart();
const { fadeSlide } = useTransition();

const reviewOrderButton = computed<UtilButtonStoryblok>(() => {
  const url = `${config.public.SHOP_BASE_URL}${(cartItems.value?.length ?? 0) > 0 ? '/checkout/cart' : ''}`;
  return {
    component: 'util_button',
    theme: 'primary',
    size: 'large',
    text: (cartItems.value?.length ?? 0) > 0 ? t('BMShoppingCartList.reviewOrder') : t('BMShoppingCartList.goToShop'),
    _uid: 'review-order-button',
    link: {
      fieldtype: 'multilink',
      linktype: 'url',
      url: url,
      cached_url: url,
      id: 'review-order-button',
    },
  };
});
const cartItems = computed(() => cartStore.value.cart?.items);
let scrollbarUpdateTimeout: ReturnType<typeof setTimeout>;

async function openLink(item: ICartItem) {
  await navigateTo(item.configure_url, {
    open: {
      target: '_blank',
    },
  });
}

function fadeListItems(_headerFading: boolean = false) {
  // Fade invisible list items
  const fadeableItems = $el.value?.querySelectorAll<HTMLElement>('.cart-list-item-fadeable');
  if (!fadeableItems) {
    return;
  }
  Array.from(fadeableItems).map(item => item.classList.remove('cart-list-item-fadeable'));
}

function updateScrollBar() {
  clearTimeout(scrollbarUpdateTimeout);
  scrollbarUpdateTimeout = setTimeout(() => {
    if (!scrollable.value) {
      return;
    }
    scrollable.value.update();
  }, 30);
}

function onScrollChange(e: { topReached: boolean; bottomReached: boolean; maxScroll: number; contentHeight: number }) {
  scrollerState.topReached = e.topReached;
  scrollerState.bottomReached = e.bottomReached;
  scrollerState.isScrollable = e.maxScroll > 0;
  scrollerState.maxScroll = e.maxScroll;
  emit('update', e.maxScroll, e.contentHeight);
}

watch(
  () => props.isOpen,
  () => {
    const fadeableItems = $el.value?.querySelectorAll<HTMLElement>('.cart-list-item-fadeable');
    if (!fadeableItems) {
      return;
    }
    Array.from(fadeableItems).map(item => item.classList.remove('cart-list-item-fadeable'));
    if (props.isOpen) {
      updateScrollBar();
    }
  }
);

watch(
  () => cartItems.value?.length,
  (value, oldValue) => {
    updateScrollBar();

    const headerFading = !value || !oldValue;

    if (headerFading) {
      void nextTick(() => {
        const cartListIntro = $el.value?.querySelector<HTMLElement>('.cart-list-intro');
        const cartListPayments = $el.value?.querySelector<HTMLElement>('.cart-list-payments');

        if (!cartListIntro || !cartListPayments) {
          return;
        }
        fadeSlide(Array.from(cartListIntro.children), {
          speedMultiplier: 0.5,
        });

        if (!value)
          fadeSlide(Array.from(cartListPayments.children), {
            speedMultiplier: 0.5,
            baseDelay: 0.3,
          });
      });
    }
    void nextTick(() => fadeListItems(headerFading));
  }
);

onBeforeUnmount(() => {
  clearTimeout(scrollbarUpdateTimeout);
});
</script>

<style src="./BMShoppingCartList.scss" lang="scss" scoped />
