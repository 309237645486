<template>
  <div ref="$card" class="em-home-card" :class="{ 'has-pointer': interactivity === 'flip' }" @click="handleFlip">
    <component
      :is="interactivity === 'link' ? BaseLink : 'div'"
      class="cards-container"
      :link="link"
      @mousemove="tiltCard"
      @mouseenter="isHovered = true"
      @mouseleave="onMouseLeave">
      <div
        ref="$cardFront"
        class="card card-front"
        :class="[cardOptions.paddingClass, isDark ? 'is-dark' : 'is-light']"
        :style="{
          alignItems: horizontalTextAlign === 'left' ? 'flex-start' : 'center',
          justifyContent:
            verticalTextAlign === 'top' ? 'flex-start' : verticalTextAlign === 'center' ? 'center' : 'flex-end',
        }">
        <!-- Front card  -->
        <div
          class="card-front-inner"
          :class="{
            'max-width-x-large': cardType === 'xLarge',
            'max-width-large': cardType === 'large',
            'max-width-medium': cardType === 'medium' || cardType === 'medium-large',
            'max-width-small': cardType === 'small' || cardType === 'xSmall',
          }"
          :style="{ textAlign: horizontalTextAlign }">
          <!-- Front text  -->
          <p
            v-if="textLayout === 'overline_headline' && frontSideOverline"
            ref="$text"
            class="front-overline"
            :class="[cardOptions.smallTextClass, cardOptions.smallTextMargin]">
            {{ frontSideOverline }}
          </p>
          <div ref="$headline">
            <BaseRichtext :class="cardOptions.largeTextClass" :text="frontSideHeadline" :isProseEnabled="false" />
          </div>
          <p
            v-if="textLayout === 'headline_subline' && frontSideSubline"
            ref="$text"
            class="front-subline"
            :class="[cardOptions.smallTextClass, cardOptions.smallTextMargin]">
            {{ frontSideSubline }}
          </p>
        </div>

        <!-- Buttons  -->
        <div
          v-if="interactivity === 'link'"
          class="icon-button is-primary"
          :tabindex="-1"
          :class="[verticalTextAlign === 'top' ? 'is-bottom' : 'is-top', { 'is-hovered': isHovered }]">
          <BaseIcon name="fi_arrow-right" :size="16" color="inherit" />
        </div>
        <button
          v-if="interactivity === 'flip'"
          :tabindex="isFlipped ? -1 : undefined"
          class="icon-button"
          :class="[isDark ? 'is-dark' : 'is-light', verticalTextAlign === 'top' ? 'is-bottom' : 'is-top']"
          :aria-label="t('global.flipCard')"
          @click="handleFlip">
          <BaseIcon name="fi_repeat" :size="16" color="inherit" />
        </button>

        <!-- Background Media  -->
        <div v-if="!!frontSideVideo || !!frontSideImage" class="card-media">
          <BaseVideo
            v-if="!!frontSideVideo && isVideo(frontSideVideo.filename)"
            :src="frontSideVideo.filename"
            :autoplay="true"
            :loop="true"
            :loadWhenInView="cardType !== 'xLarge'"
            :poster="frontSideImage?.filename"
            :posterBreakPointsWidthMap="cardOptions.breakpointsWidthMap"
            :posterHeightRatio="
              breakpointState.isTabletLarge
                ? cardOptions.heightRatioMap?.tabletLargeUp
                : breakpointState.isPhoneLarge
                  ? cardOptions.heightRatioMap?.phoneLargeUp
                  : cardOptions.heightRatioMap?.base
            "
            :preloadPoster="cardType === 'xLarge'"
            class="media is-video" />
          <BaseImage
            v-else
            :image="frontSideImage"
            :breakpointsWidthMap="cardOptions.breakpointsWidthMap"
            :heightRatio="
              breakpointState.isTabletLarge
                ? cardOptions.heightRatioMap?.tabletLargeUp
                : breakpointState.isPhoneLarge
                  ? cardOptions.heightRatioMap?.phoneLargeUp
                  : cardOptions.heightRatioMap?.base
            "
            class="media"
            :preload="cardType === 'xLarge'"
            objectCover />
        </div>
      </div>

      <!-- Back card  -->
      <div v-if="interactivity === 'flip'" ref="$cardBack" class="card card-back" :class="cardOptions.paddingClass">
        <p v-if="backSideText" class="card-back-text">
          {{ backSideText }}
        </p>
        <UtilButton
          v-if="backSideCta"
          :blok="backSideCta"
          class="card-back-button"
          :tabindex="isFlipped ? undefined : -1" />
        <button
          class="icon-button is-dark is-top"
          :tabindex="isFlipped ? undefined : -1"
          :aria-label="t('global.flipCard')"
          @click="handleFlip">
          <BaseIcon name="fi_repeat" :size="16" color="inherit" />
        </button>
      </div>
    </component>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import BaseImage from '@/components/base/BaseImage.vue';
import BaseLink from '@/components/base/BaseLink.vue';
import BaseVideo from '@/components/base/BaseVideo.vue';
import UtilButton from '@/components/storyblok/utils/UtilButton/UtilButton.vue';
import { onMounted, ref, watch } from 'vue';
import { BaseIcon, BaseRichtext } from '~/components/base';
import { use3dCard } from '~/composables/use3dCard';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { isVideo } from '~/utils/media';
import type { EMHomeCard } from './EMHome.types';

const props = defineProps<EMHomeCard>();

const emit = defineEmits<{ (e: 'flip', id: string): void }>();

const $card = ref<HTMLElement>();
const $cardFront = ref<HTMLElement>();
const $cardBack = ref<HTMLElement>();
const $headline = ref<HTMLElement>();
const $text = ref<HTMLElement>();
const tl = ref<GSAPTimeline>();
const isFlipped = ref(props.isFlipped);
const isHovered = ref(false);

const { state: breakpointState } = useBreakpoint();
const { t } = useI18n();

function handleFlip(e: MouseEvent) {
  if (props.interactivity !== 'flip') {
    return;
  }
  e.stopPropagation();
  emit('flip', props.uid);
}

function onMouseLeave() {
  resetTilt();
  isHovered.value = false;
}

interface CardOptions {
  largeTextClass: string;
  smallTextClass?: string;
  paddingClass: string;
  perspective: number;
  breakpointsWidthMap: Record<number, number>;
  heightRatioMap: Record<'base' | 'phoneLargeUp' | 'tabletLargeUp', [number, number]>;
  smallTextMargin?: string;
}

const cardOptionsMap: Record<EMHomeCard['cardType'], CardOptions> = {
  xLarge: {
    largeTextClass: 'card-xlarge-text-large',
    smallTextClass: 'card-xlarge-text-small',
    smallTextMargin: 'has-margin-large',
    paddingClass: 'padding-large',
    perspective: 5800,
    breakpointsWidthMap: {
      0: 300,
      375: 350,
      768: 940,
      1024: 1280,
    },
    heightRatioMap: {
      base: [9, 16],
      phoneLargeUp: [3, 4],
      tabletLargeUp: [3, 2],
    },
  },
  large: {
    largeTextClass: 'card-large-text-large',
    smallTextClass: 'card-large-text-small',
    smallTextMargin: 'has-margin-medium',
    paddingClass: 'padding-large',
    perspective: 4800,
    breakpointsWidthMap: {
      0: 300,
      375: 350,
      768: 512,
    },
    heightRatioMap: {
      base: [1, 1],
      phoneLargeUp: [1, 1],
      tabletLargeUp: [1, 1],
    },
  },
  'medium-large': {
    // The medium card in the 1 medium 1 small variant
    largeTextClass: 'card-medium-large-text-large',
    smallTextClass: 'card-medium-large-text-small',
    smallTextMargin: 'has-margin-small',
    paddingClass: 'padding-medium-large',
    perspective: 3400,
    breakpointsWidthMap: {
      0: 300,
      375: 350,
      768: 256,
    },
    heightRatioMap: {
      base: [3, 4],
      phoneLargeUp: [9, 16],
      tabletLargeUp: [9, 16],
    },
  },
  medium: {
    // The medium card in the 2 medium variant
    largeTextClass: 'card-medium-text-large',
    smallTextClass: 'card-medium-text-small',
    smallTextMargin: 'has-margin-small',
    paddingClass: 'padding-medium',
    perspective: 3000,
    breakpointsWidthMap: {
      0: 300,
      375: 350,
      768: 256,
    },
    heightRatioMap: {
      base: [1, 1],
      phoneLargeUp: [1, 1],
      tabletLargeUp: [1, 1],
    },
  },
  small: {
    largeTextClass: 'card-small-text-large',
    paddingClass: 'padding-small',
    perspective: 1500,
    breakpointsWidthMap: {
      0: 300,
      375: 350,
      768: 256,
    },
    heightRatioMap: {
      base: [16, 9],
      phoneLargeUp: [16, 9],
      tabletLargeUp: [16, 9],
    },
  },
  xSmall: {
    // The small cards in 1 large 2 small variant
    largeTextClass: 'card-xsmall-text-large',
    paddingClass: 'padding-small',
    perspective: 1500,
    breakpointsWidthMap: {
      0: 300,
      375: 350,
      768: 256,
    },
    heightRatioMap: {
      base: [3, 4],
      phoneLargeUp: [16, 9],
      tabletLargeUp: [16, 9],
    },
  },
};
const cardOptions = cardOptionsMap[props.cardType];

const { flipCard, resetTilt, tiltCard, setCard } = use3dCard({
  $card,
  $cardFront,
  $cardBack,
  $headline,
  $text,
  isFlipped,
  isFlippable: props.interactivity === 'flip',
  tl,
  options: { transformPerspective: cardOptions.perspective },
});

onMounted(setCard);

watch(() => props.isFlipped, flipCard);
</script>

<style lang="scss" scoped src="./EMHomeCard.scss" />
