<!-- 
  The UtilStickyBar component is a sticky bar that can be used to e.g. display filters in list modules.
  It has a fixedContent slot for content that should always be visible and an arrowsContent slot for
  content that should be scrollable.
 -->
<template>
  <div
    ref="$outerContainer"
    :class="['util-sticky-bar no-scrollbar', { 'transition-done': storedState.isTransitionDone }]"
    :style="{
      transform: `translateY(${scrollState.isStickyActive && isMovedDown ? scrollState.headerHeight : 0}px)`,
    }">
    <div ref="$floatingAnchor" class="spacing-container container-full"></div>
  </div>
  <Teleport v-if="componentIsMounted" to="body">
    <div
      ref="$floatingContainer"
      class="floating-container"
      :class="{ 'is-visible': floatingContainerVisible }"
      :style="floatingStyles">
      <div :class="['fade is-left', isDark ? 'is-dark' : 'is-light', { 'is-visible': !outerArrivedState.left }]"></div>
      <div class="fixed-content-container">
        <slot name="fixedContent"></slot>
      </div>
      <div v-if="slots.fixedContent && slots.arrowsContent" class="line"></div>
      <div v-if="slots.arrowsContent" ref="$innerContainer" class="inner-scroll-container no-scrollbar">
        <UtilStickyBarScrollButton position="left" :isVisible="!innerArrivedState.left" @click="scrollTo('left')" />
        <div class="arrows-content-container">
          <slot name="arrowsContent"></slot>
        </div>
        <UtilStickyBarScrollButton position="right" :isVisible="!innerArrivedState.right" @click="scrollTo('right')" />
      </div>
      <div
        :class="['fade is-right', isDark ? 'is-dark' : 'is-light', { 'is-visible': !outerArrivedState.right }]"></div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { autoUpdate, offset, size, useFloating } from '@floating-ui/vue';
import { useDebounceFn, useElementVisibility, useScroll as vueUseScroll } from '@vueuse/core';
import { onBeforeUnmount, onMounted, ref, useSlots, watch } from 'vue';
import { useHeader } from '~/composables/useHeader';
import { useScroll } from '~/composables/useScroll';
import UtilStickyBarScrollButton from './UtilStickyBarScrollButton.vue';

defineProps<{
  isDark?: boolean;
}>();

const $outerContainer = ref<HTMLElement>();
const $innerContainer = ref<HTMLElement>();
const $floatingAnchor = ref<HTMLElement>();
const $floatingContainer = ref<HTMLElement>();
const isMovedDown = ref(false);
const isScrolledPastRef = ref(false);
const yPosition = ref(0);
const floatingContainerVisible = ref(false);
const componentIsMounted = ref(false);
const storedState = {
  isStickyActive: false,
  isMovedDown: false,
  isTransitionDone: false,
};
let timeout: ReturnType<typeof setTimeout>;

/**
 * Wait for the portal to do its thing and then show and update the floating container
 */
onMounted(() => {
  componentIsMounted.value = true;
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    floatingContainerVisible.value = true;
    updateMeasurements();
  }, 1000);
});

const slots = useSlots();
const { state: scrollState } = useScroll();
const { state: headerState } = useHeader();
const barIsVisible = useElementVisibility($outerContainer);
// eslint-disable-next-line @typescript-eslint/unbound-method
const { arrivedState: outerArrivedState, measure: measureOuter } = vueUseScroll($floatingContainer, {
  throttle: 100,
  offset: { left: 20, right: 20 },
});
// eslint-disable-next-line @typescript-eslint/unbound-method
const { arrivedState: innerArrivedState, measure: measureInner } = vueUseScroll($innerContainer, {
  throttle: 100,
  offset: { left: 20, right: 20 },
});

/**
 * Check the scroll position on mount because the portal could interfere with the scroll position
 */
function updateMeasurements() {
  measureOuter();
  measureInner();
}
const debouncedUpdateMeasurements = useDebounceFn(updateMeasurements, 100);

/**
 *
 */
const { floatingStyles } = useFloating($floatingAnchor, $floatingContainer, {
  strategy: 'fixed',
  placement: 'bottom',
  whileElementsMounted: autoUpdate,
  middleware: [
    size({
      apply({ rects, elements }) {
        Object.assign(elements.floating.style, {
          width: `${rects.reference.width}px`,
          height: `${rects.reference.height}px`,
        });
      },
    }),
    offset(-40),
  ],
});

/**
 * Get the y position of the outer container and update it when needed
 */
function updateYPosition() {
  if ($outerContainer.value) {
    yPosition.value = $outerContainer.value.getBoundingClientRect().top + window.scrollY;
  }
}
const debouncedUpdateYPosition = useDebounceFn(updateYPosition, 100);
watch($outerContainer, updateYPosition);

/**
 * Scroll inside the arrowsContent
 */
function scrollTo(direction: 'left' | 'right') {
  $innerContainer.value?.scrollBy({
    left: direction === 'left' ? -150 : 150,
    behavior: 'smooth',
  });
}

/**
 * Set the header state to sticky when the bar is sticky and visible
 */
function setStickyAnimation() {
  if (headerState.menuOpen) {
    // No need to update state since the menu overlays it
    return;
  }

  if (!$outerContainer.value) {
    return;
  }

  if (!barIsVisible.value) {
    // The bar is scrolled out of view, therefore we reset the state
    scrollState.isStickyActive = false;
    return;
  }

  // Set the sticky bar to sticky when it is scrolled to the top of the page
  isScrolledPastRef.value = yPosition.value < window.scrollY;
  scrollState.isStickyActive = isScrolledPastRef.value;
  isMovedDown.value = isScrolledPastRef.value && scrollState.velocity < 0;

  // Storing values for menu
  storedState.isStickyActive = isScrolledPastRef.value;
  storedState.isMovedDown = isMovedDown.value;
}

function onHeaderState(value: boolean) {
  // Menu is closed - returning to stored state
  if (!value) {
    scrollState.isStickyActive = storedState.isStickyActive;
    isMovedDown.value = storedState.isMovedDown;
  } else {
    // Storing animation state because :class bindings will clear the already added classes
    storedState.isTransitionDone = $outerContainer.value?.classList.contains('transition-done') ?? false;
  }
}

watch(
  () => [barIsVisible, scrollState.velocity > 0],
  () => {
    if (barIsVisible.value) {
      setStickyAnimation();
    }
  }
);
watch(() => headerState.menuOpen, onHeaderState);

/**
 * Add scroll listeners
 */
onMounted(() => {
  window.addEventListener('resize', debouncedUpdateYPosition);

  window.addEventListener('resize', debouncedUpdateMeasurements);
  updateYPosition();
});
onBeforeUnmount(() => {
  clearTimeout(timeout);

  window.removeEventListener('resize', debouncedUpdateYPosition);

  window.removeEventListener('resize', debouncedUpdateMeasurements);
});
</script>

<style src="./UtilStickyBar.scss" lang="scss" scoped />
