<template>
  <div v-editable="blok" class="container-full">
    <div class="cm-copy">
      <!-- Copy Heading -->
      <ItemContentHead
        v-if="blok.head?.length"
        :blok="blok.head[0]"
        :isDark="blok.is_dark"
        :isVisibleOnLoad="isStorybookView"
        class="head"
        :class="[
          isHeadSmall ? 'is-small' : 'is-large',
          {
            'is-top': isHeadTop,
            'is-right': isHeadRight,
            'is-left': isHeadLeft,
          },
        ]"
        :headSize="blok.head_size" />

      <!-- Content -->
      <div
        v-if="blok.body?.length"
        class="content"
        :class="{
          'head-is-top': isHeadTop,
          'head-is-left-large': isHeadLeft && !isHeadSmall,
        }">
        <template v-for="(item, index) in blok.body" :key="index">
          <UtilRichtext v-if="item.component === 'util_richtext'" :blok="item" :isDark="blok.is_dark" />
          <ItemQuote v-if="item.component === 'item_quote'" :blok="item" :isDark="blok.is_dark" />
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import ItemContentHead from '~/components/storyblok/item/ItemContentHead/ItemContentHead.vue';
import UtilRichtext from '~/components/storyblok/utils/UtilRichtext/UtilRichtext.vue';
import ItemQuote from '~/components/storyblok/item/ItemQuote/ItemQuote.vue';
import type { CmCopyStoryblok } from '~/types/storyblok-generated';

const props = defineProps<{
  blok: CmCopyStoryblok;
  isStorybookView?: boolean;
}>();

const isHeadTop = computed(() => props.blok.head_position === 'top');
const isHeadRight = computed(() => props.blok.head_position === 'right');
const isHeadLeft = computed(() => props.blok.head_position === 'left');
const isHeadSmall = computed(() => props.blok.head_size === 'small');
</script>

<style src="./CMCopy.scss" lang="scss" scoped />
