<template>
  <div class="base-input form-field" :class="[isDark ? 'is-dark' : 'is-light', { 'is-disabled': disabled }]">
    <label v-if="label" class="label" :for="uid">{{ label }}{{ required ? '*' : '' }}</label>
    <div class="field-container">
      <input
        :id="uid"
        ref="$input"
        v-model="model"
        tabindex="0"
        :name="name"
        :type="type"
        :placeholder="placeholder"
        :readonly="disabled"
        class="input"
        :class="[
          isDark ? 'dark' : 'light',
          {
            'is-search-bar': isSearchBar,
            'has-error': errorMessage && isTouched,
          },
        ]"
        :autocomplete="autocomplete"
        @focus="emit('focus', $event)"
        @blur="emit('blur', $event)"
        @keydown.enter.prevent="emit('submit')"
        @keydown.escape="emit('escape', $event)" />

      <!--  Search Icon -->
      <BaseLink v-if="isSearchBar" class="icon" @click="emit('submit')">
        <BaseIcon name="fi_search" :size="16" :color="isDark ? 'white' : 'black'" />
      </BaseLink>
    </div>
    <div v-if="errorMessage && isTouched" class="error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, useId } from 'vue';
import BaseIcon from '~/components/base/BaseIcon.vue';
import BaseLink from '~/components/base/BaseLink.vue';
import type { FormFieldProps } from '~/types/utils';

const model = defineModel<string | undefined>({ required: true });

interface BaseInput extends FormFieldProps {
  isDark?: boolean;
  isSearchBar?: boolean;
  isAutoFocused?: boolean;
  type?: string;
  placeholder?: string;
  autocomplete?: string;
}

const props = defineProps<BaseInput>();

const emit = defineEmits<{
  (e: 'focus', event: FocusEvent): void;
  (e: 'blur', event: FocusEvent): void;
  (e: 'escape', event: KeyboardEvent): void;
  (e: 'submit'): void;
}>();

const uid = props.id ?? useId();
const $input = ref<HTMLInputElement>();

onMounted(() => {
  if (props.isAutoFocused) {
    $input.value?.focus();
  }
});
</script>

<style src="~/assets/scss/_form-field.scss" lang="scss" scoped />
<style src="./_BaseInput.scss" lang="scss" scoped />
