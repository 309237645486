import { defineNuxtRouteMiddleware, navigateTo, useRuntimeConfig } from '#imports';
import type { Locale } from '~/types/locales';

export default defineNuxtRouteMiddleware((to, from) => {
  const config = useRuntimeConfig();
  const { path, query, hash } = to;

  // In the exported header/footer, we want to actually navigate to any changed path instead of using client navigation
  if (config.public.IS_HEADER_FOOTER_EXPORT) {
    const pathHasChanged = from.path !== path;
    if (import.meta.client && pathHasChanged) {
      return navigateTo(to.fullPath, { external: true });
    }
    // We don't want the middleware to run in the header/footer export
    return;
  }

  let nextPath = path;

  // Ignore any internal Nuxt paths
  const isFilePath = /\.(js|css|png|jpg|jpeg|svg)$/i.test(nextPath);
  if (nextPath.startsWith('/_nuxt') || isFilePath) {
    return;
  }

  // Redirect to lowercase path
  nextPath = nextPath.toLocaleLowerCase();

  // Ensure all paths end with a trailing slash
  if (!nextPath.endsWith('/')) {
    nextPath += '/';
  }

  try {
    const locale = path.split('/')[1] as Locale;
    const isValidLocale = config.public.LOCALES.includes(locale);
    const isEnabledLocale = config.public.LOCALES_ENABLED.includes(locale);
    if (isValidLocale && isEnabledLocale === false && config.public.IS_DEVELOPMENT === false) {
      // The path contains a valid locale but it's disabled, redirect to the external page with the locale appended
      return navigateTo(`${config.public.LOCALES_DISABLED_REDIRECT_BASE_URL}/${locale}`, { external: true });
    }
  } catch (e) {
    console.error(`Failed to detect locale in redirect middleware: ${(e as Error).message}`);
  }

  if (path !== nextPath) {
    return {
      path: nextPath,
      query,
      hash,
    };
  }
});
