<template>
  <div class="em-showroom-stage">
    <canvas id="webGlCanvas" ref="$webGlCanvas"></canvas>
  </div>
</template>

<script lang="ts" setup>
import { useDebounceFn } from '@vueuse/core';
import type { ISbStoryData } from 'storyblok';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import type { DataProductStoryblok } from '~/types/storyblok-generated';
import { WebGlApp, type WebGlAppInstance } from '~/webglApp/webGlApp';
import { ShowroomEvent, type ShowroomCanvas, type ShowroomEventsMap } from '~/webglApp/webGlApp.types';
import type { ShowroomPointerState } from './EMShowroom.types';
// Import Stats from 'stats.js';

declare global {
  interface Window {
    webGlApp: WebGlAppInstance | null;
  }
}

const props = withDefaults(
  defineProps<{
    pointerState: ShowroomPointerState | null;
    visibleMachines: ISbStoryData<DataProductStoryblok>[];
    featuredMachines: ISbStoryData<DataProductStoryblok>[];
    current: string;
    currentIndex: number;
    filtersToggled: boolean;
  }>(),
  {
    machines: () => [],
    featuredMachines: () => [],
    current: 'none',
    currentIndex: 0,
  }
);

const emit = defineEmits<{
  (e: 'load'): void;
  (e: 'progress', progress: number): void;
}>();

const $webGlCanvas = ref<ShowroomCanvas | null>(null);

// Let stats: Stats;
let oldDateTime = performance.now();
let webGlAppIsStarted = false;

function onLoadProgress(event: ShowroomEventsMap[ShowroomEvent.LoadingProgress]) {
  const progress = event.detail;
  if (progress >= 1 && webGlAppIsStarted === false) {
    webGlAppIsStarted = true;
    emit('load');
    animate();
    window.webGlApp?.start();
  }
  emit('progress', progress);
}

let _animationFrame: number;

function animate() {
  const newDateTime = performance.now();
  const dt = newDateTime - oldDateTime;

  // Limit to 60 fps
  if (dt > 1000 / 60) {
    // Stats.begin();
    window.webGlApp?.update(dt / 1000);
    oldDateTime = newDateTime;
    // Stats.end();
  }

  _animationFrame = requestAnimationFrame(animate);
}

function onResize() {
  window.webGlApp?.resize(window.innerWidth, window.innerHeight);
}
const debouncedOnResize = useDebounceFn(onResize, 100);

function pointerDown(pointerState: ShowroomPointerState) {
  window.webGlApp?.onPointerDown(pointerState);
}

function pointerUp(pointerState: ShowroomPointerState) {
  window.webGlApp?.onPointerUp(pointerState);
}

function previousMachine() {
  window.webGlApp?.previousMachine();
}

function nextMachine() {
  window.webGlApp?.nextMachine();
}

watch(
  () => props.pointerState,
  () => {
    if (props.pointerState) {
      window.webGlApp?.onPointerMove(props.pointerState);
    }
  }
);

watch(
  () => props.visibleMachines,
  () => {
    window.webGlApp?.setMachines(props.visibleMachines);
  }
);

onMounted(() => {
  if (!$webGlCanvas.value) {
    return;
  }
  if (!window.webGlApp) {
    $webGlCanvas.value?.addEventListener(ShowroomEvent.LoadingProgress, onLoadProgress);
    window.webGlApp = new WebGlApp(
      $webGlCanvas.value as HTMLCanvasElement,
      props.featuredMachines,
      props.visibleMachines
    );
  }

  // Overall loading progress from 0.0 - 1.0
  // Assets loading progress from 0.0 - 0.9
  // Machine initilization progress from 0.0 - 0.1 = 1.0 in total = App starts

  // Used to debug performance
  // Stats = new Stats();
  // Stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
  // Document.body.appendChild(stats.dom);

  window.addEventListener('resize', debouncedOnResize);
});

onUnmounted(() => {
  $webGlCanvas.value?.removeEventListener(ShowroomEvent.LoadingProgress, onLoadProgress);
  if (_animationFrame) {
    window.cancelAnimationFrame(_animationFrame);
  }
  window.webGlApp = null;
  window.removeEventListener('resize', debouncedOnResize);
});

defineExpose({
  pointerDown,
  pointerUp,
  previousMachine,
  nextMachine,
  onResize: debouncedOnResize,
  onLoadProgress,
});
</script>

<style src="./EMShowroomStage.scss" lang="scss" scoped />
