import * as THREE from 'three';
import type { BlueNoiseInstance } from '../blueNoise/blueNoise';
// @ts-expect-error - We are importing 3d related code
import backgroundFrag from './background.frag';
// @ts-expect-error - We are importing 3d related code
import backgroundVert from './background.vert';

export type BackgroundInstance = InstanceType<typeof Background>;

export class Background {
  container = new THREE.Object3D();
  isActive = false;
  mesh;

  preInit() {
    return;
  }

  constructor(blueNoise: BlueNoiseInstance) {
    const material = new THREE.ShaderMaterial({
      uniforms: {
        u_blueNoiseTexture: { value: blueNoise.texture },
        u_blueNoiseTextureSize: { value: blueNoise.textureSize },
        u_blueNoiseTextureOffset: { value: blueNoise.textureOffset },
        u_lamellaeAmount: { value: 101 },
        u_lamellaeIntensity: { value: 15 },
        u_gradientStrip: { value: new THREE.Vector2(0.49, 0.7) },
      },
      vertexShader: backgroundVert,
      fragmentShader: backgroundFrag,
      transparent: true,
    });

    this.mesh = new THREE.Mesh(new THREE.PlaneGeometry(10, 5), material);
    this.mesh.position.set(0, 0, -2);
    this.mesh.rotation.set(0, 0, 0);
    this.container.add(this.mesh);
  }

  show() {
    this.isActive = true;
  }

  hide() {
    this.isActive = false;
  }

  update() {
    this.container.visible = this.isActive;
    if (!this.isActive) {
      return;
    }
  }
}
