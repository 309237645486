import * as THREE from 'three';
import type { AppStateInstance } from '~/webglApp/core/appState';
import type { MachineConfig } from '~/webglApp/webGlApp.types';

const TEXTURES_BASE_PATH = 'textures/machines/';
const MODELS_BASE_PATH = 'models/machines/';

function getMachinesConfig(qualityLvl: AppStateInstance['qualityLvl']): MachineConfig[] {
  return [
    {
      name: 'WMF 950 S',
      shadow: {
        texturePath: `${TEXTURES_BASE_PATH}wmf950s/${qualityLvl}/shadow/wmf950s_shadow.webp`,
        scale: new THREE.Vector3(0.8, 0.8, 1),
        position: new THREE.Vector3(0, -0.01, 0),
        intensity: 3,
      },
      modelPath: `${MODELS_BASE_PATH}wmf950s/${qualityLvl}/wmf950s.gltf`,
      zoomLevelByScreenSize: {
        'Small Phone Screen': 0.85,
        'Phone Screen': 1,
        'Small Tablet Screen': 1,
        'Tablet Screen': 1,
        'Small Screen': 1,
        'Medium Screen': 1,
        'Large Screen': 1,
        'Huge Screen': 1,
      },
    },
    {
      name: 'WMF 1100 S',
      shadow: {
        texturePath: `${TEXTURES_BASE_PATH}wmf1100s/${qualityLvl}/shadow/wmf1100s_shadow.webp`,
        scale: new THREE.Vector3(0.8, 0.8, 1),
        position: new THREE.Vector3(-0.006, 0.004, 0),
        intensity: 3.0,
      },
      modelPath: `${MODELS_BASE_PATH}wmf1100s/${qualityLvl}/wmf1100s.gltf`,
      zoomLevelByScreenSize: {
        'Small Phone Screen': 0.85,
        'Phone Screen': 1,
        'Small Tablet Screen': 1,
        'Tablet Screen': 1,
        'Small Screen': 1,
        'Medium Screen': 1,
        'Large Screen': 1,
        'Huge Screen': 1,
      },
    },
    {
      name: 'WMF 1300 S',
      shadow: {
        texturePath: `${TEXTURES_BASE_PATH}wmf1300s/${qualityLvl}/shadow/wmf1300s_shadow.webp`,
        scale: new THREE.Vector3(0.8125, 0.85, 1),
        position: new THREE.Vector3(0, 0, 0),
        intensity: 3.0,
      },
      modelPath: `${MODELS_BASE_PATH}wmf1300s/${qualityLvl}/wmf1300s.gltf`,
      zoomLevelByScreenSize: {
        'Small Phone Screen': 1,
        'Phone Screen': 1,
        'Small Tablet Screen': 1,
        'Tablet Screen': 1,
        'Small Screen': 1,
        'Medium Screen': 1,
        'Large Screen': 1,
        'Huge Screen': 1,
      },
    },
    {
      name: 'WMF 1500 S+',
      shadow: {
        texturePath: `${TEXTURES_BASE_PATH}wmf1500sPlus/${qualityLvl}/shadow/wmf1500sPlus_shadow.webp`,
        scale: new THREE.Vector3(0.825, 0.825, 1),
        position: new THREE.Vector3(0, 0, 0),
        intensity: 1.75,
      },
      modelPath: `${MODELS_BASE_PATH}wmf1500sPlus/${qualityLvl}/wmf1500sPlus.gltf`,
      zoomLevelByScreenSize: {
        'Small Phone Screen': 1,
        'Phone Screen': 1,
        'Small Tablet Screen': 1,
        'Tablet Screen': 1,
        'Small Screen': 1,
        'Medium Screen': 1,
        'Large Screen': 1,
        'Huge Screen': 1,
      },
    },
    {
      name: 'WMF 1500 F',
      shadow: {
        texturePath: `${TEXTURES_BASE_PATH}wmf1500f/${qualityLvl}/shadow/wmf1500f_shadow.webp`,
        scale: new THREE.Vector3(0.825, 0.825, 1),
        position: new THREE.Vector3(0, 0, 0),
        intensity: 2.5,
      },
      modelPath: `${MODELS_BASE_PATH}wmf1500f/${qualityLvl}/wmf1500f.gltf`,
      zoomLevelByScreenSize: {
        'Small Phone Screen': 1,
        'Phone Screen': 1,
        'Small Tablet Screen': 1,
        'Tablet Screen': 1,
        'Small Screen': 1,
        'Medium Screen': 1,
        'Large Screen': 1,
        'Huge Screen': 1,
      },
    },
    {
      name: 'WMF 5000 S+',
      shadow: {
        texturePath: `${TEXTURES_BASE_PATH}wmf5000sPlus/${qualityLvl}/shadow/wmf5000sPlus_shadow.webp`,
        scale: new THREE.Vector3(0.75, 0.725, 1),
        position: new THREE.Vector3(0, -0.0125, 0),
        intensity: 2.25,
      },
      modelPath: `${MODELS_BASE_PATH}wmf5000sPlus/${qualityLvl}/wmf5000sPlus.gltf`,
      zoomLevelByScreenSize: {
        'Small Phone Screen': 1,
        'Phone Screen': 1,
        'Small Tablet Screen': 1,
        'Tablet Screen': 1,
        'Small Screen': 1,
        'Medium Screen': 1,
        'Large Screen': 1,
        'Huge Screen': 1,
      },
    },
    {
      name: 'WMF 9000 F',
      shadow: {
        texturePath: `${TEXTURES_BASE_PATH}wmf9000f/${qualityLvl}/shadow/wmf9000f_shadow.webp`,
        scale: new THREE.Vector3(0.8, 0.7125, 1),
        position: new THREE.Vector3(-0.012, 0, 0),
        intensity: 2.5,
      },
      modelPath: `${MODELS_BASE_PATH}wmf9000f/${qualityLvl}/wmf9000f.gltf`,
      zoomLevelByScreenSize: {
        'Small Phone Screen': 1,
        'Phone Screen': 1,
        'Small Tablet Screen': 1,
        'Tablet Screen': 1,
        'Small Screen': 1,
        'Medium Screen': 1,
        'Large Screen': 1,
        'Huge Screen': 1,
      },
    },
    {
      name: 'WMF 9000 S+',
      shadow: {
        texturePath: `${TEXTURES_BASE_PATH}wmf9000sPlus/${qualityLvl}/shadow/wmf9000sPlus_shadow.webp`,
        scale: new THREE.Vector3(0.725, 0.7125, 1),
        position: new THREE.Vector3(0, 0.01, 0),
        intensity: 2.5,
      },
      modelPath: `${MODELS_BASE_PATH}wmf9000sPlus/${qualityLvl}/wmf9000sPlus.gltf`,
      zoomLevelByScreenSize: {
        'Small Phone Screen': 1,
        'Phone Screen': 1,
        'Small Tablet Screen': 1,
        'Tablet Screen': 1,
        'Small Screen': 1,
        'Medium Screen': 1,
        'Large Screen': 1,
        'Huge Screen': 1,
      },
    },
    {
      name: 'WMF espresso NEXT',
      shadow: {
        texturePath: `${TEXTURES_BASE_PATH}wmfEspressoNext/${qualityLvl}/shadow/wmfEspressoNext_shadow.webp`,
        scale: new THREE.Vector3(1.5125, 1.51, 1),
        position: new THREE.Vector3(0, 0.0125, 0),
        intensity: 3.0,
      },
      modelPath: `${MODELS_BASE_PATH}wmfEspressoNext/${qualityLvl}/wmfEspressoNext.gltf`,
      zoomLevelByScreenSize: {
        'Small Phone Screen': 1.1,
        'Phone Screen': 1,
        'Small Tablet Screen': 1,
        'Tablet Screen': 1,
        'Small Screen': 1,
        'Medium Screen': 1,
        'Large Screen': 1,
        'Huge Screen': 1,
      },
    },
  ];
}

export { getMachinesConfig };
