<template>
  <!-- Media -->
  <div
    ref="$media"
    class="cm-media-card-base"
    :class="{
      'has-modal': item.media_type === 'media' ? item.video?.filename : item.youtube_video?.[0].play_in_fullscreen,
    }"
    @click="handleVideoPlay"
    @mouseenter="setHoverTrue"
    @mouseleave="setHoverFalse">
    <div
      ref="$mediaWrapper"
      class="media-wrapper"
      :class="{ 'is-wide': mediaView === 'wide' }"
      :style="mediaView !== 'wide' ? { 'aspect-ratio': item.aspect_ratio?.replace('-', '/') } : undefined">
      <UtilYoutubeVideo
        v-if="isYoutubeVideo && item.youtube_video?.[0]"
        :blok="item.youtube_video[0]"
        :privacyWarningRichtext="youtubePrivacyWarning"
        class="media" />
      <template v-if="isYoutubeVideo === false">
        <div v-if="item.video_loop?.filename">
          <BaseVideo
            class="media"
            :src="item.video_loop.filename"
            :poster="item.image?.filename"
            :autoplay="true"
            :loop="true"
            :posterBreakPointsWidthMap="breakpointsWidthMap" />
        </div>
        <BaseImage
          v-else-if="item.image?.filename"
          class="media"
          :image="item.image"
          :breakpointsWidthMap="breakpointsWidthMap"
          :heightRatio="mediaView === 'wide' ? (breakpointState.isTablet ? [21, 9] : [1, 1]) : imageAspectRatio"
          @load="onScroll" />
      </template>
    </div>

    <!-- Play button for modal video -->
    <UtilButton
      v-if="isYoutubeVideo === false && item.video?.filename"
      :blok="{
        component: 'util_button',
        size: 'large',
        icon: 'fi_play',
        theme: 'primary',
        _uid: 'play-button',
      }"
      :aria-label="t('global.playVideo')"
      :isHovered="isHovered"
      class="play-button"
      @click="handleVideoPlay" />
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import BaseImage from '~/components/base/BaseImage.vue';
import BaseVideo from '~/components/base/BaseVideo.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import UtilYoutubeVideo from '~/components/storyblok/utils/UtilYoutubeVideo/UtilYoutubeVideo.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useModalVideo } from '~/composables/useModalVideo';
import { useScroll } from '~/composables/useScroll';
import type { CmMediaItemStoryblok, RichtextStoryblok } from '~/types/storyblok-generated';

const props = withDefaults(
  defineProps<{
    item: CmMediaItemStoryblok;
    mediaView?: 'normal' | 'wide' | 'align-right' | 'align-left';
    shouldContentFadeIn?: boolean;
    youtubePrivacyWarning: RichtextStoryblok;
    breakpointsWidthMap?: Record<number, number>;
  }>(),
  { mediaView: 'normal', aspectRatio: '3-2', breakpointsWidthMap: () => ({ 0: 700 }) }
);

const emit = defineEmits<{
  (e: 'contentFadeIn'): void;
}>();

const { state: stateModalVideo } = useModalVideo();
const { state: scrollState } = useScroll();
const { state: breakpointState } = useBreakpoint();
const { t } = useI18n();

const $media = ref<HTMLElement>();
const $mediaWrapper = ref<HTMLElement>();
const isHovered = ref(false);
const isYoutubeVideo = props.item.media_type === 'youtube-video';

const imageAspectRatio = computed<[number, number]>(() =>
  props.item.aspect_ratio ? (props.item.aspect_ratio?.split('-').map(n => parseInt(n)) as [number, number]) : [3, 2]
);

function setHoverTrue() {
  if (isYoutubeVideo) {
    return;
  }
  isHovered.value = true;
}
function setHoverFalse() {
  if (isYoutubeVideo) {
    return;
  }
  isHovered.value = false;
}

function handleVideoPlay() {
  if (isYoutubeVideo) {
    // Youtube video is handled in UtilYoutubeVideo
    return;
  }
  if (props.item.media_type === 'media' && props.item.video?.filename) {
    stateModalVideo.value = { type: 'media', filename: props.item.video?.filename };
    return;
  }
}

function fadeInMedia() {
  const card = $media.value?.parentElement;
  if (!card) {
    return;
  }
  const delay = ((card?.offsetLeft || 0) / window.innerWidth) * 0.8;
  gsap.to(card, {
    ease: 'expo.out',
    duration: 1.5,
    y: 0,
    delay: delay,
    force3D: true,
  });

  // Separate so it also works on card's content in wide view
  gsap.to(card, {
    autoAlpha: 1,
    delay: delay,
  });
}

watch(
  () => props.shouldContentFadeIn,
  () => emit('contentFadeIn')
);

function onScroll() {
  const media = $mediaWrapper.value;
  const container = $media.value;

  if (media && container) {
    const inViewport = ScrollTrigger.isInViewport(container, 0.25);

    if (!inViewport) {
      return;
    }

    let positionInViewport = 0.5 - Number(ScrollTrigger.positionInViewport(container, 'center').toFixed(2));
    if (positionInViewport > 0.5) {
      positionInViewport = 0.5;
    } else if (positionInViewport < -0.5) {
      positionInViewport = -0.5;
    }

    fadeInMedia();
    emit('contentFadeIn');

    const maxOffset = container.offsetHeight - media.offsetHeight * 1.2;

    gsap.to(media, {
      force3D: true,
      transformOrigin: 'center center',
      duration: 0.1,
      yPercent: props.mediaView === 'wide' ? 0 : -50,
      y: -positionInViewport * maxOffset,
      stagger: 1,
    });
  }
}

watch(
  () => [scrollState.top, isYoutubeVideo],
  () => {
    if (!isYoutubeVideo) {
      onScroll();
    }
  }
);

onMounted(() => {
  gsap.registerPlugin(ScrollTrigger);
  if (isYoutubeVideo) {
    emit('contentFadeIn');
    return;
  }
  if (!$mediaWrapper.value) {
    return;
  }
  gsap.set($mediaWrapper.value, {
    scale: props.mediaView === 'wide' ? 1.15 : 1.2,
    top: '50%',
    yPercent: props.mediaView === 'wide' ? 0 : -50,
  });

  void nextTick(onScroll);
});
</script>

<style src="./CMMediaCardBase.scss" lang="scss" scoped />
