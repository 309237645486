import * as THREE from 'three';
import type { AppStateInstance } from '../core/appState';
import type { EngineInstance } from '../core/engine';
import { clamp } from '../utils/mathUtils';

export type CameraControlsLookAtInstance = InstanceType<typeof CameraControlsLookAt>;

export class CameraControlsLookAt {
  appState: AppStateInstance;
  isActive: boolean = false;
  q: THREE.Quaternion;
  e: THREE.Euler;
  v1: THREE.Vector3;
  v2: THREE.Vector3;
  cameraLookStrength: number;
  cameraLookX: number;
  cameraLookY: number;
  cameraLookEaseDamp: number;
  cameraDistance: number;

  constructor(appState: AppStateInstance) {
    this.appState = appState;
    this.q = new THREE.Quaternion();
    this.e = new THREE.Euler();
    this.v1 = new THREE.Vector3();
    this.v2 = new THREE.Vector3();
    this.cameraLookStrength = 0.15;
    this.cameraLookX = 0;
    this.cameraLookY = 0;
    this.cameraLookEaseDamp = 0.1;
    this.cameraDistance = 0;
  }

  show() {
    this.isActive = true;
  }

  hide() {
    this.isActive = false;
  }

  update(engine: EngineInstance) {
    if (!this.isActive) {
      return;
    }

    // Reset
    engine.camera.matrix.identity();
    engine.camera.matrix.decompose(engine.camera.position, engine.camera.quaternion, engine.camera.scale);
    engine.camera.position.copy(engine.cameraPosition);
    engine.camera.lookAt(engine.cameraLookAtPosition);
    this.v1.set(0, 0, -1).applyQuaternion(engine.camera.quaternion);
    this.cameraDistance = this.v2.copy(engine.cameraLookAtPosition).sub(engine.camera.position).dot(this.v1);
    engine.camera.translateZ(this.cameraDistance * -1);

    const targetCameraLookAtX =
      clamp(this.appState.normalizedPointerCoords.y, -1, 1) *
      this.cameraLookStrength *
      this.appState.pointerActiveRatio *
      0.5;
    const targetCameraLookAtY =
      clamp(-this.appState.normalizedPointerCoords.x, -1, 1) *
      this.cameraLookStrength *
      this.appState.pointerActiveRatio;

    this.cameraLookX += (targetCameraLookAtX - this.cameraLookX) * this.cameraLookEaseDamp;
    this.cameraLookY += (targetCameraLookAtY - this.cameraLookY) * this.cameraLookEaseDamp;
    this.e.set(this.cameraLookX, this.cameraLookY, 0.0);
    this.q.setFromEuler(this.e);
    engine.camera.quaternion.multiply(this.q);
    engine.camera.translateZ(this.cameraDistance);
    engine.camera.matrix.compose(engine.camera.position, engine.camera.quaternion, engine.camera.scale);
    engine.camera.matrix.decompose(engine.camera.position, engine.camera.quaternion, engine.camera.scale);
    engine.camera.updateMatrixWorld();
  }
}
