import { default as _91_46_46_46slug_93ibMZjrSl3fMeta } from "/codebuild/output/src2382223574/src/seb-brand-platform/apps/wmf/pages/[...slug].vue?macro=true";
import { default as component_45stubjvXmo8lU3JMeta } from "/codebuild/output/src2382223574/src/seb-brand-platform/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubjvXmo8lU3J } from "/codebuild/output/src2382223574/src/seb-brand-platform/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en_com",
    path: "/en_com/:slug(.*)*",
    component: () => import("/codebuild/output/src2382223574/src/seb-brand-platform/apps/wmf/pages/[...slug].vue")
  },
  {
    name: "slug___de_de",
    path: "/de_de/:slug(.*)*",
    component: () => import("/codebuild/output/src2382223574/src/seb-brand-platform/apps/wmf/pages/[...slug].vue")
  },
  {
    name: component_45stubjvXmo8lU3JMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubjvXmo8lU3J
  },
  {
    name: component_45stubjvXmo8lU3JMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubjvXmo8lU3J
  },
  {
    name: component_45stubjvXmo8lU3JMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubjvXmo8lU3J
  },
  {
    name: component_45stubjvXmo8lU3JMeta?.name,
    path: "/de-DE-sitemap.xml",
    component: component_45stubjvXmo8lU3J
  }
]