<template>
  <div
    v-editable="blok"
    class="tm-highlight"
    :class="[
      blok.is_dark ? 'is-dark' : 'is-light',
      blok.light_text ? 'has-light-text' : 'has-dark-text',
      `has-visible-${mobileState.visibleEl}`,
    ]">
    <div v-if="blok.media" class="card-container" :class="`is-${blok.variant}`">
      <UtilMediaResponsive
        v-if="blok.media.filename"
        ref="$bgMedia"
        :image="blok.media"
        :imageMobile="blok.media_mobile"
        :video="blok.media"
        :videoMobile="blok.media_mobile"
        :autoplay="state.inViewport ? true : undefined"
        class="bg-media"
        breakpoint="isTablet"
        :breakpointsWidthMap="breakpointsWidthMap"
        @load="checkIfVideoShouldPlay" />

      <div
        ref="$content"
        class="content"
        :class="[`is-${blok.variant}`, `is-mobile-align-${blok.mobile_align ?? 'top'}`]">
        <div
          ref="$headlineContainer"
          class="headline-container"
          :class="[
            `is-${blok.variant}`,
            ['left', 'left-diagonal'].includes(blok.desktop_align) ? 'is-left' : 'is-right',
          ]">
          <BaseRichtext
            ref="$headline"
            class="headline heading-main-small"
            :text="blok.head"
            :removeInlineColorStyles="true"
            :isProseEnabled="false" />
          <div class="buttons">
            <template v-for="cta in transformedButtons" :key="cta._uid">
              <UtilLinkItemIcon v-if="cta.component === 'util_link-item-icon'" :blok="cta" :isDark="blok.light_text" />
              <UtilLinkItemSubtext
                v-if="cta.component === 'util_link-item-subtext'"
                :blok="cta"
                :isDark="blok.light_text" />
              <UtilButton v-if="cta.component === 'util_button'" :blok="cta" :isBackgroundDark="blok.light_text" />
            </template>
          </div>
        </div>

        <div
          ref="$textContainer"
          class="text-container"
          :class="[
            `is-${blok.variant}`,
            ['left', 'right-diagonal'].includes(blok.desktop_align) ? 'is-left' : 'is-right',
            { 'is-same-as-headline': ['left', 'right'].includes(blok.desktop_align) },
          ]">
          <span v-if="blok.overline" class="overline-text text-medium-bold">
            {{ blok.overline }}
          </span>
          <BaseRichtext
            v-if="blok.copy && hasRichtextContent(blok.copy)"
            class="description text-medium-regular"
            :removeInlineColorStyles="true"
            :text="blok.copy"
            :isProseEnabled="false" />
        </div>
      </div>
    </div>

    <div v-if="blok.footnote && hasRichtextContent(blok.footnote)" class="footnote container-full">
      <BaseRichtext class="footnote-richtext text-small-regular" :text="blok.footnote" :isProseEnabled="false" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDebounceFn, useElementVisibility } from '@vueuse/core';
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import BaseRichtext from '~/components/base/BaseRichtext/BaseRichtext.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import UtilLinkItemIcon from '~/components/storyblok/utils/UtilLinkItemIcon/UtilLinkItemIcon.vue';
import UtilLinkItemSubtext from '~/components/storyblok/utils/UtilLinkItemSubtext/UtilLinkItemSubtext.vue';
import UtilMediaResponsive from '~/components/storyblok/utils/UtilMediaResponsive/UtilMediaResponsive.vue';
import { useContent } from '~/composables/useContent';
import type { TmHighlightStoryblok } from '~/types/storyblok-generated';
import { deviceToBreakpointMap, type ModuleWrapperState } from '~/types/utils';

const props = defineProps<{
  blok: TmHighlightStoryblok;
  state: ModuleWrapperState;
}>();

const $content = ref<HTMLElement>();
const $textContainer = ref<HTMLElement>();
const $headlineContainer = ref<HTMLElement>();
const $bgMedia = ref<typeof UtilMediaResponsive>();
const $headline = ref<typeof BaseRichtext>();
let switchInterval: ReturnType<typeof setInterval> | null = null;

const { hasRichtextContent } = useContent();
const mediaIsVisible = useElementVisibility(() => $bgMedia.value?.$el as HTMLElement);

const transformedButtons = computed(() => {
  return [...(props.blok.button ? props.blok.button : []), ...(props.blok.link ? props.blok.link : [])];
});

interface MobileState {
  active: boolean;
  visibleEl: 'headline' | 'text';
}

const mobileState = reactive<MobileState>({
  active: false,
  visibleEl: 'headline',
});

const breakpointsWidthMap = {
  '0': 529,
  '576': 706,
  '768': 942,
  '1024': 1325,
  '1441': 1767,
  '1921': 2244,
  '2440': 1920,
};

/**
 * On mobile the text and headline are switched every 5 seconds
 */
function switchMobileType() {
  mobileState.visibleEl = mobileState.visibleEl === 'headline' ? 'text' : 'headline';
}

/**
 * Position the tex-container on top of the headline. Align at bottom and sync width
 */
function handleResize() {
  mobileState.active = window.innerWidth < deviceToBreakpointMap['isTablet'];
  if (!$content.value || !$textContainer.value || !$headline.value || !$headlineContainer.value) {
    return;
  }

  if (mobileState.active) {
    // Add the difference in height as margin to the headline container
    const headlineHeight = ($headline.value.$el as HTMLElement).getBoundingClientRect().height;
    const textContainerHeight = $textContainer.value.getBoundingClientRect().height;
    const difference = Math.max(0, textContainerHeight - headlineHeight);
    if (props.blok.mobile_align === 'top') {
      $headlineContainer.value.style.marginTop = `${difference}px`;
    }

    // Get the distance of the bottom edge of the headline to the bottom of the content container
    const distance = $content.value.getBoundingClientRect().bottom - $headline.value.$el.getBoundingClientRect().bottom;

    // Set the text container to the bottom of the content container
    $textContainer.value.style.bottom = `${distance}px`;
  } else {
    // Reset inline styles
    $headlineContainer.value.style.marginTop = '';
    $textContainer.value.style.bottom = '';
  }
}
const debouncedHandleResize = useDebounceFn(handleResize, 100);

watch(
  () => mobileState.active,
  () => {
    if (mobileState.active) {
      if (switchInterval) {
        clearInterval(switchInterval);
      }
      switchInterval = setInterval(switchMobileType, 5000);
    } else if (switchInterval) {
      clearInterval(switchInterval);
    }
  }
);

function checkIfVideoShouldPlay() {
  if (mediaIsVisible.value) {
    $bgMedia.value?.$el.querySelector('video')?.play();
  } else {
    $bgMedia.value?.$el.querySelector('video')?.pause();
  }
}

watch(mediaIsVisible, checkIfVideoShouldPlay);

onMounted(() => {
  if (import.meta.client) {
    switchInterval = setInterval(switchMobileType, 5000);
    handleResize();
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.addEventListener('resize', debouncedHandleResize);
  }
});

onUnmounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  window.removeEventListener('resize', debouncedHandleResize);
  if (switchInterval) {
    clearInterval(switchInterval);
  }
});
</script>

<style src="./TMHighlight.scss" lang="scss" scoped />
