import { EffectComposer, RenderPass } from 'postprocessing';
import type { AppStateInstance } from '../core/appState';
import type { EngineInstance } from '../core/engine';
import { PostGrading, type PostGradingInstance } from './postGrading/postGrading';
import { PostVignette, type PostVignetteInstance } from './postVignette/postVignette';

export type PostProcessInstance = InstanceType<typeof PostProcess>;

export class PostProcess {
  renderer;
  camera;
  scene;
  composer;
  appState: AppStateInstance;
  postVignette: PostVignetteInstance;
  postGrading: PostGradingInstance;
  isActive: boolean = false;

  constructor(appState: AppStateInstance, engine: EngineInstance) {
    this.appState = appState;
    this.renderer = engine.renderer;
    this.camera = engine.camera;
    this.scene = engine.scene;

    this.composer = new EffectComposer(this.renderer);

    const renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(renderPass);

    // PostSMAA.init();
    // This.composer.addPass(postSMAA.pass);
    // Enable this to debug SMAA
    // This.composer.addPass(postSMAA.smaaEdgesDebugPass);
    // This.composer.addPass(postSMAA.smaaWeightsDebugPass);

    this.postVignette = new PostVignette(engine);
    this.composer.addPass(this.postVignette.pass);

    this.postGrading = new PostGrading(appState, engine);
    this.composer.addPass(this.postGrading.pass);

    if (engine.renderer.capabilities.isWebGL2) {
      this.composer.multisampling = 4;
    }
  }

  show() {
    this.isActive = true;
  }

  hide() {
    this.isActive = false;
  }

  resize() {
    // PostSMAA.resize(width, height);
    this.composer.setSize(this.appState.renderSize.width, this.appState.renderSize.height);
  }

  update() {
    if (this.isActive) {
      // This.postSMAA.update(dt);
      this.postGrading.update();
      this.composer.render();
    }
  }
}
