import { reactive } from 'vue';

const state = reactive<{
  corePageLayerId: string | null;
}>({
  corePageLayerId: null,
});

export function useLayer() {
  return {
    state,
  };
}
