<template>
  <div id="main" class="core-page" :class="{ 'has-black-bg': hasBlackBg }">
    <template v-if="settings && !standaloneWidgetBlok">
      <CorePageBlokModule
        v-for="(blokModule, index) in blok.body"
        :key="index"
        :blok="hydrateBlok(blokModule)"
        :blokNext="blok.body?.[index + 1]"
        :footerBlok="footerBlok"
        :hasInPageNavigationHeader="!!blok.header_in_page?.length"
        :index="index"
        :settings="settings" />
    </template>

    <template v-else-if="standaloneWidgetBlok">
      <!-- Widget deeplink - omit all other modules -->
      <EMProductWidget :blok="hydrateBlok(standaloneWidgetBlok) as EmProductWidgetStoryblok" :isStandalone="true" />
    </template>

    <div class="core__page-assets" hidden>
      <!-- Used for inline svgs in baserichtext-->
      <BaseIcon id="fi_arrow-top-right" name="fi_arrow-top-right" color="inherit" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useWindow } from '~/composables/useWindow';
import { useProductWidget } from '~/composables/useProductWidget';

import EMProductWidget from '~/components/storyblok/extra/EMProductWidget/EMProductWidget.vue';
import CorePageBlokModule from './CorePageBlokModule.vue';
import BaseIcon from '~/components/base/BaseIcon.vue';

import type {
  BmFooterStoryblok,
  CorePageStoryblok,
  CoreSettingsStoryblok,
  EmProductWidgetStoryblok,
} from '~/types/storyblok-generated';

const { init: initWindow } = useWindow();
const { getWidgetBlok } = useProductWidget();

const props = defineProps<{
  blok: CorePageStoryblok;
  footerBlok?: BmFooterStoryblok;
  settings?: CoreSettingsStoryblok;
}>();

const standaloneWidgetBlok = ref<EmProductWidgetStoryblok | undefined>(getWidgetBlok(props.blok));

const hasBlackBg = computed(
  () => props.blok.body?.length === 1 && ['em_showroom', 'em_home'].includes(props.blok.body[0].component)
);

/**
 * Used to hydrate the product widget with the global product data if set
 */
function hydrateBlok(blok: NonNullable<CorePageStoryblok['body']>[number]) {
  if (blok.component === 'em_product-widget') {
    if (!blok.data_product || typeof blok.data_product === 'string') {
      // Console.warn('Product widget is set as module but has no data!');
    }
    blok.data_product = (blok.data_product as any)?.name ? blok.data_product : props.blok.data_product;
  }
  return blok;
}

onMounted(() => {
  initWindow();
});
</script>

<style src="./CorePage.scss" lang="scss" scoped />
