<template>
  <div ref="$el" v-editable="blok" class="em-product-widget">
    <div
      v-if="blok.data_product && typeof blok.data_product !== 'string' && blok.data_product.content"
      class="trigger"
      @click="show">
      <video
        v-if="
          blok.data_product.content.turntable_animation?.filename ||
          blok.data_product.content.turntable_animation_safari?.filename
        "
        :src="
          getTransparentVideoSource([
            blok.data_product.content.turntable_animation?.filename,
            blok.data_product.content.turntable_animation_safari?.filename,
            blok.data_product.content.turntable_animation?.filename,
          ])
        "
        muted
        autoplay
        playsinline
        loop
        disablePictureInPicture
        controlslist="nodownload"></video>

      <BaseImage v-else :image="blok.data_product.content.image_main" :breakpointsWidthMap="{ 0: 112 }" />
    </div>

    <BaseModal
      :animationType="!isStandalone ? 'custom' : 'none'"
      :isOpen="isOpen"
      :isDark="false"
      :includeClose="true"
      @close="onClose"
      @loaded="onLoaded">
      <div class="module">
        <div class="container-full widget-container">
          <BaseRichtext
            class="headline heading-main-large"
            :text="hydrateHeadline(blok.headline)"
            :isProseHeadlinesEnabled="false"
            :isProseParagraphsEnabled="false"
            :isDark="false" />

          <!-- QR Code -->
          <div v-if="!isAREnabled && !isMobile" class="qr-code">
            <img :src="qrcode" :alt="t('EMProductWidget.qrCode')" />
            <span class="action-xsmall-bold">{{ t('EMProductWidget.qrCodeOverline') }}</span>
            <span class="action-xsmall-regular">{{ t('EMProductWidget.qrCodeSubline') }}</span>
          </div>

          <div class="baseline action-large-regular">
            <BaseRichtext
              class="action-large-regular"
              :text="hydrateHeadline(t('EMProductWidget.mightVaryHint'))"
              :isProseHeadlinesEnabled="false"
              :isProseParagraphsEnabled="false"
              :isDark="false" />
          </div>
        </div>

        <div v-if="isLoaded" class="viewer">
          <BaseModelViewer :blok="blok" @arEnabled="() => (isAREnabled = true)" @zoom="onZoom" />
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import { useQRCode } from '@vueuse/integrations/useQRCode';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { BaseImage, BaseModal, BaseModelViewer, BaseRichtext } from '~/components/base';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useHeader } from '~/composables/useHeader';
import { useProductWidget } from '~/composables/useProductWidget';
import { useScroll } from '~/composables/useScroll';
import { useTransition } from '~/composables/useTransition';
import { getTransparentVideoSource } from '~/utils/media';

import type { EmProductWidgetStoryblok, RichtextStoryblok } from '~/types/storyblok-generated';

const props = defineProps<{
  blok: EmProductWidgetStoryblok;
  isStandalone?: boolean;
}>();

const $el = ref<HTMLElement>();
const isOpen = ref(false);
const isLoaded = ref(false);
const isAREnabled = ref(false);
const isZoomed = ref(false);
const isMobile = computed(() => !stateBreakpoint.isTablet);

const { fadeSlide } = useTransition();
const { state: stateBreakpoint } = useBreakpoint();
const { state: headerState } = useHeader();
const { state: scrollState } = useScroll();
const { clearWidget } = useProductWidget();
const { t } = useI18n();

// QR Code
const qrCodeTarget = ref('https://www.wmf.de');
if (import.meta.client) {
  qrCodeTarget.value = window.location.href + '/?ref=widget';
}

const qrcode = useQRCode(qrCodeTarget, { margin: 0 });

function hydrateHeadline(text: string | RichtextStoryblok) {
  return typeof text !== 'string'
    ? text
    : {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [{ text: text, type: 'text' }],
          },
        ],
      };
}

async function show() {
  if (!$el.value) {
    return;
  }
  isOpen.value = true;

  await nextTick();
  const baseline = $el.value.querySelector('.baseline');
  const viewer = $el.value.querySelector('.viewer');
  const desktopLayout = window.innerWidth > 1280;
  const baseDelay = 0.4;
  fadeSlide($el.value.querySelector('.heading-main-large'), {
    baseDelay: baseDelay,
  });
  fadeSlide(baseline, {
    baseDelay: desktopLayout ? baseDelay + 0.4 : baseDelay + 0.2,
  });
  fadeSlide(viewer, {
    baseDelay: desktopLayout ? baseDelay + 0.2 : baseDelay + 0.4,
  });
  fadeSlide($el.value.querySelector('.qr-code'), {
    baseDelay: baseDelay + 1,
  });
}

function onClose() {
  if (props.isStandalone) {
    void clearWidget();
    return;
  }
  isOpen.value = false;
}

function onLoaded() {
  isLoaded.value = true;
}

let _lastZoom = 0;

function onZoom(zoom: { percent: number; radius: number }) {
  // Weird interpolation fix
  if (zoom.radius === _lastZoom) {
    return;
  }

  isZoomed.value = zoom.percent >= 0.15 ? true : false;
  _lastZoom = zoom.radius;
}

watch(
  () => isZoomed.value,
  () => {
    if (!$el.value) {
      return;
    }
    if (isZoomed.value) {
      fadeSlide($el.value.querySelector('.headline'), {
        mode: 'out',
        offsetMultiplier: window.innerWidth < 1280 ? -2 : -1,
        force: true,
      });
      fadeSlide($el.value.querySelector('.baseline'), {
        offsetMultiplier: window.innerWidth < 1280 ? -2 : 1,
        mode: 'out',
        force: true,
      });
      fadeSlide($el.value.querySelector('.qr-code'), {
        mode: 'out',
        force: true,
      });
    } else {
      fadeSlide($el.value.querySelector('.qr-code'), {
        mode: 'in',
        force: true,
      });
      fadeSlide($el.value.querySelector('.headline'), {
        mode: 'in',
        force: true,
        offsetMultiplier: -1,
      });
      fadeSlide($el.value.querySelector('.baseline'), {
        mode: 'in',
        force: true,
        offsetMultiplier: window.innerWidth < 1280 ? -1 : 1,
      });
    }
  }
);

watch(
  () => [headerState.menuOpen, scrollState.widgetVisible],
  () => {
    if (!$el.value || props.isStandalone) {
      return;
    }
    const visible = !headerState.menuOpen && scrollState.widgetVisible;
    // Hiding/showing trigger
    fadeSlide($el.value.querySelectorAll('.trigger'), {
      mode: visible ? 'in' : 'out',
      offsetMultiplier: visible ? 1 : 0,
      baseDelay: visible ? 0 : 0,
      speedMultiplier: 0.5,
      alphaSpeedMultiplier: 0.5,
    });
  }
);

onMounted(() => {
  if (props.isStandalone) {
    void nextTick(show);
  }
});
</script>

<style src="./EMProductWidget.scss" lang="scss" scoped />
