<template>
  <div
    ref="$container"
    v-editable="blok"
    class="cm-stage container-full"
    :class="{
      'has-in-page-navigation-header': hasInPageNavigationHeader,
      'is-first-blok': isFirstBlok,
    }">
    <!-- Back button -->
    <UtilLinkItemIcon v-if="blok.breadcrumb" :blok="breadcrumbBlok" :isDark="blok.is_dark" class="back-button" />

    <!-- Content height is: screen height - (header height + bottom space)-->
    <div
      class="content"
      :class="{
        'has-large-media': hasLargeMedia,
        'has-header-in-page': hasInPageNavigationHeader,
      }">
      <!-- Media -->
      <div v-if="hasLargeMedia" class="media-container">
        <BaseVideo
          v-if="largeMedia?.main && isVideo(largeMedia.main.filename)"
          :src="largeMedia.main.filename"
          :poster="largeMedia.poster?.filename"
          :autoplay="true"
          :loop="true"
          :posterBreakPointsWidthMap="breakpointsWidthMap"
          class="media" />
        <BaseImage v-else :image="largeMedia?.main" :breakpointsWidthMap="breakpointsWidthMap" class="media" />
      </div>

      <!-- Content -->
      <div
        class="text-container"
        :class="{
          'has-small-media': hasSmallMedia,
          'has-no-large-media': !hasLargeMedia,
        }">
        <div class="head">
          <!-- Head -->
          <div
            class="head-wrapper"
            :class="{
              'has-large-media': hasLargeMedia,
              'has-no-large-media': !hasLargeMedia,
            }">
            <BaseRichtext
              class="headline"
              :class="[hasLargeMedia && blok.headline_size === 'small' ? 'heading-main-small' : 'heading-main-large']"
              :text="blok.headline"
              :isProseHeadlinesEnabled="false"
              :isProseParagraphsEnabled="false"
              :isDark="blok.is_dark" />

            <!-- Text -->
            <p v-if="blok.text" class="subline text-large-regular" :class="[blok.is_dark ? 'is-dark' : 'is-light']">
              {{ blok.text }}
            </p>
          </div>

          <UtilButton
            v-if="blok.button_text"
            :blok="{
              component: 'util_button',
              text: blok.button_text,
              link: blok.button_link,
              theme: blok.button_theme ?? '',
              size: 'large',
              icon: hasExternalLink ? 'fi_arrow-right-1' : undefined,
              _uid: 'button',
            }"
            :isBackgroundDark="blok.is_dark" />
        </div>

        <BaseImage
          v-if="hasSmallMedia"
          :image="blok.media_small"
          :breakpointsWidthMap="{ 0: 110, 1280: 200 }"
          class="image-small" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { gsap } from 'gsap';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import BaseImage from '~/components/base/BaseImage.vue';
import BaseRichtext from '~/components/base/BaseRichtext/BaseRichtext.vue';
import BaseVideo from '~/components/base/BaseVideo.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import UtilLinkItemIcon from '~/components/storyblok/utils/UtilLinkItemIcon/UtilLinkItemIcon.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useTransition } from '~/composables/useTransition';
import { isVideo } from '~/utils/media';
import { urlIsExternal } from '~/utils/url';
import type { AssetStoryblok, CmStageStoryblok, UtilLinkItemIconStoryblok } from '~/types/storyblok-generated';
import type { ModuleWrapperState } from '~/types/utils';

const props = defineProps<{
  blok: CmStageStoryblok;
  state: ModuleWrapperState;
  hasInPageNavigationHeader?: boolean;
  isFirstBlok?: boolean;
}>();

const { fadeSlide } = useTransition();
const { state: breakpointState } = useBreakpoint();

const $container = ref<HTMLElement>();
const isVisible = ref<boolean>(false);
const hasLargeMedia = computed(() => !!props.blok.media_large?.filename);
const hasSmallMedia = computed(() => !!props.blok.media_small?.filename);
const hasExternalLink = computed(
  () => props.blok.button_link?.linktype === 'url' && urlIsExternal(props.blok.button_link?.url ?? '')
);
const largeMedia = computed<{ main?: AssetStoryblok; poster?: AssetStoryblok }>(() => {
  if (breakpointState.isPhoneLarge && !breakpointState.isTabletLarge && props.blok.media_large_tablet?.filename) {
    return { main: props.blok.media_large_tablet, poster: props.blok.media_large_tablet_poster };
  } else {
    return { main: props.blok.media_large, poster: props.blok.media_large_poster };
  }
});

const breadcrumbBlok = computed<UtilLinkItemIconStoryblok>(() => ({
  component: 'util_link-item-icon',
  icon: 'fi_chevron-left',
  link: props.blok.breadcrumb_link,
  text: props.blok.breadcrumb,
  icon_position: 'start',
  _uid: `breadcrumb_${props.blok.breadcrumb}`,
}));

const breakpointsWidthMap = {
  '0': 529,
  '576': 706,
  '768': 942,
  '1024': 1177,
  '1280': 665,
  '1921': 1110,
  '2440': 948,
};

function fadeInText() {
  if (!$container.value) {
    return;
  }
  // Transition composable is taking over
  gsap.set($container.value, { autoAlpha: 1 });

  // Collecting elements
  const textElements = Array.from($container.value?.querySelector('.head-wrapper')?.children as HTMLCollection);
  const buttonElements = Array.from($container.value?.querySelectorAll('.util-button') as NodeList);
  const imageSmall = Array.from($container.value?.querySelectorAll('.image-small') as NodeList);

  // Merging elements
  const elements = [...textElements, ...buttonElements, ...imageSmall];
  const mediaElement = $container.value?.querySelector('.media-container') as HTMLElement;

  //Text container offset
  const textContainer = $container.value?.querySelector('.text-container') as HTMLElement;

  // Compare item position to calculate delay
  const mediaPos = mediaElement ? mediaElement.offsetTop : window.innerHeight;
  const textPos = textContainer.offsetTop;

  const elementDelay = textPos > mediaPos ? 0.25 : 0;
  const imageDelay = textPos < mediaPos ? 0.25 : 0;

  fadeSlide(elements, { baseDelay: elementDelay });
  if (mediaElement) fadeSlide(mediaElement, { baseDelay: imageDelay });
}

function onProgress() {
  if (!isVisible.value) {
    fadeInText();
    isVisible.value = true;
  }
}

watch(() => props.state.progress, onProgress);

onMounted(async () => {
  await nextTick();
  if (props.state.progress) {
    onProgress();
  }
});
</script>

<style src="./CMStage.scss" lang="scss" scoped />
