<template>
  <div ref="$el" class="em-showroom-sensors">
    <div data-id="top" class="top"></div>
    <div class="center">
      <div data-id="left" class="left"></div>
      <div data-id="main" class="main"></div>
      <div data-id="right" class="right"></div>
    </div>
    <div data-id="bottom" class="bottom"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import type { ShowroomPointerState, ShowroomSensorId } from './EMShowroom.types';

const props = defineProps<{
  pointerState: ShowroomPointerState | null;
}>();

const emit = defineEmits<{
  (e: 'change', value: ShowroomSensorId): void;
}>();

const $el = ref<HTMLElement>();
const sensor = ref<ShowroomSensorId | null>(null);

watch(
  () => sensor.value,
  () => {
    emit('change', sensor.value ?? 'none');
  }
);

watch(
  () => props.pointerState,
  () => {
    if (!props.pointerState) {
      return;
    }
    const sensorElements = document
      .elementsFromPoint(props.pointerState.x, props.pointerState.y)
      .filter(el => el.tagName.toLowerCase() === 'div')
      .filter(el => el.className.indexOf('sensor') !== -1);

    if (sensorElements[0]) {
      sensor.value = sensorElements[0].getAttribute('data-id') as ShowroomSensorId;
    } else {
      sensor.value = 'none';
    }
  }
);
</script>

<style src="./EMShowroomSensors.scss" lang="scss" scoped />
