export const PI = Math.PI;
export const PI2 = PI * 2;
export const HALF_PI = PI * 0.5;
export const DEG2RAD = PI / 180.0;
export const RAD2DEG = 180.0 / PI;

export function easeInExpo(val: number) {
  return val === 0 ? 0 : Math.pow(2, 10 * val - 10);
}

export function easeOutExpo(val: number) {
  return val === 1 ? 1 : 1 - Math.pow(2, -10 * val);
}

export function easeInOutExpo(val: number) {
  return val === 0
    ? 0
    : val === 1
      ? 1
      : val < 0.5
        ? Math.pow(2, 20 * val - 10) / 2
        : (2 - Math.pow(2, -20 * val + 10)) / 2;
}

export function easeOutCubic(val: number) {
  return 1 - Math.pow(1 - val, 3);
}

export function easeInOutCubic(val: number) {
  return val < 0.5 ? 4 * val * val * val : 1 - Math.pow(-2 * val + 2, 3) / 2;
}

export function easeInOutQuart(val: number) {
  return val < 0.5 ? 8 * val * val * val * val : 1 - Math.pow(-2 * val + 2, 4) / 2;
}

export function easeInQuart(val: number) {
  return val * val * val * val;
}

export function easeInCirc(val: number) {
  return 1 - Math.sqrt(1 - Math.pow(val, 2));
}

export function easeInSine(val: number) {
  return 1 - Math.cos((val * Math.PI) / 2);
}

export function easeOutSine(val: number) {
  return Math.sin((val * Math.PI) / 2);
}

export function easeOutCirc(val: number) {
  return Math.sqrt(1 - Math.pow(val - 1, 2));
}

export function easeInQuad(val: number) {
  return val * val;
}

export function easeInOutQuad(val: number) {
  return val < 0.5 ? 2 * val * val : 1 - Math.pow(-2 * val + 2, 2) / 2;
}

export function lerp(a: number, b: number, t: number) {
  return a + (b - a) * t;
}

export function step(edge: number, val: number) {
  return val < edge ? 0 : 1;
}

export function clamp(val: number, min: number, max: number) {
  return val < min ? min : val > max ? max : val;
}

export function mix(min: number, max: number, ratio: number) {
  return min + (max - min) * ratio;
}

export function cMix(min: number, max: number, ratio: number) {
  return min + (max - min) * clamp(ratio, 0, 1);
}

export function unMix(min: number, max: number, val: number) {
  return (val - min) / (max - min);
}

export function cUnMix(min: number, max: number, val: number) {
  return clamp((val - min) / (max - min), 0, 1);
}

export function saturate(val: number) {
  return clamp(val, 0, 1);
}

export function fit(
  val: number,
  min: number,
  max: number,
  toMin: number,
  toMax: number,
  ease?: (val: number) => number
) {
  val = cUnMix(min, max, val);
  if (ease) {
    val = ease(val);
  }
  return toMin + val * (toMax - toMin);
}

export function loop(v: number, min: number, max: number) {
  v -= min;
  max -= min;
  return (v < 0 ? (max - (Math.abs(v) % max)) % max : v % max) + min;
}

export function normalize(val: number, min: number, max: number) {
  return Math.max(0, Math.min(1, val - min / max - min));
}

export function smoothstep(edge0: number, edge1: number, val: number) {
  val = cUnMix(edge0, edge1, val);
  return val * val * (3 - val * 2);
}

export function fract(val: number) {
  return val - Math.floor(val);
}

export function hash(val: number) {
  return fract(Math.sin(val) * 43758.5453123);
}

export function hash2(val1: number, val2: number) {
  return fract(Math.sin(val1 * 12.9898 + val2 * 4.1414) * 43758.5453);
}

export function sign(val: number) {
  return val ? (val < 0 ? -1 : 1) : 0;
}

export function isPowerOfTwo(val: number) {
  return (val & -val) === val;
}

export function powerTwoCeilingBase(val: number) {
  return Math.ceil(Math.log(val) / Math.log(2));
}

export function powerTwoCeiling(val: number) {
  if (isPowerOfTwo(val)) return val;
  return 1 << powerTwoCeilingBase(val);
}

export function powerTwoFloorBase(val: number) {
  return Math.floor(Math.log(val) / Math.log(2));
}

export function powerTwoFloor(val: number) {
  if (isPowerOfTwo(val)) return val;
  return 1 << powerTwoFloorBase(val);
}

export function latLngBearing(lat1: number, lng1: number, lat2: number, lng2: number) {
  const y = Math.sin(lng2 - lng1) * Math.cos(lat2);
  const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1);
  return Math.atan2(y, x);
}

export function distanceTo(dX: number, dY: number) {
  return Math.sqrt(dX * dX + dY * dY);
}

export function distanceSqrTo(dX: number, dY: number) {
  return dX * dX + dY * dY;
}

export function distanceTo3(dX: number, dY: number, dZ: number) {
  return Math.sqrt(dX * dX + dY * dY + dZ * dZ);
}

export function distanceSqrTo3(dX: number, dY: number, dZ: number) {
  return dX * dX + dY * dY + dZ * dZ;
}

export function latLngDistance(lat1: number, lng1: number, lat2: number, lng2: number) {
  const tLat = Math.sin((lat2 - lat1) / 2);
  const tLng = Math.sin((lng2 - lng1) / 2);
  const a = tLat * tLat + Math.cos(lat1) * Math.cos(lat2) * tLng * tLng;
  return 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
}

export function cubicBezier(p0: number, p1: number, p2: number, p3: number, t: number) {
  const c = (p1 - p0) * 3;
  const b = (p2 - p1) * 3 - c;
  const a = p3 - p0 - c - b;
  const t2 = t * t;
  const t3 = t2 * t;
  return a * t3 + b * t2 + c * t + p0;
}

export function cubicBezierFn(p0: number, p1: number, p2: number, p3: number) {
  const c = (p1 - p0) * 3;
  const b = (p2 - p1) * 3 - c;
  const a = p3 - p0 - c - b;
  return (t: number) => {
    const t2 = t * t;
    const t3 = t2 * t;
    return a * t3 + b * t2 + c * t + p0;
  };
}

export function normalizeAngle(angle: number) {
  angle += PI;
  angle = angle < 0 ? PI2 - Math.abs(angle % PI2) : angle % PI2;
  angle -= PI;
  return angle;
}

export function closestAngleTo(from: number, to: number) {
  return from + normalizeAngle(to - from);
}

export function randomRange(min: number, max: number) {
  return min + Math.random() * (max - min);
}

export function randomRangeInt(min: number, max: number) {
  return Math.floor(randomRange(min, max + 1));
}

export function padZero(num: number, size: number) {
  if (num.toString().length >= size) {
    return num;
  } else {
    return (Math.pow(10, size) + Math.floor(num)).toString().substring(1);
  }
}

export function getSeedRandomFn(str: string) {
  let h1 = 1779033703,
    h2 = 3144134277,
    h3 = 1013904242,
    h4 = 2773480762;
  for (let i = 0, k; i < str.length; i++) {
    k = str.charCodeAt(i);
    h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
    h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
    h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
    h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
  }
  return _sfc32(
    Math.imul(h3 ^ (h1 >>> 18), 597399067),
    Math.imul(h4 ^ (h2 >>> 22), 2869860233),
    Math.imul(h1 ^ (h3 >>> 17), 951274213),
    Math.imul(h2 ^ (h4 >>> 19), 2716044179)
  );
}

function _sfc32(a: number, b: number, c: number, d: number) {
  return function () {
    a |= 0;
    b |= 0;
    c |= 0;
    d |= 0;
    const t = (((a + b) | 0) + d) | 0;
    d = (d + 1) | 0;
    a = b ^ (b >>> 9);
    b = (c + (c << 3)) | 0;
    c = (c << 21) | (c >>> 11);
    c = (c + t) | 0;
    return (t >>> 0) / 4294967296;
  };
}
