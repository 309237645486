<template>
  <div
    ref="$selectorWrapper"
    class="bm-header-rich-market-select"
    :class="[
      isDark ? 'is-dark' : 'is-light',
      {
        'is-white': !isDark,
        'is-enlarged': $enlarged,
      },
    ]">
    <UtilScrollable ref="$scrollable" :disabled="!$createScrollbar">
      <ul class="items-container">
        <li
          v-for="(locale, index) in locales"
          :key="index"
          ref="$locales"
          class="item"
          :class="{ 'is-single-option': locale.languages.length === 1 }"
          @mouseenter="onLocaleItemMouseOver"
          @mouseleave="onLocaleItemMouseOut"
          @click="onLocaleItemClick(locale)">
          <!-- prettier-ignore -->
          <BaseFlag :marketLocale="(locale.id as MarketLocale)" />
          <div class="item-content">
            <span
              class="country action-medium-bold"
              :class="[isDark ? 'is-dark' : 'is-light', { 'is-active': localeMarket === locale.id }]">
              {{ locale.label }}
            </span>
            <ul class="variants">
              <li v-for="(language, i) in locale.languages" :key="i" class="language">
                <span v-if="i > 0" class="variant-text"> | </span>
                <NuxtLink
                  :to="getLink(language.id, locale.id)"
                  :external="isShowroomUrl(getLink(language.id, locale.id))"
                  class="language-text action-medium-regular"
                  :class="{ 'is-active': localeMarket === locale.id && localeLanguage === language.id }">
                  {{ language.label }}
                </NuxtLink>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </UtilScrollable>
  </div>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components';
import { onClickOutside } from '@vueuse/core';
import { gsap } from 'gsap';
import { useRoute, useRouter, useRuntimeConfig } from 'nuxt/app';
import type { ISbAlternateObject } from 'storyblok-js-client';
import { computed, onMounted, ref } from 'vue';
import BaseFlag from '~/components/base/BaseFlag.vue';
import UtilScrollable from '~/components/storyblok/utils/UtilScrollable/UtilScrollable.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useGsap } from '~/composables/useGsap';
import { useLocale } from '~/composables/useLocale';
import type { Locale, MarketLocale } from '~/types/locales';
import type { Market } from '~/types/utils';
import { isShowroomUrl, normalizeUrl } from '~/utils/url';

const props = defineProps<{
  isDark?: boolean;
  isActive?: boolean;
  alternates?: ISbAlternateObject[];
}>();

const emit = defineEmits<{
  (e: 'hidden'): void;
}>();

const { localeMarket, localeLanguage, locale: localeCurrent, locales } = useLocale();
const route = useRoute();
const { easeOut } = useGsap();
const { state: stateBreakpoint, isTouch } = useBreakpoint();
const config = useRuntimeConfig();

const $createScrollbar = computed(() => !stateBreakpoint.isTablet);
const $selectorWrapper = ref<HTMLElement>();
const $locales = ref<HTMLElement[]>();
const $scrollable = ref<InstanceType<typeof UtilScrollable>>();
const $enlarged = ref(false);

function getLink(language: string, market: string) {
  const localeMarket = `${language}_${market}` as Locale;

  let url = '';

  // If the current locale is the same as the market, return the current URL
  if (localeCurrent === localeMarket && config.public.IS_HEADER_FOOTER_EXPORT === false) {
    url = normalizeUrl(route.path);
  } else {
    // Find the alternate with the same market
    const findAlternate = props.alternates?.find(alternate => alternate.full_slug.startsWith(localeMarket));

    // If the alternate is found, return the alternate URL
    if (findAlternate) {
      url = normalizeUrl(findAlternate.full_slug);
    } else {
      // Fallback to the home of the market
      url = normalizeUrl(localeMarket);
    }
  }

  if (config.public.IS_HEADER_FOOTER_EXPORT && !url.startsWith('http')) {
    url = `${config.public.HOST_URL}${url}`;
  }

  return url;
}

function fadeInLocales() {
  const locales = $locales.value as HTMLElement[];
  void gsap
    .to($selectorWrapper.value as HTMLElement, {
      height: 'auto',
      duration: 0.5,
      marginBottom: 24,
      ease: easeOut,
      overwrite: true,
      clearProps: 'height',
    })
    .then(() => {
      $scrollable.value?.update();
      $enlarged.value = true;
    });
  gsap.to(locales, {
    autoAlpha: 1,
    y: 0,
    duration: 0.4,
    stagger: 0.05,
    ease: easeOut,
    overwrite: true,
  });
}

function show() {
  fadeInLocales();
}

function hide() {
  const locales = $locales.value as HTMLElement[];
  gsap.to(locales, {
    autoAlpha: 0,
    y: 10,
    duration: 0.2,
    ease: easeOut,
    overwrite: true,
  });
  void gsap
    .to($selectorWrapper.value as HTMLElement, {
      height: 0,
      marginBottom: 0,
      duration: 0.5,
      overwrite: true,
      ease: easeOut,
      clearProps: 'height',
    })
    .then(() => ($enlarged.value = false));

  emit('hidden');
}

function localMouseOverAllowed(e: MouseEvent) {
  return isTouch.value || (e.target as HTMLElement).className.includes('is-single-option');
}

function onLocaleItemClick(locale: Market) {
  if (locale.languages.length > 1) {
    return;
  }
  const language = locale.languages[0].id;
  const localeId = locale.id;
  const url = getLink(language, localeId);
  const router = useRouter();
  const isShowroom = isShowroomUrl(url);
  if (isShowroom) {
    window.location.pathname = url;
  } else {
    void router.push(url);
  }
}

function onLocaleItemMouseOver(e: MouseEvent) {
  if (!localMouseOverAllowed(e)) {
    return;
  }
  const subItem = (e.target as HTMLElement).querySelector('.language-text');
  gsap.set(subItem, { color: 'currentColor' });
}

function onLocaleItemMouseOut(e: MouseEvent) {
  if (!localMouseOverAllowed(e)) {
    return;
  }
  const subItem = (e.target as HTMLElement).querySelector('.language-text');
  gsap.set(subItem, { clearProps: 'color' });
}

function clickOutside(e: MouseEvent) {
  const isTrigger = (e.target as HTMLElement).closest('.language-select');
  if (!isTrigger) {
    hide();
  }
}

defineExpose({
  hide,
  show,
});

onMounted(() => {
  onClickOutside($selectorWrapper.value, clickOutside);
});
</script>

<style src="./BmHeaderRichMarketSelect.scss" lang="scss" scoped />
