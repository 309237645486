import { ref } from 'vue';
import type { ItemYoutubeVideoStoryblok } from '~/types/storyblok-generated';

interface ModalVideoStateMedia {
  type: 'media';
  filename: string;
}

interface ModalVideoStateYoutubeVideo {
  type: 'youtubeVideo';
  youtubeVideoBlok: ItemYoutubeVideoStoryblok;
}

type ModalVideoState = ModalVideoStateMedia | ModalVideoStateYoutubeVideo | null;

const state = ref<ModalVideoState>(null);

export function useModalVideo() {
  return {
    state,
  };
}
