<template>
  <component
    :is="isActionLink ? 'button' : (is ?? 'a')"
    v-if="isActionLink || isShowroom || isExternal || isEmail || isTel"
    ref="$link"
    :href="url"
    :target="isExternal ? '_blank' : undefined"
    :rel="isExternal ? 'noopener noreferrer' : undefined"
    v-bind="attrs"
    class="util-link"
    :type="is === 'button' ? type : undefined"
    @click="handleClick"
    @mouseenter="onLinkHover"
    @mouseleave="onLinkBlur">
    <slot></slot>
  </component>
  <NuxtLink
    v-else-if="url"
    ref="$link"
    :prefetch="true"
    :to="url"
    v-bind="attrs"
    class="util-link"
    @click="handleClick"
    @mouseenter="onLinkHover"
    @mouseleave="onLinkBlur">
    <slot></slot>
  </NuxtLink>
  <component
    :is="is ?? 'a'"
    v-else
    ref="$link"
    :href="url"
    v-bind="attrs"
    class="util-link"
    :type="is === 'button' ? type : undefined"
    @click="handleClick"
    @mouseenter="onLinkHover"
    @mouseleave="onLinkBlur">
    <slot></slot>
  </component>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components';
import { useLocale, useRuntimeConfig } from '#imports';
import { useMediaQuery } from '@vueuse/core';
import { computed, ref, useAttrs, type ButtonHTMLAttributes } from 'vue';
import { useConsent } from '~/composables/useConsent';
import { useIconAnimation } from '~/composables/useIconAnimation';
import { useStoryblokPrefetch } from '~/composables/useStoryblokClient';
import type { MultilinkStoryblok } from '~/types/storyblok-generated';
import type { Page } from '~/types/utils';
import { openChatbot } from '~/utils/chatbase';
import { normalizeUrl, urlExtractFromLink, urlIsExternal, urlIsShowroom } from '~/utils/url';

const props = defineProps<{
  link?: MultilinkStoryblok;
  is?: string;
  type?: ButtonHTMLAttributes['type'];
  role?: ButtonHTMLAttributes['role'];
  skipUrlManipulation?: boolean;
}>();

const $link = ref<HTMLElement>();

const attrs = useAttrs();
const { prefetchStory } = useStoryblokPrefetch();
const { onIconMouseOver, onIconMouseOut } = useIconAnimation();
const { state: consentState } = useConsent();
const config = useRuntimeConfig();
const { locale } = useLocale();

const linkUrl = computed(() => (props.link ? urlExtractFromLink(props.link) : ''));
const isExternal = computed(
  () => !!props.link && props.link.linktype === 'url' && props.link.url && urlIsExternal(props.link.url)
);
const isShowroom = computed(() => !!props.link && props.link.linktype === 'story' && urlIsShowroom(locale, props.link));
const isEmail = computed(() => !!props.link && props.link.linktype === 'email');
const isTel = computed(() => !!linkUrl.value && linkUrl.value.startsWith('tel:'));
const isActionLink = computed(
  () =>
    !!props.link &&
    props.link.linktype === 'url' &&
    props.link.url &&
    (props.link?.url?.startsWith('#cookies') || props.link?.url?.startsWith('#chatbot'))
);
const deviceSupportsHover = useMediaQuery('(hover: hover) and (pointer: fine)');

async function onLinkHover(e: PointerEvent) {
  if (
    isShowroom.value ||
    isExternal.value ||
    isTel.value ||
    isEmail.value ||
    linkUrl.value?.startsWith('http') ||
    config.public.IS_HEADER_FOOTER_EXPORT
  ) {
    // No need to prefetch those urls
    return;
  }

  if (deviceSupportsHover.value) {
    onIconMouseOver(e);
  }
  await prefetchStory<Page>(url.value);
}

function onLinkBlur(e: PointerEvent) {
  onIconMouseOut(e);
}

const url = computed(() => {
  let _url = linkUrl.value;

  if (!_url) {
    return undefined;
  }

  if (isExternal.value || isTel.value || props.skipUrlManipulation) {
    return _url;
  }

  if (isEmail.value) {
    return `mailto:${_url}`;
  }

  _url = normalizeUrl(_url);

  if ((config.public.IS_HEADER_FOOTER_EXPORT || isShowroom.value) && !_url.startsWith('http')) {
    /**
     * In these two cases, we need to prefix with the website's base url:
     * - In the header/footer export, we are not in the Nuxt context
     * - In the showroom, we need to prevent client side navigation
     */
    _url = `${config.public.HOST_URL}${_url}`;
  }

  return _url;
});

/**
 * Handle special links to e.g. open the cookie consent or the chatbot
 */
function handleClick(event: MouseEvent) {
  if (props.link?.linktype !== 'url') {
    return;
  }
  if (props.link?.url?.startsWith('#cookies')) {
    event.preventDefault();
    consentState.visible = 'preferences';
    return;
  }
  if (props.link?.url?.startsWith('#chatbot')) {
    event.preventDefault();
    openChatbot();
    return;
  }
}
</script>

<style src="./BaseLink.scss" lang="scss" scoped />
