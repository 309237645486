<template>
  <div
    ref="$accordion"
    class="accordion"
    :class="[isDark ? 'is-dark' : 'is-light', { 'is-not-ready': !isReady, 'is-not-last': !isLast }]"
    @mouseenter="emit('accordionEnter', props.index)"
    @mouseleave="emit('accordionLeave', props.index)">
    <!-- Head -->
    <div
      v-if="$slots.head"
      ref="$head"
      class="head-wrapper"
      :class="[
        isSpacingLarge ? 'has-spacing-large' : 'has-spacing-small',
        { 'has-no-padding-top': index === 0 || !isEnabled },
      ]"
      @click="toggleAccordion">
      <div
        class="head"
        :class="{ 'is-enabled': props.isEnabled, 'is-hovered': isHovered }"
        @mouseenter="setHoverTrue"
        @mouseleave="setHoverFalse">
        <slot name="head"></slot>

        <!-- Toggle Button -->
        <UtilButton
          v-if="isEnabled"
          :blok="{
            component: 'util_button',
            text: '',
            theme: buttonTheme,
            size: isDesktop ? 'large' : 'small',
            icon: 'fi_chevron-bottom',
            _uid: 'accordion-button',
          }"
          :isBackgroundDark="isDark"
          :isHovered="isHovered"
          class="button"
          :class="{ 'is-left': !isButtonRight, 'is-small': isSmall }"
          :aria-label="t('global.toggleAccordion')"
          @click="toggleAccordion" />
      </div>
    </div>

    <!-- Body -->
    <div
      ref="$body"
      class="body"
      :class="{
        'is-table': isTable,
        'has-bottom-padding-small': hasBodyBottomPadding && isSmall,
        'has-bottom-padding-large': hasBodyBottomPadding && !isSmall,
      }">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import { useDebounceFn } from '@vueuse/core';
import gsap from 'gsap';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useGsap } from '~/composables/useGsap';
import { useTransition } from '~/composables/useTransition';

const props = withDefaults(
  defineProps<{
    isEnabled?: boolean;
    isDark?: boolean;
    isButtonRight?: boolean;
    isSpacingLarge?: boolean;
    isLast?: boolean;
    isTable?: boolean;
    isSmall?: boolean;
    hasBodyBottomPadding?: boolean;
    shouldScrollToTop?: boolean;
    shouldScrollToTopOnDesktop?: boolean;
    buttonTheme?: 'primary' | 'secondary';
    index: number;
    activeIndex: number;
  }>(),
  {
    isEnabled: true,
    isButtonRight: true,
    hasBodyBottomPadding: true,
    shouldScrollToTop: true,
    shouldScrollToTopOnDesktop: true,
    buttonTheme: 'secondary',
    index: 0,
    activeIndex: -1,
  }
);

const emit = defineEmits<{
  (e: 'accordionToggle', index: number): void;
  (e: 'accordionEnter', index: number): void;
  (e: 'accordionLeave', index: number): void;
}>();

const { state: stateBreakpoint } = useBreakpoint();
const { forceTween, updateScrollTrigger } = useTransition();
const { t } = useI18n();

const isDesktop = ref(stateBreakpoint.isTablet);
const $accordion = ref<HTMLElement>();
const $head = ref<HTMLElement>();
const $body = ref<HTMLElement>();

const accordionHeight = ref(0);
const headHeight = ref(0);
const isReady = ref(false);
const isOpen = ref(false);
const isToggling = ref(false);
const isHovered = ref(false);
const setHoverTrue = () => (isHovered.value = true);
const setHoverFalse = () => (isHovered.value = false);

const { easeOut } = useGsap();

function toggleAccordion() {
  if (!props.isEnabled || isToggling.value) {
    return;
  }

  isOpen.value = !isOpen.value;

  // Send resetModuleWrapper event on window
  document.dispatchEvent(new CustomEvent('resetModuleWrapper'));

  emit('accordionToggle', isOpen.value ? props.index : -1);

  if ($accordion.value) {
    if (
      isOpen.value &&
      props.shouldScrollToTop &&
      ((isDesktop.value && props.shouldScrollToTopOnDesktop) || !isDesktop.value)
    ) {
      const hostModule: HTMLElement | null = $accordion.value.closest('.core-module');

      let sectionTop = $accordion.value.getBoundingClientRect().top + window.scrollY;

      // Prevents content from scrolling into dimmed area
      if (
        hostModule &&
        hostModule.offsetHeight === window.innerHeight &&
        hostModule.classList.contains('next-blok-wrapped')
      )
        sectionTop = hostModule.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: sectionTop - (props.index ? 0 : 24), // Add space for first accordion
        behavior: 'smooth',
      });
    }

    isToggling.value = true;

    // Changing
    forceTween($accordion.value.querySelector<HTMLElement>('.util-button .button-icon'), {
      rotation: isOpen.value ? 180 : 0,
      transformOrigin: 'center center',
      duration: 0.25,
    });

    if (isOpen.value) {
      // Expand accordion
      gsap.to($accordion.value, {
        height: accordionHeight.value,
        ease: easeOut,
        duration: 0.4,
      });
      if ($body.value) {
        gsap.to($body.value, {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: easeOut,
          delay: 0.1,
          onComplete: () => {
            isToggling.value = false;
          },
          onUpdate: () => {
            updateScrollTrigger();
          },
        });
      }
    } else {
      // Collapse accordion
      if ($body.value) {
        gsap.to($body.value, {
          opacity: 0,
          y: 10,
          ease: easeOut,
          duration: 0.3,
        });
      }
      gsap.to($accordion.value, {
        height: headHeight.value,
        ease: easeOut,
        duration: 0.3,
        delay: 0.15,
        onComplete: () => {
          isToggling.value = false;
        },
        onUpdate: () => {
          updateScrollTrigger();
        },
      });
    }
  }
}

function setAccordionHeight() {
  if ($accordion.value) {
    accordionHeight.value = $accordion.value.scrollHeight;
    headHeight.value = $head.value?.scrollHeight ?? 0;

    gsap.set($accordion.value, {
      height: props.isEnabled ? headHeight.value : accordionHeight.value,
    });

    if (props.isEnabled && $body.value) {
      gsap.set($body.value, { opacity: 0, y: 10 });
    }
  }
  isReady.value = true;
}

const prevWindowWidth = ref(0);
function resizeHandler() {
  if (prevWindowWidth.value !== window.innerWidth) {
    prevWindowWidth.value = window.innerWidth;
    setAccordionHeight();
  }
}
const debouncedResizeHandler = useDebounceFn(resizeHandler, 100);

watch(
  () => stateBreakpoint.isTablet,
  value => (isDesktop.value = value)
);

// Close accordion when activeIndex is not the current one
watch(
  () => [props.activeIndex, props.index],
  ([activeIndex, index]) => {
    if (isOpen.value && activeIndex !== index) {
      toggleAccordion();
    }
  }
);

onMounted(() => {
  isDesktop.value = stateBreakpoint.isTablet;
  setTimeout(setAccordionHeight, 50);
  prevWindowWidth.value = window.innerWidth;
   
  window.addEventListener('resize', debouncedResizeHandler);
});

onUnmounted(() => {
   
  window.removeEventListener('resize', debouncedResizeHandler);
});
</script>

<style src="./UtilAccordion.scss" lang="scss" scoped />
