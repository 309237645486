<template>
  <div v-editable="blok" class="fm-contact">
    <BaseForm ref="$baseForm" :blok="blok">
      <template #form>
        <!--
          Personal information
        -->
        <form @submit="handleSubmit">
          <div class="section">
            <h3 class="section-headline">{{ blok.personal_data_headline }}</h3>

            <div class="fields-container">
              <!-- Salutation -->
              <div class="field is-full-width">
                <BaseRadio
                  v-model="salutation"
                  v-bind="salutationAttrs"
                  :isTouched="isFieldTouched('salutation')"
                  :errorMessage="errors['salutation']"
                  name="salutation"
                  :label="t('forms.salutation.label')"
                  :isDark="blok.is_dark"
                  :options="salutationOptions"
                  :disabled="isSubmitted" />
              </div>

              <!-- First name -->
              <div class="field">
                <BaseInput
                  v-model="firstname"
                  v-bind="firstnameAttrs"
                  :isTouched="isFieldTouched('firstname')"
                  :errorMessage="errors['firstname']"
                  name="firstname"
                  autocomplete="given-name"
                  type="text"
                  :label="t('forms.firstname')"
                  :placeholder="t('forms.firstnamePlaceholder')"
                  :isDark="blok.is_dark"
                  :disabled="isSubmitted"
                  required />
              </div>

              <!-- Last name -->
              <div class="field">
                <BaseInput
                  v-model="lastname"
                  v-bind="lastnameAttrs"
                  :isTouched="isFieldTouched('lastname')"
                  :errorMessage="errors['lastname']"
                  name="lastname"
                  autocomplete="family-name"
                  type="text"
                  :label="t('forms.lastname')"
                  :placeholder="t('forms.lastnamePlaceholder')"
                  :isDark="blok.is_dark"
                  :disabled="isSubmitted"
                  required />
              </div>

              <!-- Company -->
              <div class="field">
                <BaseInput
                  v-model="company"
                  v-bind="companyAttrs"
                  :isTouched="isFieldTouched('company')"
                  :errorMessage="errors['company']"
                  name="company"
                  autocomplete="organization"
                  type="text"
                  :label="t('forms.company')"
                  :placeholder="t('forms.companyPlaceholder')"
                  :isDark="blok.is_dark"
                  :disabled="isSubmitted"
                  required />
              </div>

              <!-- Country -->
              <div class="field">
                <BaseInputDropdown
                  v-model:dropdownValue="companyCountry"
                  v-model:inputValue="companyPostcode"
                  dropdownName="companyCountry"
                  inputName="companyPostcode"
                  dropdownAutocomplete="country"
                  inputAutocomplete="address-line2"
                  :dropdownAttrs="companyCountryAttrs"
                  :inputAttrs="companyPostcodeAttrs"
                  :dropdownIsTouched="isFieldTouched('companyCountry')"
                  :inputIsTouched="isFieldTouched('companyPostcode')"
                  :dropdownErrorMessage="errors['companyCountry']"
                  :inputErrorMessage="errors['companyPostcode']"
                  :label="t('forms.countryCityAndZipCode')"
                  :inputPlaceholder="t('forms.cityAndZipCodePlaceholder')"
                  :dropdownPlaceholder="t('forms.countryPlaceholder')"
                  :isDark="blok.is_dark"
                  :options="countries"
                  :customSelectedLabelFn="option => option.value"
                  :disabled="isSubmitted"
                  required />
              </div>

              <!-- Company Adresss -->
              <div class="field is-1-to-4">
                <BaseInput
                  v-model="companyAddressStreet"
                  v-bind="companyAddressStreetAttrs"
                  :isTouched="isFieldTouched('companyAddressStreet')"
                  :errorMessage="errors['companyAddressStreet']"
                  name="companyAddressStreet"
                  autocomplete="address-line1"
                  type="text"
                  :label="t('forms.street')"
                  :placeholder="t('forms.streetPlaceholder')"
                  :isDark="blok.is_dark"
                  :disabled="isSubmitted"
                  required />
              </div>

              <!-- Company Number -->
              <div class="field is-4-to-5">
                <BaseInput
                  v-model="companyAddressNumber"
                  v-bind="companyAddressNumberAttrs"
                  :isTouched="isFieldTouched('companyAddressNumber')"
                  :errorMessage="errors['companyAddressNumber']"
                  name="companyAddressNumber"
                  type="text"
                  :label="t('forms.houseNumber')"
                  :placeholder="t('forms.houseNumberPlaceholder')"
                  :isDark="blok.is_dark"
                  :disabled="isSubmitted"
                  required />
              </div>

              <!-- Email -->
              <div class="field">
                <BaseInput
                  v-model="email"
                  :label="t('forms.email')"
                  :placeholder="t('forms.emailPlaceholder')"
                  type="email"
                  name="email"
                  autocomplete="email"
                  v-bind="emailAttrs"
                  :isTouched="isFieldTouched('email')"
                  :errorMessage="errors['email']"
                  :isDark="blok.is_dark"
                  :disabled="isSubmitted"
                  required />
              </div>

              <!-- Phone -->
              <div class="field">
                <BaseInput
                  v-model="phone"
                  v-bind="phoneAttrs"
                  :isTouched="isFieldTouched('phone')"
                  :errorMessage="errors['phone']"
                  name="phone"
                  autocomplete="tel"
                  type="tel"
                  :label="t('forms.phone')"
                  :placeholder="t('forms.phonePlaceholder')"
                  :isDark="blok.is_dark"
                  :disabled="isSubmitted" />
              </div>
            </div>
          </div>

          <!--
          Inquiry fields
        -->
          <div class="section">
            <h3 class="section-headline">{{ blok.inquiry_headline }}</h3>
            <div class="fields-container">
              <!-- Message -->
              <div class="field is-full-width">
                <BaseTextarea
                  v-model="message"
                  v-bind="messageAttrs"
                  :isTouched="isFieldTouched('message')"
                  :errorMessage="errors['message']"
                  name="message"
                  :label="t('forms.message')"
                  :placeholder="t('forms.messagePlaceholder')"
                  :isDark="blok.is_dark"
                  :disabled="isSubmitted" />
              </div>

              <!-- File uplaod -->
              <div class="field is-full-width">
                <BaseUpload
                  v-model="files"
                  v-model:hasError="filesHaveError"
                  name="files"
                  :label="t('forms.fileUpload')"
                  :isDark="blok.is_dark"
                  :acceptedMimeTypes="['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']"
                  :maxFiles="5"
                  :maxFileSize="
                    20 * 1000 * 1000 // 20MB
                  "
                  :disabled="isSubmitted" />
              </div>
            </div>
          </div>

          <!--
            Terms and captcha
          -->
          <div class="section">
            <div class="fields-container">
              <div class="field is-full-width">
                <BaseCheckbox
                  v-model="newsletter"
                  v-bind="newsletterAttrs"
                  :isTouched="isFieldTouched('newsletter')"
                  :errorMessage="errors['newsletter']"
                  name="newsletter"
                  :label="blok.newsletter_text || t('forms.newsletterCheckbox')"
                  :isDark="blok.is_dark"
                  :disabled="isSubmitted" />
              </div>
              <div class="field is-full-width">
                <p class="required-info">
                  <BaseRichtext
                    :text="blok.terms_text || t('forms.privacyNote')"
                    :isDark="blok.is_dark"
                    :isProseEnabled="false" />
                </p>
              </div>
              <div class="field is-full-width">
                <BaseCaptcha v-model="captcha" :isDark="blok.is_dark" :disabled="isSubmitted" />
              </div>
            </div>
          </div>

          <!--
            Submit button and error messages
          -->
          <div class="section">
            <div class="fields-container">
              <div class="field is-full-width">
                <p class="required-info">{{ t('forms.mandatoryInfo') }}</p>
              </div>

              <div v-if="errorMessage" class="field is-full-width api-response-message">
                {{ t(`forms.apiResponseMessage.${errorMessage}`) }}
              </div>

              <div class="field is-full-width">
                <UtilButton
                  :blok="{
                    component: 'util_button',
                    text: t('global.submit'),
                    size: 'large',
                    theme: 'primary',
                    _uid: 'submit-button',
                  }"
                  type="button"
                  :isLoading="isSubmitting"
                  :isBackgroundDark="blok.is_dark"
                  :isDisabled="filesHaveError || (isSubmitted && !isSubmitting)"
                  @click="handleSubmit" />
              </div>
            </div>
          </div>
        </form>
      </template>
    </BaseForm>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import { toTypedSchema } from '@vee-validate/yup';
import { useForm } from 'vee-validate';
import { ref, watch } from 'vue';
import {
  BaseCaptcha,
  BaseCheckbox,
  BaseForm,
  BaseInput,
  BaseInputDropdown,
  BaseRadio,
  BaseTextarea,
  BaseUpload,
} from '~/components/base';
import BaseRichtext from '~/components/base/BaseRichtext/BaseRichtext.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import { useGtmTracking } from '~/composables/useGtmTracking';
import { useLocale } from '~/composables/useLocale';
import { getContactSchema, submitContact, type ContactForm } from '~/utils/forms';
import type { Locale } from '~/types/locales';
import type { FmContactStoryblok } from '~/types/storyblok-generated';
import { isOFetchApiError, type ApiResponseMessage } from '~/types/utils';

const props = defineProps<{ blok: FmContactStoryblok }>();

const { trackEvent } = useGtmTracking();
const { localeCountries: countries } = useLocale();

const $baseForm = ref<InstanceType<typeof BaseForm>>();
const hasBeenTracked = ref<boolean>(false);
const isSubmitted = ref<boolean>(false);
const errorMessage = ref<ApiResponseMessage | null>(null);

const { t, locale } = useI18n();
const {
  values,
  defineField,
  isSubmitting,
  handleSubmit: useFormSubmit,
  isFieldTouched,
  errors,
} = useForm({
  validationSchema: toTypedSchema(getContactSchema(locale.value as Locale)),
  initialValues: {
    companyCountry:
      countries.find(c => c.value.toLowerCase() === locale.value.split('_')[1])?.value ??
      countries.find(c => c.value.toLowerCase() === locale.value.split('_')[0])?.value,
  },
});

const salutationOptions = [
  {
    label: t('forms.salutation.mr'),
    value: 'mr',
  },
  {
    label: t('forms.salutation.mrs'),
    value: 'mrs',
  },
  {
    label: t('forms.salutation.notSpecified'),
    value: 'not-specified',
  },
];

const [salutation, salutationAttrs] = defineField('salutation');
const [firstname, firstnameAttrs] = defineField('firstname');
const [lastname, lastnameAttrs] = defineField('lastname');
const [company, companyAttrs] = defineField('company');
const [companyCountry, companyCountryAttrs] = defineField('companyCountry');
const [companyPostcode, companyPostcodeAttrs] = defineField('companyPostcode');
const [companyAddressStreet, companyAddressStreetAttrs] = defineField('companyAddressStreet');
const [companyAddressNumber, companyAddressNumberAttrs] = defineField('companyAddressNumber');
const [email, emailAttrs] = defineField('email');
const [phone, phoneAttrs] = defineField('phone');
const [message, messageAttrs] = defineField('message');
const [newsletter, newsletterAttrs] = defineField('newsletter');
const [captcha, _captchaAttrs] = defineField('captcha');
const files = ref<File[]>([]);
const filesHaveError = ref<boolean>(false);

const handleSubmit = useFormSubmit(onSuccess);

async function onSuccess(values: ContactForm) {
  if (filesHaveError.value) {
    return;
  }
  trackEvent({
    event: 'formSubmit',
    formType: 'contact',
  });
  try {
    isSubmitted.value = true;
    const res = await submitContact(values, files.value, props.blok.custom_recipient_email);
    if (res.success) {
      $baseForm.value?.openSuccessModal();
    }
  } catch (error) {
    isSubmitted.value = false;
    if (isOFetchApiError(error)) {
      console.error(error.data);
      errorMessage.value = error.data.data.messageKey;
      return;
    }
  }
}

watch(values, () => {
  if (
    !hasBeenTracked.value &&
    Object.entries(values).filter(([key, value]) => {
      return !['captcha', 'companyCountry'].includes(key) && value !== undefined && value !== null;
    }).length > 0
  ) {
    hasBeenTracked.value = true;
    trackEvent({
      event: 'formStart',
      formType: 'contact',
    });
  }
});
</script>

<style src="~/assets/scss/_form-layout.scss" lang="scss" scoped />
<style src="./FMContact.scss" lang="scss" scoped />
