<template>
  <div v-if="component === 'globe'" class="base-flag base-flag-globe">
    <BaseIcon name="fi_globe" :size="16" />
  </div>
  <component :is="component" v-else class="base-flag" />
</template>

<script lang="ts" setup>
import FlagAT from 'flag-icons/flags/4x3/at.svg?component';
import FlagBE from 'flag-icons/flags/4x3/be.svg?component';
import FlagCH from 'flag-icons/flags/4x3/ch.svg?component';
import FlagCN from 'flag-icons/flags/4x3/cn.svg?skipsvgo';
import FlagDE from 'flag-icons/flags/4x3/de.svg?component';
import FlagFR from 'flag-icons/flags/4x3/fr.svg?component';
import FlagGB from 'flag-icons/flags/4x3/gb.svg?component';
import FlagJP from 'flag-icons/flags/4x3/jp.svg?component';
import FlagNL from 'flag-icons/flags/4x3/nl.svg?component';
import FlagUS from 'flag-icons/flags/4x3/us.svg?skipsvgo';
// We use a custom ES flag because the one from flag-icons package has lots of elements
import FlagES from '~/assets/icons/flag-es.svg?component';
import { computed, type FunctionalComponent, type SVGAttributes } from 'vue';
import { MarketLocale } from '~/types/locales';
import BaseIcon from './BaseIcon.vue';

const MARKET_TO_FLAG_MAP: Record<MarketLocale, FunctionalComponent<SVGAttributes> | 'globe'> = {
  [MarketLocale.COM]: 'globe',
  [MarketLocale.UK]: FlagGB,
  [MarketLocale.NL]: FlagNL,
  [MarketLocale.US]: FlagUS,
  [MarketLocale.DE]: FlagDE,
  [MarketLocale.FR]: FlagFR,
  [MarketLocale.BE]: FlagBE,
  [MarketLocale.ES]: FlagES,
  [MarketLocale.JP]: FlagJP,
  [MarketLocale.CH]: FlagCH,
  [MarketLocale.AT]: FlagAT,
  [MarketLocale.CN]: FlagCN,
};

const props = defineProps<{ marketLocale?: MarketLocale }>();

const component = computed(() => {
  if (props.marketLocale === undefined) {
    return 'globe';
  }
  return MARKET_TO_FLAG_MAP[props.marketLocale];
});
</script>

<style src="./BaseFlag.scss" lang="scss" scoped />
