<template>
  <div ref="$el" v-editable="blok" class="cm-table container-full" :class="blok.is_dark ? 'is-dark' : 'is-light'">
    <!-- Head -->
    <div v-if="blok.head?.length" class="content-head">
      <ItemContentHead :blok="blok.head[0]" :isDark="blok.is_dark" :headSize="blok.head_size" />
    </div>

    <!-- Body -->
    <div v-if="isMounted">
      <!-- Cluster -->
      <div v-for="(cluster, index) in blok.cluster" :key="index" v-editable="cluster" class="cluster">
        <div class="cluster-text">
          <h2 class="heading-sub-medium">{{ cluster.headline }}</h2>
          <p v-if="cluster.subline" class="cluster-subline text-large-regular">
            {{ cluster.subline }}
          </p>
        </div>

        <!-- CTA -->
        <UtilButton
          v-if="cluster.cta"
          :blok="{
            component: 'util_button',
            text: cluster.cta,
            link: cluster.cta_link,
            theme: 'secondary',
            size: 'large',
            _uid: `${cluster._uid}-cta`,
          }"
          :isSmallOnMobile="true"
          class="cluster-button" />
      </div>

      <!-- Tables -->
      <div v-if="blok.tables?.length" class="tables-container" :class="{ 'has-cluster-above': blok.cluster?.length }">
        <template v-for="(table, index) in blok.tables" :key="index">
          <ItemTableStandard
            v-if="table.component === 'cm_table-standard'"
            :blok="table"
            :isDark="blok.is_dark"
            :isLast="index === (blok.tables?.length ?? 0) - 1" />
          <ItemTableFlexible v-else-if="table.component === 'cm_table-flexible'" :blok="table" :isDark="blok.is_dark" />
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import ItemContentHead from '~/components/storyblok/item/ItemContentHead/ItemContentHead.vue';
import ItemTableFlexible from '~/components/storyblok/item/ItemTable/ItemTableFlexible.vue';
import ItemTableStandard from '~/components/storyblok/item/ItemTable/ItemTableStandard.vue';
import { useScroll } from '~/composables/useScroll';
import { useTransition } from '~/composables/useTransition';
import type { CmTableStoryblok } from '~/types/storyblok-generated';

defineProps<{ blok: CmTableStoryblok }>();

const { state: scrollState } = useScroll();
const { checkViewPortElements, removeViewPortElements, addViewPortElements, hideElements } = useTransition();

const isMounted = ref(false);
const $el = ref<HTMLElement>();

function watchPageScroll() {
  if ($el.value) {
    checkViewPortElements($el.value);
  }
}

function initAnimations() {
  if (!$el.value) {
    return;
  }
  addViewPortElements($el.value.querySelectorAll('.content-head'));
  addViewPortElements($el.value.querySelectorAll('.table'));

  void nextTick(() => {
    if (!$el.value) {
      return;
    }
    hideElements($el.value);
    checkViewPortElements($el.value);
  });
}

watch(() => scrollState.top, watchPageScroll);

onBeforeUnmount(() => {
  if ($el.value) {
    removeViewPortElements($el.value);
  }
});

onMounted(() => {
  isMounted.value = true;
  void nextTick(initAnimations);
});
</script>

<style src="./CMTable.scss" lang="scss" scoped />
