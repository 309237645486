<template>
  <div class="model-viewer">
    <BaseBuffering v-if="!scriptIsReady" :isDark="false" class="buffering" />
    <!-- eslint-disable vue/attribute-hyphenation -->
    <!-- eslint-disable vue/v-on-event-hyphenation -->
    <model-viewer
      v-if="scriptIsReady"
      ref="$viewer"
      class="model-viewer__viewer"
      :src="state.model.gltf"
      :ios-src="state.model.usdz"
      :poster="state.model.poster"
      :camera-orbit="`${state.orbit.theta}deg ${state.orbit.phi}deg ${state.orbit.radius}m`"
      :camera-target="cameraTarget"
      max-camera-orbit="auto auto 150%"
      min-camera-orbit="auto auto 100%"
      with-credentials="true"
      ar
      ar-modes="webxr scene-viewer quick-look"
      loading="eager"
      reveal="auto"
      camera-controls
      disable-tap
      interaction-prompt-threshold="1000000"
      exposure="0.85"
      shadow-softness="1"
      shadow-intensity="1.15"
      tone-mapping="agx"
      @progress="onProgress"
      @load="onLoad"
      @camera-change="onZoom">
      <!-- eslint-enable vue/attribute-hyphenation -->
      <!-- eslint-disable vue/no-deprecated-slot-attribute - We are using a web component slot here -->
      <div ref="$progressBar" slot="progress-bar" class="progress-bar">
        <div class="update-bar" :style="{ transform: 'scale(' + state.progress + ',1)' }"></div>
      </div>

      <div ref="$intro" class="model-viewer__intro" @mouseover="hideIntro" @pointerdown="hideIntro">
        <div class="model-viewer__intro-wrapper">
          <div class="model-viewer__intro-instruction">
            <BaseIcon :name="instructionContent.iconRotate" :size="32" color="inherit" />
            <span class="action-large-regular">
              {{ instructionContent.labelRotate }}
            </span>
          </div>

          <div class="model-viewer__intro-instruction">
            <BaseIcon :name="instructionContent.iconZoom" :size="32" color="inherit" />
            <span class="action-large-regular">
              {{ instructionContent.labelZoom }}
            </span>
          </div>
        </div>
      </div>

      <button slot="ar-button"></button>
    </model-viewer>

    <button v-if="state.arEnabled" class="ar-button" @click="activateAR">
      <BaseIcon :name="'fi_ar'" :size="16" color="inherit" />
      {{ t('BaseModelViewer.arButtonLabel') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '#imports';
import type { ModelViewerElement } from '@google/model-viewer';
import gsap from 'gsap';
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue';
import BaseBuffering from '~/components/base/BaseBuffering.vue';
import BaseIcon from '~/components/base/BaseIcon.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import type { EmProductWidgetStoryblok } from '~/types/storyblok-generated';

declare module 'vue' {
  export interface GlobalComponents {
    'model-viewer': ModelViewerElement;
  }
}

const props = defineProps<{
  blok: EmProductWidgetStoryblok;
}>();

const $intro = ref<HTMLElement>();
const $viewer = ref<ModelViewerElement>();
const $progressBar = ref<HTMLElement>();
const scriptIsReady = ref(false);

const state = reactive({
  initialized: false,
  progress: 0,
  loading: true,
  introShown: true,
  timeMounted: 0,
  arEnabled: false,
  orbit: {
    theta: 0,
    phi: 75,
    radius: 200,
  },
  scale: 1,
  model: {
    gltf: '',
    poster: '',
    usdz: '',
  },
});

const { isTouch, state: stateBreakpoint } = useBreakpoint();
const { t } = useI18n();
const isTablet = computed(() => !stateBreakpoint.isTabletLarge);
const isMobile = computed(() => !stateBreakpoint.isPhoneLarge);

const emit = defineEmits<{
  (e: 'arEnabled'): void;
  (e: 'zoom', data: { percent: number; radius: number }): void;
}>();

function activateAR() {
  void $viewer.value?.activateAR();
}

const instructionContent = computed(() => {
  const iconRotate = 'fi_orbit';
  const iconZoom = isTouch.value ? 'fi_pinch' : 'fi_scroll';

  const labelRotate = isTouch.value
    ? t('BaseModelViewer.instructionRotateMobile')
    : t('BaseModelViewer.instructionRotateDesktop');
  const labelZoom = isTouch.value
    ? t('BaseModelViewer.instructionZoomMobile')
    : t('BaseModelViewer.instructionZoomDesktop');

  return {
    iconRotate,
    iconZoom,
    labelRotate,
    labelZoom,
  };
});

const cameraTarget = computed(() => {
  // Edge case wide machine
  if (typeof props.blok.data_product !== 'string' && props.blok.data_product?.content?.is_wide) {
    return '0m 0.35m 0m';
  }
  return isMobile.value ? '0m 0.6m 0m' : isTablet.value ? '0m 0.55m 0m' : '0m 0.45m 0m';
});

const hideIntro = (e: any, delay: number = 0) => {
  if (!state.timeMounted) {
    return;
  }

  if (e) {
    // Hover - check if loaded and instructions are read
    const loadedSince = (Date.now() - state.timeMounted) / 1000;
    if (loadedSince < 1) {
      return;
    }
  }

  if (state.loading || !state.introShown) {
    return;
  }

  gsap.to(state.orbit, {
    onStart: () => {
      state.introShown = false;
    },
    overwrite: true,
    theta: 30,
    phi: 90,
    delay: delay + 0.3,
  });

  if ($intro.value) {
    gsap.to($intro.value, { duration: 0.5, overwrite: true, autoAlpha: 0, delay: delay });
  }
};

const zoomOptions = {
  min: 0,
  max: 0,
};

function onZoom() {
  if (state.loading || !$viewer.value) {
    return;
  }

  if (!zoomOptions.max) {
    zoomOptions.max = $viewer.value.getCameraOrbit().radius;
  }
  if (!zoomOptions.min) {
    zoomOptions.min = $viewer.value.getCameraOrbit().radius / 2;
  }

  const zoom = 1 - ($viewer.value.getCameraOrbit().radius - zoomOptions.min) / (zoomOptions.max - zoomOptions.min);
  emit('zoom', { percent: zoom, radius: $viewer.value.getCameraOrbit().radius });
}

function onLoad() {
  if ($progressBar.value) {
    gsap.to($progressBar.value, {
      duration: 0.25,
      y: 25,
      autoAlpha: 0,
      delay: 0.25,
    });
  }

  state.initialized = true;
  state.loading = false;
}

function onProgress(e: { detail: { reason: string; totalProgress: number } }) {
  if (e.detail.reason === 'model-load') {
    state.progress = e.detail.totalProgress;
  }
}

const productMap = new Map<string, string>([
  ['WMF 950 S', 'wmf950s'],
  ['WMF 1100 S', 'wmf1100s'],
  ['WMF 1300 S', 'wmf1300s'],
  ['WMF 1500 S+', 'wmf1500sPlus'],
  ['WMF espresso NEXT', 'wmfEspressoNext'],
  ['WMF 1500 F', 'wmf1500f'],
  ['WMF 5000 S+', 'wmf5000sPlus'],
  ['WMF 9000 F', 'wmf9000f'],
  ['WMF 9000 S+', 'wmf9000sPlus'],
]);

const productName =
  props.blok?.data_product && typeof props.blok?.data_product !== 'string' ? props.blok?.data_product?.name : '';

const filename: string = productMap.get(productName) ?? '';
if (filename === '') {
  console.error('[product widget error] product filename is invalid!');
}

watch(
  () => state.initialized,
  async () => {
    state.timeMounted = Date.now();

    await nextTick();
    state.arEnabled = $viewer.value?.canActivateAR ?? false;
    if (state.arEnabled) emit('arEnabled');
  }
);

onMounted(() => {
  const origin = window.location.origin;

  state.model = {
    gltf: `${origin}/webgl/models/machines/${filename}/high/${filename}.gltf`,
    usdz: `${origin}/webgl/models/machines/${filename}/model.usdz`,
    poster: `${origin}/webgl/models/machines/${filename}/poster.webp`,
  };

  void import('@google/model-viewer').then(() => (scriptIsReady.value = true));
});
</script>

<style src="./BaseModelViewer.scss" lang="scss" scoped />
