import { useI18n } from '#imports';
import { useRuntimeConfig } from 'nuxt/app';
import { computed } from 'vue';
import {
  Language,
  LANGUAGE_TO_TRANSLATIONS_MAP,
  Locale,
  LOCALE_HAS_SHOP,
  LOCALE_TO_LANGUAGE_MAP,
  LOCALE_TO_MARKET_LOCALE_MAP,
  MARKET_TO_TRANSLATIONS_MAP,
} from '~/types/locales';
import type { Country, Market } from '~/types/utils';
import { countriesDe as countriesListDE, countriesEn as countriesListEN } from '@seb-platform/shared/assets';

export function useLocale() {
  const config = useRuntimeConfig();

  const { locale: i18nLocale } = useI18n();
  const locale = config.public.IS_HEADER_FOOTER_EXPORT ? Locale.DE_DE : (i18nLocale.value as Locale); // The shop is only available in DE
  const localeMarket = computed(() => LOCALE_TO_MARKET_LOCALE_MAP[locale]);
  const localeLanguage = computed(() => LOCALE_TO_LANGUAGE_MAP[locale]);
  const localeCountries = getLocaleCountries(localeLanguage.value);
  const routeHasValidLocale = computed(() => isLocale(locale));
  const hasShop = computed(() => LOCALE_HAS_SHOP[locale]);

  const locales = computed(() => {
    const marketsMap: Record<string, Market> = {};
    const locales = config.public.LOCALES;

    locales.forEach(locale => {
      const language = LOCALE_TO_LANGUAGE_MAP[locale as Locale];
      const market = LOCALE_TO_MARKET_LOCALE_MAP[locale as Locale];

      // If the market exists, add the language to the market, else create a new market
      if (!marketsMap[market]) {
        marketsMap[market] = {
          label: MARKET_TO_TRANSLATIONS_MAP[market],
          id: market,
          languages: [
            {
              label: LANGUAGE_TO_TRANSLATIONS_MAP[language],
              id: language,
            },
          ],
        };
      } else {
        marketsMap[market].languages.push({
          label: LANGUAGE_TO_TRANSLATIONS_MAP[language],
          id: language,
        });
      }
    });

    return Object.values(marketsMap);
  });

  function getLocaleCountries(language: Language | null): Country[] {
    switch (language) {
      case Language.DE:
        return countriesListDE;
      case Language.EN:
      default:
        return countriesListEN;
    }
  }

  function isLocale(locale: string): locale is Locale {
    return config.public.LOCALES.includes(locale as Locale);
  }

  return {
    locale, // Is the current or default locale
    isLocale,
    locales,
    localeMarket,
    localeLanguage,
    localeCountries,
    getLocaleCountries,
    routeHasValidLocale,
    hasShop,
  };
}
