<template>
  <div v-editable="blok" class="item-table-flexible" :class="isDark ? 'is-dark' : 'is-light'">
    <div class="wrapper" :class="{ 'has-title': hasTitle }">
      <!-- Head -->
      <div v-if="hasTitle && blok.title?.[0]" class="head">
        <UtilRichtext :blok="blok.title[0]" :isDark="isDark" :isProseEnabled="false" class="title" />
      </div>

      <!-- Body -->
      <div class="body">
        <div class="sets">
          <!-- Sets -->
          <div v-for="(set, rowsSetIndex) in blok.rows_sets" :key="rowsSetIndex" v-editable="set" class="set">
            <UtilAccordion
              :isEnabled="set.is_collapsible"
              :isDark="isDark"
              :isButtonRight="false"
              :isSmall="true"
              :isLast="isDesktop ? rowsSetIndex === (blok.rows_sets?.length ?? 0) - 1 && !hasFootnote : false"
              :hasBodyBottomPadding="isDesktop ? rowsSetIndex < (blok.rows_sets?.length ?? 0) - 1 || hasFootnote : true"
              :shouldScrollToTop="false"
              :index="rowsSetIndex"
              :activeIndex="0">
              <!-- Head / Summary Row -->
              <template #head>
                <div
                  v-if="set.is_collapsible && set.summary_row?.[0]"
                  v-editable="set.summary_row[0]"
                  class="row has-min-height"
                  :class="`grid-cols-${blok.layout}`">
                  <!-- Row title -->
                  <div class="row-title text-large-bold">
                    {{ set.summary_row[0].title }}
                  </div>

                  <!-- Cells -->
                  <div v-for="(cell, cellIndex) in set.summary_row[0].cells" :key="cellIndex" class="cell">
                    <template v-if="cell.component === 'cm_table-cell-icon-text'">
                      <BaseIcon v-if="cell.icon" :name="cell.icon" :size="24" color="inherit" class="cell-icon" />
                      <div class="cell-text">
                        {{ cell.text }}
                      </div>
                    </template>
                  </div>
                </div>
              </template>

              <!-- Body -->
              <template #body>
                <div class="rows">
                  <!-- Rows -->
                  <template v-for="row in set.rows" :key="row._uid">
                    <div v-editable="row" class="row" :class="`grid-cols-${blok.layout}`">
                      <!-- Row title -->
                      <div class="row-title text-large-regular">
                        {{ row.title }}
                      </div>

                      <!-- Cells -->
                      <div v-for="(cell, cellIndex) in row.cells" :key="cellIndex" class="cell">
                        <template v-if="cell.component === 'cm_table-cell-icon-text'">
                          <BaseIcon v-if="cell.icon" :name="cell.icon" :size="24" color="inherit" class="cell-icon" />
                          <div class="cell-text">
                            {{ cell.text }}
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </UtilAccordion>
          </div>
        </div>

        <!-- Footnote -->
        <div v-if="hasFootnote" v-editable="blok.footnote" class="row is-footnote">
          <BaseRichtext :text="blok.footnote" :isDark="isDark" :isProseParagraphsEnabled="true" class="footnote" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { BaseIcon, BaseRichtext } from '~/components/base';
import UtilAccordion from '~/components/storyblok/utils/UtilAccordion/UtilAccordion.vue';
import UtilRichtext from '~/components/storyblok/utils/UtilRichtext/UtilRichtext.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import type { CmTableFlexibleStoryblok } from '~/types/storyblok-generated';

const props = withDefaults(
  defineProps<{
    blok: CmTableFlexibleStoryblok;
    isDark?: boolean;
    index?: number;
  }>(),
  { index: 0 }
);

const { state: stateBreakpoint } = useBreakpoint();

const hasTitle = computed(() => Array.isArray(props.blok.title) && props.blok.layout === '2');
const isDesktop = computed(() => stateBreakpoint.isPhoneLarge);
const hasFootnote = computed(() => !!props.blok.footnote?.content?.[0]?.content);
</script>

<style src="./ItemTableFlexible.scss" lang="scss" scoped />
