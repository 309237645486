import { reactive } from 'vue';
import type { OneTrustGroup } from '@seb-platform/shared/types';

interface ConsentState {
  visible: false | 'banner' | 'preferences';
  groups: OneTrustGroup[];
}

const state = reactive<ConsentState>({
  visible: false,
  groups: [],
});

export function useConsent() {
  return {
    state,
  };
}
