import type { Texture, Vector3 } from 'three';
import type { GLTF } from 'three/examples/jsm/loaders/GLTFLoader.js';
import type { ShowroomPointerState } from '~/components/storyblok/extra/EMShowroom/EMShowroom.types';

export interface Dimensions {
  width: number;
  height: number;
}

export interface Asset {
  name: string;
  path: string;
  extension: string;
  onLoadCallback: (asset: LoadedAsset) => void;
}

export type LoadedAsset = Asset | Texture | GLTF;

export interface MachineConfig {
  name: string;
  shadow: {
    texturePath: string;
    scale: Vector3;
    position: Vector3;
    intensity: number;
  };
  modelPath: string;
  zoomLevelByScreenSize: Record<string, number>;
}

export interface MachineMoveEventData {
  isSwiped: boolean;
  currentMachineName: string;
}

export enum ShowroomEvent {
  Zoom = 'showroom:zoom',
  ZoomTween = 'showroom:zoomTween',
  ZoomTweenEnd = 'showroom:zoomTweenEnd',
  FilterToggled = 'showroom:filterToggled',
  NextMachine = 'showroom:nextMachine',
  PreviousMachine = 'showroom:previousMachine',
  PointerDown = 'showroom:pointerDown',
  PointerMove = 'showroom:pointerMove',
  PointerUp = 'showroom:pointerUp',
  SwipeProgress = 'showroom:swipeProgress',
  SwipeCancelled = 'showroom:swipeCancelled',
  MachinesMoveStart = 'showroom:machinesMoveStart',
  MachinesMoveProgress = 'showroom:machinesMoveProgress',
  MachinesMoveEnd = 'showroom:machinesMoveEnd',
  MachinesRecenterEnd = 'showroom:machinesRecenterEnd',
  LoadingProgress = 'showroom:loadingProgress',
  MachinesLoadingProgress = 'showroom:machinesLoadingProgress',
  AssetsLoadingProgress = 'showroom:assetsLoadingProgress',
  InitVisuals = 'showroom:initVisuals',
}

export interface ShowroomEventsPayloadMap {
  [ShowroomEvent.Zoom]: number;
  [ShowroomEvent.ZoomTween]: number;
  [ShowroomEvent.ZoomTweenEnd]: any;
  [ShowroomEvent.FilterToggled]: boolean;
  [ShowroomEvent.NextMachine]: { isSwiped: boolean };
  [ShowroomEvent.PreviousMachine]: { isSwiped: boolean };
  [ShowroomEvent.PointerDown]: ShowroomPointerState;
  [ShowroomEvent.PointerMove]: ShowroomPointerState;
  [ShowroomEvent.PointerUp]: ShowroomPointerState;
  [ShowroomEvent.SwipeProgress]: number;
  [ShowroomEvent.SwipeCancelled]: any;
  [ShowroomEvent.MachinesMoveStart]: MachineMoveEventData;
  [ShowroomEvent.MachinesMoveProgress]: { progress: number; currentMachineName: string };
  [ShowroomEvent.MachinesMoveEnd]: MachineMoveEventData;
  [ShowroomEvent.MachinesRecenterEnd]: any;
  [ShowroomEvent.LoadingProgress]: number;
  [ShowroomEvent.MachinesLoadingProgress]: number;
  [ShowroomEvent.AssetsLoadingProgress]: number;
  [ShowroomEvent.InitVisuals]: any;
}

export type ShowroomEventsMap = {
  [K in keyof ShowroomEventsPayloadMap]: CustomEvent<ShowroomEventsPayloadMap[K]>;
};

export interface ShowroomCanvas
  extends Omit<HTMLCanvasElement, 'addEventListener' | 'removeEventListener' | 'dispatchEvent'> {
  // Adds definition to Document, but you can do the same with HTMLElement
  addEventListener<K extends keyof ShowroomEventsMap>(
    type: K,
    listener: (this: Document, ev: ShowroomEventsMap[K]) => void
  ): void;
  removeEventListener<K extends keyof ShowroomEventsMap>(
    type: K,
    listener: (this: Document, ev: ShowroomEventsMap[K]) => void
  ): void;
  dispatchEvent<K extends keyof ShowroomEventsMap>(ev: ShowroomEventsMap[K]): void;
}
