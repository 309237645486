import type { ISbStoryData } from '@storyblok/vue';
import * as THREE from 'three';
import type { DataProductStoryblok } from '~/types/storyblok-generated';
import type { AppStateInstance } from '../core/appState';
import type { AssetManagerInstance } from '../core/assetManager';
import type { EngineInstance } from '../core/engine';
import { ShowroomEvent } from '../webGlApp.types';
import { Background, type BackgroundInstance } from './background/background';
import { BlueNoise } from './blueNoise/blueNoise';
import { Ground, type GroundInstance } from './ground/ground';
import { Lighting, type LightingInstance } from './lighting/lighting';
import { Machines, type MachinesInstance } from './machines/machines';

export type VisualsInstance = InstanceType<typeof Visuals>;

interface VisualsConstructorProps {
  appState: AppStateInstance;
  engine: EngineInstance;
  assetManager: AssetManagerInstance;
  featuredMachinesData: ISbStoryData<DataProductStoryblok>[];
  initialMachinesData: ISbStoryData<DataProductStoryblok>[];
}

export class Visuals {
  appState: AppStateInstance;
  container = new THREE.Object3D();
  resizeTimer: number = 0;
  resizeTimerDuration: number = 0.5;
  resizeTimerIsActive: boolean = false;
  isReady: boolean = false;
  isActive: boolean = false;
  machines: MachinesInstance;
  blueNoise: BlueNoise;
  ground: GroundInstance | null = null;
  background: BackgroundInstance | null = null;
  envTexture: THREE.Texture | null = null;
  lighting: LightingInstance;

  constructor({ appState, engine, assetManager, featuredMachinesData, initialMachinesData }: VisualsConstructorProps) {
    this.appState = appState;
    engine.scene.add(this.container);
    this.blueNoise = new BlueNoise(assetManager);
    this.lighting = new Lighting(appState);
    this.container.add(this.lighting.container);
    this.machines = new Machines(appState, engine, assetManager, featuredMachinesData, initialMachinesData);
    this.container.add(this.machines.container);
    this.appState.webGlCanvas.addEventListener(ShowroomEvent.InitVisuals, this.init.bind(this));
  }

  /**
   * Some visuals require assets to be loaded or other setup to be done before they can be initialized.
   * Therefore this method is called when these prerequisites are met.
   */
  private init() {
    this.background = new Background(this.blueNoise);
    this.container.add(this.background.container);
    this.ground = new Ground(this.appState, this.blueNoise);
    this.container.add(this.ground.container);
    this.machines.init(this.blueNoise);
    this.isReady = true;
  }

  show() {
    if (!this.isReady) {
      console.error('Visuals are not ready yet');
      return;
    }
    this.isActive = true;
    this.ground!.show();
    this.background!.show();
    this.lighting.show();
    this.machines.show();
  }

  hide() {
    this.isActive = false;
    this.ground!.hide();
    this.background!.hide();
    this.lighting.hide();
    this.machines.hide();
  }

  resize(width: number) {
    this.resizeTimerIsActive = true;
    this.machines.resize(width);
  }

  destroy() {
    this.machines.destroy();
  }

  update(engine: EngineInstance) {
    if (!this.isActive) {
      return;
    }
    if (!this.isReady) {
      console.error('Visuals are not ready yet');
      return;
    }
    this.blueNoise.update();
    this.ground!.update(engine, this.background!);
    this.background!.update();
    this.lighting.update(engine);
    this.machines.update();
  }

  setMachines(data: ISbStoryData<DataProductStoryblok>[]) {
    this.machines.setMachines(data);
  }
}
