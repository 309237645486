import { reactive } from 'vue';

interface FooterState {
  hidden: boolean;
}

const state = reactive<FooterState>({
  hidden: false,
});

export function useFooter() {
  return {
    state,
  };
}
