uniform float u_lamellaeAmount;
uniform float u_lamellaeIntensity;
uniform vec2 u_gradientStrip;


uniform sampler2D u_blueNoiseTexture;
uniform vec2 u_blueNoiseTextureSize;
uniform vec2 u_blueNoiseTextureOffset;

varying vec2 v_uv;




#include "../../glsl/circleBlur.glsl"

void main () {
  float blueNoise = texture2D(u_blueNoiseTexture, gl_FragCoord.xy * u_blueNoiseTextureSize + u_blueNoiseTextureOffset ).r;

  float circleValue = circleBlur(.15, 0.1, v_uv, .50, 0.30).r;
  vec4 newMerge = vec4(0.0, 0.0, 0.0, 1.0);


  vec3 outputColor = vec3(0.0) * circleValue;
  outputColor+= blueNoise * 0.001;

  float lamellaePattern = fract(v_uv.x * u_lamellaeAmount) * u_lamellaeIntensity;
  outputColor.rgb *= lamellaePattern;
  outputColor += blueNoise * 0.001;


  gl_FragColor = vec4(outputColor,  smoothstep(u_gradientStrip.x, u_gradientStrip.y, v_uv.y));  
}
 