import * as THREE from 'three';
// @ts-expect-error - We are importing 3d related code
import boundingBoxVert from '../../glsl/boundingBox.vert';
// @ts-expect-error - We are importing 3d related code
import boundingBoxFrag from '../../glsl/boundingBox.frag';

export class BoundingBoxMaterial extends THREE.RawShaderMaterial {
  constructor() {
    super({
      uniforms: { u_color: { value: new THREE.Color('#ff0000') } },
      vertexShader: boundingBoxVert,
      fragmentShader: boundingBoxFrag,
      wireframe: true,
    });
  }
}
