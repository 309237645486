<template>
  <a
    v-if="asset.filename"
    download
    :href="asset.filename"
    target="_blank"
    :aria-label="filename ? t('global.downloadX', { filename }) : t('global.downloadFile')"
    @mouseenter="onIconMouseOver"
    @mouseleave="onIconMouseOut">
    <slot></slot>
  </a>
</template>

<script setup lang="ts">
import { useI18n } from '#imports';
import { useIconAnimation } from '~/composables/useIconAnimation';
import type { AssetStoryblok } from '~/types/storyblok-generated';

const { onIconMouseOver, onIconMouseOut } = useIconAnimation();

defineProps<{
  asset: AssetStoryblok;
  filename?: string;
}>();

const { t } = useI18n();
</script>
