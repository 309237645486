<template>
  <div
    ref="$el"
    v-editable="blok"
    class="item-content-head"
    :class="[isDark ? 'is-dark' : 'is-black', { 'has-max-width': isMaxWidthEnabled }]">
    <!-- Overline and title -->
    <div v-if="blok.overline || hasHeadline" class="title-container">
      <span
        v-if="blok.overline"
        class="head-overline"
        :class="[
          isSmall ? 'heading-over-small' : 'heading-over-medium',
          { 'is-invisible': !isVisibleOnLoad && !didFadeIn },
        ]">
        {{ blok.overline }}
      </span>

      <BaseRichtext
        v-if="hasHeadline"
        class="head-headline"
        :class="[
          isSmall ? 'heading-main-small' : 'heading-main-large',
          { 'is-invisible': !isVisibleOnLoad && !didFadeIn },
        ]"
        :text="blok.headline"
        :isProseHeadlinesEnabled="false"
        :isProseParagraphsEnabled="false" />
    </div>

    <!-- Text -->
    <p
      v-if="blok.component !== 'item_content-head-reduced' && blok.intro"
      class="head-copy text-large-bold"
      :class="{ 'is-invisible': !isVisibleOnLoad && !didFadeIn }">
      {{ blok.intro }}
    </p>

    <!-- CTAs -->
    <div v-if="actions?.length" class="ctas-container">
      <template v-for="(action, index) in actions" :key="index">
        <UtilLinkItemIcon
          v-if="action.component === 'util_link-item-icon'"
          class="cta"
          :blok="action"
          :isDark="isDark" />
        <UtilLinkItemSubtext
          v-if="action.component === 'util_link-item-subtext'"
          class="cta"
          :blok="action"
          :isDark="isDark" />
        <UtilButton v-if="action.component === 'util_button'" class="cta" :blok="action" :isBackgroundDark="isDark" />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import BaseRichtext from '~/components/base/BaseRichtext/BaseRichtext.vue';
import UtilLinkItemIcon from '~/components/storyblok/utils/UtilLinkItemIcon/UtilLinkItemIcon.vue';
import UtilLinkItemSubtext from '~/components/storyblok/utils/UtilLinkItemSubtext/UtilLinkItemSubtext.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import { useScroll } from '~/composables/useScroll';
import { useTransition } from '~/composables/useTransition';
import type { ItemContentHeadReducedStoryblok, ItemContentHeadStoryblok } from '~/types/storyblok-generated';

const props = defineProps<{
  blok: ItemContentHeadStoryblok | ItemContentHeadReducedStoryblok;
  headSize?: 'large' | 'small' | '';
  isDark?: boolean;
  isMaxWidthEnabled?: boolean;
  staggerLines?: boolean;
  isVisibleOnLoad?: boolean;
}>();

const { inViewport, fadeSlide } = useTransition();
const { state: scrollState } = useScroll();

const $el = ref<HTMLElement>();
const didFadeIn = ref(false);

const isSmall = computed(() => props.headSize === 'small');
const actions = computed(() =>
  props.blok.component === 'item_content-head-reduced'
    ? []
    : [...(props.blok.links ?? []), ...(props.blok.buttons ?? [])]
);
const hasHeadline = props.blok.headline?.content?.[0]?.content;
const animationState = { headlineVisible: false };
let timeout: ReturnType<typeof setTimeout>;

function showHeader() {
  animationState.headlineVisible = true;
  if (!$el.value) {
    return;
  }

  // Get elements to fade in
  const headItems = [];
  headItems.push(...$el.value.querySelectorAll('.head-overline'));
  headItems.push(...$el.value.querySelectorAll('.head-headline'));
  headItems.push(...$el.value.querySelectorAll('.head-copy'));
  headItems.push(...$el.value.querySelectorAll('.cta'));
  fadeSlide(headItems);
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    // Makes all elements visible to be editable in the visual editor of Storyblok
    didFadeIn.value = true;
  }, 1000);
}

function watchPageScroll() {
  if (animationState.headlineVisible || !$el.value) {
    return;
  }
  if (inViewport($el.value)) {
    showHeader();
  }
}

watch(() => scrollState.top, watchPageScroll);

onBeforeUnmount(() => {
  clearTimeout(timeout);
});

onMounted(() => {
  void nextTick(watchPageScroll);
});
</script>

<style src="./ItemContentHead.scss" lang="scss" scoped />
