<template>
  <!-- Language switch -->
  <div v-if="languagesItems?.length" class="bm-header-rich-language-toggle">
    <RouterLink
      v-for="(item, index) in languagesItems"
      :key="index"
      class="language action-medium-bold"
      :class="[item.path === route.path ? 'is-active' : 'is-inactive', isDark ? 'is-dark' : 'is-light']"
      :to="item.path">
      {{ item.language }}
    </RouterLink>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'nuxt/app';
import type { ISbAlternateObject } from 'storyblok-js-client';
import { computed } from 'vue';
import { RouterLink } from 'vue-router';
import { useLocale } from '~/composables/useLocale';
import { normalizeUrl } from '~/utils/url';
import { LOCALE_TO_LANGUAGE_MAP, LOCALE_TO_MARKET_LOCALE_MAP, type Language, type MarketLocale } from '~/types/locales';

interface LocaleMarket {
  market: MarketLocale;
  language: Language;
  path: string;
}

const props = defineProps<{
  alternates?: ISbAlternateObject[];
  isDark?: boolean;
}>();

const route = useRoute();
const { localeMarket: currentLocaleMarket, localeLanguage: currentLocaleLanguage, isLocale } = useLocale();

// Language switch if enabled
const languagesItems = computed((): LocaleMarket[] => {
  // Find related markets and languages
  const localeMarkets: LocaleMarket[] = (props.alternates ?? [])
    .map<LocaleMarket | null>(alternate => {
      const urlLocalePart = alternate.full_slug.split('/')?.[0];
      if (isLocale(urlLocalePart)) {
        const localeMarket = LOCALE_TO_MARKET_LOCALE_MAP[urlLocalePart];
        const localeLanguage = LOCALE_TO_LANGUAGE_MAP[urlLocalePart];

        return {
          market: localeMarket,
          language: localeLanguage,
          path: normalizeUrl(alternate.full_slug),
        };
      }
      return null;
    })
    .filter(localeMarket => !!localeMarket)
    .filter(localeMarket => localeMarket.market === currentLocaleMarket.value);

  // Add current locale to the list if not already there
  if (localeMarkets.length > 0) {
    return [
      ...localeMarkets,
      {
        market: currentLocaleMarket.value,
        language: currentLocaleLanguage.value,
        path: normalizeUrl(route.path),
      },
    ].sort((a, b) => {
      return a.language.localeCompare(b.language); // Sort by language so the order is consistent
    });
  }

  // If not found, return empty array
  return [];
});
</script>

<style src="./BmHeaderRichLanguageToggle.scss" lang="scss" scoped />
