import * as THREE from 'three';
import { ShowroomEvent, type Dimensions, type ShowroomCanvas, type ShowroomEventsMap } from '../webGlApp.types';
import { sendEvent } from '../utils/showroomEvents';

const MAX_PIXEL_COUNT = 2560 * 1440;
const MAX_PIXEL_COUNT_MOBILE = 1080 * 1920;

export type AppStateInstance = InstanceType<typeof AppState>;

export class AppState {
  webGlCanvas: ShowroomCanvas;
  os: string;
  isIOS: boolean;
  browser: string;
  isWebGL1: boolean;
  isWebGL2: boolean;
  isIE11: boolean;
  isTouchDevice: boolean;
  isMobileDevice: boolean;
  hasWebpSupport: boolean | null;
  hasAvifSupport: boolean | null;
  isSupported: boolean | null;
  isReady: boolean = false;
  qualityLvl: 'low' | 'high';
  isDebugActive: boolean = false;
  IS_DEBUG: boolean;
  dpr: number;
  viewport: Dimensions;
  renderSize: Dimensions;
  visibleWidthOfCamera: number = 0;
  normalizedPointerCoords = new THREE.Vector2();
  pointerActiveRatio: number = 0;
  isPointerActive: boolean = false;
  assetsProgress = 0;
  machinesProgress = 0;

  constructor(canvas: HTMLCanvasElement) {
    this.webGlCanvas = canvas as ShowroomCanvas;
    this.os = this.getOS();
    this.isIOS = this.getIsIOS();
    this.browser = this.getBrowser();
    this.isWebGL1 = this.getIsWebGL1();
    this.isWebGL2 = this.getIsWebGL2();
    this.isIE11 = this.getIsIE11();
    this.isTouchDevice = this.getIsTouchDevice();
    this.isMobileDevice = this.getIsMobileDevice();
    this.hasWebpSupport = null;
    this.hasAvifSupport = null;
    this.qualityLvl = window.screen.width <= 1366 ? 'low' : 'high'; //1366 Ipad pro
    this.isSupported = null;
    // This is asyncronous, so we need to wait for it to finish before we can use it
    this.getImageSupport();

    this.dpr = this.getDpr();
    this.viewport = { width: window.innerWidth, height: window.innerHeight };
    this.renderSize = this.getRenderSize();

    const URL_PARAMS = new URLSearchParams(window.location.search);
    this.IS_DEBUG = URL_PARAMS.get('DEBUG_MODE') === 'true';

    this.webGlCanvas.addEventListener(ShowroomEvent.AssetsLoadingProgress, this.handleAssetsLoadingProgress.bind(this));
    this.webGlCanvas.addEventListener(
      ShowroomEvent.MachinesLoadingProgress,
      this.handleMachinesLoadingProgress.bind(this)
    );
  }

  private getProgress(assetsProgress: number, machinesProgress: number) {
    return assetsProgress * 0.9 + machinesProgress * 0.1;
  }

  private handleAssetsLoadingProgress(event: ShowroomEventsMap[ShowroomEvent.AssetsLoadingProgress]) {
    this.assetsProgress = event.detail;
    sendEvent(
      this.webGlCanvas,
      ShowroomEvent.LoadingProgress,
      this.getProgress(this.assetsProgress, this.machinesProgress)
    );
    if (this.assetsProgress === 1) {
      sendEvent(this.webGlCanvas, ShowroomEvent.InitVisuals, null);
    }
  }

  private handleMachinesLoadingProgress(event: ShowroomEventsMap[ShowroomEvent.MachinesLoadingProgress]) {
    this.machinesProgress = event.detail;
    sendEvent(
      this.webGlCanvas,
      ShowroomEvent.LoadingProgress,
      this.getProgress(this.assetsProgress, this.machinesProgress)
    );
  }

  resize(width: number, height: number) {
    this.viewport = { width, height };
    this.renderSize = this.getRenderSize();
  }

  getRenderSize() {
    const maxPixelCount = this.isMobileDevice ? MAX_PIXEL_COUNT_MOBILE : MAX_PIXEL_COUNT;
    const newRenderSize = {
      width: this.viewport.width * this.dpr,
      height: this.viewport.height * this.dpr,
    };
    if (newRenderSize.width * newRenderSize.height > maxPixelCount) {
      const aspect = newRenderSize.width / newRenderSize.height;
      newRenderSize.height = Math.sqrt(MAX_PIXEL_COUNT / aspect);
      newRenderSize.width = Math.ceil(newRenderSize.height * aspect);
      newRenderSize.height = Math.ceil(newRenderSize.height);
    }
    return newRenderSize;
  }

  async getImageSupport() {
    await this.getHasWebpSupport();
    await this.getHasAvifSupport();
    this.isSupported = (this.isWebGL1 || this.isWebGL2) && this.hasWebpSupport && !this.isIE11;
    this.isReady = true;
  }

  private getOS() {
    const sUserAgent = navigator.userAgent;
    const isWin = navigator.platform === 'Win32' || navigator.platform === 'Windows';
    const isMac =
      navigator.platform === 'Mac68K' ||
      navigator.platform === 'MacPPC' ||
      navigator.platform === 'Macintosh' ||
      navigator.platform === 'MacIntel';

    if (isMac) return 'Mac';
    const isUnix = navigator.platform === 'X11' && !isWin && !isMac;
    if (isUnix) return 'Unix';
    const isIOS =
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
      // IPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    if (isIOS) return 'iOS';
    const isLinux = String(navigator.platform).indexOf('Linux') > -1;
    if (isLinux) return 'Linux';
    if (isWin) {
      const isWin2K = sUserAgent.indexOf('Windows NT 5.0') > -1 || sUserAgent.indexOf('Windows 2000') > -1;
      if (isWin2K) return 'Win2000';
      const isWinXP = sUserAgent.indexOf('Windows NT 5.1') > -1 || sUserAgent.indexOf('Windows XP') > -1;
      if (isWinXP) return 'WinXP';
      const isWin2003 = sUserAgent.indexOf('Windows NT 5.2') > -1 || sUserAgent.indexOf('Windows 2003') > -1;
      if (isWin2003) return 'Win2003';
      const isWinVista = sUserAgent.indexOf('Windows NT 6.0') > -1 || sUserAgent.indexOf('Windows Vista') > -1;
      if (isWinVista) return 'WinVista';
      const isWin7 = sUserAgent.indexOf('Windows NT 6.1') > -1 || sUserAgent.indexOf('Windows 7') > -1;
      if (isWin7) return 'Win7';
      const isWin10 = sUserAgent.indexOf('Windows NT 10') > -1 || sUserAgent.indexOf('Windows 10') > -1;
      if (isWin10) return 'Win10';
    }

    return 'other';
  }

  private getIsIOS() {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return true;
    }
    if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) {
      return true;
    }
    return false;
  }

  private getIsIE() {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return 'IE' + parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return 'IE' + parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return 'Edge' + parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    return false;
  }

  private getBrowser() {
    const agent = navigator.userAgent.toLowerCase();
    const regStr_ff = /firefox\/[\d.]+/gi;
    const regStr_chrome = /chrome\/[\d.]+/gi;
    const regStrChrome2 = /ipad; cpu os (\d+_\d+)/gi;
    const regStr_saf = /safari\/[\d.]+/gi;

    // Firefox
    if (agent.indexOf('firefox') > 0) {
      return agent.match(regStr_ff)?.[0] ?? 'firefox';
    }

    // Safari
    if (agent.indexOf('safari') > 0 && agent.indexOf('chrome') < 0) {
      let tmpInfo;
      if (agent.indexOf('ipad') > 0) {
        tmpInfo = agent.match(regStrChrome2)?.toString().replace('ipad; cpu os ', 'ipados/');
      } else {
        tmpInfo = agent.match(regStr_saf);
        tmpInfo = tmpInfo?.toString().replace('version', 'safari');
      }
      return tmpInfo ?? 'safari';
    }

    // IE / Eege
    const tmpIsIE = this.getIsIE();
    if (tmpIsIE) {
      return tmpIsIE;
    }

    // Chrome
    if (agent.indexOf('chrome') > 0) {
      return agent.match(regStr_chrome)?.[0] ?? 'chrome';
    }

    return 'other';
  }

  private getDpr() {
    const dpr = window.devicePixelRatio || 1;
    return Math.min(dpr, 2);
  }

  private getIsWebGL1() {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    return !!gl && gl instanceof WebGLRenderingContext;
  }

  private getIsWebGL2() {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl2') || canvas.getContext('experimental-webgl2');
    return !!gl && gl instanceof WebGL2RenderingContext;
  }

  private getIsIE11() {
    return !!(window as any).MSInputMethodContext && !!(document as any).documentMode;
  }

  private getIsTouchDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  private getIsMobileDevice() {
    return window.screen.width < 1376 && this.getIsTouchDevice();
  }

  private getHasAvifSupport() {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        this.hasAvifSupport = true;
        resolve(true);
      };
      image.onerror = () => {
        this.hasAvifSupport = false;
        reject(false);
      };
      image.src =
        'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=';
    });
  }

  private getHasWebpSupport() {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        const result = image.width > 0 && image.height > 0;
        this.hasWebpSupport = result;
        resolve(result);
      };
      image.onerror = () => {
        this.hasWebpSupport = false;
        reject(false);
      };
      image.src = 'data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==';
    });
  }

  logAppState(data: AppState) {
    for (const [key, value] of Object.entries(data)) {
      console.info(`[AppState] ${key}: ${value}`);
    }
  }
}
