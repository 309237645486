<template>
  <div
    v-editable="blok"
    class="em-showroom"
    @click="onClick"
    @pointermove="onPointerMove"
    @pointerdown="onPointerDown"
    @pointerup="onPointerUp"
    @pointercancel="onPointerUp"
    @pointerleave="onPointerLeave"
    @pointerenter="onPointerEnter">
    <!-- SEO HEADLINE -->
    <h1 v-if="blok.seo_headline" class="seo-headline">{{ blok.seo_headline }}</h1>

    <EMShowroomStage
      v-if="currentMachine"
      ref="$stage"
      :visibleMachines="state.visibleMachines"
      :featuredMachines="state.featuredMachines"
      :current="currentMachine.slug"
      :currentIndex="state.index"
      :pointerState="state.pointer"
      :filtersToggled="state.filtersToggled"
      @load="onStageLoaded"
      @progress="onStageProgress" />

    <EMShowroomSensors v-if="state.webGlLoaded" :pointerState="state.pointer" @change="onSensorChange" />

    <EMShowroomMedia
      v-if="state.webGlLoaded && currentMachine"
      ref="$media"
      :blok="props.blok"
      :product="currentMachine"
      :productsLength="state.featuredMachines.length"
      :filteredProductsLength="state.visibleMachines.length"
      :pointer="state.pointer"
      :filtersToggled="state.filtersToggled"
      :sensor="state.sensor"
      :currentIndex="state.index"
      :cursorMessages="cursorMessages"
      @toggleFilters="onFilterToggle"
      @next="onNextMachine"
      @prev="onPreviousMachine"
      @product="onProductChange" />

    <EMShowroomFilters
      v-if="state.webGlLoaded && currentMachine"
      :product="currentMachine"
      :isVisible="state.filtersToggled"
      :filtered="filteredMachines"
      :allMachinesLength="state.featuredMachines.length"
      :blok="blok"
      @filtersChange="onFiltersChange"
      @close="onFiltersClose"
      @applyFilters="onFiltersApply" />
  </div>

  <EMShowroomLoader
    v-if="!state.loaderHidden"
    :progress="state.loaderProgress"
    :text="props.blok.loader_texts"
    @remove="() => (state.loaderHidden = true)" />
</template>

<script lang="ts" setup>
import type { ISbStoryData } from 'storyblok-js-client';
import { computed, onMounted, reactive, ref } from 'vue';
import { useStoryblokClient } from '~/composables/useStoryblokClient';
import { useTheme } from '~/composables/useTheme';
import type { DataProductStoryblok, EmShowroomStoryblok } from '~/types/storyblok-generated';
import type { EMShowroomState, ShowroomFilters, ShowroomSensorId } from './EMShowroom.types';
import EMShowroomFilters from './EMShowroomFilters.vue';
import EMShowroomLoader from './EMShowroomLoader.vue';
import EMShowroomMedia from './EMShowroomMedia.vue';
import EMShowroomSensors from './EMShowroomSensors.vue';
import EMShowroomStage from './EMShowroomStage.vue';
import { useLocale } from '#imports';

const props = defineProps<{
  blok: EmShowroomStoryblok;
}>();

const { getStories } = await useStoryblokClient();
const { state: themeState } = useTheme();
const { locale } = useLocale();

const $stage = ref<InstanceType<typeof EMShowroomStage>>();
const $media = ref<InstanceType<typeof EMShowroomMedia>>();

const cursorMessages = computed(() =>
  [props.blok.cursor_message_1, props.blok.cursor_message_2, props.blok.cursor_message_3].filter(m => m !== undefined)
);

/**
 * All machines that are available in the CMS
 */
const allMachines = await getStories<DataProductStoryblok>({
  starts_with: locale,
  content_type: 'data_product',
});

/**
 * The machines that are available in the showroom
 */
const filteredMachines = computed(() => {
  let _filteredMachines = state.featuredMachines;

  // Filter by volume
  if (state.filter.volume !== undefined) {
    _filteredMachines = _filteredMachines.filter(obj => parseInt(obj.content.serving_power) >= state.filter.volume!);
  }

  // Filter by type
  if (state.filter.type) {
    _filteredMachines = _filteredMachines.filter(obj => obj.content.type === state.filter.type);
  }

  // Filter by suitable_industries
  if (state.filter.industry) {
    _filteredMachines = _filteredMachines.filter(obj =>
      obj.content.suitable_industries.includes(
        state.filter.industry as DataProductStoryblok['suitable_industries'][number]
      )
    );
  }

  return _filteredMachines;
});

const currentMachine = computed(() => state.visibleMachines[state.index]);

const machinesInOrder: ISbStoryData<DataProductStoryblok>[] = props.blok.products
  .map(id => allMachines.value.find(obj => obj.uuid === id))
  .filter(product => product !== undefined);

const state = reactive<EMShowroomState>({
  loaderHidden: false,
  loaderProgress: 0,
  webGlLoaded: false,
  sensor: 'none',
  initialized: false,
  filtersToggled: false,
  featuredMachines: machinesInOrder || [],
  visibleMachines: machinesInOrder || [],
  filter: {
    type: undefined,
    industry: undefined,
    volume: undefined,
  },
  index: 0,
  pointer: null,
});

function onNextMachine() {
  state.index += 1;
  if (state.index > state.visibleMachines.length - 1) {
    state.index = 0;
  }
  $stage.value?.nextMachine();
}

function onPreviousMachine() {
  state.index -= 1;
  if (state.index < 0) {
    state.index = state.visibleMachines.length - 1;
  }
  $stage.value?.previousMachine();
}

function onProductChange(productName: string) {
  const machineIndex = state.visibleMachines.findIndex(machine => machine.name === productName);
  state.index = machineIndex;
}

function onStageProgress(progress: number) {
  if (progress > state.loaderProgress) {
    // We only want to increase the progress to prevent flickering
    state.loaderProgress = progress;
  }
}

function onStageLoaded() {
  if (state.pointer) {
    state.pointer.x = window.innerWidth * 0.5;
    state.pointer.y = window.innerHeight * 0.5;
  }
  state.initialized = true;
  state.webGlLoaded = true;
}

function onSensorChange(id: ShowroomSensorId) {
  state.sensor = id;
}

function onFilterToggle() {
  state.filtersToggled = !state.filtersToggled;
}

function onFiltersClose() {
  state.filtersToggled = false;
}

function onFiltersApply() {
  onFiltersClose();
  state.index = 0;
  state.visibleMachines = filteredMachines.value;
}

function onFiltersChange(filter: ShowroomFilters) {
  state.filter = filter;
}

function onPointerDown(e: PointerEvent | MouseEvent) {
  if (state.pointer) {
    state.pointer.isDown = true;
    state.pointer.event = e as PointerEvent;
  }
  $stage.value?.pointerDown(state.pointer!);
}

function onPointerUp(e: PointerEvent | MouseEvent) {
  if (state.pointer) {
    state.pointer.isDown = false;
    state.pointer.event = e as PointerEvent;
  }
  $stage.value?.pointerUp(state.pointer!);
}

function onClick(e: PointerEvent | MouseEvent) {
  if (state.filtersToggled) {
    return;
  }
  const isLeft = (e.target as HTMLElement).classList.contains('em-showroom-sensors__left');
  const isRight = (e.target as HTMLElement).classList.contains('em-showroom-sensors__right');
  if (state.visibleMachines.length === 1) {
    return;
  }
  if (state.sensor === 'left' || isLeft) {
    $media.value?.onPrevClick();
  }
  if (state.sensor === 'right' || isRight) {
    $media.value?.onNextClick();
  }
}

function onPointerMove(e: PointerEvent | MouseEvent) {
  if (!state.initialized) {
    return;
  }
  let x = 0;
  let y = 0;
  if (e instanceof PointerEvent) {
    x = e.clientX;
    y = e.clientY;
  } else {
    x = e.clientX;
    y = e.clientY;
  }
  const pointer = {
    x,
    y,
    isDown: state.pointer?.isDown ?? false,
    isOff: state.pointer?.isOff ?? false,
    event: e as PointerEvent,
  };
  state.pointer = pointer;
}

function onPointerLeave(e: PointerEvent) {
  state.sensor = 'none';
  const pointer = {
    x: state.pointer?.x ?? 0,
    y: state.pointer?.y ?? 0,
    isDown: state.pointer?.isDown ?? false,
    isOff: true,
    event: e,
  };
  state.pointer = pointer;
}

function onPointerEnter(e: PointerEvent) {
  const pointer = {
    x: state.pointer?.x ?? 0,
    y: state.pointer?.y ?? 0,
    isDown: state.pointer?.isDown ?? false,
    isOff: false,
    event: e,
  };
  state.pointer = pointer;
}

onMounted(() => {
  themeState.isDark = true;
});
</script>

<style src="./EMShowroom.scss" lang="scss" scoped />
