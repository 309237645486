<template>
  <div
    ref="$baseForm"
    v-editable="blok"
    class="base-form"
    :class="[
      blok.is_dark ? 'is-dark' : 'is-light',
      {
        'has-overline': isHeadText && blok.head_text?.[0].component !== 'item_quote' && blok.head_text?.[0]?.overline,
      },
    ]">
    <!-- Heading -->
    <div class="head-wrapper">
      <div class="head">
        <div
          class="head-content"
          :class="{
            'is-small':
              blok.head_type === 'text' &&
              blok.head_text?.[0].component === 'item_content-head-reduced' &&
              blok.head_text?.[0]?.head_size === 'small',
            'is-large':
              blok.head_type === 'text' &&
              blok.head_text?.[0].component === 'item_content-head-reduced' &&
              blok.head_text?.[0]?.head_size === 'large',
            statement: blok.head_type === 'text' && blok.head_text?.[0].component === 'item_quote',
          }">
          <!-- Text -->
          <div v-if="isHeadText" class="text">
            <template v-for="(item, index) in blok.head_text" :key="index">
              <ItemContentHead
                v-if="item.component === 'item_content-head-reduced'"
                :blok="item"
                :headSize="item.head_size"
                :isDark="blok.is_dark" />
              <ItemQuote
                v-if="item.component === 'item_quote'"
                :blok="item"
                :isDark="blok.is_dark"
                :isLeftIndented="false" />
            </template>
          </div>

          <!-- Media Responsive -->
          <div v-if="isHeadMedia" class="media">
            <div class="media-wrapper">
              <UtilMediaResponsive
                v-if="!isMediaVideo || (isMediaVideo && isMediaWebm)"
                :image="blok.head_media"
                :video="blok.head_media"
                :videoFallbackForWebm="blok.component === 'fm_newsletter' ? blok.head_video_fallback : undefined"
                :autoplay="true"
                aspectRatio="3/2" />
            </div>
          </div>

          <!-- Contact Box — Desktop -->
          <BaseFormContactBox v-if="blok.component === 'fm_contact'" :blok="blok" class="is-desktop" />
        </div>
      </div>
    </div>

    <!-- Form -->
    <div class="form-container">
      <slot name="form"></slot>
    </div>

    <BaseFormContactBox v-if="blok.component === 'fm_contact'" :blok="blok" class="is-mobile" />

    <!-- Success Modal -->
    <BaseFormSuccessModal ref="$baseFormSuccessModal" :blok="blok" />
  </div>
</template>

<script lang="ts" setup>
import { gsap } from 'gsap';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import ItemContentHead from '~/components/storyblok/item/ItemContentHead/ItemContentHead.vue';
import ItemQuote from '~/components/storyblok/item/ItemQuote/ItemQuote.vue';
import UtilMediaResponsive from '~/components/storyblok/utils/UtilMediaResponsive/UtilMediaResponsive.vue';
import { useScroll } from '~/composables/useScroll';
import { useTransition } from '~/composables/useTransition';
import type { FmContactStoryblok, FmNewsletterStoryblok } from '~/types/storyblok-generated';
import { isVideo, isVideoWebm } from '~/utils/media';
import BaseFormSuccessModal from '../BaseFormSuccessModal/BaseFormSuccessModal.vue';
import BaseFormContactBox from './BaseFormContactBox.vue';

const props = defineProps<{ blok: FmContactStoryblok | FmNewsletterStoryblok }>();

defineExpose({ openSuccessModal });

const { state: scrollState } = useScroll();
const { checkViewPortElements, removeViewPortElements, addViewPortElements, hideElements } = useTransition();

const $baseForm = ref<HTMLElement>();
const $baseFormSuccessModal = ref<InstanceType<typeof BaseFormSuccessModal>>();

const isHeadText = computed(() => !!props.blok.head_text?.length && props.blok.head_type === 'text');
const isHeadMedia = computed(() => !!props.blok.head_media?.filename && props.blok.head_type === 'media');
const isMediaVideo = computed(() => isHeadMedia.value && isVideo(props.blok.head_media?.filename));
const isMediaWebm = computed(() => isHeadMedia.value && isVideoWebm(props.blok.head_media?.filename));

function openSuccessModal() {
  $baseFormSuccessModal.value?.openModal();
}

watch(() => scrollState.top, watchPageScroll);

function watchPageScroll() {
  if (!$baseForm.value) {
    return;
  }
  checkViewPortElements($baseForm.value);
}

function initAnimations() {
  if (!$baseForm.value) {
    return;
  }
  addViewPortElements($baseForm.value.querySelectorAll('.form-container'));
  addViewPortElements($baseForm.value.querySelectorAll('.contact-box'));
  addViewPortElements($baseForm.value.querySelectorAll('.media'));

  void nextTick(() => {
    if (!$baseForm.value) {
      return;
    }
    hideElements($baseForm.value);
    gsap.set($baseForm.value, { autoAlpha: 1 });
    checkViewPortElements($baseForm.value);
  });
}

onMounted(() => {
  initAnimations();
});

onBeforeUnmount(() => {
  if (!$baseForm.value) {
    return;
  }
  removeViewPortElements($baseForm.value);
});
</script>

<style src="./BaseForm.scss" lang="scss" scoped />
