import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

type TweenVars = gsap.TweenVars;
type TweenTarget = gsap.TweenTarget;
type DOMTarget = gsap.DOMTarget;

interface TransitionOptions {
  baseDelay?: number;
  force?: boolean;
  mode?: string;
  offsetMultiplier?: number;
  staggerEach?: number;
  speedMultiplier?: number;
  alphaSpeedMultiplier?: number;
  direction?: string;
}

export function useTransition() {
  const transitionElements: Record<string, Array<any>> = {};

  function getUniqueKey($label: string, id: number) {
    // Generates unique key for animating items
    const key = $label.toLowerCase().replaceAll(' ', '_').trim() + '_' + id;
    return key;
  }

  function checkViewPortElements($parent: HTMLElement, options: TransitionOptions | undefined = undefined) {
    // Test if parent element is in viewport
    if (!inViewport($parent)) {
      return;
    }

    const id = $parent.closest('.core-module')?.getAttribute('id');
    if (!id) {
      return;
    }

    const moduleElements = transitionElements[id];
    watchViewport(moduleElements, options);
  }

  function removeViewPortElements($parent: HTMLElement) {
    // Removes module from index
    const id = $parent.closest('.core-module')?.getAttribute('id');
    if (!id) {
      return;
    }
    if (transitionElements[id]) {
      clearElements(transitionElements[id]);
    }
    // We use a dynamically computed key here. We like to live dangerously.
    delete transitionElements[id];
  }

  function addViewPortElements($elements: NodeListOf<Element>) {
    if (!$elements.length) {
      return;
    }

    const id = $elements[0].closest('.core-module')?.getAttribute('id');
    if (!id) {
      return;
    }

    // Check if animation group is already active
    if (!transitionElements[id]) {
      transitionElements[id] = [];
    }

    // Pushing item to index / removing duplicates
    transitionElements[id].push(...$elements);
    transitionElements[id] = Array.from(new Set(transitionElements[id]));
  }

  function onLinkMouseOver(e: any) {
    const link = e.currentTarget! as Element;

    const icon = link.querySelector('svg');
    const underline = link.querySelector('.link-underline');

    if (underline) gsap.to(underline, { y: '-50%', duration: 0.2 });
    if (icon) gsap.to(icon, { rotation: 45, duration: 0.2 });
  }

  function onLinkMouseOut(e: any) {
    const link = e.currentTarget! as Element;

    const icon = link.querySelector('svg');
    const underline = link.querySelector('.link-underline');

    if (underline) gsap.to(underline, { y: 0, duration: 0.2 });
    if (icon) gsap.to(icon, { rotation: 0, duration: 0.2 });
  }

  function addAdvancedHover(
    parentElement: Element,
    elementHoverCallBack: EventListenerOrEventListenerObject | undefined = undefined
  ) {
    if (parentElement.classList.contains('hover-advanced') && elementHoverCallBack) {
      // No children
      parentElement.addEventListener('mouseenter', elementHoverCallBack, true);
      parentElement.addEventListener('mouseleave', elementHoverCallBack, true);
      return;
    }

    const advancedHoverItems = Array.from(parentElement.querySelectorAll('.hover-advanced'));

    advancedHoverItems.forEach((link: Element) => {
      link.addEventListener('mouseenter', onLinkMouseOver, true);
      link.addEventListener('mouseleave', onLinkMouseOut, true);
    });
  }

  function removeAdvancedHover(
    parentElement: HTMLElement,
    elementHoverCallBack: EventListenerOrEventListenerObject | undefined = undefined
  ) {
    if (!parentElement) {
      return;
    }

    if (parentElement.classList.contains('hover-advanced') && elementHoverCallBack) {
      parentElement.removeEventListener('mouseover', elementHoverCallBack);
      parentElement.removeEventListener('mouseout', elementHoverCallBack);
      return;
    }

    const hoverItems = parentElement.querySelectorAll('.hover-advanced');

    hoverItems.forEach(link => {
      link.removeEventListener('mouseover', onLinkMouseOver);
      link.removeEventListener('mouseout', onLinkMouseOut);
    });
  }

  function staggerTextLines(el: DOMTarget) {
    //Not working properly
    gsap.set(el, { 'font-kerning': 'none' });
    const split = new SplitText(el, { type: 'lines' });
    fadeSlide(split.lines)?.then(() => {
      split.revert();
    });
  }

  function forceTween(el: TweenTarget, vars: TweenVars) {
    if (!el) {
      return;
    }
    // Used to 'overwrite' css transitions
    gsap.set(el, { transition: 'none', willChange: 'transform, opacity' });
    gsap.to(el, vars);
  }

  function hideElements($parent: HTMLElement) {
    const id = $parent.closest('.core-module')?.getAttribute('id');
    if (!id) {
      return;
    }

    if (!transitionElements[id]) {
      return;
    }

    const elements = transitionElements[id].filter(_el => !!_el);
    elements.forEach(el => el.classList.add('transition-init'));
    gsap.set(elements, { autoAlpha: 0 });

    gsap.set($parent, { autoAlpha: 1 });

    return elements;
  }

  // Viewport wrapper method
  function inViewport(el: HTMLElement) {
    if (!el) {
      return false;
    }

    return ScrollTrigger.isInViewport(el, el.offsetHeight * 3 > window.innerHeight ? 0 : 0.33);
  }

  // Viewport callback
  function watchViewport(elements: Array<HTMLElement>, options: TransitionOptions | undefined = undefined) {
    if (!elements) {
      return;
    }

    // Only handle hidden items
    const visibleElements = elements.filter(el => !el.className.includes('transition-done'));

    // All animations are done. return
    if (!visibleElements.length) {
      return;
    }

    let stagger = 0;

    visibleElements.forEach(el => {
      const isInViewport = ScrollTrigger.isInViewport(el, el.offsetHeight * 2 > window.innerHeight ? 0 : 0.05);

      if (isInViewport) {
        el.classList.add('transition-done');

        let delay = Number(el.getAttribute('data-transition-delay')) || 0;

        if (options) {
          delay += options.baseDelay || 0;
          stagger += options.staggerEach || 0;
        }

        fadeSlide(el, {
          baseDelay: delay + stagger,
        });
      }
    });
  }

  function clearElements(elements: Array<HTMLElement>) {
    // Clears tweens elements

    elements.forEach(el => {
      gsap.set(el, { clearProps: 'all', overwrite: true });
      el.classList.remove('transition-done');
    });
  }

  // Fade slide method
  function fadeSlide(
    el: any,
    {
      baseDelay = 0,
      force = true,
      mode = 'in',
      offsetMultiplier = 1,
      staggerEach = 0.15,
      speedMultiplier = 1,
      alphaSpeedMultiplier = 1,
      direction = 'y',
    }: TransitionOptions = {}
  ) {
    if (!el) {
      return;
    }

    const baseOffset = 20;

    // Stager for multiple items
    let stagger;
    if (el.constructor === Array) {
      el = (el).filter(_el => !!_el);

      stagger = {
        each: staggerEach,
      };

      if (!el.length) {
        return;
      }
    }

    // Test if already faded

    // Fresh start
    if (force && mode === 'in')
      gsap.set(el, {
        y: direction === 'y' ? baseOffset * offsetMultiplier : 0,
        x: direction === 'x' ? baseOffset * offsetMultiplier : 0,
        autoAlpha: 0,
        overwrite: true,
      });

    // Removing css transition for smooth tween
    gsap.set(el, {
      force3D: true,
      transition: 'none',
      willChange: 'transform, opacity',
    });

    const targetAlpha = mode === 'in' ? 1 : 0;
    const targetOffset = mode === 'in' ? 0 : baseOffset * offsetMultiplier;

    gsap.to(el, {
      overwrite: true,
      delay: baseDelay,
      autoAlpha: targetAlpha,
      duration: 0.5 * alphaSpeedMultiplier,
      stagger,
    });

    const slideTween = gsap.to(el, {
      delay: baseDelay,
      y: direction === 'y' ? targetOffset : 0,
      x: direction === 'x' ? targetOffset : 0,
      ease: 'expo.out',
      duration: 1.5 * speedMultiplier,
      stagger,
      clearProps: mode === 'in' ? 'transition,willChange' : 'none',
    });

    return slideTween;
  }

  function updateScrollTrigger() {
    ScrollTrigger.refresh(true);

    setTimeout(() => window.dispatchEvent(new Event('scrolltrigger')), 100);
  }

  function morphIcon(animation: string, icon: HTMLElement, isMorphed: boolean) {
    if (animation === 'fi_download') {
      const arrow = icon.querySelectorAll('.fi_download__arrow');
      gsap.to(arrow, { y: isMorphed ? 2 : 0, duration: 0.2 });
    } else if (animation === 'fi_arrow-right-1') {
      gsap.to(icon, { y: isMorphed ? -2 : 0, x: isMorphed ? 2 : 0, duration: 0.2 });
    } else if (animation === 'fi_arrow-right') {
      gsap.to(icon, { x: isMorphed ? 2 : 0, duration: 0.2 });
    } else if (animation === 'menu') {
      const rects = icon.querySelectorAll('path');

      const duration = 0.5;
      const ease = 'expo.out';

      if (isMorphed) {
        gsap.to(rects, { scaleY: 0.7, transformOrigin: 'center center' });

        gsap.to(rects[0], { ease, duration, rotate: -43 });
        gsap.to(rects[0], { ease, duration, scaleX: 1.4, x: -1.3 - 0.5 });
        gsap.to(rects[0], { ease, duration, y: -2.5 });

        gsap.to(rects[1], { ease, duration, rotate: 43 });
        gsap.to(rects[1], { ease, duration, x: -0.5, scaleX: 1.1 });
        gsap.to(rects[1], { ease, duration, y: 2.4 });
      } else {
        gsap.to(rects, { overwrite: true, ease, duration, scaleY: 1 });

        gsap.to(rects[0], { ease, duration, scaleX: 1, x: 0, y: 0 });
        gsap.to(rects[0], { ease, duration, rotate: 0 });

        gsap.to(rects[1], { ease, duration, x: 0, y: 0 });
        gsap.to(rects[1], { ease, duration, rotate: 0 });
      }
    }
  }

  return {
    getUniqueKey,
    fadeSlide,
    morphIcon,
    hideElements,
    forceTween,
    removeAdvancedHover,
    addAdvancedHover,
    staggerTextLines,
    inViewport,
    clearElements,
    addViewPortElements,
    removeViewPortElements,
    checkViewPortElements,
    updateScrollTrigger,
  };
}
