<template>
  <div :class="['util-richtext', { 'is-dark': isDark }]">
    <BaseRichtext
      v-editable="blok"
      :text="blok.text"
      :isDark="isDark"
      :isProseEnabled="isProseEnabled"
      :isProseParagraphsEnabled="isProseParagraphsEnabled"
      :preventInlineIcons="preventInlineIcons"
      :textSize="textSize"
      :class="[
        { 'is-dark': isDark },
        isProseEnabled
          ? {
              'text-medium-regular': textSize === 'medium',
              'text-large-regular': textSize === 'large',
            }
          : undefined,
      ]" />
    <div v-if="actions?.length" class="actions">
      <template v-for="(action, index) in actions" :key="index">
        <UtilLinkItemIcon v-if="action.component === 'util_link-item-icon'" :blok="action" :isDark="isDark" />
        <UtilLinkItemSubtext v-if="action.component === 'util_link-item-subtext'" :blok="action" :isDark="isDark" />
        <UtilButton v-if="action.component === 'util_button'" :blok="action" :isBackgroundDark="isDark" />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import BaseRichtext from '~/components/base/BaseRichtext/BaseRichtext.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import UtilLinkItemIcon from '~/components/storyblok/utils/UtilLinkItemIcon/UtilLinkItemIcon.vue';
import UtilLinkItemSubtext from '~/components/storyblok/utils/UtilLinkItemSubtext/UtilLinkItemSubtext.vue';
import type {
  UtilRichtextLightStoryblok,
  UtilRichtextReducedStoryblok,
  UtilRichtextStoryblok,
} from '~/types/storyblok-generated';

const props = withDefaults(
  defineProps<{
    blok: UtilRichtextStoryblok | UtilRichtextReducedStoryblok | UtilRichtextLightStoryblok;
    isDark?: boolean;
    isProseEnabled?: boolean;
    isProseParagraphsEnabled?: boolean;
    textSize?: 'large' | 'medium';
    // Prevents hydrating links with inline icons (used for cm media...)
    preventInlineIcons?: boolean;
  }>(),
  {
    isProseEnabled: true,
    isProseParagraphsEnabled: true,
    textSize: 'large',
  }
);

const actions = computed(() =>
  props.blok.component !== 'util_richtext-light' ? [...(props.blok.links ?? []), ...(props.blok.buttons ?? [])] : []
);
</script>

<style src="./UtilRichtext.scss" lang="scss" scoped />
