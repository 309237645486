import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "38",
  height: "12",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#B3BABF",
      d: "M16.392 11.827h-3.004L15.267.211h3.003zM27.28.495A7.4 7.4 0 0 0 24.588 0c-2.967 0-5.055 1.582-5.068 3.843-.025 1.669 1.495 2.595 2.632 3.152 1.162.568 1.557.939 1.557 1.446-.011.778-.939 1.137-1.803 1.137-1.2 0-1.842-.185-2.819-.618l-.395-.186-.42 2.608c.704.32 2.002.606 3.35.618 3.151 0 5.203-1.557 5.227-3.967.012-1.322-.79-2.336-2.521-3.164-1.05-.531-1.694-.89-1.694-1.433.012-.495.544-1.001 1.73-1.001a5.1 5.1 0 0 1 2.237.445l.272.123zM31.273 7.712l1.199-3.25c-.013.025.247-.68.395-1.112l.21 1s.569 2.781.692 3.362zM34.98.211h-2.323c-.717 0-1.26.21-1.57.964l-4.462 10.652h3.152l.63-1.742h3.857c.086.407.358 1.742.358 1.742h2.781zM10.88.21 7.938 8.133l-.321-1.606c-.544-1.854-2.25-3.868-4.153-4.87l2.694 10.159h3.176L14.056.21z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#E9EBED",
      d: "M5.207.21H.374l-.05.236c3.77.964 6.267 3.287 7.293 6.08l-1.05-5.339C6.392.445 5.861.235 5.206.211"
    }, null, -1)
  ])))
}
export default { render: render }