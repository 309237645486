export enum Locale {
  EN_COM = 'en_com',
  DE_DE = 'de_de',
  DE_AT = 'de_at',
  EN_UK = 'en_uk',
  NL_BE = 'nl_be',
  FR_BE = 'fr_be',
  FR_FR = 'fr_fr',
  NL_NL = 'nl_nl',
  ES_ES = 'es_es',
  JA_JP = 'ja_jp',
  EN_US = 'en_us',
  ZH_CN = 'zh_cn',
  FR_CH = 'fr_ch',
  DE_CH = 'de_ch',
}

export enum LocaleHreflang {
  EN_COM = 'en',
  DE_DE = 'de-DE',
  DE_AT = 'de-AT',
  EN_UK = 'en-GB',
  NL_BE = 'nl-BE',
  FR_BE = 'fr-BE',
  FR_FR = 'fr-FR',
  NL_NL = 'nl-NL',
  ES_ES = 'es-ES',
  JA_JP = 'ja-JP',
  EN_US = 'en-US',
  ZH_CN = 'zh-CN',
  FR_CH = 'fr-CH',
  DE_CH = 'de-CH',
}

export enum Language {
  EN = 'en',
  ES = 'es',
  NL = 'nl',
  DE = 'de',
  FR = 'fr',
  JA = 'ja',
  ZH = 'zh',
}

export enum MarketLocale {
  COM = 'com',
  UK = 'uk',
  NL = 'nl',
  US = 'us',
  DE = 'de',
  FR = 'fr',
  BE = 'be',
  ES = 'es',
  JP = 'jp',
  CH = 'ch',
  AT = 'at',
  CN = 'cn',
}

export const LOCALE_TO_MARKET_LOCALE_MAP: Record<Locale, MarketLocale> = {
  [Locale.EN_COM]: MarketLocale.COM,
  [Locale.DE_DE]: MarketLocale.DE,
  [Locale.DE_AT]: MarketLocale.AT,
  [Locale.EN_UK]: MarketLocale.UK,
  [Locale.NL_BE]: MarketLocale.BE,
  [Locale.FR_BE]: MarketLocale.BE,
  [Locale.FR_FR]: MarketLocale.FR,
  [Locale.NL_NL]: MarketLocale.NL,
  [Locale.ES_ES]: MarketLocale.ES,
  [Locale.JA_JP]: MarketLocale.JP,
  [Locale.EN_US]: MarketLocale.US,
  [Locale.ZH_CN]: MarketLocale.CN,
  [Locale.FR_CH]: MarketLocale.CH,
  [Locale.DE_CH]: MarketLocale.CH,
};

export const LOCALE_HAS_SHOP: Record<Locale, boolean> = {
  [Locale.EN_COM]: false,
  [Locale.DE_DE]: true,
  [Locale.DE_AT]: false,
  [Locale.EN_UK]: false,
  [Locale.NL_BE]: false,
  [Locale.FR_BE]: false,
  [Locale.FR_FR]: false,
  [Locale.NL_NL]: false,
  [Locale.ES_ES]: false,
  [Locale.JA_JP]: false,
  [Locale.EN_US]: false,
  [Locale.ZH_CN]: false,
  [Locale.FR_CH]: false,
  [Locale.DE_CH]: false,
};

export const LOCALE_TO_LANGUAGE_MAP: Record<Locale, Language> = {
  [Locale.EN_COM]: Language.EN,
  [Locale.DE_DE]: Language.DE,
  [Locale.DE_AT]: Language.DE,
  [Locale.EN_UK]: Language.EN,
  [Locale.NL_BE]: Language.NL,
  [Locale.FR_BE]: Language.FR,
  [Locale.FR_FR]: Language.FR,
  [Locale.NL_NL]: Language.NL,
  [Locale.ES_ES]: Language.ES,
  [Locale.JA_JP]: Language.JA,
  [Locale.EN_US]: Language.EN,
  [Locale.ZH_CN]: Language.ZH,
  [Locale.FR_CH]: Language.FR,
  [Locale.DE_CH]: Language.DE,
};

export const LANGUAGE_TO_TRANSLATIONS_MAP: Record<Language, string> = {
  [Language.EN]: 'English',
  [Language.ES]: 'Español',
  [Language.NL]: 'Dutch',
  [Language.DE]: 'Deutsch',
  [Language.FR]: 'Français',
  [Language.JA]: '日本語',
  [Language.ZH]: '中文',
};

export const LOCALE_TO_HREFLANG_MAP: Record<Locale, LocaleHreflang> = {
  [Locale.DE_AT]: LocaleHreflang.DE_AT,
  [Locale.DE_CH]: LocaleHreflang.DE_CH,
  [Locale.DE_DE]: LocaleHreflang.DE_DE,
  [Locale.EN_COM]: LocaleHreflang.EN_COM,
  [Locale.EN_UK]: LocaleHreflang.EN_UK,
  [Locale.EN_US]: LocaleHreflang.EN_US,
  [Locale.ES_ES]: LocaleHreflang.ES_ES,
  [Locale.FR_BE]: LocaleHreflang.FR_BE,
  [Locale.FR_CH]: LocaleHreflang.FR_CH,
  [Locale.FR_FR]: LocaleHreflang.FR_FR,
  [Locale.JA_JP]: LocaleHreflang.JA_JP,
  [Locale.NL_BE]: LocaleHreflang.NL_BE,
  [Locale.NL_NL]: LocaleHreflang.NL_NL,
  [Locale.ZH_CN]: LocaleHreflang.ZH_CN,
};

export const MARKET_TO_TRANSLATIONS_MAP: Record<MarketLocale, string> = {
  [MarketLocale.COM]: 'International',
  [MarketLocale.UK]: 'United Kingdom',
  [MarketLocale.NL]: 'Nederland',
  [MarketLocale.US]: 'United States',
  [MarketLocale.DE]: 'Deutschland',
  [MarketLocale.FR]: 'France',
  [MarketLocale.BE]: 'Belgique',
  [MarketLocale.ES]: 'España',
  [MarketLocale.JP]: '日本',
  [MarketLocale.CH]: 'Switzerland',
  [MarketLocale.AT]: 'Österreich',
  [MarketLocale.CN]: '中国',
};
