<template>
  <div ref="$el" class="cart">
    <!-- Mobile modal -->
    <ClientOnly>
      <BaseModal
        v-if="isMobile"
        :isDark="true"
        :isOpen="isOpen"
        :isNavigation="true"
        animationType="custom"
        @close="onMobileModalClose">
        <div class="mobile-modal">
          <!-- Logo -->
          <div class="logo">
            <BaseImage
              v-if="props.headerBlok.top_center_logo_white"
              :image="props.headerBlok.top_center_logo_white"
              :breakpointsWidthMap="{ 0: 26, 1024: 32 }" />
          </div>

          <!-- List -->
          <BMShoppingCartList :inModal="true" :isOpen="headerState.cartOpen" :blok="blok" />
        </div>
      </BaseModal>

      <!-- Desktop flyout -->
      <div v-else class="container-full">
        <div class="flyout" :style="{ ['height']: flyoutHeight + 'px' }">
          <!-- List -->
          <BMShoppingCartList
            :isOpen="headerState.cartOpen"
            :blok="blok"
            @close="() => (headerState.cartOpen = false)"
            @update="updateListHeight" />
        </div>
      </div>
    </ClientOnly>
  </div>
</template>
<script setup lang="ts">
import { onClickOutside as _onClickOutside } from '@vueuse/core';
import gsap from 'gsap';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { BaseImage, BaseModal } from '~/components/base';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useHeader } from '~/composables/useHeader';
import { useShoppingCart } from '~/composables/useShoppingCart';
import { useTransition } from '~/composables/useTransition';
import type { BmHeaderRichStoryblok, BmShoppingCartStoryblok } from '~/types/storyblok-generated';
import BMShoppingCartList from './BMShoppingCartList.vue';

const props = defineProps<{
  blok: BmShoppingCartStoryblok;
  headerBlok: BmHeaderRichStoryblok;
}>();

const { state: headerState } = useHeader();
const { state: breakpointState } = useBreakpoint();
const { sync: syncCart } = useShoppingCart();
const { fadeSlide } = useTransition();

const $el = ref<HTMLElement>();
const isOpen = ref(false);
const flyoutHeight = ref(50000);

const isMobile = computed(() => {
  return breakpointState.isPhone;
});

watch(
  () => headerState.cartOpen,
  () => {
    isOpen.value = headerState.cartOpen;
    const flyouts = $el.value?.querySelectorAll<HTMLElement>('.flyout');
    if (!flyouts) {
      return;
    }
    fadeSlide(Array.from(flyouts), {
      mode: headerState.cartOpen ? 'in' : 'out',
      direction: 'x',
      speedMultiplier: 0.25,
      alphaSpeedMultiplier: 0.25,
      offsetMultiplier: -5,
    });
  }
);

watch(
  () => isMobile.value,
  () => {
    const oldState = headerState.cartOpen;

    if (isMobile.value) {
      removeClickOutside();
    } else {
      void nextTick(addClickOutside);
    }

    if (!oldState) {
      return;
    }
    headerState.cartOpen = false;

    void nextTick(() => {
      headerState.cartOpen = true;
    });
  }
);

function updateListHeight(_maxScroll: number, contentHeight: number) {
  const headerHeight = $el.value?.querySelector<HTMLElement>('.cart-list-header')?.offsetHeight ?? 0;
  const footerHeight = $el.value?.querySelector<HTMLElement>('.cart-list-footer')?.offsetHeight ?? 0;
  const fixedHeight = headerHeight + footerHeight + 45;
  const minHeight = 400;
  const targetHeight = contentHeight + fixedHeight > minHeight ? contentHeight + fixedHeight : minHeight;
  gsap.to(flyoutHeight, {
    duration: 0.2,
    value: targetHeight,
  });
}

function onMobileModalClose() {
  isOpen.value = headerState.cartOpen = false;
}

let removeClickOutside: () => void;

function addClickOutside() {
  if (removeClickOutside) {
    removeClickOutside();
  }
  removeClickOutside = _onClickOutside($el.value?.querySelector<HTMLElement>('.flyout'), onClickOutside);
}

function onClickOutside(e: PointerEvent) {
  if (
    !headerState.cartOpen ||
    (e.target as HTMLElement).classList.contains('debug-trigger') ||
    (e.target as HTMLElement).classList.contains('is-cart')
  ) {
    return;
  }
  headerState.cartOpen = false;
}

onMounted(async () => {
  window.addEventListener('private-content-loaded', syncCart);
  await nextTick();
  await syncCart();
  if (!isMobile.value) {
    addClickOutside();
  }
});
</script>

<style src="./BMShoppingCart.scss" lang="scss" scoped />
