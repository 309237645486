import { gsap } from 'gsap';
import * as THREE from 'three';
import type { AppStateInstance } from '~/webglApp/core/appState';
import type { AssetManagerInstance } from '~/webglApp/core/assetManager';
import type { EngineInstance } from '~/webglApp/core/engine';
import { BoundingBoxMaterial } from '~/webglApp/materials/boundingBoxMaterial/boundingBoxMaterial';
import { sendEvent } from '~/webglApp/utils/showroomEvents';
import utils from '~/webglApp/utils/utils';
import { ShowroomEvent, type MachineConfig } from '~/webglApp/webGlApp.types';
import type { BlueNoiseInstance } from '../blueNoise/blueNoise';
import MachineShadow from './machineShadow/MachineShadow';

class Machine {
  appState: AppStateInstance;
  assetManager: AssetManagerInstance;
  engine: EngineInstance;
  name: string;
  container = new THREE.Object3D();
  mesh: THREE.Object3D | null = null;
  meshShadow;
  envIntensity = 1;
  materials: THREE.MeshStandardMaterial[] = [];
  v1 = new THREE.Vector3();
  v2 = new THREE.Vector3();
  offset = 0.575;
  emissivesIntensity: { value: number } = { value: 0 };
  emissivesEnabled = false;
  emissivesInTransition = false;
  isActive: boolean = false;

  constructor(
    appState: AppStateInstance,
    assetManager: AssetManagerInstance,
    engine: EngineInstance,
    machineConfig: MachineConfig,
    machineOrderIndex: number,
    isCopy = false
  ) {
    this.appState = appState;
    this.assetManager = assetManager;
    this.engine = engine;
    const { name, modelPath, shadow, zoomLevelByScreenSize } = machineConfig;
    this.name = isCopy === true ? name + '-copy' : name;
    this.hide();

    assetManager.addToLoader(modelPath, loadedAsset => {
      if (!('scene' in loadedAsset)) {
        return;
      }
      this.mesh = loadedAsset.scene.children[0];
      this.mesh.userData.name = this.name;
      this.mesh.userData.orderIndex = machineOrderIndex;
      this.mesh.userData.zoomValue =
        zoomLevelByScreenSize[utils.getScreenSizeCategoryByViewportWidth(this.appState.viewport.width).name];
    });

    this.meshShadow = new MachineShadow(shadow, assetManager);
  }

  init(envTexture: THREE.Texture, blueNoise: BlueNoiseInstance, isFirstMachine: boolean) {
    this.initMeshes(envTexture);
    this.initBoundingBox();
    this.meshShadow.init(blueNoise);
    if (this.mesh) {
      this.mesh.add(this.meshShadow.container);
    }
    if (isFirstMachine) {
      this.emissivesIntensity.value = 1;
      this.emissivesEnabled = true;
    }
    this.materials.forEach(element => {
      element.envMapIntensity = Math.max(this.emissivesIntensity.value * this.envIntensity, 0.1);
      element.emissiveIntensity = this.emissivesIntensity.value;
    });
  }

  private initMeshes(envTexture: THREE.Texture) {
    if (!this.mesh) {
      console.error(`Mesh is not loaded on  ${this.name}`);
      return;
    }
    this.mesh.traverse(item => {
      if ('isMesh' in item && item.isMesh) {
        const typedItem = item as THREE.Mesh<THREE.BufferGeometry, THREE.MeshStandardMaterial>;
        if (typedItem.material) {
          typedItem.material.envMap = envTexture;
          typedItem.material.envMapIntensity = this.emissivesIntensity.value;
          typedItem.material.depthWrite = true;
          if (typedItem.material.emissiveMap) {
            typedItem.material.emissive.set('#ffffff');
            typedItem.material.emissiveIntensity = this.emissivesIntensity.value;
          }
          this.materials.push(typedItem.material);
        }
        typedItem.castShadow = true;
        typedItem.receiveShadow = true;
      }
    });

    // Returns new Set/Array with removed duplicates
    this.materials = [...new Set(this.materials)];

    this.materials.forEach(material => {
      material.onBeforeCompile = shader => {
        shader.fragmentShader = shader.fragmentShader.replace(
          'vec3 totalSpecular = reflectedLight.directSpecular + reflectedLight.indirectSpecular;',
          'vec3 totalSpecular = reflectedLight.directSpecular * roughnessFactor + reflectedLight.indirectSpecular;'
        );
      };
      material.needsUpdate = true;
    });

    this.container.add(this.mesh);
  }

  private initBoundingBox() {
    if (!this.mesh) {
      return;
    }
    this.calculateBoundingBox(this.mesh, this.v1, this.v2);
    if (this.appState.IS_DEBUG) {
      this.createDebugBoxMesh(this.mesh, new BoundingBoxMaterial());
    }
  }

  private calculateBoundingBox(object: THREE.Object3D, vectorSize: THREE.Vector3, vectorCenter: THREE.Vector3) {
    object.updateMatrixWorld(true);
    const box = new THREE.Box3().setFromObject(object);
    const boxSize = box.getSize(vectorSize);
    const center = box.getCenter(vectorCenter);
    object.userData.box = box;
    object.userData.boxSize = boxSize;
    object.userData.center = center;
  }

  private createDebugBoxMesh(mesh: THREE.Object3D, material: THREE.Material) {
    const boundingBoxMesh = new THREE.Mesh(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      new THREE.BoxGeometry(mesh.userData.boxSize.x, mesh.userData.boxSize.y, mesh.userData.boxSize.z),
      material
    );
    mesh.userData.boundingBoxMesh = boundingBoxMesh;
    mesh.userData.boundingBoxMesh.position.copy(mesh.userData.center);
    this.container.add(mesh.userData.boundingBoxMesh as typeof boundingBoxMesh);
  }

  show() {
    this.isActive = true;
    if (this.mesh) {
      this.mesh.visible = true;
    }
  }

  hide() {
    this.isActive = false;
    if (this.mesh) {
      this.mesh.visible = false;
    }
  }

  resize(width: number) {
    this.offset = utils.getOffsetByViewportWidth(width);
  }

  destroy() {
    if (this.mesh) {
      this.container.remove(this.mesh);
    }
  }

  private updateEmissive(isEnabled: boolean) {
    if (this.emissivesEnabled !== isEnabled) {
      this.emissivesEnabled = isEnabled;
      this.emissivesInTransition = true;
      gsap.to(this.emissivesIntensity, {
        value: isEnabled ? 1 : 0,
        duration: 0.5,
        onComplete: () => {
          this.emissivesInTransition = false;
          this.materials.forEach(element => {
            element.envMapIntensity = Math.max(isEnabled ? 1 : 0 * this.envIntensity, 0.1);
            element.emissiveIntensity = isEnabled ? 1 : 0;
          });
        },
      });
    }
  }

  align(index: number) {
    if (!this.mesh || (index < 0 && index > 2)) {
      // This only runs when necessary
      return;
    }

    const visibleHalfWidthOfCamera = this.appState.visibleWidthOfCamera * 0.5;
    const boxSizeHalfWidth = this.mesh.userData.boxSize.x * 0.5;
    const offsetDifference = boxSizeHalfWidth - this.offset;
    let targetPositionX;

    // Align to left screen
    if (index === 0) {
      targetPositionX = 0 - visibleHalfWidthOfCamera - offsetDifference;
      this.mesh.position.set(targetPositionX, 0, 0);
    }
    // Align to center
    else if (index === 1) {
      targetPositionX = 0;
      this.mesh.position.set(targetPositionX, 0, 0);
    }
    // Align to right
    else if (index === 2) {
      targetPositionX = visibleHalfWidthOfCamera + offsetDifference;
      this.mesh.position.set(targetPositionX, 0, 0);
    }

    this.updateEmissive(index === 1);
  }

  alignTween(index: number) {
    if (!this.mesh) {
      return;
    }

    if (index > 0 && index < 2) {
      // This only runs when necessary
      return;
    }

    let positionPromise: Promise<void> = new Promise(resolve => {
      resolve();
    });
    const boxSizeHalfWidth = this.mesh.userData.boxSize.x * 0.5;
    const offsetDifference = boxSizeHalfWidth - this.offset;
    const visibleHalfWidthOfCamera = this.appState.visibleWidthOfCamera * 0.5;

    // Align to left screen
    if (index === 0) {
      const targetPositionX = 0 - visibleHalfWidthOfCamera - offsetDifference;
      positionPromise = new Promise(resolve => {
        if (this.mesh) {
          gsap.to(this.mesh.position, {
            x: targetPositionX,
            duration: 0.25,
            ease: 'power2.out',
            onComplete: resolve,
          });
        }
      });
    }
    // Align to center
    else if (index === 1) {
      const targetPositionX = 0;
      positionPromise = new Promise(resolve => {
        if (this.mesh) {
          gsap.to(this.mesh.position, {
            x: targetPositionX,
            duration: 0.25,
            ease: 'power2.out',
            onComplete: resolve,
          });
        }
      });
    }
    // Align to right
    else if (index === 2) {
      const targetPositionX = visibleHalfWidthOfCamera + offsetDifference;
      positionPromise = new Promise(resolve => {
        if (this.mesh) {
          gsap.to(this.mesh.position, {
            x: targetPositionX,
            duration: 0.25,
            ease: 'power2.out',
            onComplete: resolve,
          });
        }
      });
    }

    this.updateEmissive(index === 1);
    return positionPromise;
  }

  setPosition(position: THREE.Vector3) {
    this.mesh?.position.copy(position);
  }

  // Move machine to new position
  // With respect to their bounding box size width and defined offset
  moveToLeft(index: number) {
    if (!this.mesh) {
      return;
    }
    const boxSizeHalfWidth = this.mesh.userData.boxSize.x * 0.5;
    const offsetDifference = boxSizeHalfWidth - this.offset;
    let targetPositionX;
    const visibleHalfWidthOfCamera = this.appState.visibleWidthOfCamera * 0.5;
    let duration = window.innerWidth <= 480 ? 0.7 : 1;

    // 0 = current machine at left
    // 1 = current machine at center
    // 2 = current machine at right
    // 3 = current machine at right outside

    // Align to left screen
    if (index === 1) {
      targetPositionX = 0 - visibleHalfWidthOfCamera - offsetDifference;
    }
    // Align to center
    else if (index === 2) {
      targetPositionX = 0;
    }
    // Align to right
    else if (index === 3) {
      targetPositionX = visibleHalfWidthOfCamera + offsetDifference;
    }
    // Out of view
    else {
      targetPositionX = this.mesh.position.x - visibleHalfWidthOfCamera;
      duration = 0;
    }

    const positionPromise = new Promise(resolve => {
      if (!this.mesh) {
        resolve(false);
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-this-alias -- self is used to access class properties
      const self = this;

      gsap.to(this.mesh.position, {
        x: targetPositionX,
        duration,
        ease: 'expo.out',
        onUpdate: function () {
          // Main item
          if (targetPositionX === 0) {
            sendEvent(self.appState.webGlCanvas, ShowroomEvent.MachinesMoveProgress, {
              currentMachineName: self.mesh?.userData.name,
              progress: this.progress(),
            });
          }
        },
        onComplete: () => {
          resolve(true);
        },
      });
    });

    this.updateEmissive(index === 2);
    return Promise.resolve(positionPromise);
  }

  moveToRight(index: number) {
    if (!this.mesh) {
      return;
    }
    const boxSizeHalfWidth = this.mesh.userData.boxSize.x * 0.5;
    const offsetDifference = boxSizeHalfWidth - this.offset;
    let targetPositionX;
    const visibleHalfWidthOfCamera = this.appState.visibleWidthOfCamera * 0.5;
    let duration = window.innerWidth <= 480 ? 0.7 : 1;

    // 0 = current machine at left
    // 1 = current machine at center
    // 2 = current machine at right
    // 3 = current machine at right outside

    // Align to left
    if (index === 0) {
      targetPositionX = 0 - visibleHalfWidthOfCamera - offsetDifference;
    }
    // Align to center
    else if (index === 1) {
      targetPositionX = 0;
    }
    // Align to right
    else if (index === 2) {
      targetPositionX = visibleHalfWidthOfCamera + offsetDifference;
    }
    // Out of view
    else {
      targetPositionX = this.mesh.position.x + visibleHalfWidthOfCamera;
      duration = 0;
    }

    const positionPromise = new Promise(resolve => {
      if (!this.mesh) {
        resolve(false);
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-this-alias -- self is used to access class properties
      const self = this;

      gsap.to(this.mesh.position, {
        x: targetPositionX,
        duration,
        ease: 'expo.out',
        onUpdate: function () {
          // Main item
          if (targetPositionX === 0) {
            sendEvent(self.appState.webGlCanvas, ShowroomEvent.MachinesMoveProgress, {
              currentMachineName: self.mesh?.userData.name,
              progress: this.progress(),
            });
          }
        },
        onComplete: () => {
          // We hide machine if it is outside of the screen
          this.isActive = index >= 0 && index <= 2;
          resolve(true);
        },
      });
    });

    this.updateEmissive(index === 1);
    return Promise.resolve(positionPromise);
  }

  update() {
    this.container.visible = this.isActive;
    if (this.isActive && this.emissivesInTransition) {
      this.materials.forEach(element => {
        element.envMapIntensity = Math.max(this.emissivesIntensity.value * this.envIntensity, 0.1);
        element.emissiveIntensity = this.emissivesIntensity.value;
      });
    }
  }
}

export default Machine;
