export type ModuleAppearance = 'slide' | 'slide-desktop' | 'sticky' | 'normal' | 'widget-overlay';

export interface ModuleSettings {
  appearance?: ModuleAppearance;
  buffer?: boolean;
  scrollMultiplier?: number;
  isAlwaysDark?: boolean;
  removePadding?: boolean;
  removePaddingTop?: boolean;
  marginMultiplier?: number; // Needed to overlap the next module if set
  standalone?: boolean; // Prevents the header from sliding up. used in em_home and em_showroom (standalone modules)
  hideFooter?: boolean; // Hides footer (em home only)
  hasFullHeight?: boolean; // Required for horizontal scrolling module
}

export const moduleSettingsMap: Record<string, ModuleSettings> = {
  cm_highlights: {
    appearance: 'slide',
    buffer: true,
    scrollMultiplier: 2,
    removePadding: true,
    marginMultiplier: 3,
  },
  ['cm_text-highlight']: {
    appearance: 'slide',
    buffer: true,
    scrollMultiplier: 0.3,
    removePadding: true,
  },
  ['cm_stage-product-rich']: {
    appearance: 'sticky',
    scrollMultiplier: 2,
    isAlwaysDark: true,
    removePadding: true,
  },
  ['cm_horizontal-scrolling']: {
    appearance: 'slide-desktop',
    buffer: false,
    scrollMultiplier: 3,
    marginMultiplier: 4,
    removePadding: true,
    hasFullHeight: true,
  },
  cm_stage: {
    removePaddingTop: true,
  },
  em_home: {
    appearance: 'slide-desktop',
    buffer: false,
    standalone: true,
    hideFooter: true,
    scrollMultiplier: 3,
    removePadding: true,
    isAlwaysDark: true,
  },
  em_showroom: {
    appearance: 'slide-desktop',
    buffer: false,
    standalone: true,
    removePadding: true,
    isAlwaysDark: true,
  },
  ['em_product-widget']: {
    appearance: 'widget-overlay',
    removePadding: true,
  },
};
