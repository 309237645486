<template>
  <div class="modal">
    <p class="heading-sub-medium">{{ t('EMMapModal.headline') }}</p>
    <p class="text text-large-regular">
      {{ t('EMMapModal.description') }}
    </p>
    <div class="buttons-container">
      <UtilButton
        :blok="{
          component: 'util_button',
          theme: 'secondary',
          text: t('EMMapModal.disagreeButtonLabel'),
          size: 'large',
          _uid: 'disagree',
        }"
        :isBackgroundDark="true"
        @click="() => emit('reject')" />
      <UtilButton
        :blok="{
          component: 'util_button',
          theme: 'primary',
          text: t('EMMapModal.agreeButtonLabel'),
          size: 'large',
          _uid: 'agree',
        }"
        @click="() => emit('accept')" />
    </div>
    <button class="close-icon" @click="() => emit('reject')">
      <BaseIcon name="fi_x" :size="16" color="inherit" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import { BaseIcon } from '~/components/base';
import UtilButton from '../../utils/UtilButton/UtilButton.vue';

const emit = defineEmits<{
  (e: 'reject'): void;
  (e: 'accept'): void;
}>();

const { t } = useI18n();
</script>

<style src="./EMMapModal.scss" lang="scss" scoped />
