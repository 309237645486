<template>
  <div v-editable="blok" class="cm-stage-product-rich">
    <div class="wrapper">
      <div class="stage" :class="{ 'is-left': isLeftAligned }">
        <!-- Texts -->
        <div class="text-wrapper" :class="isLeftAligned ? 'is-left' : 'is-center'">
          <div class="headings">
            <div class="lines">
              <p v-if="blok.overline" ref="$overline" class="overline-text" :class="{ 'is-invisible': !isHeroLoaded }">
                {{ blok.overline }}
              </p>
              <BaseRichtext
                ref="$headline"
                class="headline heading-main-large"
                :text="blok.headline"
                :isProseEnabled="false"
                :class="{ 'is-invisible': !isHeroLoaded }" />
              <p v-if="blok.subline" ref="$subline" class="subline" :class="{ 'is-invisible': !isHeroLoaded }">
                {{ blok.subline }}
              </p>
            </div>

            <!-- CTA -->
            <UtilButton
              v-if="blok.button?.length"
              ref="$cta"
              :blok="blok.button[0]"
              class="cta"
              :class="{ 'is-invisible': !isHeroLoaded }" />
          </div>

          <BaseRichtext
            ref="$claim"
            :class="blok.claim_style === 'headline' ? 'is-headline' : 'is-claim'"
            :text="blok.claim"
            :isProseEnabled="false" />
        </div>

        <!-- Card intro -->
        <div class="card-container">
          <div class="container-full">
            <div
              v-if="blok.card_intro_logo?.filename"
              ref="$cardIntro"
              class="card card-intro"
              :class="{ 'is-invisible': !isHeroLoaded }">
              <BaseImage
                v-if="blok.card_intro_logo?.filename"
                :image="blok.card_intro_logo"
                :breakpointsWidthMap="{ 0: 32 }" />
              <span class="card-intro-text">{{ blok.card_intro_text }}</span>
            </div>
            <!-- Card claim -->
            <BaseLink
              v-if="blok.card_claim_image?.filename"
              ref="$cardClaim"
              :link="blok.card_claim_link"
              class="card card-claim">
              <BaseImage :image="blok.card_claim_image" :breakpointsWidthMap="{ 0: 88 }" />
            </BaseLink>
          </div>
        </div>

        <!-- Hero -->
        <div ref="$hero" class="hero">
          <UtilVideoScrub
            v-if="blok.video_scrub?.length"
            :blok="blok.video_scrub[0]"
            :progress="Math.min(state.progress * 1.2, 1)"
            :mediaSource="videoMediaSource"
            :preloadPoster="state.active"
            @load="onHeroLoaded" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import UtilVideoScrub from '~/components/storyblok/utils/UtilVideoScrub/UtilVideoScrub.vue';
import { BaseRichtext, BaseImage, BaseLink } from '~/components/base';
import { useScroll } from '~/composables/useScroll';
import { useTransition } from '~/composables/useTransition';
import type { CmStageProductRichStoryblok } from '~/types/storyblok-generated';
import type { ModuleWrapperState } from '~/types/utils';
import { useHead } from '#imports';

interface CustomHTMLElement extends HTMLElement {
  // This property exists, but is Vue internal.
  // We should not use it, but it is needed here.
  $el?: HTMLElement;
}

const props = defineProps<{
  blok: CmStageProductRichStoryblok;
  state: ModuleWrapperState;
}>();

const { resetWindowScroll } = useScroll();
const { fadeSlide } = useTransition();

const isLeftAligned = props.blok.alignment === 'left';

const $overline = ref<CustomHTMLElement>();
const $headline = ref<CustomHTMLElement>();
const $subline = ref<CustomHTMLElement>();
const $cta = ref<CustomHTMLElement>();
const $hero = ref<CustomHTMLElement>();
const $claim = ref<CustomHTMLElement>();
const $cardIntro = ref<CustomHTMLElement>();
const $cardClaim = ref<CustomHTMLElement>();
const isHeroLoaded = ref(false);
const isHeadlineVisible = ref(true);
const isClaimVisible = ref(true);
const windowSize = ref(typeof window !== 'undefined' ? { width: window.innerWidth, height: window.innerHeight } : null);
const videoMediaSource = computed<'mobile' | 'tablet' | 'desktop'>(() => {
  const isLandscape = !windowSize.value || windowSize.value.width > windowSize.value.height;
  if (isLandscape) {
    // Desktop is the only video that supports landscape
    return 'desktop';
  }

  if (windowSize.value && windowSize.value.width < 1024) {
    return 'mobile';
  }
  if (windowSize.value && windowSize.value.width < 1400) {
    return 'tablet';
  }
  return 'desktop';
});
let fadeInTimeout: ReturnType<typeof setTimeout>;

// We need a different theme color here, since the background is just black (#000)
useHead({
  meta: [
    {
      name: 'theme-color',
      content: '#000',
    },
  ],
});

watch(
  () => props.state.progress,
  value => {
    if (value > 0.15) {
      isHeadlineVisible.value = false;
    } else {
      isHeadlineVisible.value = true;
    }

    if (value > 0.2 && value < 0.75) {
      isClaimVisible.value = true;
    } else {
      isClaimVisible.value = false;
    }
  }
);

watch(
  () => isHeadlineVisible.value,
  value => {
    fadeSlide([$overline.value, $headline.value?.$el, $subline.value, $cta.value?.$el, $cardIntro.value], {
      mode: !value ? 'out' : undefined,
      staggerEach: value ? undefined : 0,
      offsetMultiplier: !value ? 0 : undefined,
      baseDelay: !value ? 0 : 0.5,
    });
  }
);

watch(
  () => isClaimVisible.value,
  value => {
    fadeSlide($claim.value?.$el, {
      baseDelay: value ? 0.5 : 0,
      mode: value ? 'in' : 'out',
      offsetMultiplier: value ? 1 : 0,
    });

    fadeSlide($cardClaim.value?.$el, {
      baseDelay: value ? 0.5 : 0,
      mode: value ? 'in' : 'out',
      offsetMultiplier: value ? 1 : 0,
    });
  }
);

function onHeroLoaded() {
  if (isHeroLoaded.value) {
    return;
  }
  isHeroLoaded.value = true;

  void nextTick(() => {
    if (!$headline.value?.$el) {
      return;
    }

    if (isHeadlineVisible.value) {
      fadeSlide([$overline.value, $headline.value.$el, $subline.value, $cta.value?.$el, $cardIntro.value], {
        baseDelay: 0.25,
        speedMultiplier: 2,
        offsetMultiplier: 2,
      });
    } else if (isClaimVisible.value) {
      fadeSlide([$claim.value?.$el, $cardClaim.value?.$el]);
    }

    if ($hero.value) {
      fadeSlide($hero.value, {
        speedMultiplier: 2,
        offsetMultiplier: 5,
        alphaSpeedMultiplier: 2,
      });
    }
  });
}

function onResize() {
  windowSize.value = { width: window.innerWidth, height: window.innerHeight };
}

onMounted(() => {
  resetWindowScroll(true);
  window.addEventListener('resize', onResize);
  onResize();
});

onUnmounted(() => {
  clearTimeout(fadeInTimeout);
  window.removeEventListener('resize', onResize);
});
</script>

<style src="./CMStageProductRich.scss" lang="scss" scoped />
