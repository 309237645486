import { reactive } from 'vue';
import type { CorePageStoryblok } from '~/types/storyblok-generated';

interface ThemeState {
  isDark: boolean;
}

const alwaysDarkBloks = ['cm_stage-product-rich', 'em_home', 'em_showroom'];
const state = reactive<ThemeState>({ isDark: false });

export function useTheme() {
  /**
   * Checks if the given blok should be dark and sets the state accordingly.
   * This is used to e.g. handle the color of the header by reading this state.
   * @param blok The blok to check
   */
  function setIsDarkByComponent(blok: NonNullable<CorePageStoryblok['body']>[number]) {
    if (alwaysDarkBloks.includes(blok.component)) {
      state.isDark = true;
      return;
    }
    state.isDark = 'is_dark' in blok ? !!blok.is_dark : false;
  }

  return {
    state,
    setIsDarkByComponent,
  };
}
