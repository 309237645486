<template>
  <div class="base-textarea form-field" :class="[isDark ? 'is-dark' : 'is-light', { 'is-disabled': disabled }]">
    <label v-if="label" class="label" :for="uid">{{ label }}{{ required ? '*' : '' }}</label>
    <div class="field-container">
      <textarea
        :id="uid"
        ref="$textarea"
        v-model="model"
        :name="name"
        :placeholder="placeholder"
        :required="required"
        class="textarea"
        :class="{ 'has-error': errorMessage && isTouched }"
        @focus="emit('focus', $event)"
        @blur="emit('blur', $event)"
        @keydown.escape="emit('escape')"></textarea>
    </div>
    <div v-if="errorMessage && isTouched" class="error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, useId } from 'vue';
import type { FormFieldProps } from '~/types/utils';

const model = defineModel<string | undefined>({ required: true });

interface BaseTextarea extends FormFieldProps {
  isAutoFocused?: boolean;
  placeholder?: string;
}

const props = defineProps<BaseTextarea>();

const $textarea = ref<HTMLTextAreaElement>();
const uid = props.id ?? useId();

const emit = defineEmits<{
  (e: 'focus', event: FocusEvent): void;
  (e: 'blur', event: FocusEvent): void;
  (e: 'escape'): void;
}>();

onMounted(() => {
  if (props.isAutoFocused) {
    $textarea.value?.focus();
  }
});
</script>

<style src="~/assets/scss/_form-field.scss" lang="scss" scoped />
<style src="./_BaseTextarea.scss" lang="scss" scoped />
