<template>
  <div
    v-editable="blok"
    class="cm-media-section"
    :class="`is-${blok.component.replace('cm_media--', '')}-${'variant' in blok ? blok.variant : 'default'}`">
    <!-- Exception for variant type C-2 -->
    <template v-if="blok.component === 'cm_media--section-c' && blok.variant === '2'">
      <CMMediaCardWide
        v-if="blok.item_2?.[0].component === 'cm_media--item'"
        class="card-1"
        :youtubePrivacyWarning="settings?.youtube_privacy_warning"
        :item="blok.item_2[0]"
        :isDark="isDark" />
    </template>
    <template v-else>
      <!-- Item 1 -->
      <template v-if="firstEntry?.item">
        <CMMediaCard
          v-if="firstEntry.item?.component === 'cm_media--item'"
          class="card-1"
          :youtubePrivacyWarning="settings?.youtube_privacy_warning"
          :item="firstEntry.item"
          :mediaView="getCardMediaView(0)"
          :breakpointsWidthMap="firstEntry.breakpointsWidthMap"
          :isDark="isDark" />
        <ItemCardContent v-else :blok="firstEntry.item" class="card-1" />
      </template>

      <!-- Item 2 -->
      <template v-if="secondEntry?.item">
        <CMMediaCard
          v-if="secondEntry.item?.component === 'cm_media--item'"
          class="card-2"
          :youtubePrivacyWarning="settings?.youtube_privacy_warning"
          :item="secondEntry.item"
          :mediaView="getCardMediaView(1)"
          :breakpointsWidthMap="secondEntry.breakpointsWidthMap"
          :isDark="isDark" />
        <ItemCardContent v-else :blok="secondEntry.item" class="card-2" :customTransformPerspective="3000" />
      </template>

      <!-- Item 3 -->
      <template v-if="thirdEntry?.item">
        <CMMediaCard
          v-if="thirdEntry.item.component === 'cm_media--item'"
          class="card-3"
          :youtubePrivacyWarning="settings?.youtube_privacy_warning"
          :item="thirdEntry.item"
          :mediaView="getCardMediaView(2)"
          :breakpointsWidthMap="thirdEntry.breakpointsWidthMap"
          :isDark="isDark" />
        <ItemCardContent v-else :blok="thirdEntry.item" class="card-3" />
      </template>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import CMMediaCard from '~/components/storyblok/content/CMMedia/CMMediaCard.vue';
import CMMediaCardWide from '~/components/storyblok/content/CMMedia/CMMediaCardWide.vue';
import ItemCardContent from '~/components/storyblok/item/ItemCardContent/ItemCardContent.vue';
import type {
  CmMediaItemStoryblok,
  CmMediaStoryblok,
  CoreSettingsStoryblok,
  ItemCardContentMediaStoryblok,
  ItemCardContentNumbersStoryblok,
  ItemCardContentSvgStoryblok,
} from '~/types/storyblok-generated';

const props = defineProps<{
  blok: NonNullable<CmMediaStoryblok['body']>[number];
  settings: CoreSettingsStoryblok;
  isDark?: boolean;
}>();

/**
 * Explanation of the sections types:
 * - Section A: 2 cards (small, large). Variant 1 has the large card on the left, variant 2 on the right
 * - Section B: 2 cards (very small, very large). Variant 1 has the large card on the left, variant 2 on the right
 * - Section C: 1 card (full screen width).
 * - Section D: 3 cards. Variant 1 has medium, small, medium. Variant 2 has medium, medium, small.
 * - Section E: 3 cards. Variant 1 has small, medium, medium. Variant 2 has medium, small, medium. (Section D flipped horizontally)
 * - Section F: 3 cards with same width.
 */

interface ItemEntry {
  item:
    | CmMediaItemStoryblok
    | ItemCardContentMediaStoryblok
    | ItemCardContentSvgStoryblok
    | ItemCardContentNumbersStoryblok
    | undefined;
  breakpointsWidthMap: Record<number, number>;
}

const defaultMobileBreakpointsSmall = { '0': 420, '576': 562, '768': 749 };
const defaultMobileBreakpointsLarge = { '0': 528, '576': 704, '768': 938 };
const defaultDesktopBreakpointsSmall = { '1024': 339, '1280': 508, '1920': 652, '2440': 555 };
const defaultDesktopBreakpointsLarge = { '1024': 458, '1280': 687, '1920': 878, '2440': 749 };

const breakpoints = {
  'cm_media--section-a': {
    // Missing mobile breakpoints because they differ between entries
    small: { '1024': 452, '1280': 688, '1920': 879, '2440': 749 },
    large: { '1024': 697, '1280': 1045, '1920': 1332, '2440': 1138 },
  },
  'cm_media--section-b': {
    // Missing mobile breakpoints because they differ between entries
    small: defaultDesktopBreakpointsSmall,
    large: { '1024': 877, '1280': 1316, '1920': 1676, '2440': 1449 },
  },
  'cm_media--section-c': {
    default: { '0': 662, '576': 882, '768': 1177, '1024': 1471, '1280': 2207, '1920': 2805, '2440': 2433 },
  },
  'cm_media--section-d': {
    small: { ...defaultMobileBreakpointsSmall, ...defaultDesktopBreakpointsSmall },
    medium: { ...defaultMobileBreakpointsLarge, ...defaultDesktopBreakpointsLarge },
  },
  'cm_media--section-e': {
    small: { ...defaultMobileBreakpointsSmall, ...defaultDesktopBreakpointsSmall },
    medium: { ...defaultMobileBreakpointsLarge, ...defaultDesktopBreakpointsLarge },
  },
  'cm_media--section-f': {
    default: { '0': 635, '576': 847, '768': 1130, ...defaultDesktopBreakpointsLarge },
  },
};

const firstEntry = computed<ItemEntry | null>(() => {
  switch (props.blok.component) {
    case 'cm_media--section-a':
    case 'cm_media--section-b':
      return {
        item: props.blok.variant === '2' ? props.blok.item_2?.[0] : props.blok.item_1?.[0],
        breakpointsWidthMap: {
          ...defaultMobileBreakpointsLarge,
          ...breakpoints[props.blok.component][props.blok.variant === '2' ? 'small' : 'large'],
        },
      };
    case 'cm_media--section-e':
      return {
        item: props.blok.variant === '2' ? props.blok.item_2?.[0] : props.blok.item_1?.[0],
        breakpointsWidthMap: breakpoints[props.blok.component][props.blok.variant === '2' ? 'medium' : 'small'],
      };
    case 'cm_media--section-d':
      return {
        item: props.blok.item_1?.[0],
        breakpointsWidthMap: breakpoints[props.blok.component].medium,
      };
    case 'cm_media--section-c':
    case 'cm_media--section-f':
      return {
        item: props.blok.item_1?.[0],
        breakpointsWidthMap: breakpoints[props.blok.component].default,
      };
    default:
      return null;
  }
});

const secondEntry = computed<ItemEntry | null>(() => {
  switch (props.blok.component) {
    case 'cm_media--section-a':
    case 'cm_media--section-b':
      return {
        item: props.blok.variant === '2' ? props.blok.item_1?.[0] : props.blok.item_2?.[0],
        breakpointsWidthMap: {
          ...defaultMobileBreakpointsSmall,
          ...breakpoints[props.blok.component][props.blok.variant === '2' ? 'large' : 'small'],
        },
      };
    case 'cm_media--section-e':
      return {
        item: props.blok.variant === '2' ? props.blok.item_1?.[0] : props.blok.item_2?.[0],
        breakpointsWidthMap: breakpoints[props.blok.component][props.blok.variant === '2' ? 'small' : 'medium'],
      };
    case 'cm_media--section-d':
      return {
        item: props.blok.variant === '2' ? props.blok.item_3?.[0] : props.blok.item_2?.[0],
        breakpointsWidthMap: breakpoints[props.blok.component][props.blok.variant === '2' ? 'medium' : 'small'],
      };
    case 'cm_media--section-f':
      return {
        item: props.blok.item_2?.[0],
        breakpointsWidthMap: breakpoints[props.blok.component].default,
      };
    default:
      return null;
  }
});

const thirdEntry = computed<ItemEntry | null>(() => {
  switch (props.blok.component) {
    case 'cm_media--section-d':
      return {
        item: props.blok.variant === '2' ? props.blok.item_2?.[0] : props.blok.item_3?.[0],
        breakpointsWidthMap: breakpoints[props.blok.component][props.blok.variant === '2' ? 'small' : 'medium'],
      };
    case 'cm_media--section-e':
      return {
        item: props.blok.item_3?.[0],
        breakpointsWidthMap: breakpoints[props.blok.component].medium,
      };
    case 'cm_media--section-f':
      return {
        item: props.blok.item_3?.[0],
        breakpointsWidthMap: breakpoints[props.blok.component].default,
      };
    default:
      return null;
  }
});

function getCardMediaView(index: number) {
  if (props.blok.component === 'cm_media--section-b') {
    switch (props.blok.variant) {
      case '1':
        return index === 0 ? 'align-left' : 'normal';
      case '2':
        return index === 1 ? 'align-right' : 'normal';
    }
  }
  return 'normal';
}
</script>

<style src="./CMMediaSection.scss" lang="scss" scoped />
