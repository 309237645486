uniform sampler2D u_projectionTexture;
uniform sampler2D u_projectionDepthTexture;
uniform sampler2D u_projectionBlurTexture;
uniform float  u_mixBlur;
uniform float  u_mirror;
uniform float  u_mixStrength;
uniform float  u_minDepthThreshold;
uniform float  u_maxDepthThreshold;
uniform float  u_mixContrast;
uniform float  u_depthScale;
uniform float  u_depthToBlurRatioBias;
 
uniform sampler2D u_blueNoiseTexture;
uniform vec2 u_blueNoiseTextureSize;
uniform vec2 u_blueNoiseTextureOffset;

varying vec2 v_uv;
varying vec4 v_uvReflection;




#include "../../glsl/circleBlur.glsl"

void main () {
  //https://stackoverflow.com/questions/28818410/blur-mirror-shader
  vec2 uvReflection = v_uvReflection.xy / v_uvReflection.w;

  vec4 new_vUv = v_uvReflection;


  vec4 base = texture2DProj(u_projectionTexture, new_vUv );
  vec4 blur = texture2DProj( u_projectionBlurTexture, new_vUv);
  float blueNoise = texture2D(u_blueNoiseTexture, gl_FragCoord.xy * u_blueNoiseTextureSize + u_blueNoiseTextureOffset ).r;
  
  vec4 merge = base;

  float depthFactor = 0.0001;
  float blurFactor = 0.0;



  #ifdef USE_DEPTH
    vec4 depth = texture2DProj(u_projectionDepthTexture, new_vUv);
    depthFactor = smoothstep(u_minDepthThreshold, u_maxDepthThreshold, 1.0-(depth.r * depth.a));
    depthFactor *= u_depthScale;
    depthFactor = max(0.0001, min(1.0, depthFactor));

    #ifdef USE_BLUR
      blur = blur * min(1.0, depthFactor + u_depthToBlurRatioBias);
      merge = merge * min(1.0, depthFactor + 0.5);
    #else
      merge = merge * depthFactor;
    #endif

  #endif




  float reflectorRoughnessFactor = 1.0;
  #ifdef USE_ROUGHNESSMAP
    vec4 reflectorTexelRoughness = texture2D( u_roughnessMap, v_uv );
    reflectorRoughnessFactor *= reflectorTexelRoughness.g;
  #endif
  
  blurFactor = min(1.0, u_mixBlur * reflectorRoughnessFactor);
  merge = mix(merge, blur, blurFactor);

  vec4 newMerge = vec4(0.0, 0.0, 0.0, 1.0);
  newMerge.r = (merge.r - 0.5) *  u_mixContrast + 0.5;
  newMerge.g = (merge.g - 0.5) *  u_mixContrast + 0.5;
  newMerge.b = (merge.b - 0.5) *  u_mixContrast + 0.5;

  float circleValue = circleBlur(.25, 0.24, v_uv, .50, 0.40).r;
  float  mixStrength = u_mixStrength * circleValue;

  newMerge.rgb = newMerge.rgb * ((1.0 - min(1.0,  u_mirror)) + newMerge.rgb * mixStrength);
  newMerge.rgb += blueNoise * 0.001;



  vec3 spotColor = circleBlur(.08, 0.089, vec2(v_uv.x, v_uv.y - 0.2), .250, 0.20).r *vec3(1.0);
  spotColor *= 0.0015;

  vec3 outputColor = newMerge.rgb + spotColor;
  outputColor += blueNoise * 0.001;


  gl_FragColor = vec4(outputColor, 1.0);  
}
 