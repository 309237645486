<template>
  <div
    ref="$wrapper"
    class="dropdown-wrapper"
    :class="[{ 'is-padded': isShowroomStyle && isOpen }, `dropdown-wrapper-${id}`]">
    <div
      class="dropdown action-medium-bold"
      :class="[
        isDark ? 'is-dark' : 'is-light',
        isSmall ? 'is-small' : 'is-large',
        {
          'is-open': isOpen,
          'is-selected': !!selectedOption && !isOpen,
          'is-input': isInput,
          'has-error': hasError,
          'is-showroom-style': isShowroomStyle,
        },
      ]"
      @click="() => toggleDropdown()">
      <!-- Main button -->
      <div
        ref="$trigger"
        class="dropdown-trigger"
        :class="[isInput && !selectedOption ? 'action-medium-regular' : 'action-medium-bold']"
        @click="emit('buttonClick')">
        <div>
          {{ !isOpen && !!selectedOption ? selectedOption.name : t('forms.pleaseSelect') }}
        </div>
        <BaseIcon :name="isOpen ? 'fi_chevron-top' : 'fi_chevron-bottom'" :size="16" color="inherit" />
      </div>

      <!-- List (teleported to body when floating)-->
      <Teleport v-if="isActuallyFloating" to="body">
        <div ref="$list" class="dropdown-list-container" :style="floatingStyles">
          <ItemDropdownList
            :isDark="!!isDark"
            :isSmall="!!isSmall"
            :isShowroomStyle="isShowroomStyle"
            :isOpen="isOpen"
            :selectedOption="selectedOption"
            :uniqueId="id"
            :isFloating="!!isActuallyFloating"
            :options="parsedOptions"
            @reset="emit('reset', $event)"
            @select="emit('select', $event)" />
        </div>
      </Teleport>
      <ItemDropdownList
        v-else
        :isDark="!!isDark"
        :isSmall="!!isSmall"
        :isShowroomStyle="isShowroomStyle"
        :isOpen="isOpen"
        :selectedOption="selectedOption"
        :uniqueId="id"
        :isFloating="!!isActuallyFloating"
        :options="parsedOptions"
        @reset="emit('reset', $event)"
        @select="emit('select', $event)" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { autoUpdate, offset, size, useFloating } from '@floating-ui/vue';
import { onClickOutside } from '@vueuse/core';
import { computed, onMounted, ref } from 'vue';
import { BaseIcon } from '~/components/base';
import ItemDropdownList from './ItemDropdownList.vue';
import type {
  InternalCategoryTitle,
  InternalOption,
  ItemDropdownCategory,
  ItemDropdownOption,
  ItemDropdownOptions,
} from './ItemDropdown.types';
import { useId } from '#app';
import { useI18n } from '#imports';

const props = withDefaults(
  defineProps<{
    isDark?: boolean;
    isSmall?: boolean;
    isInput?: boolean;
    isShowroomStyle?: boolean;
    hasError?: boolean;
    selectedOption: ItemDropdownOption | null;
    options: ItemDropdownOptions;
    uniqueId?: string;
    /**
     * This makes the dropdown flyout float above the rest of the content.
     */
    isFloating?: boolean;
  }>(),
  {
    defaultLabel: 'Select an option',
    resetLabel: 'Reset',
    isShowroomStyle: false,
  }
);

const emit = defineEmits<{
  (e: 'buttonClick'): void;
  (e: 'select', option: ItemDropdownOption, id?: string): void;
  (e: 'reset', id?: string): void;
  (e: 'update'): void;
  (e: 'toggle', id?: string): void;
  (e: 'tween'): void;
}>();

const $wrapper = ref<HTMLElement>();
const $list = ref<HTMLElement>();
const $trigger = ref<HTMLElement>();
const isOpen = ref(false);
const id = props.uniqueId ?? useId();
const { t } = useI18n();
const isActuallyFloating = props.isFloating && !props.isShowroomStyle;

let floatingStyles: ReturnType<typeof useFloating>['floatingStyles'] | {} = {};
if (isActuallyFloating) {
  const { floatingStyles: _floatingStyles } = useFloating($trigger, $list, {
    strategy: 'fixed',
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
      offset(props.isSmall ? -40 : -53),
    ],
    open: isOpen,
  });
  floatingStyles = _floatingStyles;
}

function isCategoryOptions(options: ItemDropdownOptions): options is ItemDropdownCategory[] {
  return Array.isArray((options[0] as any)['options']);
}

/**
 * Parse options to a unified format. This is always an array of options or category titles.
 */
const parsedOptions = computed<(InternalOption | InternalCategoryTitle)[]>(() => {
  if (props.options.length === 0) {
    return [];
  }
  if (isCategoryOptions(props.options)) {
    const newParsedOptions: (InternalOption | InternalCategoryTitle)[] = [];
    props.options.forEach(category => {
      newParsedOptions.push({
        type: 'categoryTitle',
        name: category.name,
      });
      category.options.forEach(option => {
        newParsedOptions.push({
          type: 'option',
          name: option.name,
          value: option.value,
        });
      });
    });
    return newParsedOptions;
  } else {
    return props.options.map(options => ({
      ...options,
      type: 'option',
    }));
  }
});

function toggleDropdown(newState?: boolean) {
  isOpen.value = newState ?? !isOpen.value;
  emit('toggle', id);
}

onMounted(() => {
  onClickOutside($wrapper.value, () => toggleDropdown(false));
});
</script>

<style src="./ItemDropdown.scss" lang="scss" scoped />
