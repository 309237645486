<template>
  <div v-editable="blok" class="em-map container-full">
    <div class="head-container">
      <ItemContentHead
        v-if="blok.head?.[0]?.component === 'item_content-head'"
        class="head"
        :blok="blok.head[0]"
        :headSize="blok.head_size"
        :isDark="blok.is_dark" />
    </div>
    <div ref="$mapContainer" class="map-container" :class="{ 'layer-visible': !!sortedStores }">
      <template v-if="!hasConsented && !didAskForConsent">
        <EMMapModal class="modal" @reject="() => (didAskForConsent = true)" @accept="requestConsentChange" />
        <img class="blurred-images" src="/images/map-blurred.jpg" />
      </template>
      <template v-else>
        <GoogleMap
          v-if="hasConsented"
          ref="googleMapRef"
          class="google-map"
          :class="{ 'layer-visible': !!sortedStores }"
          :apiKey="config.public.GOOGLE_MAPS_API_KEY_PUBLIC"
          :center="initialLocation"
          :zoom="5.6"
          :styles="mapStyles"
          :zoomControl="false"
          :fullscreenControl="false"
          :streetViewControl="false"
          :mapTypeControl="false"
          backgroundColor="#0D0D0D"
          @drag="debouncedHandleMapDrag">
          <UtilButton
            class="search-in-area-button"
            :class="{ 'is-hidden': !showSearchInArea }"
            :blok="{
              component: 'util_button',
              text: t('EMMap.searchInThisArea'),
              size: 'small',
              theme: 'primary',
              _uid: 'search-in-area-button',
            }"
            @click="handleSearchInArea" />

          <MarkerCluster v-if="googleMapRef?.api" :options="{ renderer }">
            <template v-for="store in stores">
              <Marker
                v-if="store.content?.location?.latitude && store.content?.location?.longitude"
                :key="store.uuid"
                :options="{
                  position: {
                    lat: store.content.location.latitude,
                    lng: store.content.location.longitude,
                  },
                  icon: {
                    url: store.content.is_showroom ? `/icons/map-pin-showroom.svg` : `/icons/map-pin-partner.svg`,
                    scaledSize: new googleMapRef.api.Size(40, 40),
                  },
                }"
                @click="() => handlePinClick(store)" />
            </template>
          </MarkerCluster>
        </GoogleMap>
        <div
          v-else-if="blok.fallback_image_desktop && blok.fallback_image_mobile"
          class="google-map"
          :class="{ 'layer-visible': !!sortedStores }">
          <BaseImage
            :image="breakpointState.isTablet ? blok.fallback_image_desktop : blok.fallback_image_mobile"
            class="fallback-image"
            :breakpointsWidthMap="{
              '0': 375,
              '375': 706,
              '768': 942,
              '1024': 1178,
              '1280': 1325,
              '1440': 1920,
            }" />
          <p class="consent-info-text text-small-regular">
            {{ t('EMMap.consentText') }}
            <button class="consent-info-button" @click="requestConsentChange">
              {{ t('EMMap.consentButtonLabel') }}</button
            >.
          </p>
        </div>
        <EMMapResultsLayer
          class="results-layer"
          :class="{ 'is-visible': !!sortedStores }"
          :results="sortedStores ?? []"
          :locationCountryCode="currentLocationResult?.countryCode"
          :storeContactType="blok.store_contact_type"
          :buttonLink="blok.button_link"
          :selectedStore="selectedStore"
          @storeSelected="handleStoreSelected" />
        <EMMapSearchInput
          class="search-input"
          :class="{ 'no-consent': !hasConsented }"
          :resultsOpen="!!sortedStores"
          @focusChange="handleFocus"
          @locationChange="handleLocationChange" />
        <div class="mobile-autocomplete-bg" :class="{ 'is-visible': mobileInputVisible }"></div>
        <div v-if="hasConsented" class="zoom-controls">
          <EMMapZoomButton icon="fi_plus" @click="() => changeZoom(1)" />
          <EMMapZoomButton icon="fi_minus" @click="() => changeZoom(-1)" />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import type { MarkerClustererOptions } from '@googlemaps/markerclusterer';
import { OneTrustGroup } from '@seb-platform/shared/types';
import { useDebounceFn } from '@vueuse/core';
import { gsap } from 'gsap';
import haversine from 'haversine-distance';
import { useRuntimeConfig } from 'nuxt/app';
import { computed, ref, watch } from 'vue';
import { GoogleMap, Marker, MarkerCluster } from 'vue3-google-map';
import BaseImage from '~/components/base/BaseImage.vue';
import ItemContentHead from '~/components/storyblok/item/ItemContentHead/ItemContentHead.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useConsent } from '~/composables/useConsent';
import { useStoryblokClient } from '~/composables/useStoryblokClient';
import type { LocationResult } from '~/types/google-maps';
import type { EmMapStoryblok, StoreEntryStoryblok } from '~/types/storyblok-generated';
import UtilButton from '../../utils/UtilButton/UtilButton.vue';
import type { SortedStore, Store } from './EMMap.types';
import EMMapModal from './EMMapModal.vue';
import EMMapResultsLayer from './EMMapResultsLayer.vue';
import EMMapSearchInput from './EMMapSearchInput.vue';
import EMMapZoomButton from './EMMapZoomButton.vue';
import { mapStyles } from './map-styles';

// // @ts-expect-error We have to do a weird import here because the package is ESM
// Import pkg from '@googlemaps/markerclusterer';
// Const { GridAlgorithm } = pkg;

const [MIN_ZOOM, MAX_ZOOM] = [0, 22];
const initialLocation = { lat: 51.3, lng: 10.5 };

defineProps<{ blok: EmMapStoryblok }>();

const $mapContainer = ref<HTMLElement>();
const sortedStores = ref<SortedStore[] | null>(null);
const currentLocationResult = ref<LocationResult | null>(null);
const hasConsented = computed(() => consentState.groups.includes(OneTrustGroup.Tracking));
const didAskForConsent = ref<boolean>(false);
const showSearchInArea = ref<boolean>(false);
const mobileInputVisible = ref<boolean>(false);
const selectedStore = ref<Store | null>(null);
const googleMapRef = ref<{
  mapRef: HTMLElement | undefined;
  ready: boolean;
  map: google.maps.Map | undefined;
  api: typeof google.maps | undefined;
  mapTilesLoaded: boolean;
} | null>(null);

// TODO: We need the GridAlgorithm to prevent the pins from disappearing when zooming out, but the import of
// GridAlgorithm from the package is not working. We have therefore disabled this fix for now
//
// We need to define an algorithm for the marker clusterer otherwise the pins will
// Disappear when zooming out or paning very far, because of a low default viewport padding
// Const algorithm: MarkerClustererOptions['algorithm'] = new GridAlgorithm({
//   GridSize: 60,
//   ViewportPadding: 1000,
//   MaxZoom: MAX_ZOOM,
// });

const renderer: MarkerClustererOptions['renderer'] = {
  render: ({ count, position }) => {
    return new googleMapRef.value!.api!.Marker({
      label: {
        text: String(count),
        className: 'marker-cluster-number',
      },
      position,
      icon: {
        url: `/icons/map-pin-partner.svg`,
        scaledSize: new googleMapRef.value!.api!.Size(40, 40),
      },
      zIndex: Number(googleMapRef.value!.api!.Marker.MAX_ZINDEX) + count,
    });
  },
};

const { state: consentState } = useConsent();
const config = useRuntimeConfig();
const { getStories } = await useStoryblokClient();
const { state: breakpointState } = useBreakpoint();
const { t } = useI18n();

const stores = await getStories<StoreEntryStoryblok>({
  starts_with: '_stores/',
  content_type: 'store_entry',
});

/**
 * Sorts stores by assigned countries and distance and adjusts the map view
 */
function handleLocationChange(locationResult: LocationResult | null) {
  if (locationResult && stores.value) {
    currentLocationResult.value = locationResult;
    const [assignedStores, storesOthers] = stores.value
      // Remove stores without location
      .filter(store => !!store.content.location?.latitude && !!store.content.location?.longitude)
      // Split stores into 3 categories
      .reduce(
        ([assignedStores, otherStores]: Store[][], store) => {
          if (store.content.assigned_countries.includes(locationResult.countryCode ?? 'NOT_FOUND')) {
            // Store is assigned to the country
            return [[store, ...assignedStores], otherStores];
          } else {
            return [assignedStores, [store, ...otherStores]];
          }
        },
        [[], [], []]
      );

    sortedStores.value = [
      ...sortStoresByDistance(assignedStores, locationResult),
      ...sortStoresByDistance(storesOthers, locationResult),
    ];
    selectedStore.value = sortedStores.value[0]?.store ?? null;

    // Zoom to the bounds of the first 3 stores
    if (googleMapRef.value?.api && googleMapRef.value?.map) {
      const latlng = sortedStores.value
        .slice(0, 3)
        .filter(s => s.store.content.location?.latitude && s.store.content.location?.longitude)
        .map(
          s =>
            new googleMapRef.value!.api!.LatLng(
              s.store.content.location!.latitude!,
              s.store.content.location!.longitude
            )
        );
      const latlngbounds = new google.maps.LatLngBounds();
      for (let i = 0; i < latlng.length; i++) {
        latlngbounds.extend(latlng[i]);
      }
      googleMapRef.value.map.fitBounds(latlngbounds);
      panToStore(sortedStores.value[0].store);
    }
  }
}

function changeZoom(changeBy: number) {
  if (googleMapRef.value?.map) {
    const currentZoom = googleMapRef.value.map.getZoom() ?? 0;
    const newZoom = Math.max(Math.min(currentZoom + changeBy, MAX_ZOOM), MIN_ZOOM);
    googleMapRef.value.map.setZoom(newZoom);
  }
}

function sortStoresByDistance(stores: Store[], locationResult: LocationResult) {
  return stores
    .map(store => ({
      distance: haversine(
        {
          latitude: store.content.location!.latitude!,
          longitude: store.content.location!.longitude!,
        },
        {
          latitude: locationResult.location.lat,
          longitude: locationResult.location.lng,
        }
      ),
      store: store,
    }))
    .sort((a, b) => a.distance - b.distance);
}

/**
 * Focus map on selected store
 */
function handleStoreSelected(store: Store) {
  selectedStore.value = store;
  panToStore(store);
}

function panToStore(store: Store) {
  if (
    googleMapRef.value?.api &&
    googleMapRef.value?.map &&
    store.content.location?.latitude &&
    store.content.location?.longitude
  ) {
    googleMapRef.value.map.panTo(
      new googleMapRef.value.api.LatLng(store.content.location.latitude, store.content.location.longitude)
    );
  }
}

/**
 * Animate layer fade in on mobile
 */
watch(
  () => sortedStores.value?.length ?? 0 > 0,
  () => {
    if ((sortedStores.value?.length ?? 0 > 0) && !breakpointState.isTablet && $mapContainer.value) {
      gsap.fromTo(
        $mapContainer.value,
        { height: $mapContainer.value.clientHeight },
        {
          height: 'auto',
          duration: 0.3,
          ease: 'ease-in-out',
          onComplete: function () {
            gsap.set(this.targets() as gsap.TweenTarget, { clearProps: 'all' });
          },
        }
      );
    }
  }
);

function handlePinClick(store: Store) {
  if (store.content.location?.latitude && store.content.location?.longitude) {
    if (!(sortedStores.value?.length ?? 0 > 0)) {
      handleLocationChange({
        location: {
          lat: store.content.location.latitude,
          lng: store.content.location.longitude,
        },
        countryCode: undefined,
      });
    } else {
      handleStoreSelected(store);
    }
  }
}

/**
 * Check if the user has dragged 3.5km away from the initial location and if yes set showSearchInArea to true
 */
function handleMapDrag() {
  const location =
    selectedStore.value?.content.location?.latitude && selectedStore.value?.content.location?.longitude
      ? {
          lat: selectedStore.value.content.location.latitude,
          lng: selectedStore.value.content.location.longitude,
        }
      : initialLocation;
  const center = googleMapRef.value?.map?.getCenter();
  if (center) {
    const distance = haversine({ lat: center.lat(), lng: center.lng() }, location);
    if (distance > 3500) {
      showSearchInArea.value = true;
    } else {
      showSearchInArea.value = false;
    }
  }
}
const debouncedHandleMapDrag = useDebounceFn(handleMapDrag, 500);

function handleSearchInArea() {
  const center = googleMapRef.value?.map?.getCenter();
  handleLocationChange({
    location: {
      lat: center?.lat() ?? 0,
      lng: center?.lng() ?? 0,
    },
    countryCode: undefined,
  });
  showSearchInArea.value = false;
}

/**
 * Handle the autocomplete dropdown visibility on mobile.
 * We add a black element similar to the search results for better visibility
 */
function handleFocus(isVisible: boolean, isResultSelect: boolean) {
  if (
    breakpointState.isTablet ||
    isResultSelect ||
    (sortedStores.value?.length ?? 0) > 0 ||
    mobileInputVisible.value === isVisible
  ) {
    // We don't want this to run on desktop or when a result is selected which triggers a different animation
    return;
  }

  const targetSize = breakpointState.isPhoneLarge ? 700 : 1250;
  mobileInputVisible.value = isVisible;
  if ($mapContainer.value) {
    gsap.fromTo(
      $mapContainer.value,
      { height: isVisible ? 500 : targetSize },
      {
        height: isVisible ? targetSize : 500,
        duration: 0.5,
        ease: 'ease-in-out',
      }
    );
  }
}
watch(
  () => breakpointState.isTablet,
  () => {
    // Reset the autocomplete dropdown visibility when switching to desktop
    if (breakpointState.isTablet && mobileInputVisible.value && $mapContainer.value) {
      gsap.set($mapContainer.value, { clearProps: 'all' });
    }
  }
);

/**
 * Cookie consent
 */

function requestConsentChange() {
  consentState.visible = 'preferences';
}
</script>

<style src="./EMMap.scss" lang="scss" scoped />
