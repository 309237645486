<template>
  <div class="em-home-column" :class="large_card_position === 'top' ? 'largeCardTop' : 'largeCardBottom'">
    <EMHomeCard
      :frontSideOverline="large_card[0].overline"
      :frontSideHeadline="large_card[0].headline"
      :frontSideSubline="large_card[0].subline"
      :frontSideImage="large_card[0].image"
      :frontSideVideo="large_card[0].video"
      :link="large_card[0].link"
      :backSideText="undefined"
      :backSideCta="undefined"
      horizontalTextAlign="left"
      :verticalTextAlign="large_card[0].vertical_text_align"
      :isDark="!!large_card[0].is_dark"
      :textLayout="large_card[0].text_layout"
      :interactivity="large_card[0].link ? 'link' : 'none'"
      cardType="large"
      class="large-card"
      :uid="large_card[0]._uid"
      :isFlipped="flippedCardUid === large_card[0]._uid"
      @flip="emit('flip', large_card[0]._uid)" />
    <EMHomeCard
      v-if="small_cards?.[0]"
      :frontSideOverline="undefined"
      :frontSideHeadline="small_cards[0].front_side_headline"
      :frontSideSubline="undefined"
      :frontSideImage="
        breakpointState.isPhoneLarge ? small_cards[0].front_side_image_desktop : small_cards[0].front_side_image_mobile
      "
      :frontSideVideo="
        breakpointState.isPhoneLarge ? small_cards[0].front_side_video_desktop : small_cards[0].front_side_video_mobile
      "
      :link="small_cards[0].link"
      :backSideText="small_cards[0].back_side_text"
      :backSideCta="small_cards[0].back_side_cta?.[0]"
      horizontalTextAlign="center"
      :verticalTextAlign="small_cards[0].vertical_text_align"
      :isDark="!!small_cards[0].is_dark"
      :textLayout="undefined"
      :interactivity="small_cards[0].interactivity"
      cardType="xSmall"
      class="small-card small-card-1"
      :uid="small_cards[0]._uid"
      :isFlipped="flippedCardUid === small_cards[0]._uid"
      @flip="emit('flip', small_cards[0]._uid)" />
    <EMHomeCard
      v-if="small_cards?.[1]"
      :frontSideOverline="undefined"
      :frontSideHeadline="small_cards[1].front_side_headline"
      :frontSideSubline="undefined"
      :frontSideImage="
        breakpointState.isPhoneLarge ? small_cards[1].front_side_image_desktop : small_cards[1].front_side_image_mobile
      "
      :frontSideVideo="
        breakpointState.isPhoneLarge ? small_cards[1].front_side_video_desktop : small_cards[1].front_side_video_mobile
      "
      :link="small_cards[1].link"
      :backSideText="small_cards[1].back_side_text"
      :backSideCta="small_cards[1].back_side_cta?.[0]"
      horizontalTextAlign="center"
      :verticalTextAlign="small_cards[1].vertical_text_align"
      :isDark="!!small_cards[1].is_dark"
      :textLayout="undefined"
      :interactivity="small_cards[1].interactivity"
      cardType="xSmall"
      class="small-card small-card-2"
      :uid="small_cards[1]._uid"
      :isFlipped="flippedCardUid === small_cards[1]._uid"
      @flip="emit('flip', small_cards[1]._uid)" />
  </div>
</template>

<script lang="ts" setup>
import { useBreakpoint } from '~/composables/useBreakpoint';
import type { EmHomeCol1Large2Small } from './EMHome.types';
import EMHomeCard from './EMHomeCard.vue';

defineProps<EmHomeCol1Large2Small>();

const emit = defineEmits<{ (e: 'flip', id: string): void }>();

const { state: breakpointState } = useBreakpoint();
</script>

<style lang="scss" scoped>
.em-home-column {
  display: grid;
  grid-gap: 8px;
  height: 100%;
  width: 100%;
  @media (min-width: 768px) {
    gap: 16px;
  }
  @media (min-width: 1024px) {
    gap: 24px;
  }
}

.largeCardTop {
  grid-template-areas: 'large large' 'small1 small2';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2.5fr 1fr;
}

.largeCardBottom {
  grid-template-areas: 'small1 small2' 'large large';
  grid-template-rows: 1fr 2.5fr;
}

.large-card {
  grid-area: large;
}

.small-card {
  min-height: 200px;

  @media (min-width: 768px) {
    min-height: auto;
  }
}

.small-card-1 {
  grid-area: small1;
}

.small-card-2 {
  grid-area: small2;
}
</style>
