<template>
  <div v-editable="blok" class="item-table-standard-cell cell" :class="{ 'is-span': !isMobileTable && !isFullWidth }">
    <!-- Mobile column title -->
    <div v-if="!!titleCell && isMobileTable" class="cell-column-title">
      <UtilRichtext
        v-if="titleCell?.component === 'cm_table-cell-media-copy' && titleCell.copy?.length"
        :blok="titleCell.copy[0]"
        :isDark="isDark"
        :isProseParagraphsEnabled="false"
        textSize="medium" />

      <div v-else-if="titleCell?.component === 'cm_table-cell-icon-text'" class="text-medium-bold">
        {{ titleCell.text }}
      </div>
    </div>

    <!-- Cell Download -->
    <UtilLinkItemSubtext v-if="blok.component === 'util_link-item-subtext'" :blok="blok" :isDark="isDark" />

    <!-- Text -->
    <template v-else>
      <!-- Icon + Text -->
      <div
        v-if="blok.component === 'cm_table-cell-icon-text'"
        class="cell-icon-text"
        :class="{ 'is-title': isCellTitle }">
        <BaseIcon v-if="blok.icon" :name="blok.icon" :size="24" color="inherit" class="cell-icon" />
        <div class="cell-text">
          {{ blok.text }}
        </div>
      </div>

      <!-- Rich text -->
      <div
        v-if="blok.component === 'cm_table-cell-media-copy'"
        class="cell-media-copy"
        :class="{ 'is-full-width': isFullWidth }">
        <UtilRichtext v-if="blok.copy.length" :blok="blok.copy[0]" :isDark="isDark" :isProseParagraphsEnabled="false" />
        <BaseImage
          :image="blok.image"
          :breakpointsWidthMap="breakpointsWidthMap"
          :heightRatio="blok.is_image_icon ? [1, 1] : [16, 9]"
          :lazy="false"
          class="cell-media-image"
          :class="blok.is_image_icon ? 'is-icon' : 'is-image'" />
      </div>

      <!-- Empty cell as fallback -->
      <div></div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import BaseIcon from '~/components/base/BaseIcon.vue';
import BaseImage from '~/components/base/BaseImage.vue';
import UtilLinkItemSubtext from '~/components/storyblok/utils/UtilLinkItemSubtext/UtilLinkItemSubtext.vue';
import UtilRichtext from '~/components/storyblok/utils/UtilRichtext/UtilRichtext.vue';
import type {
  CmTableCellEmptyStoryblok,
  CmTableCellIconTextStoryblok,
  CmTableCellMediaCopyStoryblok,
  UtilLinkItemSubtextStoryblok,
} from '~/types/storyblok-generated';

type CellType =
  | UtilLinkItemSubtextStoryblok
  | CmTableCellIconTextStoryblok
  | CmTableCellMediaCopyStoryblok
  | CmTableCellEmptyStoryblok;

const props = defineProps<{
  blok: CellType;
  isDark?: boolean;
  isCellTitle?: boolean;
  isFullWidth?: boolean;
  isMobileTable?: boolean;
  titleCell?: CellType;
  cellsInRowCount: number;
  tableHasTitleCell: boolean;
}>();

/**
 * Get the approximate width of the cell image based on the number of cells in a row and the table layout
 */
const breakpointsWidthMap = computed(() => {
  if (props.blok.component !== 'cm_table-cell-media-copy') {
    return { 0: 0 };
  }

  if (props.blok.is_image_icon) {
    return { 0: 80 };
  }

  const breakpointsWidthMapMobile = {
    '0': 529,
    '576': 706,
    '768': 942,
  };
  const breakpointsWidthMapDesktop = {
    '1024': 1325,
    '1441': 1767,
    '1921': 2244,
    '2440': 1920,
  };

  const gapHead = 16; // We go with the smallest gap here so that we don't have to listen to resizes
  const gapCell = 24;
  let totalGap = 0;
  let modifier = 1;
  if (props.tableHasTitleCell) {
    // The table has a head cell and therefore the body is only 2/3 of the width
    modifier = modifier * (2 / 3);
    totalGap += gapHead;
  }
  // We check how many cells are in a row and adjust the width accordingly
  modifier = modifier * (1 / props.cellsInRowCount);
  totalGap += gapCell * (props.cellsInRowCount - 1);

  // Cells have a max width of 85.7142%
  modifier = modifier * 0.85714;

  return {
    // Mobile is always the same
    ...breakpointsWidthMapMobile,
    ...Object.fromEntries(
      Object.entries(breakpointsWidthMapDesktop).map(([key, value]) => [key, Math.ceil(value * modifier - totalGap)])
    ),
  };
});
</script>

<style src="./ItemTableStandardCell.scss" lang="scss" scoped />
