<template>
  <div
    ref="$baseRichtext"
    class="base-richtext"
    :class="[
      isDark ? 'is-dark' : 'is-light',
      {
        'has-prose-styling': isProseEnabled,
        'has-prose-headline-styling': isProseEnabled && isProseHeadlinesEnabled,
        'has-prose-paragraph-styling': isProseEnabled && isProseParagraphsEnabled,
        'is-text-large': isProseEnabled && textSize === 'large',
        'is-text-medium': isProseEnabled && textSize === 'medium',
      },
    ]"
    v-html="content"></div>
</template>

<script lang="ts" setup>
import type { ISbRichtext } from 'storyblok-js-client';
import RichTextResolver from 'storyblok-js-client/richTextResolver';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useTransition } from '~/composables/useTransition';
import { normalizeUrl, urlIsExternal } from '~/utils/url';

const { removeAdvancedHover, addAdvancedHover } = useTransition();

const $baseRichtext = ref<HTMLElement>();

const props = withDefaults(
  defineProps<{
    text?: ISbRichtext;
    isDark?: boolean;
    textSize?: 'large' | 'medium';
    removeInlineColorStyles?: boolean;
    /**
     * Add styling to the richtext/prose
     */
    isProseEnabled?: boolean;
    isProseHeadlinesEnabled?: boolean;
    isProseParagraphsEnabled?: boolean;
    preventInlineIcons?: boolean;
  }>(),
  {
    textSize: 'large',
    removeInlineColorStyles: true,
    isProseEnabled: true,
    isProseHeadlinesEnabled: true,
    isProseParagraphsEnabled: true,
  }
);

const resolver = new RichTextResolver();
const content = computed(() => {
  if (!props.text) {
    return '';
  }

  let newContent = resolver.render(props.text);

  // We allow the use of &shy; and &nbsp; in the richtext
  newContent = newContent.replaceAll('&amp;shy;', '&shy;').replaceAll('&amp;nbsp;', '&nbsp;');

  // Product names should not break
  newContent = newContent.replaceAll(/(WMF) (\d{3,5}) ([A-z]+)/g, '$1&nbsp;$2&nbsp;$3');

  return newContent;
});

/**
 * Function to add inline icons and better hover
 */
function addInlineIcons() {
  if (!$baseRichtext.value) {
    return;
  }

  // External
  const externalHTML = document.getElementById('fi_arrow-top-right')?.innerHTML;

  // Removing empty links
  const links = Array.from($baseRichtext.value.querySelectorAll('a')).filter(link => link.text.trim().length);

  links.forEach(link => {
    const href = link.getAttribute('href') ?? '';
    const target = link.getAttribute('target') ?? '';
    const isExternal = target === '_blank' || urlIsExternal(href);

    if (!isExternal) {
      // Normalize internal urls
      link.setAttribute('href', normalizeUrl(href));
    }

    if (isExternal && link.text && !props.preventInlineIcons) {
      link.innerHTML += externalHTML;
      link.classList.add('is-external', 'hover-advanced');
    } else {
      link.classList.add('hover-advanced');
    }

    if (isExternal) {
      link.setAttribute('target', '_blank');
      link.classList.add('is-external');
    }
  });
}

/**
 * Function to remove empty <p> tags
 */
function removeEmptyParagraphs() {
  if (!$baseRichtext.value) {
    return;
  }
  const paragraphs = $baseRichtext.value.querySelectorAll('p');
  paragraphs.forEach(paragraph => {
    if (!paragraph.textContent || paragraph.textContent.trim() === '') {
      paragraph.remove();
    }
  });
}

onBeforeUnmount(() => {
  if ($baseRichtext.value) {
    removeAdvancedHover($baseRichtext.value);
  }
});

onMounted(() => {
  removeEmptyParagraphs();
  addInlineIcons();
  if ($baseRichtext.value) addAdvancedHover($baseRichtext.value);
});
</script>

<style src="./BaseRichtext.scss" lang="scss" scoped />
