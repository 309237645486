<template>
  <div
    ref="$captcha"
    class="base-captcha cf-turnstile"
    :class="[isDark ? 'is-dark' : 'is-light', { 'has-error': errorMessage && isTouched }]"></div>
  <div v-if="errorMessage && isTouched" class="error">
    {{ errorMessage }}
  </div>
</template>
<script setup lang="ts">
import { useDebounceFn, useScriptTag } from '@vueuse/core';
import { useRuntimeConfig } from 'nuxt/app';
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';

const props = defineProps<{
  isDark?: boolean;
  modelValue?: string;
  isTouched?: boolean;
  errorMessage?: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', token: string): void;
}>();

defineExpose({ getToken });

const config = useRuntimeConfig();

const $captcha = ref<HTMLElement>();
const scriptLoaded = ref<boolean>(false);
const widgetInitiated = ref<boolean>(false);

useScriptTag('https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit', onScriptLoad);

function onScriptLoad() {
  scriptLoaded.value = true;
}

function getToken(forceRefresh: boolean) {
  if (!$captcha.value) {
    return;
  }
  const isExpired = window.turnstile.isExpired($captcha.value);
  if (isExpired || forceRefresh) {
    window.turnstile.reset($captcha.value);
  }
  const token = window.turnstile.getResponse($captcha.value) as string | null;
  return token;
}

const debouncedRerenderWidget = useDebounceFn(async () => {
  removeWidget();
  await nextTick();
  renderWidget();
}, 500);

function removeWidget() {
  if (!$captcha.value) {
    return;
  }
  window.turnstile.remove($captcha.value);
  if ($captcha.value) {
    $captcha.value.innerHTML = '';
  }
}

function renderWidget() {
  if (!$captcha.value) {
    return;
  }
  window.turnstile.render($captcha.value, {
    sitekey: config.public.TURNSTILE_PUBLIC_KEY,
    theme: props.isDark ? 'dark' : 'light',
    'refresh-timeout': 'never',
    language: document.documentElement.lang || 'auto',
    callback: (token: string) => {
      emit('update:modelValue', token);
    },
  });
}

watch(
  () => scriptLoaded.value,
  () => {
    if (!widgetInitiated.value) {
      widgetInitiated.value = true;
      renderWidget();
    }
  }
);

onBeforeUnmount(() => {
   
  window.removeEventListener('resize', debouncedRerenderWidget);
   
  window.removeEventListener('scrolltrigger', debouncedRerenderWidget);
});

onMounted(() => {
  if (scriptLoaded.value) {
    widgetInitiated.value = true;
    renderWidget();
  }
   
  window.addEventListener('resize', debouncedRerenderWidget);
   
  window.addEventListener('scrolltrigger', debouncedRerenderWidget);
});
</script>

<style src="~/assets/scss/_form-field.scss" lang="scss" scoped />
<style src="./BaseCaptcha.scss" lang="scss" />
