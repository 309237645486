<template>
  <UtilLinkItemIcon
    v-editable="blok"
    :blok="itemIconBlok"
    :subtext="blok.subtext"
    :isDark="isDark"
    :asset="blok.asset" />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import UtilLinkItemIcon from '~/components/storyblok/utils/UtilLinkItemIcon/UtilLinkItemIcon.vue';
import type { UtilLinkItemIconStoryblok, UtilLinkItemSubtextStoryblok } from '~/types/storyblok-generated';

const props = defineProps<{
  blok: UtilLinkItemSubtextStoryblok;
  isDark?: boolean;
}>();

// This wrapper is needed to avoid having subtexts in all link items
const itemIconBlok = computed<UtilLinkItemIconStoryblok>(() => ({
  component: 'util_link-item-icon',
  icon: props.blok.icon,
  text: props.blok.text,
  link: props.blok.link,
  icon_position: 'start',
  _uid: props.blok._uid,
}));
</script>
