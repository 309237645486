import type { ISbStoryData } from '@storyblok/vue';
import * as THREE from 'three';
import type { DataProductStoryblok } from '~/types/storyblok-generated';
import type { AppStateInstance } from '~/webglApp/core/appState';
import type { AssetManagerInstance } from '~/webglApp/core/assetManager';
import type { EngineInstance } from '~/webglApp/core/engine';
import { sendEvent } from '~/webglApp/utils/showroomEvents';
import { ShowroomEvent, type MachineConfig, type ShowroomEventsMap } from '~/webglApp/webGlApp.types';
import type { BlueNoiseInstance } from '../blueNoise/blueNoise';
import Machine from '../machine/Machine';
import { getMachinesConfig } from './machinesConfig';

export type MachinesInstance = InstanceType<typeof Machines>;

export class Machines {
  appState: AppStateInstance;
  featuredMachinesData: ISbStoryData<DataProductStoryblok>[];
  initialMachinesData: ISbStoryData<DataProductStoryblok>[];
  machinesConfig: MachineConfig[];
  envTexture: THREE.Texture | null = null;
  container = new THREE.Object3D();
  collection: Machine[] = [];
  filteredCollection: Machine[] = [];
  inViewCollection: Machine[] = [];
  v1 = new THREE.Vector3();
  isActive: boolean = false;
  prerenderStarted: boolean = false;
  prerenderIsActive: boolean = false;
  prerenderTime = 0;
  prerenderDuration = 0.1;
  machinesInitStarted = false;

  constructor(
    appState: AppStateInstance,
    engine: EngineInstance,
    assetManager: AssetManagerInstance,
    featuredMachinesData: ISbStoryData<DataProductStoryblok>[],
    initialMachinesData: ISbStoryData<DataProductStoryblok>[]
  ) {
    this.appState = appState;
    this.featuredMachinesData = featuredMachinesData;
    this.initialMachinesData = initialMachinesData;
    this.machinesConfig = getMachinesConfig(appState.qualityLvl);

    assetManager.addToLoader('textures/environment-map.hdr', loadedAsset => {
      if (!(loadedAsset instanceof THREE.Texture)) {
        console.error('Env texture is not a texture');
        return;
      }
      this.envTexture = loadedAsset;
      this.envTexture.flipY = false;
      const pmremGenerator = new THREE.PMREMGenerator(engine.renderer);
      pmremGenerator.compileEquirectangularShader();
      this.envTexture = pmremGenerator.fromEquirectangular(this.envTexture).texture;
      this.envTexture.dispose();
      pmremGenerator.dispose();
    });

    this.featuredMachinesData.forEach((machineData, index) => {
      const machineConfig = this.machinesConfig.find(entry => entry.name === machineData.name);

      if (machineConfig) {
        const machine = new Machine(appState, assetManager, engine, machineConfig, index, false);
        const machineCopy = new Machine(appState, assetManager, engine, machineConfig, index, true);

        this.collection.push(machine);
        this.collection.push(machineCopy);

        this.container.add(machine.container);
        this.container.add(machineCopy.container);
      } else {
        console.error('[Machines] preInit() not found machine:', machineData.name);
      }
    });

    this.appState.webGlCanvas.addEventListener(ShowroomEvent.NextMachine, this.onNextMachine.bind(this));
    this.appState.webGlCanvas.addEventListener(ShowroomEvent.PreviousMachine, this.onPreviousMachine.bind(this));
    this.appState.webGlCanvas.addEventListener(ShowroomEvent.SwipeProgress, this.onSwipeInProgress.bind(this));
    this.appState.webGlCanvas.addEventListener(ShowroomEvent.SwipeCancelled, this.onSwipeCanceled.bind(this));
  }

  async init(blueNoise: BlueNoiseInstance) {
    if (this.machinesInitStarted) {
      return;
    }
    if (!this.envTexture) {
      // Env texture is not loaded yet

      setTimeout(() => this.init(blueNoise), 100);
      return;
    }
    this.machinesInitStarted = true;
    const machines = this.getMachinesInOrder(this.initialMachinesData);
    this.setFilteredCollection(machines);

    for (let i = 0; i < this.collection.length; i++) {
      this.collection[i].init(this.envTexture, blueNoise, i === 0);
      // Wait for 20ms to allow the renderer to render the machine
      await new Promise(resolve => setTimeout(resolve, 20));
      sendEvent(this.appState.webGlCanvas, ShowroomEvent.MachinesLoadingProgress, (i + 1) / this.collection.length);
    }
  }

  show() {
    this.showFilteredCollection();
    this.setInViewCollection();
    this.updateMachinesVisibility();
    this.isActive = true;
  }

  hide() {
    this.isActive = false;
    this.collection.forEach(machine => {
      machine.hide();
    });
  }

  resize(width: number) {
    this.collection.forEach(machine => {
      machine.resize(width);
    });
    this.alignFilteredCollection();
  }

  destroy() {
    this.collection.forEach(machine => {
      machine.destroy();
    });
  }

  update() {
    if (this.isActive) {
      this.collection.forEach(machine => {
        machine.update();
      });
    }
  }

  /**
   * We only want to show 5 machines at a time and update the visibility of the machines after a transition.
   */
  private updateMachinesVisibility() {
    this.filteredCollection.map((machine, index) => {
      machine.show();
      if ((index >= 0 && index <= 3) || index === this.filteredCollection.length - 1) {
        machine.show();
      } else {
        machine.hide();
      }
    });
  }

  /**
   * Retrieves machines by their names from the collection.
   * Checks if a machine is already collected. If so, appends '-copy'
   * to the name to find the duplicate machine. Ensures each machine
   * is collected only once.
   */
  private getMachinesByName(machineNames: string[]): Machine[] {
    const machines: Machine[] = [];
    const collectedMachines: Record<string, boolean> = {};

    machineNames.forEach(name => {
      const machineName = collectedMachines[name] ? `${name}-copy` : name;
      const machine = this.collection.find(collectionMachine => collectionMachine.name === machineName);
      if (machine) {
        machines.push(machine);
        collectedMachines[name] = true;
      }
    });

    return machines;
  }

  /**
   * Returns an array of machines in the order they should be displayed.
   */
  private getMachinesInOrder(machines: ISbStoryData<DataProductStoryblok>[]): Machine[] {
    const machineNames = machines.map(machine => machine.name);
    let machinesInOrder;

    if (machineNames.length === 1) {
      const orderedMachineNames = [machineNames[0]];
      machinesInOrder = this.getMachinesByName(orderedMachineNames);
    } else if (machineNames.length === 2) {
      // Transform [A, B] to [B, A, B, A]
      const orderedMachineNames = [machineNames[1], machineNames[0], machineNames[1], machineNames[0]];
      machinesInOrder = this.getMachinesByName(orderedMachineNames);
    } else if (machineNames.length === 3) {
      // Transform [A, B, C] to [C, A, B, C, A, B]
      const orderedMachineNames = [
        machineNames[2],
        machineNames[0],
        machineNames[1],
        machineNames[2],
        machineNames[0],
        machineNames[1],
      ];
      machinesInOrder = this.getMachinesByName(orderedMachineNames);
    } else if (machineNames.length > 3) {
      //Transform from [A, B, C, n] to [n, A, B, C, n]
      const orderedMachineNames = [...machineNames];
      const lastMachineName = orderedMachineNames.pop();
      if (lastMachineName) {
        orderedMachineNames.unshift(lastMachineName);
      }
      machinesInOrder = this.getMachinesByName(orderedMachineNames);
    }

    return machinesInOrder ?? [];
  }

  private setFilteredCollection(machines: Machine[]) {
    this.filteredCollection.length = 0;
    machines.forEach(machine => {
      this.filteredCollection.push(machine);
    });
  }

  private setInViewCollection() {
    this.inViewCollection.length = 0;
    this.filteredCollection.forEach((machine, index) => {
      if (index === 0 || index === 1 || index === 2) {
        this.inViewCollection.push(machine);
      }
    });
  }

  private showFilteredCollection() {
    this.filteredCollection.forEach(machine => {
      machine.show();
    });
  }

  private alignFilteredCollection() {
    // Due the fact that we have to prerender the machines initially all at origin position(0,0,0)
    // We now have to make sure first, that they are in general are lined up in a row
    // After that we align them to the respective viewport positions left/center/right
    if (this.filteredCollection.length === 1) {
      const position = this.v1.set(0, 0, 0);
      const machine = this.filteredCollection[0];
      machine.setPosition(position);
      machine.align(1);
    } else {
      this.filteredCollection.forEach((machine, index) => {
        const position = this.v1.set((index - 1) * this.appState.visibleWidthOfCamera, 0, 0);
        machine.setPosition(position);
      });

      this.filteredCollection.forEach((machine, index) => {
        machine.align(index);
      });
      sendEvent(this.appState.webGlCanvas, ShowroomEvent.Zoom, this.filteredCollection[1].mesh?.userData.zoomValue);
    }
  }

  private reorderFilteredCollection(toLeft = true, toRight = false) {
    const farthestLeftMachine = this.filteredCollection[0];
    const farthestRightMachine = this.filteredCollection[this.filteredCollection.length - 1];
    if (!farthestLeftMachine.mesh || !farthestRightMachine.mesh) {
      return;
    }
    const moveDistance = this.appState.visibleWidthOfCamera * 0.5;
    let position;

    if (toLeft === true) {
      const newPositionX = farthestLeftMachine.mesh.position.x - moveDistance;
      position = this.v1.set(newPositionX, 0, 0);
      farthestRightMachine.setPosition(position);
    } else if (toRight === true) {
      const newPositionX = farthestRightMachine.mesh.position.x + moveDistance;
      position = this.v1.set(newPositionX, 0, 0);
      farthestLeftMachine.setPosition(position);
    }
  }

  private reorderFilteredCollectionArray() {
    this.filteredCollection.sort((a, b) => {
      const aPositionX = a.mesh?.position.x ?? 0;
      const bPositionX = b.mesh?.position.x ?? 0;
      return aPositionX - bPositionX;
    });
  }

  setMachines(newMachines: ISbStoryData<DataProductStoryblok>[]) {
    this.collection.forEach(machine => {
      // We hide all machines
      machine.hide();
    });
    const machines = this.getMachinesInOrder(newMachines);
    this.setFilteredCollection(machines);
    this.showFilteredCollection();
    this.alignFilteredCollection();
    this.setInViewCollection();
  }

  private onNextMachine(event: ShowroomEventsMap[ShowroomEvent.NextMachine]) {
    sendEvent(this.appState.webGlCanvas, ShowroomEvent.MachinesMoveStart, {
      isSwiped: event.detail.isSwiped,
      currentMachineName: this.inViewCollection[1].name,
    });

    const zoomPromise = new Promise(resolve => {
      this.appState.webGlCanvas.addEventListener(ShowroomEvent.ZoomTweenEnd, () => {
        resolve(true);
      });
    });
    sendEvent(this.appState.webGlCanvas, ShowroomEvent.ZoomTween, this.filteredCollection[2].mesh?.userData.zoomValue);

    const movePromises = this.filteredCollection.map((machine, index) => {
      return machine.moveToLeft(index);
    });

    Promise.all([zoomPromise, ...movePromises])
      .then(() => {
        // Realign the machines in the row
        this.reorderFilteredCollection(false, true);
        // Reorder to reflect the new positions of machines
        // Needed for alignFilteredCollection() to ensure calculating with correct machines
        this.reorderFilteredCollectionArray();
        this.setInViewCollection();
        this.updateMachinesVisibility();
        sendEvent(this.appState.webGlCanvas, ShowroomEvent.Zoom, this.filteredCollection[1].mesh?.userData.zoomValue);
        sendEvent(this.appState.webGlCanvas, ShowroomEvent.MachinesMoveEnd, {
          isSwiped: event.detail.isSwiped,
          currentMachineName: this.inViewCollection[1].name,
        });
      })
      .catch(() => console.error('Error while moving machines'));
  }

  private onPreviousMachine(event: ShowroomEventsMap[ShowroomEvent.PreviousMachine]) {
    sendEvent(this.appState.webGlCanvas, ShowroomEvent.MachinesMoveStart, {
      isSwiped: event.detail.isSwiped,
      currentMachineName: this.inViewCollection[1].name,
    });
    this.reorderFilteredCollection(true, false);
    this.reorderFilteredCollectionArray();
    this.setInViewCollection();

    const zoomPromise = new Promise(resolve => {
      this.appState.webGlCanvas.addEventListener(ShowroomEvent.ZoomTweenEnd, () => {
        resolve(true);
      });
    });
    sendEvent(this.appState.webGlCanvas, ShowroomEvent.ZoomTween, this.filteredCollection[1].mesh?.userData.zoomValue);

    const movePromises = this.filteredCollection.map((machine, index) => {
      return machine.moveToRight(index);
    });

    Promise.all([zoomPromise, ...movePromises])
      .then(() => {
        this.updateMachinesVisibility();
        sendEvent(this.appState.webGlCanvas, ShowroomEvent.MachinesMoveEnd, {
          isSwiped: event.detail.isSwiped,
          currentMachineName: this.inViewCollection[1].name,
        });
      })
      .catch(() => console.error('Error while moving machines'));
  }

  private onSwipeInProgress(event: ShowroomEventsMap[ShowroomEvent.SwipeProgress]) {
    const swipeAmount = event.detail;
    this.inViewCollection.forEach(machine => {
      if (!machine.mesh) {
        return;
      }
      machine.mesh.position.x += swipeAmount;
    });
  }

  private onSwipeCanceled() {
    const promises = this.inViewCollection.map((machine, index) => {
      return machine.alignTween(index);
    });
    Promise.all(promises)
      .then(() => {
        sendEvent(this.appState.webGlCanvas, ShowroomEvent.MachinesRecenterEnd, null);
      })
      .catch(() => console.error('Error while recentering machines'));
  }
}
