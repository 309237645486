import { hasFullWebmSupport } from '~/utils/browser';

const VIDEO_FORMATS = ['mp4', 'webm', 'm4v'];

export function isVideo(src: string | undefined | null) {
  if (!src) {
    return false;
  }
  return VIDEO_FORMATS.some(format => src.endsWith(format));
}

export function isVideoWebm(src: string | undefined | null) {
  return src?.endsWith('webm');
}

export function getTransparentVideoSource(sources: (string | undefined | null)[] | undefined): string | undefined {
  const preferredSuffix = hasFullWebmSupport() ? 'webm' : 'mp4';

  if (Array.isArray(sources) && sources.length > 0) {
    return sources.find(source => source?.includes(preferredSuffix)) ?? undefined;
  }

  return undefined;
}
