/**
 * This service is based on the scrolly-video package by Daniel Kao.
 * @see https://github.com/dkaoster/scrolly-video/blob/main/src/videoDecoder.js
 *
 * We can't use this package directly but would like to use the WebCodecs functionality.
 * Therefore we have to use this service of the package and adapt it to our needs.
 */

import * as Mp4box from 'mp4box';

/**
 * Taken from https://github.com/w3c/webcodecs/blob/main/samples/mp4-decode/mp4_demuxer.js
 */
class Writer {
  idx: number;
  size: number;
  data: Uint8Array;

  constructor(size: number) {
    this.data = new Uint8Array(size);
    this.idx = 0;
    this.size = size;
  }

  getData() {
    if (this.idx !== this.size) throw new Error('Mismatch between size reserved and sized used');
    return this.data.slice(0, this.idx);
  }

  writeUint8(value: number) {
    this.data.set([value], this.idx);
    this.idx += 1;
  }

  writeUint16(value: number) {
    const arr = new Uint16Array(1);
    arr[0] = value;
    const buffer = new Uint8Array(arr.buffer);
    this.data.set([buffer[1], buffer[0]], this.idx);
    this.idx += 2;
  }

  writeUint8Array(value: number[]) {
    this.data.set(value, this.idx);
    this.idx += value.length;
  }
}

/**
 * Taken from https://github.com/w3c/webcodecs/blob/main/samples/mp4-decode/mp4_demuxer.js
 *
 * @param avccBox
 * @returns {*}
 */
function getExtradata(avccBox: any): any {
  let i;
  let size = 7;
  for (i = 0; i < avccBox.SPS.length; i += 1) {
    // Nalu length is encoded as a uint16.
    size += 2 + avccBox.SPS[i].length;
  }
  for (i = 0; i < avccBox.PPS.length; i += 1) {
    // Nalu length is encoded as a uint16.
    size += 2 + avccBox.PPS[i].length;
  }

  const writer = new Writer(size);

  writer.writeUint8(avccBox.configurationVersion);
  writer.writeUint8(avccBox.AVCProfileIndication);
  writer.writeUint8(avccBox.profile_compatibility);
  writer.writeUint8(avccBox.AVCLevelIndication);
  writer.writeUint8(avccBox.lengthSizeMinusOne + (63 << 2));

  writer.writeUint8(avccBox.nb_SPS_nalus + (7 << 5));
  for (i = 0; i < avccBox.SPS.length; i += 1) {
    writer.writeUint16(avccBox.SPS[i].length);
    writer.writeUint8Array(avccBox.SPS[i].nalu);
  }

  writer.writeUint8(avccBox.nb_PPS_nalus);
  for (i = 0; i < avccBox.PPS.length; i += 1) {
    writer.writeUint16(avccBox.PPS[i].length);
    writer.writeUint8Array(avccBox.PPS[i].nalu);
  }

  return writer.getData();
}

/**
 * DecodeVideo takes an url to a mp4 file and converts it into frames.
 *
 * The steps for this are:
 *  1. Determine the codec for this video file and demux it into chunks.
 *  2. Read the chunks with VideoDecoder as fast as possible.
 *  3. Return an array of frames that we can efficiently draw to a canvas.
 *
 * @param src
 * @param VideoDecoder
 * @param EncodedVideoChunk
 * @param emitFrame
 * @param debug
 * @returns {Promise<unknown>}
 */
export async function decodeVideo(src: string, emitFrame: (bitmap: ImageBitmap) => void): Promise<unknown> {
  return new Promise((resolve, reject) => {
    try {
      // Uses mp4box for demuxing
      const mp4boxfile = Mp4box.createFile();
      let codec;

      /**
       * Create a new VideoDecoder
       */
      const decoder = new VideoDecoder({
        output: (frame: any) => {
          createImageBitmap(frame, { resizeQuality: 'low' }).then(bitmap => {
            emitFrame(bitmap);
            frame.close();
            if (decoder.decodeQueueSize <= 0) {
              // Give it an extra half second to finish everything
              setTimeout(() => {
                if (decoder.state !== 'closed') {
                  decoder.close();
                  resolve(true);
                }
              }, 500);
            }
          });
        },
        error: (e: Error) => {
          console.error(e);
          reject(e);
        },
      });

      mp4boxfile.onReady = (info: any) => {
        if (info && info.videoTracks && info.videoTracks[0]) {
          [{ codec }] = info.videoTracks;

          // Gets the avccbox used for reading extradata
          const avccBox = mp4boxfile.moov?.traks[0].mdia.minf.stbl.stsd.entries[0].avcC;
          const extradata = getExtradata(avccBox);

          // Configure decoder
          decoder.configure({ codec, description: extradata });

          // Setup mp4box file for breaking it into chunks
          mp4boxfile.setExtractionOptions(info.videoTracks[0].id);
          mp4boxfile.start();
        } else {
          reject(new Error('URL provided is not a valid mp4 video file.'));
        }
      };

      mp4boxfile.onSamples = (_track_id: any, _ref: any, samples: any) => {
        for (let i = 0; i < samples.length; i += 1) {
          const sample = samples[i];
          const type = sample.is_sync ? 'key' : 'delta';

          const chunk = new EncodedVideoChunk({
            type,
            timestamp: sample.cts,
            duration: sample.duration,
            data: sample.data,
          });
          decoder.decode(chunk);
        }
      };

      // Fetches the file into arraybuffers
      fetch(src).then(res => {
        const reader = res.body?.getReader();
        let offset = 0;

        function appendBuffers({ done, value }: { done: boolean; value?: any }): any {
          if (done) {
            mp4boxfile.flush();
            decoder.flush();
            return null;
          }

          const buf = value.buffer;
          buf.fileStart = offset;
          offset += buf.byteLength;
          mp4boxfile.appendBuffer(buf);

          return reader?.read().then(t => {
            appendBuffers(t);
          });
        }

        return reader?.read().then(({ value, done }) => appendBuffers({ value, done }));
      });
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
}
