<template>
  <div class="control">
    <BaseIcon name="fi_search" :size="16" color="inherit" class="search-icon" />
    <input
      ref="$searchInput"
      v-model="query"
      class="input"
      :class="{ 'has-results-open': resultsOpen, 'has-autocomplete-open': showAutocompleteDropdown }"
      type="text"
      :placeholder="t('EMMapSearchInput.placeholder')"
      @input="handleSearchInput"
      @focus="handleFocus"
      @keydown.enter.prevent="handleSubmit" />
    <button class="button" type="button" @click="handleButtonClick">
      <BaseIcon
        name="fi_navigation"
        :size="16"
        color="inherit"
        class="button-icon navigation-icon"
        :class="{ 'is-hidden': isLoading || hasSubmitButton }" />
      <BaseIcon
        name="fi_arrow-right"
        :size="16"
        color="inherit"
        class="button-icon arrow-icon"
        :class="{ 'is-hidden': isLoading || !hasSubmitButton }" />
      <div v-if="isLoading" class="loading-spinner"></div>
    </button>

    <ul
      v-if="showAutocompleteDropdown"
      ref="$autocompleteDropdown"
      class="flyout autocomplete-dropdown"
      :style="floatingAutocompleteStyles">
      <li v-for="result in autocompleteResults" :key="result.placeId">
        <button
          class="autocomplete-entry text-medium-regular"
          type="button"
          @click="handleAutocompleteResultClick(result)">
          {{ result.label }}
        </button>
      </li>
    </ul>

    <div v-if="showSearchError" ref="$errorDropdown" class="flyout" :style="floatingErrorStyles">
      <p class="error-message text-medium-regular">
        {{ searchError }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import { autoUpdate, offset, size, useFloating } from '@floating-ui/vue';
import { onClickOutside, useDebounceFn } from '@vueuse/core';
import { onBeforeMount, onMounted, ref, watch } from 'vue';
import { BaseIcon } from '~/components/base';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useGoogleMaps } from '~/composables/useGoogleMaps';
import type { AutocompleteResultEntry, LocationResult } from '~/types/google-maps';

defineProps<{
  resultsOpen: boolean;
}>();

const emit = defineEmits<{
  (e: 'locationChange', selectedLocationRes: LocationResult | null): void;
  (e: 'focusChange', isVisible: boolean, isResultSelect: boolean): void;
}>();

const $autocompleteDropdown = ref<HTMLElement>();
const showAutocompleteDropdown = ref(false);
const $errorDropdown = ref<HTMLElement>();
const searchError = ref<string | null>(null);
const showSearchError = ref(false);
const showSearchErrorTimeout = ref<ReturnType<typeof setTimeout> | null>(null);
const autocompleteTimeout = ref<ReturnType<typeof setTimeout> | null>(null);
const query = ref('');
const selectedLocationRes = ref<LocationResult | null>(null);
const autocompleteResults = ref<AutocompleteResultEntry[]>([]);
const hasSubmitButton = ref(false);
const ignoreScrolling = ref(false);
const $searchInput = ref<HTMLInputElement | null>(null);
let focusTimeout1: ReturnType<typeof setTimeout>;
let focusTimeout2: ReturnType<typeof setTimeout>;

const { state: breakpointState } = useBreakpoint();
const { t } = useI18n();

const floatingUiOptions = {
  strategy: 'fixed' as const,
  whileElementsMounted: autoUpdate,
  middleware: [
    size({
      apply({ rects, elements }) {
        Object.assign(elements.floating.style, {
          width: `${rects.reference.width}px`,
        });
      },
    }),
    offset(-53),
  ],
};

const { floatingStyles: floatingAutocompleteStyles } = useFloating(
  $searchInput,
  $autocompleteDropdown,
  floatingUiOptions
);
const { floatingStyles: floatingErrorStyles } = useFloating($searchInput, $errorDropdown, floatingUiOptions);

const { getLocationByQuery, requestPlacesAutocomplete, getLocationByPlaceId, isLoading } = useGoogleMaps();
const debouncedGetLocationByQuery = useDebounceFn(getLocationByQuery, 500);

onClickOutside($autocompleteDropdown, () => {
  emit('focusChange', false, false);
  showAutocompleteDropdown.value = false;
});

const closeDropdownTimeout = ref<ReturnType<typeof setTimeout> | undefined>(undefined);
function handleSearchInput() {
  hasSubmitButton.value = !!query.value;
  if (query.value.length >= 3) {
    if (closeDropdownTimeout.value) {
      clearTimeout(closeDropdownTimeout.value);
    }
    if (autocompleteTimeout.value) {
      clearTimeout(autocompleteTimeout.value);
    }
    closeDropdownTimeout.value = setTimeout(() => {
      emit('focusChange', false, false);
      showAutocompleteDropdown.value = false;
    }, 3000);
    autocompleteTimeout.value = setTimeout(() => {
      void requestPlacesAutocomplete(query.value).then(results => {
        clearTimeout(closeDropdownTimeout.value);
        autocompleteResults.value = results ?? [];
        showSearchError.value = false;
        if (!results || results.length === 0) {
          return;
        }

        emit('focusChange', true, false);
        showAutocompleteDropdown.value = true;
      });
    }, 500);
  }
}

function handleFocus() {
  ignoreScrolling.value = true;
  emit('focusChange', true, false);
  if (window.innerWidth < 1024 && $searchInput.value) {
    focusTimeout1 = setTimeout(() => {
      if (!$searchInput.value) {
        return;
      }
      // On mobile we also scroll
      const targetScrollY = $searchInput.value.getBoundingClientRect().top + window.scrollY - 16;
      window.scrollTo({ top: targetScrollY, behavior: 'smooth' });
      focusTimeout2 = setTimeout(() => {
        ignoreScrolling.value = false;
      }, 400);
    }, 10);
  }
}

async function handleAutocompleteResultClick(result: AutocompleteResultEntry) {
  query.value = result.label;
  emit('focusChange', false, true);
  showAutocompleteDropdown.value = false;
  const locationRes = await getLocationByPlaceId(result.placeId);
  if (locationRes) {
    selectedLocationRes.value = locationRes;
  }
}

function triggerSearchError(message: string) {
  searchError.value = message;
  showSearchError.value = true;
  if (showSearchErrorTimeout.value) {
    clearTimeout(showSearchErrorTimeout.value);
  }
  showSearchErrorTimeout.value = setTimeout(() => {
    showSearchError.value = false;
  }, 3000);
}

async function handleSubmit() {
  if (autocompleteTimeout.value) {
    clearTimeout(autocompleteTimeout.value);
  }
  emit('focusChange', false, true);
  showAutocompleteDropdown.value = false;
  hasSubmitButton.value = false;
  if (query.value.length > 3) {
    const res = await debouncedGetLocationByQuery(query.value);
    if (res?.status === 'success') {
      selectedLocationRes.value = res;
      return;
    }
    triggerSearchError(res?.message ?? t('global.errorOccurred'));
  } else {
    triggerSearchError(t('EMMapSearchInput.atLeast4Characters'));
  }
}

function getBrowserLocation() {
  emit('focusChange', false, true);
  showAutocompleteDropdown.value = false;
  isLoading.value = true;
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(async pos => {
      // We still need to get the location from the API for the country code
      const res = await debouncedGetLocationByQuery(`${pos.coords.latitude},${pos.coords.longitude}`);
      if (res?.status === 'success') {
        selectedLocationRes.value = res;
        query.value = t('EMMapSearchInput.yourLocation');
      } else {
        triggerSearchError(res?.message ?? t('global.errorOccurred'));
      }
      isLoading.value = false;
    });
  }
}

async function handleButtonClick() {
  if (hasSubmitButton.value) {
    await handleSubmit();
  } else {
    getBrowserLocation();
  }
}

function handleScroll() {
  if (!breakpointState.isTablet && !ignoreScrolling.value) {
    emit('focusChange', false, false);
    showAutocompleteDropdown.value = false;
  }
}

watch(selectedLocationRes, value => {
  emit('locationChange', value);
  showSearchError.value = false;
});

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onBeforeMount(() => {
  clearTimeout(focusTimeout1);
  clearTimeout(focusTimeout2);
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style src="./EMMapSearchInput.scss" lang="scss" scoped />
