<template>
  <div ref="$el" class="em-showroom-volume-animation">
    <div ref="$wrapper" class="animation-container" :style="{ gap: cupBounds.gap + 'px' }">
      <div
        v-for="(_item, index) in cupNum"
        :key="'cup-' + index"
        class="cup"
        :style="{ width: cupBounds.width + 'px' }">
        <img v-if="index < 2" src="/images/cup-animation-fill.png" alt="" />
        <video
          v-if="index === 2"
          ref="$videoEl"
          muted
          playsinline
          poster="/videos/cup-animation-poster.jpg"
          src="/videos/cup-animation.mp4"
          @loadedmetadata="onMetaData"></video>
        <img v-else-if="index > 2" src="/images/cup-animation.png" alt="" />
      </div>
    </div>

    <div class="buttons">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { nextTick, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import type { ShowroomVolume } from './EMShowroom.types';

const $el = ref<HTMLElement>();
const $videoEl = ref<HTMLVideoElement[] | null>(null);
const $wrapper = ref<HTMLElement>();
const videoPlayState = ref<'paused' | 'start'>('paused');
const delays = [1, 0.5, 0];
const timelineSpeeds = [0.8, 1.25, 2];
const playbackRates = [0.8, 1.25, 2.75];

const cupNum = 4;
let isInitialized = false;

const props = withDefaults(
  defineProps<{
    step: ShowroomVolume;
  }>(),
  { step: 0 }
);

function onMetaData() {
  if (isInitialized) {
    return;
  }
  isInitialized = true;

  if (!$videoEl.value?.[0]) {
    return;
  }
  $videoEl.value[0].currentTime = 0;
  $videoEl.value[0].pause();
  void createTimeline();
  updateAnimation();
  timeline.play();
  window.addEventListener('resize', createTimeline);
}

const timeline = gsap.timeline({ repeat: -1, repeatDelay: 0, paused: true });

function updateAnimation() {
  timeline.timeScale(timelineSpeeds[props.step]);
  timeline.repeatDelay(delays[props.step]);
  if (!$videoEl.value?.[0]) {
    return;
  }
  $videoEl.value[0].playbackRate = playbackRates[props.step];
}

const cupBounds = reactive({
  width: 120,
  gap: 0,
});

function updateCupBounds() {
  if (!$el.value) {
    return;
  }
  const containerWidth = $el.value.offsetWidth;
  cupBounds.gap = $el.value.offsetWidth * 0.2;
  cupBounds.width = containerWidth / 2 - cupBounds.gap;
}

async function createTimeline() {
  await nextTick();
  updateCupBounds();
  timeline.clear();

  const cups = $wrapper.value?.querySelectorAll('.cup');
  if (!cups) {
    return;
  }

  timeline.set(videoPlayState, { value: 'start', overwrite: true }, 0);

  timeline.set(cups[0], { alpha: 0.25, scale: 1, overwrite: true }, 0);
  timeline.set(cups[1], { alpha: 1, scale: 1.2, overwrite: true }, 0);
  timeline.set(cups[2], { alpha: 0.25, scale: 1, overwrite: true }, 0);
  timeline.set(cups[3], { alpha: 0.25, scale: 1, overwrite: true }, 0);

  timeline.to(cups[0], { ease: 'sine.out', alpha: 0.25, scale: 1 }, 0);
  timeline.to(cups[1], { ease: 'sine.out', alpha: 0.25, scale: 1 }, 0);

  // Hero
  timeline.to(cups[2], { ease: 'sine.out', alpha: 1, scale: 1.2 }, 0);
  timeline.to(cups[3], { ease: 'sine.out', alpha: 0.25, scale: 1 }, 0);

  timeline.to(videoPlayState, { value: 'paused', ease: 'steps(1)', duration: 2 }, 0);

  if ($wrapper.value) {
    timeline.set(
      $wrapper.value,
      {
        x: -(cupBounds.width * 0.5),
        overwrite: true,
      },
      0
    );
  }
  const endPosition = cupBounds.width + cupBounds.gap + cupBounds.width * 0.5;

  if ($wrapper.value) {
    timeline.to(
      $wrapper.value,
      {
        duration: 1,
        ease: 'quad.out',
        x: -endPosition,
      },
      0
    );
  }
}

watch(
  () => videoPlayState.value,
  () => {
    if (videoPlayState.value !== 'start') {
      return;
    }
    if (!$videoEl.value?.[0]) {
      return;
    }
    $videoEl.value[0].pause();
    $videoEl.value[0].currentTime = 0;
    void $videoEl.value[0].play();
  }
);

watch(() => props.step, updateAnimation);

onUnmounted(() => {
  window.removeEventListener('resize', createTimeline);
});

onMounted(async () => {
  await nextTick();
});
</script>

<style src="./EMShowroomVolumeAnimation.scss" lang="scss" scoped />
