import type { AppStateInstance } from '../core/appState';
import type { EngineInstance } from '../core/engine';
import { CameraControlsLookAt, type CameraControlsLookAtInstance } from './cameraControlsLookAt';

export type CameraControlsInstance = InstanceType<typeof CameraControls>;

export class CameraControls {
  isActive: boolean = false;
  isMobileDevice: boolean;
  cameraControlsLookAt: CameraControlsLookAtInstance | null = null;

  constructor(appState: AppStateInstance) {
    this.isMobileDevice = appState.isMobileDevice;
    if (!this.isMobileDevice) {
      this.cameraControlsLookAt = new CameraControlsLookAt(appState);
    }
  }

  show() {
    this.isActive = true;
    if (!this.isMobileDevice) {
      this.cameraControlsLookAt?.show();
    }
  }

  hide() {
    this.isActive = false;
  }

  update(engine: EngineInstance) {
    if (!this.isActive) {
      return;
    }
    if (!this.isMobileDevice) {
      this.cameraControlsLookAt?.update(engine);
    }
  }
}
