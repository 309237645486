<template>
  <video
    ref="$video"
    :poster="posterSrc['1x'] ?? undefined"
    :muted="muted"
    :playsinline="playsinline"
    :autoplay="autoplay"
    :loop="loop"
    :src="(!loadWhenInView || videoInView) && src ? src : undefined"
    :controls="controls"
    disablePictureInPicture
    controlslist="nodownload"
    @canplay="onCanPlay"></video>
</template>

<script setup lang="ts">
import { useHead } from '#imports';
import { computed, onBeforeUnmount, onMounted, ref, watch, type VideoHTMLAttributes } from 'vue';
import { getActiveBreakpointWidth, getSrcResized } from '~/utils/image';

const props = withDefaults(
  defineProps<{
    src: string | null;
    poster?: string | null;
    posterBreakPointsWidthMap?: Record<string, number>;
    posterHeightRatio?: number[];
    autoplay?: VideoHTMLAttributes['autoplay'];
    muted?: VideoHTMLAttributes['muted'];
    playsinline?: VideoHTMLAttributes['playsinline'];
    loop?: VideoHTMLAttributes['loop'];
    controls?: VideoHTMLAttributes['controls'];
    /**
     * Load the video when it is in view.
     * Define the percentage of the video that needs to be in view.
     */
    loadWhenInView?: boolean;
    preloadPoster?: boolean;
  }>(),
  {
    autoplay: true,
    muted: true,
    playsinline: true,
  }
);

const emit = defineEmits<{
  (e: 'load'): void;
}>();

const $video = ref<HTMLVideoElement | null>(null);
const videoInView = ref(false);
const observer = ref<IntersectionObserver | null>(null);

/**
 * We try to get a resized poster image based on the current breakpoint.
 */
const posterSrc = computed(() =>
  props.poster && props.posterBreakPointsWidthMap
    ? getSrcResized({
        src: props.poster,
        width: getActiveBreakpointWidth(props.posterBreakPointsWidthMap),
        heightRatio: props.posterHeightRatio,
        cropFitIn: false,
      })
    : { '1x': props.poster, '2x': props.poster }
);

function onCanPlay() {
  if (props.autoplay && $video.value) {
    void $video.value.play();
  }
  emit('load');
}

/**
 * Preload the poster image
 */
if (props.preloadPoster) {
  useHead({
    link: [
      {
        rel: 'preload',
        as: 'image',
        href: posterSrc.value['1x'],
      },
      {
        rel: 'preload',
        as: 'image',
        href: posterSrc.value['2x'],
      },
    ],
  });
}

watch(
  () => props.autoplay,
  () => {
    if (!$video.value) {
      return;
    }
    if (props.autoplay) {
      void $video.value.play();
    } else {
      $video.value.pause();
    }
  }
);

onMounted(() => {
  // Setup intersection observer to check if the video is in view
  if (props.loadWhenInView && $video.value) {
    observer.value = new IntersectionObserver(
      entries => {
        videoInView.value = entries[0].isIntersecting;
      },
      {
        threshold: 0.25,
      }
    );
    observer.value?.observe($video.value);
  }
});

onBeforeUnmount(() => {
  observer.value?.disconnect();
});
</script>
