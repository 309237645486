<template>
  <CorePageBlokModuleWrapper
    v-slot="{ state }"
    :index="index"
    :appearance="appearance"
    :inModal="inModal"
    :isWrapped="isWrapped"
    :nextBlokWrapped="nextBlokWrapped"
    :buffer="moduleSettings?.buffer || false"
    :scrollMultiplier="moduleSettings?.scrollMultiplier || 1"
    :isDark="moduleSettings?.isAlwaysDark || ('is_dark' in props.blok ? !!props.blok.is_dark : false)"
    :marginMultiplier="moduleSettings?.marginMultiplier"
    :nextModuleSelector="`#module-${index + 2}`"
    class="module-wrapper"
    :class="{
      'has-next-blok-wrapped': nextBlokWrapped,
      'has-full-height-on-desktop': moduleSettings.hasFullHeight,
      'has-padding-top-small': paddingTop === 'small',
      'has-padding-top-large': paddingTop === 'large',
      'has-padding-bottom-small': paddingBottom === 'small',
      'has-padding-bottom-large': paddingBottom === 'large',
    }">
    <CMAccordion v-if="settings && blok.component === 'cm_accordion'" :blok="blok" :settings="settings" />
    <CMCopy v-if="blok.component === 'cm_copy'" :blok="blok" />
    <CMCopyMedia
      v-if="settings && blok.component === 'cm_copy-media'"
      :blok="blok"
      :state="state"
      :settings="settings" />
    <CMHighlights v-if="blok.component === 'cm_highlights'" :blok="blok" :state="state" />
    <CMHorizontalScrolling v-if="blok.component === 'cm_horizontal-scrolling'" :blok="blok" :state="state" />
    <CMMedia v-if="settings && blok.component === 'cm_media'" :blok="blok" :state="state" :settings="settings" />
    <CMStage
      v-if="blok.component === 'cm_stage'"
      :blok="blok"
      :state="state"
      :hasInPageNavigationHeader="hasInPageNavigationHeader && index === 0"
      :isFirstBlok="index === 0" />
    <CMStageProductRich v-if="blok.component === 'cm_stage-product-rich'" :blok="blok" :state="state" />
    <CMTable v-if="blok.component === 'cm_table'" :blok="blok" />
    <CMTextHighlight v-if="blok.component === 'cm_text-highlight'" :blok="blok" :state="state" />
    <EMMap v-if="blok.component === 'em_map'" :blok="blok" />
    <EMHome v-if="blok.component === 'em_home' && !!footerBlok" :blok="blok" :footerBlok="footerBlok" :state="state" />
    <EMProductWidget v-if="blok.component === 'em_product-widget'" :blok="blok" />
    <EMShowroom v-if="blok.component === 'em_showroom'" :blok="blok" />
    <FMContact v-if="blok.component === 'fm_contact'" :blok="blok" />
    <FMNewsletter v-if="blok.component === 'fm_newsletter'" :blok="blok" />
    <LMSlider v-if="blok.component === 'lm_slider'" :blok="blok" />
    <LMGrid v-if="blok.component === 'lm_grid'" :blok="blok" />
    <LMGridIndustries v-if="blok.component === 'lm_grid--industries'" :blok="blok" />
    <LMDownloads v-if="blok.component === 'lm_downloads'" :blok="blok" />
    <TMGrid v-if="blok.component === 'tm_grid'" :blok="blok" />
    <TMHighlight v-if="blok.component === 'tm_highlight'" :blok="blok" :state="state" />
  </CorePageBlokModuleWrapper>
  <div v-if="blok.component === 'cm_stage-product-rich'" style="height: 400vh"></div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import CMAccordion from '~/components/storyblok/content/CMAccordion/CMAccordion.vue';
import CMCopy from '~/components/storyblok/content/CMCopy/CMCopy.vue';
import CMCopyMedia from '~/components/storyblok/content/CMCopyMedia/CMCopyMedia.vue';
import CMHighlights from '~/components/storyblok/content/CMHighlights/CMHighlights.vue';
import CMHorizontalScrolling from '~/components/storyblok/content/CMHorizontalScrolling/CMHorizontalScrolling.vue';
import CMMedia from '~/components/storyblok/content/CMMedia/CMMedia.vue';
import CMStage from '~/components/storyblok/content/CMStage/CMStage.vue';
import CMStageProductRich from '~/components/storyblok/content/CMStageProductRich/CMStageProductRich.vue';
import CMTable from '~/components/storyblok/content/CMTable/CMTable.vue';
import CMTextHighlight from '~/components/storyblok/content/CMTextHighlight/CMTextHighlight.vue';
import EMHome from '~/components/storyblok/extra/EMHome/EMHome.vue';
import EMMap from '~/components/storyblok/extra/EMMap/EMMap.vue';
import EMProductWidget from '~/components/storyblok/extra/EMProductWidget/EMProductWidget.vue';
import EMShowroom from '~/components/storyblok/extra/EMShowroom/EMShowroom.vue';
import FMContact from '~/components/storyblok/form/FMContact/FMContact.vue';
import FMNewsletter from '~/components/storyblok/form/FMNewsletter/FMNewsletter.vue';
import LMDownloads from '~/components/storyblok/list/LMDownloads/LMDownloads.vue';
import LMGrid from '~/components/storyblok/list/LMGrid/LMGrid.vue';
import LMGridIndustries from '~/components/storyblok/list/LMGridIndustries/LMGridIndustries.vue';
import LMSlider from '~/components/storyblok/list/LMSlider/LMSlider.vue';
import TMGrid from '~/components/storyblok/teaser/TMGrid/TMGrid.vue';
import TMHighlight from '~/components/storyblok/teaser/TMHighlight/TMHighlight.vue';
import { moduleSettingsMap, type ModuleAppearance } from '~/utils/module-settings';
import type { BmFooterStoryblok, CorePageStoryblok, CoreSettingsStoryblok } from '~/types/storyblok-generated';
import CorePageBlokModuleWrapper from './CorePageBlokModuleWrapper.vue';

const props = withDefaults(
  defineProps<{
    blok: NonNullable<CorePageStoryblok['body']>[number];
    blokNext?: NonNullable<CorePageStoryblok['body']>[number];
    footerBlok?: BmFooterStoryblok;
    settings?: CoreSettingsStoryblok;
    /**
     * Is true when the page has an additional in-page navigation underneat the header
     */
    hasInPageNavigationHeader?: boolean;
    index: number;
    inModal?: boolean;
  }>(),
  { index: -1 }
);

const moduleSettings = computed(() => moduleSettingsMap[props.blok.component] || {});
const appearance = computed<ModuleAppearance>(() => {
  return moduleSettings.value.appearance ?? 'normal';
});
const nextBlokWrapped = computed(() => {
  return !!props.blokNext && 'is_wrapped' in props.blokNext ? props.blokNext.is_wrapped : false;
});
const isWrapped = computed(() => ('is_wrapped' in props.blok ? !!props.blok.is_wrapped : false));
const paddingTop = computed(() =>
  !moduleSettings.value.removePadding && !moduleSettings.value.removePaddingTop && 'spacing_top' in props.blok
    ? props.blok.spacing_top
    : false
);
const paddingBottom = computed(() =>
  !moduleSettings.value.removePadding && 'spacing_bottom' in props.blok ? props.blok.spacing_bottom : false
);
</script>

<style src="./CorePageBlokModule.scss" lang="scss" scoped />
