import { useI18n } from '#imports';
import type { ISbStoryData } from 'storyblok-js-client';
import type {
  AssetStoryblok,
  DataAddOnStoryblok,
  DataProductStoryblok,
  MultilinkStoryblok,
  UtilTagStoryblok,
} from '~/types/storyblok-generated';
import type { IconNameStoryblok } from '~/types/storyblok-icons';

export interface ItemCardTeaserProduct {
  component: 'item_card-teaser--product';
  headline: string;
  image?: AssetStoryblok;
  image_hover?: AssetStoryblok;
  tags: UtilTagStoryblok[];
  link?: MultilinkStoryblok;
}

// Functions related to WMF machines
export function useProduct() {
  const { t } = useI18n();

  function getMachineTypeIcon(type: DataProductStoryblok['type']): IconNameStoryblok | undefined {
    switch (type) {
      case 'fully-automatic':
        return 'fi_automatic-machine';
      case 'automatic-portafilter':
        return 'fi_automatic-portafilter-machine';
      case 'filter-machines':
        return 'fi_filter-machine';
      default:
        return undefined;
    }
  }

  function getMachineTypeText(type: DataProductStoryblok['type']): string {
    return t(`useProducts.machineTypeText.${type}`);
  }

  function getPlannerDataTag(): UtilTagStoryblok {
    return {
      component: 'util_tag',
      text: t('useProducts.plannerData'),
      _uid: `planner-data-tag`,
    };
  }

  function transformMachineToItemCardTeaser(
    machine: ISbStoryData<DataProductStoryblok>,
    /**
     * We use this to change the card for planner data
     */
    preset?: 'planner-data'
  ): ItemCardTeaserProduct {
    let tags: UtilTagStoryblok[] = [];
    let link: MultilinkStoryblok | undefined = machine.content.page;

    if (machine.content.type) {
      tags.push({
        component: 'util_tag',
        text: getMachineTypeText(machine.content.type),
        icon: undefined,
        _uid: `tag-${machine.content.type}`,
      });
    }

    if (machine.content.serving_power) {
      tags.push({
        component: 'util_tag',
        text: machine.content.serving_power,
        icon: 'fi_coffee',
        _uid: `tag-${machine.content.serving_power}`,
      });
    }

    if (preset === 'planner-data') {
      // In this case we only want to show the planner data tag
      tags = [getPlannerDataTag()];
      link = machine.content.planner_data_page;
    }

    return {
      component: 'item_card-teaser--product',
      headline: machine.content.name,
      image: machine.content.image_main,
      image_hover: machine.content.image_alternative,
      tags: tags,
      link: link,
    };
  }

  function transformAddOnToItemCardTeaser(
    addon: ISbStoryData<DataAddOnStoryblok>,
    /**
     * We use this to change the card for planner data
     */
    preset?: 'planner-data'
  ): ItemCardTeaserProduct {
    let tags: UtilTagStoryblok[] = [];
    let link: MultilinkStoryblok | undefined = {
      id: addon.uuid,
      url: addon.full_slug,
      cached_url: addon.full_slug,
      linktype: 'story',
      fieldtype: 'multilink',
      story: {
        uuid: addon.uuid,
        id: addon.id,
        name: addon.content.name,
        created_at: '',
        published_at: '',
        full_slug: addon.full_slug,
        slug: addon.slug,
      },
    };

    if (addon.content.is_new) {
      tags.unshift({
        component: 'util_tag',
        text: 'New',
        icon: 'fi_bell',
        _uid: 'tag-new',
      });
    }

    if (preset === 'planner-data') {
      // In this case we only want to show the planner data tag
      tags = [getPlannerDataTag()];
      link = addon.content.planner_data_page;
    }

    return {
      component: 'item_card-teaser--product',
      headline: addon.content.name,
      image: addon.content.image_main,
      image_hover: addon.content.image_alternative,
      tags: tags,
      link: link,
    };
  }

  return {
    getMachineTypeIcon,
    getMachineTypeText,
    transformMachineToItemCardTeaser,
    transformAddOnToItemCardTeaser,
  };
}
