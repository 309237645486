<template>
  <div
    class="base-input-dropdown form-field"
    :class="[
      isDark ? 'is-dark' : 'is-light',
      {
        'is-focused': isFocused || isHovered,
        'is-disabled': disabled,
        'has-error': inputHasError || dropdownHasError,
      },
    ]">
    <label v-if="label" class="label" :for="uid">{{ label }}{{ required ? '*' : '' }}</label>
    <div class="field-container" @mouseover="isHovered = true" @mouseleave="isHovered = false">
      <div class="select-container">
        <select
          v-bind="dropdownAttrs"
          :name="dropdownName"
          :required="required"
          class="select"
          :class="{
            'is-active': dropdownIsFocused,
            'has-error': dropdownHasError,
            'has-default': !dropdownValue,
            'has-fake-label': !!customSelectedLabelFn,
          }"
          :value="dropdownValue"
          :autocomplete="dropdownAutocomplete"
          @focus="e => handleFocus(e, true)"
          @blur="e => handleBlur(e, true)"
          @click="handleDropdownClick"
          @change="handleDropdownChange">
          <option v-if="dropdownPlaceholder" value="" disabled selected>
            {{ !dropdownIsFocused ? dropdownPlaceholder : t('forms.pleaseSelect') }}
          </option>
          <option v-for="option in options" :key="option.value" :value="option.value">
            {{ !dropdownIsFocused && customSelectedLabelFn ? customSelectedLabelFn(option) : option.label }}
          </option>
        </select>
        <span v-if="customSelectedLabelFn" class="fake-label">
          {{
            dropdownValue && options.find(o => o.value === dropdownValue)
              ? customSelectedLabelFn(options.find(o => o.value === dropdownValue)!)
              : (dropdownPlaceholder ?? t('forms.pleaseSelect'))
          }}
        </span>
        <BaseIcon
          :name="dropdownIsFocused ? 'fi_chevron-top' : 'fi_chevron-bottom'"
          :size="16"
          color="inherit"
          class="select-icon" />
      </div>

      <!-- line -->
      <div class="line"></div>

      <input
        v-bind="inputAttrs"
        :id="uid"
        ref="$input"
        tabindex="0"
        :name="inputName"
        type="text"
        :placeholder="inputPlaceholder"
        :value="inputValue"
        :readonly="disabled"
        class="input"
        :class="{ 'has-error': inputHasError }"
        :autocomplete="inputAutocomplete"
        @input="handleInputChange"
        @focus="e => handleFocus(e, false)"
        @blur="e => handleBlur(e, false)"
        @keydown.enter.prevent="emit('submitted')"
        @keydown.escape="emit('escape')" />
    </div>
    <div v-if="dropdownHasError" class="error">
      {{ dropdownErrorMessage }}
    </div>
    <div v-if="inputHasError" class="error">
      {{ inputErrorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '#imports';
import { computed, onMounted, ref, useId } from 'vue';
import { BaseIcon } from '~/components/base';
import type { FormFieldOption, FormFieldProps } from '~/types/utils';

const $input = ref<HTMLInputElement>();

interface BaseInputDropdown extends Omit<FormFieldProps, 'name' | 'isTouched' | 'errorMessage'> {
  isAutoFocused?: boolean;
  options: FormFieldOption[];
  dropdownName: string;
  dropdownPlaceholder?: string;
  dropdownValue: FormFieldOption['value'] | undefined;
  dropdownIsTouched?: boolean;
  dropdownErrorMessage?: string;
  inputName: string;
  inputPlaceholder?: string;
  inputValue: string | undefined;
  inputIsTouched?: boolean;
  inputErrorMessage?: string;
  dropdownAttrs?: Record<string, unknown>;
  inputAttrs?: Record<string, unknown>;
  /**
   * The dropdown usually gets quite small, so we may need to adjust the label of the selected option.
   */
  customSelectedLabelFn?: (option: FormFieldOption) => string;
  inputAutocomplete?: string;
  dropdownAutocomplete?: string;
}

const props = defineProps<BaseInputDropdown>();

const emit = defineEmits<{
  (e: 'focus', event: FocusEvent): void;
  (e: 'blur', event: FocusEvent): void;
  (e: 'submitted'): void;
  (e: 'escape'): void;
  (e: 'update:dropdownValue', value: string | undefined): void;
  (e: 'update:inputValue', value: string): void;
}>();

const isFocused = ref<boolean>(false);
const dropdownIsFocused = ref<boolean>(false);
const inputHasError = computed(() => props.inputErrorMessage && props.inputIsTouched);
const dropdownHasError = computed(() => props.dropdownErrorMessage && props.dropdownIsTouched);

const isHovered = ref<boolean>(false);
const uid = props.id ?? useId();
const { t } = useI18n();

function handleFocus(e: FocusEvent, isDropdown: boolean) {
  isFocused.value = true;
  emit('focus', e);
  if (isDropdown) {
    dropdownIsFocused.value = true;
  }
}

function handleBlur(e: FocusEvent, isDropdown: boolean) {
  isFocused.value = false;
  emit('blur', e);
  if (isDropdown) {
    dropdownIsFocused.value = false;
  }
}

function handleInputChange(e: Event) {
  if (e.target instanceof HTMLInputElement) {
    emit('update:inputValue', e.target.value);
  }
}

function handleDropdownClick() {
  dropdownIsFocused.value = true;
}

function handleDropdownChange(e: Event) {
  dropdownIsFocused.value = false;
  if (e.target instanceof HTMLSelectElement) {
    emit('update:dropdownValue', e.target.value);
  }
}

onMounted(() => {
  if (props.isAutoFocused) {
    $input.value?.focus();
  }
});
</script>

<style src="~/assets/scss/_form-field.scss" lang="scss" scoped />
<style src="./_BaseInputDropdown.scss" lang="scss" scoped />
