import { gsap } from 'gsap';
import { EffectPass } from 'postprocessing';
import type { AppStateInstance } from '~/webglApp/core/appState';
import type { EngineInstance } from '~/webglApp/core/engine';
import { ShowroomEvent } from '~/webglApp/webGlApp.types';
import { PostGradingEffect } from './postGradingEffect';

export type PostGradingInstance = InstanceType<typeof PostGrading>;

export class PostGrading {
  effect: PostGradingEffect;
  pass: EffectPass;
  tintIntensity = 0;

  constructor(appState: AppStateInstance, engine: EngineInstance) {
    appState.webGlCanvas.addEventListener(ShowroomEvent.FilterToggled, () => {
      const value = this.tintIntensity === 0 ? 0.8 : 0;
      gsap.to(this, { tintIntensity: value, duration: 0.5, ease: 'expo.out' });
    });
    this.effect = new PostGradingEffect();
    this.pass = new EffectPass(engine.camera, this.effect);
  }

  update() {
    if (this.effect.uniforms.get('u_tintIntensity')?.value) {
      this.effect.uniforms.get('u_tintIntensity')!.value = this.tintIntensity;
    }
  }
}
