<template>
  <div v-editable="blok" :class="['tm-grid', blok.is_dark ? 'is-dark' : 'is-light']">
    <div v-if="blok.statement" class="top-container">
      <h2 class="statement">{{ blok.statement }}</h2>
    </div>

    <div class="link-container">
      <BaseLink
        v-for="(item, index) in blok.items"
        :key="index"
        v-editable="item"
        :link="item.link"
        :class="['link', { 'is-touch': isTouch, 'has-three-cols': blok.items?.length === 3 }]"
        @mouseenter="() => onLinkMouseEnter(item._uid)"
        @mouseleave="() => onLinkMouseLeave()">
        <div class="line"></div>
        <div v-if="!isTouch" class="line-background"></div>
        <div class="link-text">
          {{ item.text }}
        </div>
        <UtilButton
          v-if="getIsLinkExternal(item.link)"
          :isHovered="linkHovered === item._uid"
          :isBackgroundDark="blok.is_dark"
          isFakeButton
          :blok="{
            component: 'util_button',
            size: 'small',
            text: '',
            theme: 'secondary',
            icon: 'fi_arrow-right-1',
            _uid: 'arrow-right-button',
          }" />
      </BaseLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { BaseLink } from '~/components/base';
import type { MultilinkStoryblok, TmGridStoryblok } from '~/types/storyblok-generated';

defineProps<{
  blok: TmGridStoryblok;
}>();

const { isTouch } = useBreakpoint();

const linkHovered = ref<string | null>(null);
function onLinkMouseEnter(linkId: string) {
  linkHovered.value = linkId;
}
function onLinkMouseLeave() {
  linkHovered.value = null;
}

const getIsLinkExternal = (link: MultilinkStoryblok) => {
  const isExternal = !!link && link.linktype === 'url';

  return isExternal;
};
</script>

<style src="./TMGrid.scss" lang="scss" scoped />
