import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';

export function useGsap() {
  let easeOut: gsap.EaseFunction | undefined = undefined;

  // Avoid warnings
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    gsap.registerPlugin(CustomEase);
    easeOut = CustomEase.create('custom', 'M0,0 C0.1,0 0.1,1 1,1') as gsap.EaseFunction;
  }

  return {
    easeOut,
  };
}
