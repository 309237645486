<template>
  <div class="em-home-column" :class="medium_card_position === 'top' ? 'medium-card-top' : 'medium-card-bottom'">
    <EMHomeCard
      v-if="medium_card[0]"
      :frontSideOverline="medium_card[0].front_side_overline"
      :frontSideHeadline="medium_card[0].front_side_headline"
      :frontSideSubline="medium_card[0].front_side_subline"
      :frontSideImage="
        breakpointState.isPhoneLarge ? medium_card[0].front_side_image_desktop : medium_card[0].front_side_image_mobile
      "
      :frontSideVideo="
        breakpointState.isPhoneLarge ? medium_card[0].front_side_video_desktop : medium_card[0].front_side_video_mobile
      "
      :link="medium_card[0].link"
      :backSideText="medium_card[0].back_side_text"
      :backSideCta="medium_card[0].back_side_cta?.[0]"
      horizontalTextAlign="center"
      :verticalTextAlign="medium_card[0].vertical_text_align"
      :isDark="!!medium_card[0].is_dark"
      :textLayout="medium_card[0].text_layout"
      :interactivity="medium_card[0].interactivity"
      cardType="medium-large"
      class="medium-card"
      :isFlipped="flippedCardUid === medium_card[0]._uid"
      :uid="medium_card[0]._uid"
      @flip="emit('flip', medium_card[0]._uid)" />
    <EMHomeCard
      v-if="small_card?.[0]"
      :frontSideOverline="undefined"
      :frontSideHeadline="small_card[0].front_side_headline"
      :frontSideSubline="undefined"
      :frontSideImage="
        breakpointState.isPhoneLarge ? small_card[0].front_side_image_desktop : small_card[0].front_side_image_mobile
      "
      :frontSideVideo="
        breakpointState.isPhoneLarge ? small_card[0].front_side_video_desktop : small_card[0].front_side_video_mobile
      "
      :link="small_card[0].link"
      :backSideText="small_card[0].back_side_text"
      :backSideCta="small_card[0].back_side_cta?.[0]"
      horizontalTextAlign="center"
      :verticalTextAlign="small_card[0].vertical_text_align"
      :isDark="!!small_card[0].is_dark"
      :textLayout="undefined"
      :interactivity="small_card[0].interactivity"
      cardType="small"
      class="small-card"
      :uid="small_card[0]._uid"
      :isFlipped="flippedCardUid === small_card[0]._uid"
      @flip="emit('flip', small_card[0]._uid)" />
  </div>
</template>

<script lang="ts" setup>
import EMHomeCard from './EMHomeCard.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import type { EmHomeCol1Medium1Small } from './EMHome.types';

defineProps<EmHomeCol1Medium1Small>();

const emit = defineEmits<{ (e: 'flip', id: string): void }>();

const { state: breakpointState } = useBreakpoint();
</script>

<style lang="scss" scoped>
.em-home-column {
  display: grid;
  grid-gap: 8px;
  height: 100%;
  width: 100%;
  @media (min-width: 768px) {
    gap: 16px;
  }
  @media (min-width: 1024px) {
    gap: 24px;
  }
}

.medium-card-top {
  grid-template-areas: 'large' 'small';
  grid-template-rows: 2.5fr 1fr;
}

.medium-card-bottom {
  grid-template-areas: 'small' 'large';
  grid-template-rows: 1fr 2.5fr;
}

.medium-card {
  grid-area: large;
}

.small-card {
  grid-area: small;
  min-height: 200px;

  @media (min-width: 768px) {
    min-height: auto;
  }
}
</style>
