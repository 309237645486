import { KawaseBlurPass } from 'postprocessing';
import * as THREE from 'three';
import type { AppStateInstance } from '~/webglApp/core/appState';
import type { EngineInstance } from '~/webglApp/core/engine';
import type { BackgroundInstance } from '../background/background';
import type { BlueNoiseInstance } from '../blueNoise/blueNoise';
// @ts-expect-error - We are importing 3d related code
import groundFrag from './ground.frag';
// @ts-expect-error - We are importing 3d related code
import groundVert from './ground.vert';

const CLIP_BIAS = 0.003;

export type GroundInstance = InstanceType<typeof Ground>;

export class Ground {
  container = new THREE.Object3D();
  mesh: THREE.Mesh;
  rtTextureResolution = [1024, 1024];
  hasBlur = false;
  blurResolution = [1024, 256];
  kawaseBlurPass: InstanceType<typeof KawaseBlurPass> | null = null;
  depthScale = 0;
  reflectorPlane = new THREE.Plane();
  normal = new THREE.Vector3();
  reflectorWorldPosition = new THREE.Vector3();
  cameraWorldPosition = new THREE.Vector3();
  rotationMatrix = new THREE.Matrix4();
  lookAtPosition = new THREE.Vector3(0, 0, -1);
  clipPlane = new THREE.Vector4();
  view = new THREE.Vector3();
  target = new THREE.Vector3();
  q = new THREE.Vector4();
  textureMatrix = new THREE.Matrix4();
  virtualCamera = new THREE.PerspectiveCamera();
  projectionRT: THREE.WebGLRenderTarget<THREE.Texture>;
  blurRT: THREE.WebGLRenderTarget<THREE.Texture>;
  appState: AppStateInstance;
  isActive = false;

  constructor(appState: AppStateInstance, blueNoise: BlueNoiseInstance) {
    this.appState = appState;
    this.hasBlur = this.blurResolution[0] + this.blurResolution[1] > 0;

    this.projectionRT = new THREE.WebGLRenderTarget(this.rtTextureResolution[0], this.rtTextureResolution[1], {
      minFilter: THREE.NearestFilter,
      magFilter: THREE.NearestFilter,
      depthBuffer: true,
    });
    this.projectionRT.depthTexture = new THREE.DepthTexture(this.rtTextureResolution[0], this.rtTextureResolution[1]);
    this.projectionRT.depthTexture.format = THREE.DepthFormat;
    this.projectionRT.depthTexture.type = THREE.UnsignedShortType;

    this.blurRT = new THREE.WebGLRenderTarget(this.rtTextureResolution[0], this.rtTextureResolution[1], {
      minFilter: THREE.LinearFilter,
      magFilter: THREE.LinearFilter,
      depthBuffer: false,
    });

    if (this.hasBlur) {
      this.kawaseBlurPass = new KawaseBlurPass();
      this.kawaseBlurPass.setSize(this.blurResolution[0], this.blurResolution[1]);
    }

    const material = new THREE.ShaderMaterial({
      uniforms: {
        u_textureMatrix: { value: this.textureMatrix },
        u_projectionTexture: { value: this.projectionRT.texture },
        u_projectionDepthTexture: { value: this.projectionRT.depthTexture },
        u_projectionBlurTexture: { value: this.blurRT.texture },
        u_mirror: { value: 1.0 },
        u_mixBlur: { value: 1.0 },
        u_hasBlur: { value: this.hasBlur },
        u_mixStrength: { value: 0.5 },
        u_minDepthThreshold: { value: 0.9 },
        u_maxDepthThreshold: { value: 1 },
        u_depthScale: { value: this.depthScale },
        u_depthToBlurRatioBias: { value: 0.25 },
        u_mixContrast: { value: 0.75 },
        u_blueNoiseTexture: { value: blueNoise.texture },
        u_blueNoiseTextureSize: { value: blueNoise.textureSize },
        u_blueNoiseTextureOffset: { value: blueNoise.textureOffset },
      },
      vertexShader: groundVert,
      fragmentShader: groundFrag,
    });

    if (this.hasBlur) {
      material.defines.USE_BLUR = true;
    }

    if (this.depthScale > 0) {
      material.defines.USE_DEPTH = true;
    }

    this.mesh = new THREE.Mesh(new THREE.PlaneGeometry(10, 10), material);
    this.mesh.position.set(0, -0.001, 4);
    this.mesh.rotation.set(-Math.PI / 2, 0, 0);
    this.container.add(this.mesh);
  }

  show() {
    this.isActive = true;
  }

  hide() {
    this.isActive = false;
  }

  update(engine: EngineInstance, background: BackgroundInstance) {
    this.container.visible = this.isActive;

    if (!this.isActive) {
      return;
    }

    this.reflectorWorldPosition.setFromMatrixPosition(this.mesh.matrixWorld);
    this.cameraWorldPosition.setFromMatrixPosition(engine.camera.matrixWorld.clone());
    this.rotationMatrix.extractRotation(this.mesh.matrixWorld);
    this.normal.set(0, 0, 1);
    this.normal.applyMatrix4(this.rotationMatrix);
    this.view.subVectors(this.reflectorWorldPosition, this.cameraWorldPosition);

    // Avoid rendering when reflector is facing away
    if (this.view.dot(this.normal) > 0) {
      return;
    }
    this.view.reflect(this.normal).negate();
    this.view.add(this.reflectorWorldPosition);

    this.rotationMatrix.extractRotation(engine.camera.matrixWorld);

    this.lookAtPosition.set(0, 0, -1);
    this.lookAtPosition.applyMatrix4(this.rotationMatrix);
    this.lookAtPosition.add(this.cameraWorldPosition);

    this.target.subVectors(this.reflectorWorldPosition, this.lookAtPosition);
    this.target.reflect(this.normal).negate();
    this.target.add(this.reflectorWorldPosition);

    this.virtualCamera.position.copy(this.view);
    this.virtualCamera.up.set(0, 1, 0);
    this.virtualCamera.up.applyMatrix4(this.rotationMatrix);
    this.virtualCamera.up.reflect(this.normal);
    this.virtualCamera.lookAt(this.target);

    // Used in WebGLBackground
    this.virtualCamera.near = engine.camera.near;
    this.virtualCamera.far = engine.camera.far;
    this.virtualCamera.updateMatrixWorld();
    this.virtualCamera.projectionMatrix.copy(engine.camera.projectionMatrix);

    // Update the texture matrix
    this.textureMatrix.set(0.5, 0.0, 0.0, 0.5, 0.0, 0.5, 0.0, 0.5, 0.0, 0.0, 0.5, 0.5, 0.0, 0.0, 0.0, 1.0);
    this.textureMatrix.multiply(this.virtualCamera.projectionMatrix);
    this.textureMatrix.multiply(this.virtualCamera.matrixWorldInverse);
    this.textureMatrix.multiply(this.mesh.matrixWorld);

    // Now update projection matrix with new clip plane, implementing code from: http://www.terathon.com/code/oblique.html
    // Paper explaining this technique: http://www.terathon.com/lengyel/Lengyel-Oblique.pdf
    this.reflectorPlane.setFromNormalAndCoplanarPoint(this.normal, this.reflectorWorldPosition);
    this.reflectorPlane.applyMatrix4(this.virtualCamera.matrixWorldInverse);

    this.clipPlane.set(
      this.reflectorPlane.normal.x,
      this.reflectorPlane.normal.y,
      this.reflectorPlane.normal.z,
      this.reflectorPlane.constant
    );

    const projectionMatrix = this.virtualCamera.projectionMatrix;

    this.q.x = (Math.sign(this.clipPlane.x) + projectionMatrix.elements[8]) / projectionMatrix.elements[0];
    this.q.y = (Math.sign(this.clipPlane.y) + projectionMatrix.elements[9]) / projectionMatrix.elements[5];
    this.q.z = -1.0;
    this.q.w = (1.0 + projectionMatrix.elements[10]) / projectionMatrix.elements[14];

    // Calculate the scaled plane vector
    this.clipPlane.multiplyScalar(2.0 / this.clipPlane.dot(this.q));

    // Replacing the third row of the projection matrix
    projectionMatrix.elements[2] = this.clipPlane.x;
    projectionMatrix.elements[6] = this.clipPlane.y;
    projectionMatrix.elements[10] = this.clipPlane.z + 1.0 - CLIP_BIAS;
    projectionMatrix.elements[14] = this.clipPlane.w;

    // Render
    this.projectionRT.texture.colorSpace = engine.renderer.outputColorSpace;

    this.container.visible = false;
    background.container.visible = false;

    //Const currentRenderTarget = engine.renderer.getRenderTarget();
    const currentXrEnabled = engine.renderer.xr.enabled;
    const currentShadowAutoUpdate = engine.renderer.shadowMap.autoUpdate;

    // Avoid camera modification
    engine.renderer.xr.enabled = false;
    // Avoid re-computing shadows
    engine.renderer.shadowMap.autoUpdate = false;
    engine.renderer.setRenderTarget(this.projectionRT);
    // Make sure the depth buffer is writable so it can be properly cleared, see #18897
    engine.renderer.state.buffers.depth.setMask(true);
    if (engine.renderer.autoClear === false) {
      engine.renderer.clear();
    }
    engine.renderer.render(engine.scene, this.virtualCamera);

    if (this.hasBlur && this.kawaseBlurPass) {
      this.kawaseBlurPass.render(engine.renderer, this.projectionRT, this.blurRT);
    }

    engine.renderer.xr.enabled = currentXrEnabled;
    engine.renderer.shadowMap.autoUpdate = currentShadowAutoUpdate;

    this.container.visible = true;
    background.container.visible = true;
    engine.renderer.setRenderTarget(null);
  }
}
