import * as THREE from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { clamp } from '../utils/mathUtils';
import { sendEvent } from '../utils/showroomEvents';
import { ShowroomEvent, type Asset, type LoadedAsset } from '../webGlApp.types';
import type { AppStateInstance } from './appState';

export type AssetManagerInstance = InstanceType<typeof AssetManager>;

export class AssetManager {
  appState: AppStateInstance;
  loadingManager: THREE.LoadingManager;
  textureLoader: THREE.TextureLoader;
  dracoLoader: DRACOLoader;
  gltfLoader: GLTFLoader;
  rgbeLoader: RGBELoader;
  assetsToLoad: Asset[] = [];
  assets: Record<string, LoadedAsset> = {};
  prevProgress = 0;
  prerenderProgress = 0;
  progress = 0;
  startTime = 0;
  baseURL = '/webgl/';
  isDebug = false;

  constructor(appState: AppStateInstance, isDebug: boolean = false) {
    this.appState = appState;
    THREE.Cache.enabled = true;

    this.loadingManager = new THREE.LoadingManager();

    this.textureLoader = new THREE.TextureLoader(this.loadingManager);
    this.textureLoader.setCrossOrigin('');

    this.dracoLoader = new DRACOLoader();
    this.dracoLoader.setDecoderPath(this.baseURL + 'js/draco/');

    this.gltfLoader = new GLTFLoader(this.loadingManager);
    this.gltfLoader.setDRACOLoader(this.dracoLoader);

    this.rgbeLoader = new RGBELoader(this.loadingManager);
    this.rgbeLoader.setCrossOrigin('');

    this.isDebug = isDebug;
  }

  addToLoader(assetPath: string, onLoadCallback: (loadedAsset: LoadedAsset) => void) {
    const splittedAssetPath = assetPath.split('/').pop();
    const cleanedAssetPath = window.origin + (this.baseURL + assetPath).replaceAll('//', '/');

    const name = splittedAssetPath?.split('.').shift();
    const extension = splittedAssetPath?.split('.').pop();

    if (!name || !extension) {
      console.error('[AssetManager] Error loading asset:', assetPath);
      return;
    }

    const object: Asset = {
      name,
      path: cleanedAssetPath,
      extension,
      onLoadCallback: onLoadCallback,
    };

    this.assetsToLoad.push(object);
  }

  loadAssets() {
    this.progress = 0;
    this.startTime = performance.now();
    try {
      return new Promise((resolve, reject) => {
        this.assetsToLoad.forEach(assetObject => {
          switch (assetObject.extension) {
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'webp':
            case 'avif':
              this.textureLoader.load(
                assetObject.path,
                texture => {
                  this.assets[assetObject.name] = texture;
                  if (assetObject.onLoadCallback) {
                    assetObject.onLoadCallback(this.assets[assetObject.name]);
                  }
                },
                undefined,
                () => {
                  console.error('[AssetManager] error png/jpg/jpeg:', assetObject.name);
                }
              );
              break;
            case 'gltf':
            case 'glb':
            case 'drc':
              this.gltfLoader.load(
                assetObject.path,
                gltf => {
                  this.assets[assetObject.name] = gltf;
                  if (assetObject.onLoadCallback) {
                    assetObject.onLoadCallback(this.assets[assetObject.name]);
                  }
                },
                undefined,
                error => {
                  console.error('[AssetManager] error gltf:', assetObject.name, error);
                }
              );
              break;
            case 'hdr':
              this.rgbeLoader.load(
                assetObject.path,
                texture => {
                  this.assets[assetObject.name] = texture;
                  if (assetObject.onLoadCallback) {
                    assetObject.onLoadCallback(this.assets[assetObject.name]);
                  }
                },
                undefined,
                () => {
                  console.error('[AssetManager] error png/jpg/jpeg:', assetObject.name);
                }
              );
              break;
            default:
              console.error(
                `[AssetManager] Error loading asset: ${assetObject.path}. Unrecognized file extension: ${assetObject.extension}`
              );
              break;
          }
        });

        this.loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
          if (url.includes('blob')) {
            return;
          }
          this.progress = clamp(itemsLoaded / itemsTotal + Number.EPSILON, 0, 1);
          if (this.progress < this.prevProgress) {
            return;
          }
          this.prevProgress = this.progress;
          sendEvent(this.appState.webGlCanvas, ShowroomEvent.AssetsLoadingProgress, this.progress);
        };

        this.loadingManager.onLoad = () => {
          if (this.isDebug) {
            console.error('[AssetManager] webglApp assets loaded: ', this.assets);
          }
          resolve(this.assets);
        };

        this.loadingManager.onError = _url => {
          console.error('[AssetManager] manager errror:', _url);
          reject(new Error(`Error loading asset: ${_url}`));
        };
      });
    } catch (error) {
      if (error && typeof error === 'object' && 'message' in error) {
        console.error('[AssetManager] error:', error.message);
      }
    }
  }
}
