type Browser = 'android' | 'chrome' | 'firefox' | 'internetExplorer' | 'safari' | 'unknown';

export function getBrowser() {
  let browser: Browser = 'unknown';

  if (typeof window === 'undefined') {
    return browser;
  }

  const chrome = window.navigator.userAgent.search('Chrome');
  const firefox = window.navigator.userAgent.search('Firefox');
  const ie = window.navigator.userAgent.search('MSIE');
  const safari = window.navigator.userAgent.search('Safari');
  const android = window.navigator.userAgent.search('Android');

  if (android > -1) {
    // Could be Chrome or other browser on Android
    browser = 'android';
  } else if (chrome > -1) {
    browser = 'chrome';
  } else if (firefox > -1) {
    browser = 'firefox';
  } else if (ie > -1) {
    browser = 'internetExplorer';
  } else if (safari > -1) {
    browser = 'safari';
  }

  return browser;
}

/**
 * Checks if browser is Safari < 16
 */
export function isOldSafari() {
  const ua = navigator.userAgent;
  const isSafari = /Safari/.test(ua) && /Apple Computer/.test(navigator.vendor);
  if (isSafari) {
    const versionMatch = ua.match(/Version\/(\d+)/);
    if (versionMatch && versionMatch.length > 1) {
      const version = parseInt(versionMatch[1], 10);
      if (version < 16) {
        return true;
      }
    }
  }
  return false;
}

/**
 * Returns true if the browser fully supports WebM (including transparency)
 */
export function hasFullWebmSupport() {
  const browser = getBrowser();
  return browser !== 'safari';
}

/**
 * Returns true if mp4 scroll scrubbing is supported
 */
export function hasMp4ScrubSupport() {
  const browser = getBrowser();
  return !['android', 'firefox'].includes(browser);
}
