<template>
  <div ref="$el" class="em-showroom-cards" :class="{ ['is-' + layout]: layout }">
    <div class="cards-container container-full">
      <div class="slot is-left">
        <!-- Serving power -->
        <div data-depth=".9" class="card is-sp">
          <EMShowroomCardSP :count="servingPowerCount" :subline="servingPowerSubline" :isDark="useDarkVariant" />
        </div>

        <!-- Coffee connect -->
        <div v-if="coffeeConnectReady && isPhone" data-depth=".9" class="card is-cc">
          <EMShowroomCardCC :product="product" :cardImage="coffeeConnectImage" :isDark="!useDarkVariant" />
        </div>

        <div v-if="coffeeConnectReady && !isPhone" data-depth=".9" class="card is-cc">
          <EMShowroomCardCC :product="product" :cardImage="coffeeConnectImage" :isDark="!useDarkVariant" />
        </div>
      </div>
      <div
        class="hero"
        :class="{ 'is-touch': isTouch }"
        :data-cursor="
          JSON.stringify({
            button: productButton,
            cursor: 'pointer',
            labels: props.cursorMessages,
          })
        "
        @mouseenter="$emit('hero', true)"
        @mouseleave="$emit('hero', false)"></div>

      <div class="slot is-right">
        <!-- Video loop -->
        <div v-if="product.content.video_loop?.filename" data-depth=".9" class="card is-video">
          <EMShowroomCardVideo :product="product" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import gsap from 'gsap';
import type { ISbStoryData } from 'storyblok-js-client';
import { computed, nextTick, ref, watch } from 'vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import type { AssetStoryblok, DataProductStoryblok, UtilButtonStoryblok } from '~/types/storyblok-generated';
import EMShowroomCardCC from './EMShowroomCardCC.vue';
import EMShowroomCardSP from './EMShowroomCardSP.vue';
import EMShowroomCardVideo from './EMShowroomCardVideo.vue';

const props = withDefaults(
  defineProps<{
    layout: 'landscape' | 'portrait' | 'portrait-flipped';
    product: ISbStoryData<DataProductStoryblok>;
    coffeeConnectImage?: AssetStoryblok;
    servingPowerSubline?: string;
    darkVariant?: boolean;
    cardsVisible?: boolean;
    offset?: { x: number; y: number };
    cursorMessages?: string[];
  }>(),
  {
    layout: 'portrait',
    offset: () => ({ x: 0, y: 0 }),
    cursorMessages: () => ['Please add a cursor Text'],
  }
);

defineEmits<{
  (e: 'hero', value: boolean): void;
}>();

const { state: stateBreakpoint, isTouch } = useBreakpoint();

const servingPowerCount = ref(0);
const useDarkVariant = ref(props.darkVariant);

const $el = ref<HTMLElement>();
const isPhone = computed(() => !stateBreakpoint.isPhoneLarge);
const coffeeConnectReady = computed(() => {
  return props.product?.content.is_coffeeconnect_ready;
});

const productButton = computed<UtilButtonStoryblok>(() => {
  return {
    component: 'util_button',
    text: props.cursorMessages[0],
    theme: 'primary',
    size: 'large',
    link: props.product.content.page,
    _uid: props.product.content._uid,
  };
});

function moveCards(skipAnimation: boolean = false) {
  if (!$el.value) {
    return;
  }
  const cards = $el.value.querySelectorAll('.card');

  cards.forEach(card => {
    const x = props.offset.x;
    const y = props.offset.y;

    const depth = parseFloat(card.getAttribute('data-depth')!);
    const cardOffsetX = depth * (window.innerWidth * 0.1) * x * 0.25;
    const cardOffsetY = depth * (window.innerWidth * 0.1) * y * 0.1;

    gsap.to(card.children[0], {
      duration: skipAnimation ? 0 : 0.3,
      ease: 'sine.out',
      transformOrigin: 'center center',
      x: cardOffsetX,
      y: cardOffsetY,
    });
  });
}

watch(
  () => props.offset,
  () => {
    moveCards();
  }
);

watch(
  () => props.cardsVisible,
  async () => {
    if (!$el.value?.querySelector('.card')) {
      return;
    }

    await nextTick();
    moveCards(true);

    if (props.cardsVisible) {
      useDarkVariant.value = props.darkVariant;

      gsap.set(servingPowerCount, { value: 0, overwrite: true });
      gsap.to(servingPowerCount, {
        duration: 2,
        delay: 0.25,
        ease: 'sine.inout',
        value: props.product.content.serving_power,
        roundProps: 'value',
      });

      gsap.to('.card', {
        overwrite: true,
        duration: 1.4,
        ease: 'expo.out',
        y: 0,
        stagger: { each: 0.25 },
      });
      gsap.to('.card', {
        autoAlpha: 1,
        duration: 0.25,
        stagger: { each: 0.25 },
      });
    } else {
      void gsap
        .to('.card', {
          y: 0,
          autoAlpha: 0,
          duration: 0.1,
          overwrite: true,
        })
        .then(() => {
          gsap.set('.card', {
            y: 40,
          });
        });
    }
  }
);
</script>

<style src="./EMShowroomCards.scss" lang="scss" scoped />
