/**
 * All of the types in here are just a partial representations of the actual types
 */

export interface OneTrust {
  AllowAll: (e: any) => void;
  BlockGoogleAnalytics: (e: any, t: any) => void;
  Close: (e: any) => void;
  FetchAndDownloadPC: () => void;
  GetDomainData: () => OneTrustDomainData;
  Init: (e: any) => void;
  InitializeBanner: () => void;
  InsertHtml: (e: any, t: any, o: any, n: any, r: any) => void;
  InsertScript: (e: any, t: any, o: any, n: any, r: any, i: any) => void;
  IsAlertBoxClosed: () => void;
  IsAlertBoxClosedAndValid: () => void;
  IsVendorServiceEnabled: () => void;
  LoadBanner: () => void;
  OnConsentChanged: (e: any) => void;
  ReconsentGroups: () => void;
  RejectAll: (e: any) => void;
  SetAlertBoxClosed: (e: any) => void;
  ToggleInfoDisplay: () => void;
  TriggerGoogleAnalyticsEvent: (e: any, t: any, o: any, n: any) => void;
  UpdateConsent: (l: any, c: any) => void;
  UpdateGCM: (e: any) => void;
  changeLanguage: (t: any) => void;
  getCSS: () => void;
  getGeolocationData: () => void;
  getHTML: () => void;
  initializeCookiePolicyHtml: (e: any) => void;
  setGeoLocation: (e: any, t: any) => void;
  testLog: () => void;
  useGeoLocationService: boolean;
}

interface OneTrustDomainData {
  Groups: OneTrustGroupsEntry[];
}

export enum OneTrustGroup {
  Marketing = 'C0004',
  Functional = 'C0003',
  Tracking = 'C0002',
  SocialMedia = 'C0005',
  StrictlyNecessary = 'C0001',
  TempUnassigned = 'C9999',
}

interface OneTrustGroupsEntry {
  ShowInPopup: boolean;
  ShowInPopupNonIAB: boolean;
  ShowSDKListLink: boolean;
  Order: string;
  OptanonGroupId: OneTrustGroup;
  Parent: string;
  ShowSubgroup: boolean;
  ShowSubGroupDescription: boolean;
  ShowSubgroupToggle: boolean;
  AlwaysShowCategory: boolean;
  GroupDescription: string;
  GroupDescriptionOTT: string;
  GroupNameMobile: string; // Changes based on the language
  GroupNameOTT: string; // Changes based on the language
  GroupName: string; // Changes based on the language
  IsIabPurpose: boolean;
  GeneralVendorsIds: [];
  FirstPartyCookies: [];
  Hosts: [];
  PurposeId: string;
  CustomGroupId: string;
  GroupId: string;
  Status: 'inactive' | 'active' | 'always active';
  IsDntEnabled: boolean;
  Type: string;
  DescriptionLegal: string;
  IabIllustrations: [];
  HasLegIntOptOut: boolean;
  HasConsentOptOut: boolean;
  IsGpcEnabled: boolean;
  VendorServices: null;
  TrackingTech: null;
  Cookies: [];
}
