import { useDebounceFn } from '@vueuse/core';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { reactive } from 'vue';

interface ScrollState {
  top: number;
  velocity: number;
  status: 'none' | 'scroll';
  activeModule: string;
  activeModuleIndex: number;
  isStickyActive: boolean;
  headerHeight: number;
  footerVisible: boolean;
  stageVisible: boolean;
  widgetVisible: boolean;
}

const state = reactive<ScrollState>({
  top: 0,
  velocity: 0,
  status: 'none',
  activeModule: '',
  activeModuleIndex: 0,
  isStickyActive: false,
  headerHeight: 72,
  footerVisible: false,
  stageVisible: false,
  widgetVisible: false,
});

const debounceCoreElementsWatcher = useDebounceFn(watchCoreElements, 30);

function watchCoreElements() {
  const stage = document.querySelector('.stage');
  const footer = document.querySelector('.footer');

  const cmTextHightlight = document.querySelector('.cm-text-highlight');
  const cmHighlights = document.querySelector('.cm-highlights');

  if (footer) {
    state.footerVisible = ScrollTrigger.isInViewport(footer);
  } else {
    state.footerVisible = false;
  }
  if (stage) {
    state.stageVisible = ScrollTrigger.isInViewport(stage, 0.75);
  } else {
    state.stageVisible = false;
  }

  if (cmTextHightlight && cmHighlights) {
    state.widgetVisible =
      cmTextHightlight.getBoundingClientRect().y <= 25 && cmHighlights.getBoundingClientRect().y >= window.innerHeight;
  } else {
    state.widgetVisible = false;
  }
}

async function enableScroll() {
  // Remove overflow hidden from body and do not replace it
  document.body.style.overflow = '';
}

function disableScroll() {
  document.body.style.overflow = 'hidden';
}

function setScroll(top: number, velocity?: number, isModal?: boolean) {
  if (document.body.style.position === 'fixed' && !isModal) {
    return;
  }
  if (velocity === undefined) {
    velocity = (top - state.top) * 100;
  }
  state.top = top;
  state.velocity = velocity;
  debounceCoreElementsWatcher();
}

function setActiveModule(module: string, moduleIndex: number) {
  state.activeModule = module;
  state.activeModuleIndex = moduleIndex;
  debounceCoreElementsWatcher();
}

function resetWindowScroll(deleteMemory = false) {
  if ('scrollRestoration' in history && deleteMemory) {
    history.scrollRestoration = 'manual';
  }
  requestAnimationFrame(() => {
    window.scrollTo({ left: 0, top: 0 });
  });
}

export function useScroll() {
  return {
    resetWindowScroll,
    state,
    setScroll,
    setActiveModule,
    enableScroll,
    disableScroll,
  };
}
