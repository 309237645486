import { useRoute, useRouter } from 'nuxt/app';
import type { CorePageStoryblok } from '~/types/storyblok-generated';

export function useProductWidget() {
  function pageIsWidget(blok: CorePageStoryblok) {
    return blok.body?.filter(blok => blok.component === 'em_product-widget') && useRoute().query.ref === 'widget';
  }

  function getWidgetBlok(blok: CorePageStoryblok) {
    return pageIsWidget(blok) ? blok.body?.find(blok => blok.component === 'em_product-widget') : undefined;
  }

  async function clearWidget() {
    const router = useRouter();
    const cleanRoute = router.currentRoute;
    await router.push({ path: cleanRoute.value.path });
    router.go(0);
  }

  return {
    clearWidget,
    getWidgetBlok,
  };
}
