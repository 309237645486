import * as THREE from 'three';
// @ts-expect-error - We are importing 3d related code
import fragmentShader from './postGrading.frag';
import { BlendFunction, Effect } from 'postprocessing';

export class PostGradingEffect extends Effect {
  constructor() {
    super('PostGradingEffect', fragmentShader, {
      blendFunction: BlendFunction.SET,
      uniforms: new Map<string, THREE.Uniform<number | THREE.Color>>([
        ['u_brightness', new THREE.Uniform(0)],
        ['u_contrast', new THREE.Uniform(1)],
        ['u_saturation', new THREE.Uniform(1)],
        ['u_tintIntensity', new THREE.Uniform(0)],
        ['u_tintColor', new THREE.Uniform(new THREE.Color('#000000'))],
      ]),
    });
  }
}
