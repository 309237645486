<template>
  <div class="layer">
    <p
      v-if="firstCountryCode && locationCountryCode && locationCountryCode !== firstCountryCode"
      class="other-country-info">
      {{
        t('EMMapResultsLayer.otherCountryInfo', {
          country: sbCountryCodeToLabelMap[firstCountryCode] ?? t('EMMapResultsLayer.differentCountry'),
        })
      }}
    </p>
    <p class="results-count">{{ t('EMMapResultsLayer.resultsCount', { count: results.length }) }}</p>
    <div ref="$resultsContainer" class="results-container">
      <div class="fade is-top" :class="{ 'is-hidden': arrivedState.top }"></div>
      <EMMapResult
        v-for="result in visibleEntries"
        :key="result.store.uuid"
        :store="result.store"
        :isSelected="selectedStore?.uuid === result.store.uuid"
        :buttonLink="buttonLink"
        :storeContactType="storeContactType"
        @click="emit('storeSelected', result.store)" />
      <div class="fade is-bottom" :class="{ 'is-hidden': arrivedState.bottom }"></div>
    </div>
    <div v-if="!isTablet && props.results.length > visibleEntriesCount" class="mobile-button-container">
      <UtilButton
        :blok="{
          component: 'util_button',
          text: t('global.loadMore'),
          size: 'large',
          theme: 'secondary',
          _uid: 'load-more',
        }"
        :isBackgroundDark="true"
        class="mobile-button"
        @click="handleLoadMore" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import { useScroll } from '@vueuse/core';
import { gsap } from 'gsap';
import { computed, ref, watch } from 'vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { type SbCountryCode, sbCountryCodeToLabelMap } from '~/types/google-maps';
import type { EmMapStoryblok } from '~/types/storyblok-generated';
import UtilButton from '../../utils/UtilButton/UtilButton.vue';
import type { SortedStore, Store } from './EMMap.types';
import EMMapResult from './EMMapResult.vue';

const props = defineProps<{
  results: SortedStore[];
  locationCountryCode?: SbCountryCode;
  buttonLink?: EmMapStoryblok['button_link'];
  storeContactType: EmMapStoryblok['store_contact_type'];
  selectedStore: Store | null;
}>();

const emit = defineEmits<{
  (e: 'storeSelected', store: Store): void;
}>();

const visibleEntriesCount = ref<number>(4);
const visibleEntries = computed(() => (isTablet ? props.results : props.results.slice(0, visibleEntriesCount.value)));
const firstCountryCode = computed(() => visibleEntries.value?.[0]?.store.content.location?.country);
const $resultsContainer = ref<HTMLElement>();

const {
  state: { isTablet },
} = useBreakpoint();
const { arrivedState } = useScroll($resultsContainer, {
  throttle: 100,
  offset: { top: 20, bottom: 20 },
});
const { t } = useI18n();

watch(
  () => props.results,
  () => (visibleEntriesCount.value = 4)
);

function handleLoadMore() {
  if (!$resultsContainer.value) {
    return;
  }
  const elHeight = $resultsContainer.value?.scrollHeight ?? 0;
  visibleEntriesCount.value += 4;
  gsap.fromTo($resultsContainer.value, { height: elHeight }, { height: 'auto', duration: 0.3, ease: 'ease-in-out' });
}
</script>

<style src="./EMMapResultsLayer.scss" lang="scss" scoped />
