import * as THREE from 'three';
import type { AssetManagerInstance } from '~/webglApp/core/assetManager';
import type { BlueNoiseInstance } from '~/webglApp/visuals/blueNoise/blueNoise';
import type { MachineConfig } from '~/webglApp/webGlApp.types';
// @ts-expect-error - We are importing 3d related code
import machineShadowFrag from './machineShadow.frag';
// @ts-expect-error - We are importing 3d related code
import machineShadowVert from './machineShadow.vert';

class MachineShadow {
  container = new THREE.Object3D();
  shadow: MachineConfig['shadow'];
  mesh: THREE.Mesh | null = null;
  texture: THREE.Texture | null = null;

  constructor(shadow: MachineConfig['shadow'], assetManager: AssetManagerInstance) {
    this.shadow = shadow;

    assetManager.addToLoader(shadow.texturePath, loadedAsset => {
      if (loadedAsset instanceof THREE.Texture) {
        this.texture = loadedAsset;
        this.texture.flipY = false;
        this.texture.colorSpace = THREE.SRGBColorSpace;
      }
    });
  }

  init(blueNoise: BlueNoiseInstance) {
    const geometry = new THREE.PlaneGeometry(1, 1);

    const material = new THREE.ShaderMaterial({
      uniforms: {
        u_texture: { value: this.texture },
        u_intensity: { value: this.shadow.intensity },
        u_blueNoiseTexture: { value: blueNoise.texture },
        u_blueNoiseTextureSize: { value: blueNoise.textureSize },
        u_blueNoiseTextureOffset: { value: blueNoise.textureOffset },
      },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      vertexShader: machineShadowVert,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      fragmentShader: machineShadowFrag,
      transparent: true,
    });

    this.mesh = new THREE.Mesh(geometry, material);
    this.mesh.scale.copy(this.shadow.scale);
    this.mesh.rotation.x = -Math.PI;
    this.mesh.position.copy(this.shadow.position);

    this.container.add(this.mesh);
  }
}

export default MachineShadow;
