import type { CorePageLayerStoryblok, CorePageStoryblok, IndustriesArticlePageStoryblok } from './storyblok-generated';

export type Page = CorePageStoryblok | CorePageLayerStoryblok | IndustriesArticlePageStoryblok;

export type FormType = 'contact' | 'newsletter';

export interface ModuleWrapperState {
  index: number;
  progress: number;
  active: boolean;
  visible: boolean;
  inViewport: boolean;
  atTopOfViewport: boolean;
  blenderAlpha: number;
}

export interface Market {
  label: string;
  id: string;
  languages: {
    label: string;
    id: string;
  }[];
}

export interface Country {
  label: string;
  value: string;
}

export type Device =
  | 'isPhone'
  | 'isPhoneLarge'
  | 'isTablet'
  | 'isTabletLarge'
  | 'isLaptop'
  | 'isLaptopLarge'
  | 'isDesktop'
  | 'isDesktopLarge'
  | 'isCinema'
  | 'isCinemaLarge';

export const deviceToBreakpointMap: Record<Device, number> = {
  isPhone: -640,
  isPhoneLarge: 768,
  isTablet: 1024,
  isTabletLarge: 1280,
  isLaptop: 1400,
  isLaptopLarge: 1640,
  isDesktop: 1920,
  isDesktopLarge: 2440,
  isCinema: 2840,
  isCinemaLarge: 2840,
};

export interface FormFieldOption {
  label: string;
  value: string;
}

export interface FormFieldProps {
  id?: string;
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  isDark?: boolean;
  isTouched?: boolean;
  errorMessage?: string;
}

export enum ApiResponseMessage {
  ValidationError = 'validation-error',
  CaptchaError = 'captcha-error',
  MaileonError = 'maileon-error',
  SendGridError = 'sendgrid-error',
}

export interface ApiError {
  statusCode: number;
  statusMessage: string;
  message: string;
  data: {
    messageKey: ApiResponseMessage;
  };
}

export interface OFetchApiError {
  data: ApiError;
}

export function isApiError(error: any): error is ApiError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'statusCode' in error &&
    'statusMessage' in error &&
    'message' in error &&
    'data' in error &&
    'messageKey' in (error as ApiError).data
  );
}

export function isOFetchApiError(error: any): error is OFetchApiError {
  return typeof error === 'object' && error !== null && 'data' in error && isApiError((error as ApiError).data);
}
