import { reactive } from 'vue';

type HeaderState = {
  preventSlide: boolean;
  collapsed: boolean;
  dimmed: boolean;
  menuOpen: boolean;
  cartOpen: boolean;
};

const state = reactive<HeaderState>({
  preventSlide: false,
  collapsed: false,
  dimmed: false,
  menuOpen: false,
  cartOpen: false,
});

export function useHeader() {
  return {
    state,
  };
}
