<template>
  <div class="base-radio form-field" :class="[isDark ? 'is-dark' : 'is-light', { 'is-disabled': disabled }]">
    <div class="radios-container">
      <label v-for="option in options" :key="option.value" :for="`${uid}__${option.value}`" class="option">
        <input
          :id="`${uid}__${option.value}`"
          :checked="modelValue === option.value"
          :name="name"
          type="radio"
          class="input"
          :disabled="disabled"
          @input="() => emit('update:modelValue', option.value)" />
        <div class="label">
          {{ option.label }}
        </div>
      </label>
    </div>
    <div v-if="errorMessage && isTouched" class="error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useId } from 'nuxt/app';
import type { FormFieldOption, FormFieldProps } from '~/types/utils';

interface BaseRadio extends FormFieldProps {
  options: FormFieldOption[];
  modelValue: FormFieldOption['value'] | undefined;
}

const props = defineProps<BaseRadio>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: FormFieldOption['value']): void;
}>();

const uid = props.id ?? useId();
</script>

<style src="~/assets/scss/_form-field.scss" lang="scss" scoped />
<style src="./_BaseRadio.scss" lang="scss" scoped />
