/**
 * This service is based on the scrolly-video package by Daniel Kao.
 * @see https://github.com/dkaoster/scrolly-video/blob/main/src/ScrollyVideo.js
 *
 * We can't use this package directly but would like to use the WebCodecs functionality.
 * Therefore we have to use this service of the package and adapt it to our needs.
 */

import { getBrowser } from './browser';
import { decodeVideo } from './video-decoder';

export class WebCodecsScrubber {
  src: string;
  canvasEl: HTMLCanvasElement;
  context: CanvasRenderingContext2D | null = null;
  videoEl: HTMLVideoElement;
  isReady = false;
  onReady = () => {};
  frames: ImageBitmap[] = [];
  currentFrameIndex: number | null = null;
  throttledPaintInterval: number | null = null;

  constructor({
    src,
    canvasEl,
    videoEl,
    onReady,
  }: {
    src: string;
    canvasEl: HTMLCanvasElement;
    videoEl: HTMLVideoElement;
    onReady?: () => void;
  }) {
    this.src = src;
    this.canvasEl = canvasEl;

    if (onReady) {
      this.onReady = onReady;
    }

    this.videoEl = videoEl;
    this.videoEl.src = src;
    this.videoEl.preload = 'auto';
    this.videoEl.tabIndex = 0;
    // @ts-expect-error - This property is not widely supported
    this.videoEl.autobuffer = true;
    this.videoEl.playsInline = true;
    this.videoEl.muted = true;
    this.videoEl.pause();
    this.videoEl.load();

    // Calls decode video to attempt webcodecs method
    void this.decodeVideo();
  }

  /**
   * Uses webCodecs to decode the video into frames
   */
  async decodeVideo() {
    try {
      await decodeVideo(this.src, frame => this.frames.push(frame));
    } catch (error) {
      console.error('Error encountered while decoding video', error);

      // Remove all decoded frames if a failure happens during decoding
      this.frames = [];

      // Force a video reload when videoDecoder fails
      this.videoEl.load();
    }

    // If no frames, something went wrong
    if (this.frames.length === 0) {
      console.error('No frames were received from webCodecs');
      this.onReady();
      return;
    }

    // Remove the video and add the canvas
    this.context = this.canvasEl.getContext('2d');

    // Hide the video and add the canvas to the container
    this.videoEl.style.display = 'none';

    // Paint our first frame
    requestAnimationFrame(() => this.paintCanvasFrame(0));

    this.isReady = true;
    this.onReady();
  }

  /**
   * Paints the frame of to the canvas
   */
  paintCanvasFrame(frameIndex: number) {
    if (!this.isReady || this.currentFrameIndex === frameIndex) {
      return;
    }

    // Get the frame and paint it to the canvas
    const currentFrame = this.frames[frameIndex];
    this.currentFrameIndex = frameIndex;

    if (!this.canvasEl || !this.context || !currentFrame) {
      return;
    }

    // Make sure the canvas is scaled properly, similar to setCoverStyle
    this.canvasEl.width = currentFrame.width;
    this.canvasEl.height = currentFrame.height;

    // Draw the frame to the canvas context
    this.context.drawImage(currentFrame, 0, 0, currentFrame.width, currentFrame.height);
  }

  // Throttle the paintCanvasFrame function to only fire once every 40ms
  throttledPaintCanvasFrame(frameIndex: number) {
    if (this.currentFrameIndex === frameIndex) {
      return;
    }

    if (this.throttledPaintInterval) {
      return;
    }

    this.throttledPaintInterval = window.setTimeout(() => {
      this.paintCanvasFrame(frameIndex);
      this.throttledPaintInterval = null;
    }, 30);
  }

  setProgress(progress: number) {
    this.throttledPaintCanvasFrame(Math.floor(progress * this.frames.length));
  }

  destroy() {
    this.videoEl.pause();
    this.videoEl.src = '';
    this.frames = [];
    this.currentFrameIndex = null;
  }
}

export function hasWebCodecsSupport() {
  const browser = getBrowser();
  return (
    browser !== 'safari' &&
    typeof VideoDecoder === 'function' &&
    typeof EncodedVideoChunk === 'function' &&
    typeof createImageBitmap === 'function'
  );
}
