<template>
  <div v-if="!urlHasConsentDisabledParam" class="base-consent-blender"></div>
</template>

<script lang="ts" setup>
import { useGtm } from '@gtm-support/vue-gtm';
import { useScriptTag } from '@vueuse/core';
import { useHead, useRuntimeConfig } from 'nuxt/app';
import { nextTick } from 'process';
import { onMounted, watch } from 'vue';
import { useConsent } from '~/composables/useConsent';
import { OneTrustGroup } from '@seb-platform/shared/types';

const { state: consentState } = useConsent();
const config = useRuntimeConfig();
const gtm = useGtm();

// We use this to disable the consent banner with a query parameter during pagespeed tests
const urlHasConsentDisabledParam =
  import.meta.client && new URLSearchParams(window.location.search).get('disable-consent') === 'true';

useHead({
  script: [
    {
      // Set default consent
      innerHTML: `function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[],gtag("consent","default",{ad_user_data:"denied",ad_personalization:"denied",ad_storage:"denied",analytics_storage:"denied",wait_for_update:500});`,
      tagPosition: 'head',
    },
  ],
});

/**
 * Info about OneTrust SDK
 * @see https://my.onetrust.com/s/article/UUID-d81787f6-685c-2262-36c3-5f1f3369e2a7?language=en_US
 * @see https://my.onetrust.com/articles/en_US/Knowledge/UUID-c2b2aec4-f46d-b21e-2269-94cd62c6f2a8
 */
const { load: loadOtAutoBlock } = useScriptTag(
  'https://cdn.cookielaw.org/consent/f276c760-6e8e-4901-b7bd-716a7fb29f07/OtAutoBlock.js',
  undefined,
  { manual: true }
);
const { load: loadOtSDKStub } = useScriptTag('https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', undefined, {
  manual: true,
  attrs: {
    'data-domain-script': 'f276c760-6e8e-4901-b7bd-716a7fb29f07',
    'data-document-language': 'true',
  },
});

function onConsentClick(e: MouseEvent) {
  const el = e.target as HTMLElement;
  let buttonType: 'close' | 'preferences' | undefined;

  const closeButtonIds = [
    'close-pc-btn-handler',
    'onetrust-close-btn-container',
    'onetrust-reject-all-handler',
    'onetrust-accept-btn-handler',
    'accept-recommended-btn-handler',
  ];

  const classList = Array.from(el.classList);

  if (
    classList.includes('onetrust-close-btn-handler') ||
    classList.includes('ot-pc-refuse-all-handler') ||
    closeButtonIds.includes(el.id)
  ) {
    buttonType = 'close';
  } else if (el.id === 'onetrust-pc-btn-handler') {
    buttonType = 'preferences';
  }

  switch (buttonType) {
    case 'preferences':
      consentState.visible = 'preferences';
      hideBanner();
      break;
    case 'close':
      consentState.visible = false;
      break;
    default:
      break;
  }
}

function onConsentUpdate() {
  updateTrackingGroups();
}

function updateTrackingGroups() {
  let consentString = window.OptanonActiveGroups;
  if (consentString) {
    if (consentString.startsWith(',')) {
      consentString = consentString.slice(1);
    }
    if (consentString.endsWith(',')) {
      consentString = consentString.slice(0, -1);
    }
    consentState.groups = consentString.split(',') as OneTrustGroup[];
  }

  const shouldEnableGtm = consentState.groups.includes(OneTrustGroup.Tracking);
  if (config.public.IS_HEADER_FOOTER_EXPORT) {
    // In the exported version, the GTM is loaded by an external party, so we simply trigger it via an event
    if (shouldEnableGtm) {
      window.dispatchEvent(new CustomEvent('googletagmanager:init'));
    }
  } else {
    gtm?.enable(shouldEnableGtm);
  }

  window.dataLayer!.push({ event: 'OneTrustGroupsUpdated' });
}

function showBanner() {
  document.body.classList.add('one-trust-banner-visible');
}

function hideBanner() {
  document.body.classList.remove('one-trust-banner-visible');
}

function showBlender() {
  document.body.classList.add('base-consent-blender-visible');
  document.body.style.overflow = 'hidden';
}

function hideBlender() {
  document.body.classList.remove('base-consent-blender-visible');
  nextTick(() => {
    document.body.style.overflow = '';
  });
}

watch(
  () => consentState.visible,
  () => {
    if (consentState.visible === 'banner') {
      showBlender();
      showBanner();
    } else if (consentState.visible === 'preferences') {
      showBlender();
      window.OneTrust.ToggleInfoDisplay();
    } else {
      hideBlender();
      hideBanner();
    }
  }
);

onMounted(async () => {
  if (urlHasConsentDisabledParam) {
    return;
  }

  try {
    await Promise.all([loadOtAutoBlock(), loadOtSDKStub()]);
  } catch (e) {
    // This is most likely due to them being blocked by an ad blocker / browser
    console.error('Failed to load OneTrust scripts', e);
    return;
  }

  document.addEventListener('click', onConsentClick);
  window.addEventListener('OneTrustGroupsUpdated', onConsentUpdate);
  updateTrackingGroups();

  const cookies = document.cookie.split(';');
  const optanonCookie = cookies.find(cookie => cookie.trim().startsWith('OptanonConsent='));
  const alertCookie = cookies.find(cookie => cookie.trim().startsWith('OptanonAlertBoxClosed='));

  let needsConsent = false;

  if (optanonCookie && !alertCookie) {
    const decoded = decodeURIComponent(optanonCookie).replace('OptanonConsent=', '');
    const params = Object.fromEntries(new URLSearchParams(decoded));
    if (params.AwaitingReconsent !== 'false') {
      needsConsent = true;
    }
  }

  if (!optanonCookie) {
    needsConsent = true;
  }

  if (needsConsent) {
    consentState.visible = 'banner';
  }
});
</script>

<style src="./BaseConsent.scss" lang="scss" />
