<template>
  <div
    class="base-form-upload"
    :class="[
      isDark ? 'is-dark' : 'is-light',
      { 'has-error': attachementsTotalSize > props.maxFileSize || (isTouched && errorMessage) },
    ]">
    <label :for="uid" class="upload-button-container">
      <UtilButton
        :blok="{
          component: 'util_button',
          text: t('forms.upload.uploadButtonLabel'),
          size: 'large',
          theme: 'secondary',
          icon: 'fi_upload',
          _uid: 'button',
        }"
        class="upload-button"
        :isDisabled="disabled || visibleAttachements.length >= props.maxFiles"
        :isBackgroundDark="isDark" />
      <input
        :id="uid"
        :name="name"
        class="upload-input-field"
        type="file"
        :accept="acceptedMimeTypes.join(',')"
        :multiple="maxFiles > 1"
        :disabled="visibleAttachements.length >= props.maxFiles"
        @change="onUpload" />
    </label>

    <div class="info-text">
      <span>
        {{
          t('forms.upload.infoFormats', {
            allowedExtensions: allowedExtensions.map(ext => `.${ext.toUpperCase()}`).join(', '),
            maxSize: filesize(maxFileSize),
          })
        }}
      </span>
      <br />
      <span v-if="visibleAttachements.length >= props.maxFiles">
        <strong>{{
          t('forms.upload.infoCount', {
            maxFilesString: t('forms.upload.file', props.maxFiles),
          })
        }}</strong>
      </span>
      <span v-else>{{
        t('forms.upload.infoCount', {
          maxFilesString: t('forms.upload.file', props.maxFiles),
        })
      }}</span>
    </div>

    <div v-if="visibleAttachements.length > 0" class="files">
      <div class="files-headline">
        {{ label }}
      </div>

      <!-- Files -->
      <div v-for="(attachement, index) in visibleAttachements" :key="index" class="file">
        <BaseIcon class="file-icon" name="fi_file" :size="32" :color="isDark ? 'grey-light-3' : 'grey-light-5'" />
        <div class="file-info">
          <div class="file-title">
            <div class="file-name">{{ attachement.name }}</div>
            <div class="remove-button" @click="handleRemove(attachement.name)">
              <BaseIcon name="fi_trash" :size="16" color="inherit" />
            </div>
          </div>
          <div class="subtext">
            {{ attachement.type.split('/')[1]?.toUpperCase() }}, {{ filesize(attachement.size) }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="attachementsTotalSize > props.maxFileSize" class="error-message">
      {{
        t('forms.upload.filesTooLarge', {
          filesSize: filesize(attachementsTotalSize),
          maxSize: filesize(maxFileSize),
        })
      }}
    </div>
    <div v-if="errorMessage && isTouched" class="error-message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import { filesize } from 'filesize';
import mime from 'mime/lite';
import { useId } from 'nuxt/app';
import { computed, ref, watch } from 'vue';
import { BaseIcon } from '~/components/base';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import type { FormFieldProps } from '~/types/utils';

interface BaseUpload extends FormFieldProps {
  isSubmitted?: boolean;
  maxFiles: number;
  /**
   * The max file size in bytes (e.g. 20 * 1000 * 1000 for 20 MB)
   */
  maxFileSize: number;
  /**
   * The accepted mime types (e.g. 'image/jpeg', 'application/pdf')
   */
  acceptedMimeTypes: string[];
  modelValue: File[] | undefined;
  hasError?: boolean;
}

const props = defineProps<BaseUpload>();

const emit = defineEmits<{
  (e: 'update:modelValue', files: File[]): void;
  (e: 'update:hasError', hasError: boolean): void;
}>();

const uid = props.id ?? useId();
const { t } = useI18n();

const visibleAttachements = ref<File[]>([]);
const validAttachements = ref<File[]>([]);
const attachementsTotalSize = computed(() => visibleAttachements.value.reduce((acc, file) => acc + file.size, 0));
const allowedExtensions = computed(() => {
  const extensions = new Set<string>();
  props.acceptedMimeTypes.forEach(mimeType => {
    const extension = mime.getExtension(mimeType);
    if (extension) {
      extensions.add(extension);
    }
  });
  return Array.from(extensions);
});

watch(
  () => attachementsTotalSize.value,
  newSize => {
    emit('update:hasError', newSize > props.maxFileSize);
  }
);

watch(
  () => visibleAttachements.value.length,
  () => {
    if (visibleAttachements.value.some(file => file.size > props.maxFileSize)) {
      // None of the files are valid if at least one is too large
      validAttachements.value = [];
    } else {
      validAttachements.value = visibleAttachements.value;
    }
    emit('update:modelValue', visibleAttachements.value);
  }
);

async function onUpload(event: Event) {
  const target = event.target as HTMLInputElement;

  if (!target?.files) {
    return;
  }

  Array.from(target.files)
    .filter(file => visibleAttachements.value.every(visAtt => visAtt.name !== file.name))
    .forEach(file => {
      if (visibleAttachements.value.length < props.maxFiles) {
        visibleAttachements.value.push(file);
      }
    });
}

function handleRemove(name: string) {
  visibleAttachements.value = visibleAttachements.value.filter(file => file.name !== name);
}
</script>

<style src="./BaseUpload.scss" lang="scss" scoped />
