<template>
  <div
    v-editable="blok"
    class="item-table-standard table"
    :class="[isDark ? 'is-dark' : 'is-light', { 'has-title': hasTitle, ['is-format-' + blok.format]: blok.format }]">
    <UtilAccordion
      :isEnabled="blok.is_collapsible"
      :isDark="isDark"
      :index="0"
      :activeIndex="0"
      :isLast="props.isLast"
      buttonTheme="primary"
      isSpacingLarge
      isTable>
      >
      <!-- Head -->
      <template v-if="hasTitle" #head>
        <div ref="$head" class="head" :class="{ 'is-collapsible': blok.is_collapsible }">
          <UtilRichtext
            v-if="blok.title?.[0]"
            :blok="blok.title[0]"
            :isDark="isDark"
            :isProseEnabled="false"
            class="title" />
        </div>
      </template>

      <!-- Body -->
      <template #body>
        <div class="body">
          <div class="body-content">
            <!-- Mobile Table -->
            <div class="rows is-mobile">
              <!-- Rows -->
              <template v-for="(row, rowIndex) in mobileRows" :key="rowIndex">
                <div
                  v-editable="row"
                  class="row"
                  :class="{
                    'has-border-bottom':
                      row.type !== 'cm_table-standard--row-full-width' && rowIndex < mobileRows.length - 1,
                  }">
                  <!-- Row title -->
                  <template v-if="row.titleCell">
                    <div
                      v-if="row.titleCell.component === 'cm_table-cell-icon-text' && row.titleCell.text"
                      class="row-title">
                      {{ row.titleCell.text }}
                    </div>
                    <div v-else-if="row.titleCell.component === 'cm_table-cell-media-copy' && row.titleCell.copy">
                      <UtilRichtext
                        v-if="row.titleCell.copy.length"
                        :blok="row.titleCell.copy[0]"
                        :isDark="isDark"
                        :isProseParagraphsEnabled="false" />
                    </div>
                  </template>

                  <!-- Cells -->
                  <template v-for="(entry, cellIndex) in row.cells" :key="cellIndex">
                    <ItemTableStandardCell
                      v-if="entry.cell"
                      :blok="entry.cell"
                      :isDark="isDark"
                      :isCellTitle="!!entry.titleCell"
                      :isFullWidth="row.type === 'cm_table-standard--row-full-width'"
                      :titleCell="entry.titleCell"
                      :cellsInRowCount="numCols"
                      :tableHasTitleCell="hasTitle"
                      isMobileTable />
                  </template>
                </div>
              </template>

              <!-- Footnote -->
              <div v-if="hasFootnote" v-editable="blok.footnote" class="row is-footnote">
                <BaseRichtext
                  :text="blok.footnote"
                  :isDark="isDark"
                  :isProseParagraphsEnabled="true"
                  class="footnote" />
              </div>
            </div>

            <!-- Desktop Table -->
            <div class="rows is-desktop">
              <!-- Rows -->
              <div
                v-for="(row, rowIndex) in blok.rows"
                :key="rowIndex"
                v-editable="row"
                class="row"
                :style="{
                  gridTemplateColumns: `repeat(${numCols}, minmax(0, 1fr)`,
                }">
                <!-- Cells -->
                <template v-for="(cell, cellIndex) in row.cells" :key="cellIndex">
                  <ItemTableStandardCell
                    v-if="cell"
                    :blok="cell"
                    :isDark="isDark"
                    :isCellTitle="isCellTitleDesktop(cellIndex, rowIndex)"
                    :cellsInRowCount="numCols"
                    :tableHasTitleCell="hasTitle"
                    :isFullWidth="row.component === 'cm_table-standard--row-full-width'" />
                </template>
              </div>

              <!-- Caption -->
              <div class="caption">
                <BaseRichtext
                  v-if="blok.caption"
                  v-editable="blok.caption"
                  :text="blok.caption"
                  :isDark="isDark"
                  :isProseParagraphsEnabled="true" />
              </div>

              <!-- Footnote -->
              <div v-if="hasFootnote" v-editable="blok.footnote" class="row is-footnote">
                <BaseRichtext
                  :text="blok.footnote"
                  :isDark="isDark"
                  :isProseParagraphsEnabled="true"
                  class="footnote" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </UtilAccordion>
  </div>
</template>

<script lang="ts" setup>
import ItemTableStandardCell from './ItemTableStandardCell.vue';
import UtilAccordion from '~/components/storyblok/utils/UtilAccordion/UtilAccordion.vue';
import UtilRichtext from '~/components/storyblok/utils/UtilRichtext/UtilRichtext.vue';
import BaseRichtext from '~/components/base/BaseRichtext/BaseRichtext.vue';
import { computed, ref } from 'vue';
import type {
  CmTableCellEmptyStoryblok,
  CmTableCellIconTextStoryblok,
  CmTableCellMediaCopyStoryblok,
  CmTableStandardRowFullWidthStoryblok,
  CmTableStandardRowStoryblok,
  CmTableStandardStoryblok,
  UtilLinkItemSubtextStoryblok,
} from '~/types/storyblok-generated';

type Row = CmTableStandardRowStoryblok | CmTableStandardRowFullWidthStoryblok;
type Cell =
  | CmTableCellIconTextStoryblok
  | CmTableCellMediaCopyStoryblok
  | CmTableCellEmptyStoryblok
  | UtilLinkItemSubtextStoryblok;

type MobileRow = {
  titleCell?: CmTableCellIconTextStoryblok | CmTableCellMediaCopyStoryblok;
  type: CmTableStandardRowStoryblok['component'] | CmTableStandardRowFullWidthStoryblok['component'];
  cells: {
    cell: Cell;
    titleCell?: Cell;
  }[];
};

const props = withDefaults(
  defineProps<{
    blok: CmTableStandardStoryblok;
    isDark?: boolean;
    isLast: boolean;
    index?: number;
  }>(),
  { index: 0 }
);

const $head = ref<HTMLElement>();

const hasTitle = computed(() => !!props.blok.title?.length);
const hasFootnote = computed(() => !!props.blok.footnote?.content?.[0]?.content);
const numCols = computed(() => Math.max(...props.blok.rows!.map(arr => arr.cells?.length ?? 0)));
const mobileRows = computed(() => createMobileRows(props.blok.rows ?? []));

/**
 * Creates a list of rows for the mobile view.
 * This is based on the format of the table and the sort order (for format "row-column").
 */
function createMobileRows(rows: Row[]): MobileRow[] {
  const { format, sort_mobile: sortMobile } = props.blok;

  const tableIsSortedByRow =
    (props.blok.format === 'row-column' && sortMobile === 'rows') || props.blok.format === 'row';

  let colOffset = 0;
  let rowOffset = 0;
  if (format === 'column') {
    // In this format, the first row is used for titles and does not hold any content.
    rowOffset = 1;
  }
  if (format === 'row') {
    // In this format, the first column is used for titles and does not hold any content.
    colOffset = 1;
  }
  if (format === 'row-column') {
    // In this format, both the first row and first column are used for titles and do not hold any content.
    colOffset = 1;
    rowOffset = 1;
  }
  const numCols = Math.max(...rows.map(row => row.cells?.length ?? 0)) - colOffset;

  if (tableIsSortedByRow) {
    // Table is sorted by rows
    const hasTitleCells = format === 'row-column' || format === 'row';

    return rows.map((_, rowIndex) => {
      const row = rows[rowIndex + rowOffset];
      if (row?.component === 'cm_table-standard--row-full-width') {
        return { type: 'cm_table-standard--row-full-width', cells: [{ cell: row.cells![0] }] };
      }

      let titleCellAdded = false; // Make sure to only add title cell once per row
      const mobileRow = {
        titleCell: hasTitleCells
          ? (row?.cells?.[0] as CmTableCellMediaCopyStoryblok | CmTableCellIconTextStoryblok)
          : undefined,
        type: 'cm_table-standard--row',
        cells: rows.map((_, rowIndexCurrent) => {
          const cell = row?.cells![rowIndexCurrent + colOffset];
          const titleCell = hasTitleCells && titleCellAdded === false ? row?.cells?.[0] : undefined;
          titleCellAdded = true;

          return { cell, titleCell };
        }),
      } satisfies MobileRow;
      return mobileRow;
    });
  } else {
    // Table is sorted by columns (default)
    const hasTitleCells = format === 'row-column' || format === 'column';

    return new Array(numCols).fill(null).map((_, colIndex) => {
      return {
        titleCell: hasTitleCells
          ? (rows[0].cells?.[colIndex + colOffset] as
              | CmTableCellMediaCopyStoryblok
              | CmTableCellIconTextStoryblok
              | undefined)
          : undefined,
        type: 'cm_table-standard--row',
        cells: rows
          .map((_, rowIndex) => {
            const row = rows[rowIndex + rowOffset];
            if (row?.component === 'cm_table-standard--row-full-width') {
              if (colIndex === rowIndex + rowOffset) {
                return { cell: row.cells![0] };
              } else {
                return { cell: undefined };
              }
            }

            const cell = row?.cells![colIndex + colOffset];
            const titleCell = hasTitleCells ? row?.cells?.[0] : undefined;

            return { cell, titleCell };
          })
          .filter(entry => !!entry.cell),
      } satisfies MobileRow;
    });
  }
}

function isCellTitleDesktop(cellIndex: number, rowIndex: number) {
  return (
    (cellIndex === 0 && props.blok.format === 'row') ||
    (rowIndex === 0 && props.blok.format === 'column') ||
    ((rowIndex === 0 || cellIndex === 0) && props.blok.format === 'row-column')
  );
}
</script>

<style src="./ItemTableStandard.scss" lang="scss" scoped />
