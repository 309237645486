<template>
  <div ref="$el" v-editable="blok" class="container-full">
    <ItemContentHead
      v-if="blok.head?.[0]"
      :blok="blok.head[0]"
      :isDark="blok.is_dark"
      :headSize="blok.head_size"
      class="content-head" />

    <UtilStickyBar v-if="blok.filters_enabled" :isDark="blok.is_dark">
      <template #fixedContent>
        <div class="categories-container">
          <UtilFilterButton
            v-for="tag in tags"
            :key="tag.value"
            :value="tag.value"
            :label="tag.label"
            :isDark="blok.is_dark"
            :isSelected="selectedTag === tag.value"
            @click="onTagToggle(tag.value)" />
        </div>
      </template>
    </UtilStickyBar>

    <div ref="$cardsContainer" class="cards-container">
      <LMGridIndustriesCard
        v-for="card in visibleArticles"
        :key="card.article.full_slug"
        class="grid-card"
        :class="{
          'is-smaller-on-mobile': card.layout.isSmallOnMobile,
          'is-vertically-centered': card.layout.isVerticallyCentered,
        }"
        :style="{
          gridColumn: `${card.layout.columnStart} / ${card.layout.columnEnd}`,
        }"
        :article="card.article"
        :breakpointsWidthMap="card.layout.breakpointsWidthMap"
        :imageRatio="card.layout.imageRatio"
        :isDark="blok.is_dark" />
    </div>
    <div class="button-container">
      <UtilButton
        v-if="blok.load_more_button_shown && visibleCardsCount < filteredArticles.length"
        :blok="{
          component: 'util_button',
          text: t('global.loadMore'),
          theme: 'secondary',
          size: 'large',
          _uid: 'load-more-button',
        }"
        @click="increaseCardCount" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import type { ISbStoryData } from 'storyblok-js-client';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import ItemContentHead from '~/components/storyblok/item/ItemContentHead/ItemContentHead.vue';
import UtilFilterButton from '~/components/storyblok/utils/UtilFilterButton/UtilFilterButton.vue';
import UtilStickyBar from '~/components/storyblok/utils/UtilStickyBar/UtilStickyBar.vue';
import { useLocale } from '~/composables/useLocale';
import { useScroll } from '~/composables/useScroll';
import { useStoryblokClient } from '~/composables/useStoryblokClient';
import { useTransition } from '~/composables/useTransition';
import type { LmGridIndustriesStoryblok } from '~/types/storyblok-generated';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import { cardsDefaultLayout, cardsFilteredLayout, excludedFields, type ReducedArticle } from './LMGridIndustries.types';
import LMGridIndustriesCard from './LMGridIndustriesCard.vue';

const props = defineProps<{ blok: LmGridIndustriesStoryblok }>();

const { getDatasourceEntries, getStories } = await useStoryblokClient();
const { checkViewPortElements, addViewPortElements, removeViewPortElements } = useTransition();
const { state: scrollState } = useScroll();
const { locale } = useLocale();

const selectedTag = ref<string | null>(null);
const $cardsContainer = ref<HTMLElement>();
const $el = ref<HTMLElement>();
const initialCardsCount = computed(() => Math.ceil((parseInt(props.blok.rows_shown ?? '1') ?? 1) * 2.5));
const cardsLayout = computed(() => (selectedTag.value ? cardsFilteredLayout : cardsDefaultLayout));
const visibleCardsCount = ref(initialCardsCount.value);
const { t } = useI18n();

/**
 * Get available tags
 */
const tagsData = await getDatasourceEntries('lm-grid-industries-tags');
const tags =
  tagsData.value?.map(tag => ({
    value: tag.value ?? '',
    label: t(`dataSources.lm-grid-industries-tags.${tag.value}`, { count: 2 /* Forcing usage of plural version */ }),
  })) ?? [];

function onTagToggle(tag: string) {
  selectedTag.value = selectedTag.value === tag ? null : tag;

  // Reset card count
  if (selectedTag.value) {
    visibleCardsCount.value = 6;
  } else {
    visibleCardsCount.value = initialCardsCount.value;
  }

  // Scroll to top of cards container
  if ($cardsContainer.value) {
    window.scrollTo({
      top: $cardsContainer.value.getBoundingClientRect().top + window.scrollY - 160,
      behavior: 'smooth',
    });
  }
}

function increaseCardCount() {
  if (selectedTag.value) {
    visibleCardsCount.value += 6;
  } else {
    visibleCardsCount.value += 5;
  }
}

function unique(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index;
}

const articles: ISbStoryData<ReducedArticle>[] = await getStories<ReducedArticle>({
  starts_with: `${locale}/`,
  content_type: 'industries_article_page',
  excluding_fields: excludedFields.join(','),
}).then(res => res.value ?? []);
const articlesCombined = [
  // Combine highlighted and fetched articles
  ...(props.blok.highlighted_articles?.map(id => articles.find(article => article.uuid === id)) ?? []),
  ...articles,
]
  .filter(article => !!article)
  .filter(unique);

const filteredArticles = computed(() => {
  if (selectedTag.value === null) {
    return articlesCombined ?? [];
  }
  return (articlesCombined ?? []).filter(article =>
    article.content.tags?.some(tag => tag.toString() === selectedTag.value)
  );
});
const visibleArticles = computed(() => {
  return filteredArticles.value
    .slice(0, visibleCardsCount.value)
    .map((article, index) => ({ article, layout: cardsLayout.value[index % cardsLayout.value.length] }));
});

function watchPageScroll(isMounted: boolean = false) {
  checkViewPortElements(
    $el.value as HTMLElement,
    isMounted
      ? {
          baseDelay: props.blok.head?.length ? 0.3 : 0,
          staggerEach: 0.1,
        }
      : undefined
  );
}

function parseAnimatedItems(mounted: boolean = false) {
  if (!$el.value) {
    return;
  }
  addViewPortElements($el.value.querySelectorAll('.util-sticky-bar'));
  addViewPortElements($el.value.querySelectorAll('.grid-card'));
  watchPageScroll(mounted);
}

watch(
  () => visibleArticles.value,
  async () => {
    await nextTick(parseAnimatedItems);
  }
);

watch(
  () => scrollState.top,
  () => {
    watchPageScroll();
  }
);

onBeforeUnmount(() => {
  if ($el.value) {
    removeViewPortElements($el.value);
  }
});

onMounted(async () => {
  await nextTick(() => parseAnimatedItems(true));
});
</script>

<style src="./LMGridIndustries.scss" lang="scss" scoped />
