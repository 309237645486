import { useI18n } from '#imports';
import { ref } from 'vue';

export function useGoogleMaps() {
  const isLoading = ref(false);
  const autocompleteError = ref('');
  const { t } = useI18n();

  async function getLocationByQuery(query: string) {
    isLoading.value = true;
    const { status, data } = await $fetch('/api/google-maps/geocode', {
      params: { query },
    });
    isLoading.value = false;
    if (status === 'not_found') {
      return { status, message: t('useGoogleMaps.locationNotFound'), location: null };
    }
    if (status === 'success') {
      return {
        status,
        location: data.location,
        countryCode: data.countryCode,
      };
    }
  }

  async function requestPlacesAutocomplete(query: string) {
    isLoading.value = true;
    const { data } = await $fetch('/api/google-maps/autocomplete', {
      params: { query: query },
    });
    isLoading.value = false;
    if (data) {
      return data.suggestions;
    }
  }

  async function getLocationByPlaceId(placeId: string) {
    const { status, data } = await $fetch('/api/google-maps/geocode', {
      params: { placeId },
    });
    if (status === 'success') {
      return {
        location: data.location,
        countryCode: data.countryCode,
      };
    }
  }

  return {
    getLocationByQuery,
    requestPlacesAutocomplete,
    getLocationByPlaceId,
    isLoading,
    autocompleteError,
  };
}
