import { Locale } from '~/types/locales';
import type { MultilinkStoryblok } from '~/types/storyblok-generated';

/**
 * Adds a trailing and leading slash where applicable.
 */
export function normalizeUrl(url: string): string {
  if (!url) {
    return url;
  }

  if (url.startsWith('mailto:')) {
    // Ignore mailto links
    return url;
  }

  const hasFileEnding = url.match(/\.[a-z0-9]{2,4}$/i);
  if (!url.endsWith('/') && !hasFileEnding) {
    url = `${url}/`;
  }

  if (!url.startsWith('/') && !url.startsWith('http')) {
    url = `/${url}`;
  }

  return url;
}

export function urlIsActive(currentUrl: string, url: string | undefined): boolean {
  if (!url) {
    return false;
  }

  // Normalize current route and given url for comparison
  return normalizeUrl(currentUrl) === normalizeUrl(url);
}

export function urlExtractFromLink(link: MultilinkStoryblok) {
  if (link.linktype === 'story') {
    return link?.story?.full_slug ?? link?.cached_url;
  }
  if (link.linktype === 'asset') {
    return link?.url;
  }
  if (link.linktype === 'url') {
    return link?.url;
  }
}

export function urlIsExternal(url: string) {
  const isShopOrSiteDomain = /^https:\/\/(\w+\.wmf-coffeemachines\.com|\w+\.do-show\.y1\.de)/.test(url);
  if (isShopOrSiteDomain) {
    // Links pointing to the shop should not be considered external
    return false;
  }

  return /^https?:\/\//.test(url);
}

export function urlIsShowroom(locale: Locale, link: MultilinkStoryblok) {
  if (![Locale.DE_DE, Locale.EN_COM].includes(locale)) {
    // TODO: Add to sentry
    console.error(`Unsupported showroom locale: ${locale}`);
  }
  const url = urlExtractFromLink(link);
  return isShowroomUrl(url ?? '');
}

export function isShowroomUrl(url: string) {
  return /\/?(de_de\/produkte|en_com\/products)\/?(\?.*)?$/.test(url);
}
