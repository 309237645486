<template>
  <div v-editable="blok" class="lm-slider" :class="blok.is_dark ? 'is-dark' : 'is-light'">
    <div class="top">
      <ItemContentHead
        v-if="blok.head?.[0]"
        :blok="blok.head[0]"
        :isDark="blok.is_dark"
        :isMaxWidthEnabled="true"
        :isVisibleOnLoad="isStorybookView"
        :headSize="blok.head_size"
        class="head" />
    </div>

    <div class="swiper-outer-container" :class="{ 'has-min-height': !swiperInstance }">
      <swiper v-show="!!swiperInstance" v-bind="options" @swiper="onSwiperInnit" @slideChange="onSlideChange">
        <swiper-slide v-for="(item, index) in itemCards" :key="index">
          <ItemCardTeaser :blok="item" :isDark="blok.is_dark" />
        </swiper-slide>
        <template #container-end>
          <div v-if="!swiperInstance?.isLocked" class="arrows">
            <button
              class="arrow is-prev"
              type="button"
              :class="{ 'is-disabled': isBeginning }"
              :aria-label="t('global.previousSlide')"
              @click="prevSlide">
              <BaseIcon
                name="fi_arrow-left"
                :size="16"
                :color="
                  blok.is_dark ? (isBeginning ? 'grey-light-4' : 'white') : isBeginning ? 'grey-light-3' : 'black'
                " />
            </button>
            <button
              class="arrow is-next"
              type="button"
              :class="{ 'is-disabled': isEnd }"
              :aria-label="t('global.nextSlide')"
              @click="nextSlide">
              <BaseIcon
                name="fi_arrow-right"
                :size="16"
                :color="blok.is_dark ? (isEnd ? 'grey-light-4' : 'white') : isEnd ? 'grey-light-3' : 'black'" />
            </button>
          </div>
        </template>
      </swiper>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import type { ISbStoryData } from 'storyblok';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import type { Swiper as SwiperInstance, SwiperOptions } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { computed, ref } from 'vue';
import BaseIcon from '~/components/base/BaseIcon.vue';
import ItemCardTeaser from '~/components/storyblok/item/ItemCardTeaser/ItemCardTeaser.vue';
import ItemContentHead from '~/components/storyblok/item/ItemContentHead/ItemContentHead.vue';
import { useProduct, type ItemCardTeaserProduct } from '~/composables/useProduct';
import type { DataAddOnStoryblok, DataProductStoryblok, LmSliderStoryblok } from '~/types/storyblok-generated';

const props = defineProps<{ blok: LmSliderStoryblok; isStorybookView?: boolean }>();

const swiperInstance = ref<SwiperInstance | null>(null);
const isBeginning = ref(true);
const isEnd = ref(false);
const { t } = useI18n();

const SLIDES_PER_VIEW = {
  mobile: 2,
  tablet: 3,
  tabletLarge: 4,
};

const options = {
  preventInteractionOnTransition: true,
  centerInsufficientSlides: true,
  grabCursor: true,
  slidesPerView: SLIDES_PER_VIEW.mobile,
  slidesPerGroup: SLIDES_PER_VIEW.mobile,
  navigation: false,
  breakpoints: {
    769: {
      slidesPerView: SLIDES_PER_VIEW.tablet,
      slidesPerGroup: SLIDES_PER_VIEW.tablet,
    },
    1024: {
      slidesPerView: SLIDES_PER_VIEW.tabletLarge,
      slidesPerGroup: SLIDES_PER_VIEW.tabletLarge,
    },
  },
  pagination: {
    clickable: true,
  },
  modules: [Navigation, Pagination],
  // We have to use satisfies here because SwiperOptions does not quite match the Vue component props
} satisfies SwiperOptions;

function onSwiperInnit(swiper: SwiperInstance) {
  swiperInstance.value = swiper;
}

function onSlideChange(swiper: SwiperInstance) {
  isBeginning.value = swiper.isBeginning;
  isEnd.value = swiper.isEnd;
}

function nextSlide() {
  swiperInstance.value?.slideNext();
}

function prevSlide() {
  swiperInstance.value?.slidePrev();
}

const { transformMachineToItemCardTeaser, transformAddOnToItemCardTeaser } = useProduct();

const itemCards = computed((): ItemCardTeaserProduct[] => {
  if (!props.blok.products) {
    return [];
  }
  return props.blok.products
    .map(product => {
      if (typeof product === 'string') {
        return null;
      }
      if (product.content.component === 'data_product') {
        return transformMachineToItemCardTeaser(product as ISbStoryData<DataProductStoryblok>);
      }
      return transformAddOnToItemCardTeaser(product as ISbStoryData<DataAddOnStoryblok>);
    })
    .filter(p => !!p);
});
</script>

<style src="./LMSlider.scss" lang="scss" scoped />
