<template>
  <div v-if="!!settings">
    <Teleport to="#header">
      <BMHeaderRich
        v-if="typeof header !== 'string' && header?.content"
        :blok="header.content"
        :inPageBlok="header.content.shop_header_in_page" />

      <!-- Shopping cart -->
      <BMShoppingCart
        v-if="
          typeof settings.content.shopping_cart !== 'string' &&
          settings.content.shopping_cart?.content &&
          typeof header !== 'string' &&
          header?.content
        "
        :blok="settings.content.shopping_cart.content"
        :headerBlok="header?.content" />
    </Teleport>
    <Teleport to="#footer">
      <BMFooter
        v-if="typeof settings.content.footer_default !== 'string' && settings.content.footer_default?.content"
        :blok="settings.content.footer_default.content" />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import BMFooter from '~/components/storyblok/basic/BMFooter/BMFooter.vue';
import BMHeaderRich from '~/components/storyblok/basic/BMHeaderRich/BMHeaderRich.vue';
import BMShoppingCart from '~/components/storyblok/basic/BMShoppingCart/BMShoppingCart.vue';
import { useStoryblokClient } from '~/composables/useStoryblokClient';
import { Locale } from '~/types/locales';

const locale = Locale.DE_DE; // He shop is only available in DE for now

const { getSettings } = await useStoryblokClient();
const settings = await getSettings(locale);

const header = computed(() => settings.value.content.header_rich_default);
</script>

<style lang="scss" scoped>
@use '~/assets/scss/variables';

.has-black-bg {
  background-color: variables.$black;
}

.has-white-bg {
  background-color: variables.$white;
}
</style>
