import { useLocale } from '#imports';
import { useGtm } from '@gtm-support/vue-gtm';
import { useRoute, useRuntimeConfig } from 'nuxt/app';
import type { ISbStoryData } from 'storyblok-js-client';
import { onMounted, watch, type Ref } from 'vue';
import type { CorePageStoryblok } from '~/types/storyblok-generated';
import type { FormType } from '~/types/utils';
import type { FetchedStory } from './useStoryblokClient';

// Start of the form filling process
interface FormStartEvent {
  event: 'formStart';
  formType: FormType;
}

// Successful form submission
interface FormSubmitEvent {
  event: 'formSubmit';
  formType: FormType;
}

// Successful newsletter signup
interface NewsletterSignupEvent {
  event: 'newsletterSignup';
}

// Click in the search bar
interface ClickOnSearchEvent {
  event: 'clickOnSearch';
}

// Start of the search process (when clicking on search or pressing the Enter key with the entered search term)
interface SearchEvent {
  event: 'search';
  searchTerm: string;
}

// Click in navigation
interface NavigationClickEvent {
  event: 'navigationClick';
  navigationItem: string; // Name of the clicked link
}

// Click on a machine flyout
interface MachineFlyoutClickEvent {
  event: 'machineFlyout';
}

// Click on a product in the machine flyout
interface FlyoutProductClickEvent {
  event: 'flyoutProductClick';
  product: string; // Name of the clicked product
}

// Usage of the product filter
interface ProductFilterEvent {
  event: 'productFilter';
  filterValue: (string | null)[]; // All selected filters
}

type TrackingEvent =
  | FormStartEvent
  | FormSubmitEvent
  | NewsletterSignupEvent
  | ClickOnSearchEvent
  | SearchEvent
  | NavigationClickEvent
  | MachineFlyoutClickEvent
  | FlyoutProductClickEvent
  | ProductFilterEvent;

export function useGtmTracking() {
  const gtm = useGtm();
  const route = useRoute();
  const config = useRuntimeConfig();
  const { locale } = useLocale();

  function trackPageView(story: ISbStoryData<CorePageStoryblok> | null) {
    if (config.public.IS_HEADER_FOOTER_EXPORT) {
      return;
    }
    const path = route.fullPath;

    if (!locale || !story) {
      return;
    }

    const pageType = story.content.page_type || 'unset';

    gtm?.trackView(story.content.page_type, path, {
      pageLocale: locale,
      pageVersion: '1.0.0',
      pageType,
    });
  }

  /**
   * Start tracking page views based on the current route
   */
  function trackPageViews(storyRes: Ref<FetchedStory<CorePageStoryblok>>) {
    onMounted(() => trackPageView(storyRes.value.story));
    watch(
      () => [route.path, storyRes.value.story?.slug],
      () => trackPageView(storyRes.value.story)
    );
  }

  function trackEvent(event: TrackingEvent) {
    gtm?.push(event);
  }

  return { trackPageViews, trackEvent };
}
