<template>
  <div ref="$el" v-editable="blok" class="lm-downloads container-full">
    <div class="wrapper">
      <!-- Copy Heading -->
      <ItemContentHead
        v-if="blok.head?.length"
        class="head"
        :blok="blok.head[0]"
        :isDark="blok.is_dark"
        :headSize="blok.head_size" />

      <!-- Content -->
      <div class="content">
        <div v-for="(section, i) in blok.items" :key="i" v-editable="section">
          <!-- Line -->
          <div v-if="isTopLineShown(i)" :class="blok.is_dark ? 'line-dark-big' : 'line-light-big'"></div>

          <!-- Headline -->
          <div v-if="section.component !== 'lm_downloads--clusters'" class="heading">
            <div>
              <h2 class="heading-sub-medium">{{ section.headline }}</h2>
              <p
                v-if="section.component === 'lm_downloads--packages' && section.subline"
                class="subline text-large-regular"
                :class="blok.is_dark ? 'is-dark' : 'is-light'">
                {{ section.subline }}
              </p>
            </div>

            <UtilButton
              v-for="(button, j) in section.button"
              :key="j"
              class="section-button"
              :isNoWrap="true"
              :blok="button"
              :isBackgroundDark="blok.is_dark"
              :isSmallOnMobile="true" />
          </div>

          <!-- Grid -->
          <div v-if="section.component === 'lm_downloads--grid'" class="grid">
            <UtilLinkItemSubtext
              v-for="(item, index) in section.items"
              :key="index"
              :blok="item"
              :isDark="blok.is_dark" />
          </div>

          <!-- Rich Package -->
          <div v-if="section.component === 'lm_downloads--packages'" class="packages">
            <template v-for="(pkg, index) in section.items" :key="index">
              <div v-if="!!pkg.media?.filename" v-editable="pkg" class="package">
                <BaseVideo v-if="isVideo(pkg.media.filename)" :src="pkg.media.filename" :autoplay="true" :loop="true" />
                <template v-else>
                  <!-- Desktop image -->
                  <BaseImage
                    v-if="breakpointState.isPhoneLarge"
                    :image="pkg.media"
                    :breakpointsWidthMap="{ 0: 1200 }"
                    :heightRatio="[32, 9]"
                    class="image-desktop" />
                  <!-- Mobile image -->
                  <BaseImage
                    v-else
                    :image="pkg.media_mobile || pkg.media"
                    :breakpointsWidthMap="{ 0: 600 }"
                    :heightRatio="[3, 4]"
                    class="image-mobile" />
                </template>
                <div class="package-heading" :class="pkg.is_dark ? 'is-dark' : 'is-light'">
                  <h3 class="package-headline heading-sub-medium">{{ pkg.headline }}</h3>
                  <p v-if="pkg.subline" class="package-subline">{{ pkg.subline }}</p>
                </div>
                <div class="package-bottom">
                  <UtilLinkItemSubtext v-for="(item, j) in pkg.link" :key="j" :blok="item" :isDark="pkg.is_dark" />
                </div>
              </div>
            </template>
          </div>

          <!-- Clusters -->
          <div v-if="section.component === 'lm_downloads--clusters'" class="clusters">
            <div v-for="(cluster, j) in section.items" :key="j" v-editable="cluster">
              <div ref="$clustersContent" class="clusters-content">
                <div class="clusters-text">
                  <h2 class="clusters-headline heading-sub-medium">
                    {{ cluster.headline }}
                  </h2>
                  <p
                    v-if="cluster.subline"
                    class="subline text-large-regular"
                    :class="blok.is_dark ? 'is-dark' : 'is-light'">
                    {{ cluster.subline }}
                  </p>
                </div>
                <UtilButton
                  v-for="(button, k) in cluster.button"
                  :key="k"
                  class="button"
                  :isNoWrap="true"
                  :blok="button"
                  :isBackgroundDark="blok.is_dark"
                  :isSmallOnMobile="true" />
              </div>

              <div class="clusters-items">
                <UtilLinkItemSubtext v-for="(item, k) in cluster.items" :key="k" :blok="item" :isDark="blok.is_dark" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { BaseImage, BaseVideo } from '~/components/base';
import ItemContentHead from '~/components/storyblok/item/ItemContentHead/ItemContentHead.vue';
import UtilLinkItemSubtext from '~/components/storyblok/utils/UtilLinkItemSubtext/UtilLinkItemSubtext.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import { isVideo } from '~/utils/media';
import { useScroll } from '~/composables/useScroll';
import { useTransition } from '~/composables/useTransition';
import type { LmDownloadsStoryblok } from '~/types/storyblok-generated';
import { useBreakpoint } from '~/composables/useBreakpoint';

const props = defineProps<{
  blok: LmDownloadsStoryblok;
}>();

const { state: scrollState } = useScroll();
const { state: breakpointState } = useBreakpoint();
const { checkViewPortElements, removeViewPortElements, addViewPortElements, hideElements } = useTransition();

const $el = ref<HTMLElement | null>();

function isTopLineShown(index: number) {
  // First item never has a line
  if (index === 0) {
    return false;
  }

  // Line is only shown if the previous item is not a rich package
  return props.blok.items?.[index - 1].component !== 'lm_downloads--packages';
}

watch(() => scrollState.top, watchPageScroll);

function watchPageScroll() {
  if ($el.value) {
    checkViewPortElements($el.value);
  }
}

function initAnimations() {
  if (!$el.value) {
    return;
  }
  addViewPortElements($el.value.querySelectorAll('.clusters-content'));
  addViewPortElements($el.value.querySelectorAll('.clusters-items'));
  addViewPortElements($el.value.querySelectorAll('.grid'));
  addViewPortElements($el.value.querySelectorAll('.line-light-big,.line-dark-big'));
  addViewPortElements($el.value.querySelectorAll('.heading'));
  addViewPortElements($el.value.querySelectorAll('.packages'));

  void nextTick(() => {
    if (!$el.value) {
      return;
    }
    hideElements($el.value);
    checkViewPortElements($el.value, {
      baseDelay: 0.5,
      staggerEach: 0.1,
    });
  });
}

onBeforeUnmount(() => {
  if ($el.value) {
    removeViewPortElements($el.value);
  }
});

onMounted(() => {
  void nextTick(initAnimations);
});
</script>

<style src="./LMDownloads.scss" lang="scss" scoped />
