<template>
  <div class="em-showroom-card-video">
    <BaseVideo
      v-if="product.content.video_loop?.filename"
      :autoplay="true"
      :loop="true"
      :poster="posterImage"
      :src="product.content.video_loop.filename"
      :playsinline="true" />
  </div>
</template>

<script setup lang="ts">
import type { ISbStoryData } from 'storyblok-js-client';
import { computed } from 'vue';
import { BaseVideo } from '~/components/base';
import type { DataProductStoryblok } from '~/types/storyblok-generated';

const props = defineProps<{
  product: ISbStoryData<DataProductStoryblok>;
}>();

const posterImage = computed(() => {
  if (!props.product.content.video_loop_poster) {
    return undefined;
  }

  return props.product.content.video_loop_poster.filename !== ''
    ? props.product.content.video_loop_poster.filename + '/m/320x0'
    : undefined;
});
</script>

<style src="./EMShowroomCardVideo.scss" lang="scss" scoped />
