<template>
  <div v-editable="blok" class="bm-header-rich-in-page" :class="isDark ? 'is-dark' : 'is-light'">
    <div class="nav">
      <!-- Title -->
      <div class="title" @click="toggleIsSectionsListActive">
        {{ blok.text }}
      </div>

      <!-- Desktop Section links -->
      <div class="section-links-desktop">
        <div class="sections-list-desktop">
          <div v-for="(item, index) in blok.anchors" :key="index" class="link-container">
            <BaseLink
              class="link"
              :link="item.link"
              :class="{ 'is-active': urlIsActive(route.path, urlExtractFromLink(item.link)) }"
              :skipUrlManipulation="config.public.IS_HEADER_FOOTER_EXPORT"
              @click="() => trackNavigationClick(item.text)">
              {{ item.text }}
            </BaseLink>

            <BaseIcon
              v-if="urlIsActive(route.path, urlExtractFromLink(item.link))"
              name="fi_chevron-top"
              :size="16"
              :color="isDark ? 'white' : 'black'"
              class="sections-chevron" />
          </div>
        </div>
      </div>

      <!-- CTA -->
      <div class="cta">
        <div v-if="blok.anchors?.length" class="link sections-dropdown-toggle" @click="toggleIsSectionsListActive">
          <BaseIcon
            :name="isSectionsListActive ? 'fi_chevron-top' : 'fi_chevron-bottom'"
            :size="16"
            :color="isDark ? 'white' : 'black'" />
        </div>

        <UtilButton v-for="(cta, index) in blok.call_to_actions" :key="index" :blok="cta" :isSmallOnMobile="true" />
      </div>
    </div>

    <!-- Links Section Mobile -->
    <div ref="$sectionsList" class="section-links-mobile">
      <div class="sections-list-mobile">
        <div v-for="(item, index) in blok.anchors" :key="index" ref="$anchors" class="section-list-item">
          <BaseLink
            class="link-mobile"
            :link="item.link"
            :class="{ 'is-active': urlIsActive(route.path, urlExtractFromLink(item.link)) }"
            :skipUrlManipulation="config.public.IS_HEADER_FOOTER_EXPORT">
            {{ item.text }}
          </BaseLink>
        </div>
      </div>
      <div class="header-line" :class="isDark ? 'line-dark' : 'line-light'"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CustomEase from 'gsap/CustomEase';
import BaseLink from '~/components/base/BaseLink.vue';
import BaseIcon from '~/components/base/BaseIcon.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useGtmTracking } from '~/composables/useGtmTracking';
import { useGsap } from '~/composables/useGsap';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import type { BmHeaderInPageStoryblok } from '~/types/storyblok-generated';
import { urlIsActive, urlExtractFromLink } from '~/utils/url';
import { useRoute } from 'vue-router';
import { useRuntimeConfig } from '#imports';

gsap.registerPlugin(ScrollTrigger, CustomEase);

defineProps<{
  blok: BmHeaderInPageStoryblok;
  isDark?: boolean;
}>();

const emit = defineEmits<{
  (e: 'sectionsListActive', value: boolean): void;
}>();

const { state: stateBreakpoint } = useBreakpoint();
const { easeOut } = useGsap();
const { trackEvent } = useGtmTracking();
const config = useRuntimeConfig();
const route = useRoute();

const $sectionsList = ref<HTMLElement>();
const $anchors = ref<HTMLElement[]>();
const isTabletLarge = computed(() => stateBreakpoint.isTabletLarge);
const isSectionsListActive = ref(false);
const isListAnimating = ref(false);
const sectionsListHeight = ref(0);

const setSectionsList = () => {
  if (window.innerWidth >= 1024 && isSectionsListActive.value) {
    toggleIsSectionsListActive();
    return;
  }
  // Set sections list behind the navigation on mobile
  if (sectionsListHeight.value) {
    return;
  }

  sectionsListHeight.value = $sectionsList.value?.clientHeight || 100;
  isSectionsListActive.value = false;

  if (!$sectionsList.value || !$anchors.value) {
    return;
  }

  gsap.set($sectionsList.value, {
    height: 0,
  });

  gsap.set($anchors.value, {
    opacity: 0,
    y: isTabletLarge.value ? 0 : -5,
  });
};

const toggleIsSectionsListActive = () => {
  if (isTabletLarge.value || isListAnimating.value) {
    return;
  }

  isListAnimating.value = true;

  // Toggle visitbililty of sections list
  const sectionList = $sectionsList.value as HTMLElement;
  const anchors = $anchors.value as HTMLElement[];
  const tl = gsap.timeline();

  if (!isSectionsListActive.value) {
    // Show: first fade in then move down
    tl.to(sectionList, {
      height: sectionsListHeight.value,
      opacity: 1,
      duration: 0.3,
      ease: easeOut,
    }).to(
      anchors,
      {
        opacity: 1,
        y: 0,
        stagger: 0.05,
        ease: easeOut,
        onComplete: () => {
          isListAnimating.value = false;
        },
      },
      '-=0.2'
    );
  } else {
    // Hide: first move up then fade out
    tl.to(anchors, {
      opacity: 0,
      y: -5,
      stagger: -0.05,
      ease: easeOut,
    }).to(
      sectionList,
      {
        height: 0,
        opacity: 0,
        duration: 0.5,
        ease: easeOut,
        onComplete: () => {
          isListAnimating.value = false;
        },
      },
      '-=100%'
    );
  }

  isSectionsListActive.value = !isSectionsListActive.value;
};

watch(
  () => isSectionsListActive.value,
  value => emit('sectionsListActive', value)
);

onMounted(() => {
  setSectionsList();
  window.addEventListener('resize', setSectionsList);
});

onUnmounted(() => {
  window.removeEventListener('resize', setSectionsList);
});

function trackNavigationClick(label: string) {
  trackEvent({ event: 'navigationClick', navigationItem: label });
}
</script>

<style src="./BMHeaderRichInPage.scss" lang="scss" scoped />
