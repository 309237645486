<template>
  <BaseLink
    :is="isFakeButton ? 'div' : blok.link?.linktype === 'url' ? 'a' : 'button'"
    v-editable="blok"
    role="button"
    :link="blok.link"
    :tabindex="isDisabled ? -1 : (tabindex ?? undefined)"
    class="util-button"
    :class="{
      'is-follow': isMouseFollow,
      'is-primary': blok.theme === 'primary',
      'is-secondary-dark': blok.theme === 'secondary' && isBackgroundDark,
      'is-secondary-light': blok.theme === 'secondary' && !isBackgroundDark,
      'is-background-dark': isBackgroundDark,
      'has-text': hasText,
      'is-large': blok.size === 'large' && !isSmallOnMobile,
      'is-large-above-mobile': blok.size === 'large' && isSmallOnMobile,
      'is-disabled': isDisabled,
      'is-hovered': isHovered,
      'is-loading': isLoading,
    }"
    :type="type"
    :skipUrlManipulation="props.skipUrlManipulation"
    @click="onButtonClick">
    <div
      v-if="insetBorder"
      class="inset-border"
      :style="
        insetBorder
          ? {
              borderImageSource: `linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, ${insetBorder} 48.44%, rgba(255, 255, 255, 0) 100%)`,
            }
          : undefined
      "></div>
    <span v-if="blok.text" class="button-label" :style="{ whiteSpace: isNoWrap ? 'nowrap' : undefined }">
      {{ blok.text }}
    </span>

    <div v-if="blok.icon" ref="$icon" class="button-icon">
      <BaseIcon :name="blok.icon" :size="16" color="inherit" />
    </div>
  </BaseLink>
</template>

<script lang="ts" setup>
import { computed, ref, type ButtonHTMLAttributes } from 'vue';
import BaseIcon from '~/components/base/BaseIcon.vue';
import BaseLink from '~/components/base/BaseLink.vue';
import { useLayer } from '~/composables/useLayer';
import type { UtilButtonStoryblok } from '~/types/storyblok-generated';

const props = defineProps<{
  blok: UtilButtonStoryblok;
  isDisabled?: boolean;
  tabindex?: ButtonHTMLAttributes['tabindex'];
  isBackgroundDark?: boolean;
  isSmallOnMobile?: boolean;
  isNoWrap?: boolean;
  isHovered?: boolean;
  onClick?: () => void;
  isMouseFollow?: boolean;
  isLoading?: boolean;
  type?: ButtonHTMLAttributes['type'];
  skipUrlManipulation?: boolean;
  /**
   * If true, the button will be a div element
   */
  isFakeButton?: boolean;
  /**
   * Add a gradient border (mainly used for the BaseCursor component)
   */
  insetBorder?: string;
}>();

defineExpose({ onButtonClick });

const $icon = ref<HTMLElement>();
const hasText = computed(() => !!props.blok.text?.length);

function onButtonClick(e?: PointerEvent): boolean {
  e?.stopPropagation();

  if (props.onClick) {
    props.onClick();
  } else {
    // Needed to determine if a click is set as property
    return false;
  }
  if (!props.blok.item_overlay) {
    return false;
  }

  // Open overlay
  const { state } = useLayer();
  if (typeof props.blok.item_overlay === 'string') {
    state.corePageLayerId = null;
  } else {
    state.corePageLayerId = props.blok.item_overlay.uuid;
  }
  return true;
}
</script>

<style src="./UtilButton.scss" lang="scss" scoped />
