<template>
  <div v-if="storyRes?.story && !!settings" :class="storyRes.story.content.is_dark ? 'has-black-bg' : 'has-white-bg'">
    <BMHeaderRich
      v-if="typeof header !== 'string' && header?.content"
      :blok="header.content"
      :inPageBlok="storyRes.story.content.header_in_page"
      :dataProduct="
        typeof storyRes.story.content.data_product !== 'string' ? storyRes.story.content.data_product : undefined
      "
      :alternates="storyRes.story.alternates" />
    <CorePage v-if="settings?.content" :blok="storyRes.story.content" :settings="settings.content" />
    <BMFooter
      v-if="
        settings?.content.footer_default &&
        typeof settings.content.footer_default !== 'string' &&
        settings?.content.footer_default?.content
      "
      :blok="settings.content.footer_default.content"
      :isDark="storyRes.story.content.is_dark" />
    <BaseModalVideo :youtubePrivacyWarning="settings.content.youtube_privacy_warning" />
  </div>
  <div v-else>
    <!-- In case the Storyblok error story can not be found this is the fallback -->
    <div class="container-full">
      <h1 class="heading-main-large" :style="{ marginTop: '128px' }">Error 404</h1>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStoryblokBridge } from '@storyblok/vue';
import { useHead } from 'nuxt/app';
import { computed, onMounted, watch } from 'vue';
import BaseModalVideo from '~/components/base/BaseModalVideo.vue';
import BMHeaderRich from '~/components/storyblok/basic/BMHeaderRich/BMHeaderRich.vue';
import BMFooter from '~/components/storyblok/basic/BMFooter/BMFooter.vue';
import CorePage from '~/components/storyblok/core/CorePage/CorePage.vue';
import { useFooter } from './composables/useFooter';
import { useHeader } from './composables/useHeader';
import { useLocale } from './composables/useLocale';
import { defaultStoryParams, useStoryblokClient } from './composables/useStoryblokClient';
import { useTheme } from './composables/useTheme';
import type { CorePageStoryblok } from './types/storyblok-generated';
import { moduleSettingsMap } from './utils/module-settings';
import { createSeoMetaTags } from './utils/seo-meta';

const { locale } = useLocale();
const { state: headerState } = useHeader();
const { state: footerState } = useFooter();
const { getStory, getSettings } = await useStoryblokClient();

const settings = await getSettings(locale);
const storyRes = await getStory<CorePageStoryblok>(`/${locale}/error-page`);

onMounted(() => {
  useStoryblokBridge(storyRes.value?.story?.id, evStory => (storyRes.value.story = evStory), defaultStoryParams);
});

/**
 * Get relevant header and alert from story or settings
 */
const header = computed(() => {
  if (storyRes.value.story.content.header_alternative?.length) {
    return storyRes.value.story.content.header_alternative[0];
  }
  return settings.value.content.header_rich_default;
});

/**
 * Set dark mode by the first component. Used for the theme of the header.
 */
if (storyRes.value.story && (storyRes.value.story.content.body?.length ?? 0) > 0) {
  const { setIsDarkByComponent } = useTheme();
  const firstBlok = storyRes.value.story.content.body?.[0];
  if (firstBlok) {
    setIsDarkByComponent(firstBlok);
  }
}

/**
 * Set header state
 */
function updateHeaderState() {
  // Prevent header sliding when standalone modules are present
  const storyHasStandaloneModules = storyRes.value.story.content.body?.some(
    blok => moduleSettingsMap[blok.component]?.standalone
  );
  headerState.preventSlide = !!storyHasStandaloneModules;
}
updateHeaderState();
watch(storyRes, updateHeaderState);
watch(settings, updateHeaderState);

/**
 * Set the footer state
 */
function setFooterState() {
  const hasHiddenFooter = storyRes.value.story.content.body?.some(
    blok => moduleSettingsMap[blok.component]?.hideFooter
  );
  footerState.hidden = !!hasHiddenFooter;
}
watch(storyRes, setFooterState);
setFooterState();

/**
 * Add SEO meta tags
 */
useHead({ ...createSeoMetaTags(locale, storyRes.value.story) });
</script>

<style lang="scss" scoped>
@use '~/assets/scss/variables';

.has-black-bg {
  background-color: variables.$black;
}

.has-white-bg {
  background-color: variables.$white;
}
</style>
