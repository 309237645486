import { EffectPass, VignetteEffect, VignetteTechnique } from 'postprocessing';
import type { EngineInstance } from '~/webglApp/core/engine';

export type PostVignetteInstance = InstanceType<typeof PostVignette>;

export class PostVignette {
  effect: VignetteEffect;
  pass: EffectPass;

  constructor(engine: EngineInstance) {
    this.effect = new VignetteEffect({
      technique: VignetteTechnique.DEFAULT,
      offset: 0.45,
      darkness: 0.75,
    });
    this.pass = new EffectPass(engine.camera, this.effect);
  }
}
