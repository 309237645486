<template>
  <div v-editable="blok" class="em-home">
    <!-- SEO HEADLINE -->
    <h1 v-if="blok.seo_headline" class="seo-headline">{{ blok.seo_headline }}</h1>

    <div ref="$container" class="em-home-swiper-container" :class="{ 'container-is-hidden': !swiperIsInitialized }">
      <Swiper
        class="em-home-swiper"
        :modules="[Scrollbar]"
        :spaceBetween="8"
        slidesPerView="auto"
        wrapperClass="swiper-wrapper"
        :updateOnWindowResize="false"
        :scrollbar="{
          el: '.swiper-scrollbar',
          draggable: true,
          dragSize: 90,
        }"
        :threshold="4"
        :breakpoints="{
          768: { spaceBetween: 16 },
          1024: { allowTouchMove: false, spaceBetween: 24 },
        }"
        @swiper="setSwiperRef">
        <SwiperSlide :style="{ width: `${xLargeWidth}px` }">
          <EMHomeCard
            v-if="blok.card_xlarge?.[0]"
            :frontSideOverline="blok.card_xlarge[0].overline"
            :frontSideHeadline="blok.card_xlarge[0].headline"
            :frontSideSubline="blok.card_xlarge[0].subline"
            :frontSideImage="
              breakpointState.isTabletLarge
                ? blok.card_xlarge[0].image_desktop
                : breakpointState.isPhoneLarge
                  ? blok.card_xlarge[0].image_tablet
                  : blok.card_xlarge[0].image_mobile
            "
            :frontSideVideo="
              breakpointState.isTabletLarge
                ? blok.card_xlarge[0].video_desktop
                : breakpointState.isPhoneLarge
                  ? blok.card_xlarge[0].video_tablet
                  : blok.card_xlarge[0].video_mobile
            "
            :link="blok.card_xlarge[0].link"
            :backSideText="undefined"
            :backSideCta="undefined"
            horizontalTextAlign="left"
            verticalTextAlign="bottom"
            :isDark="!!blok.card_xlarge[0].is_dark"
            :textLayout="blok.card_xlarge[0].text_layout"
            :interactivity="blok.card_xlarge[0].link ? 'link' : 'none'"
            cardType="xLarge"
            :isFlipped="flippedCardUid === blok.card_xlarge[0]._uid"
            :uid="blok.card_xlarge[0]._uid"
            @flip="handleFlip" />
        </SwiperSlide>
        <SwiperSlide v-for="slide in slides" v-bind="slide" :key="slide._uid" :style="{ width: slide.colWidth }">
          <EMHome1Large2Small
            v-if="slide.component === 'em_home-col-1-large-2-small'"
            v-editable="slide"
            :flippedCardUid="flippedCardUid"
            v-bind="slide"
            @flip="handleFlip" />
          <EMHome1Medium1Small
            v-else-if="slide.component === 'em_home-col-1-medium-1-small'"
            v-editable="slide"
            :flippedCardUid="flippedCardUid"
            v-bind="slide"
            @flip="handleFlip" />
          <EMHome1Medium2Small
            v-else-if="slide.component === 'em_home-col-1-medium-2-small'"
            v-editable="slide"
            :flippedCardUid="flippedCardUid"
            v-bind="slide"
            @flip="handleFlip" />
          <EMHome2Medium
            v-else-if="slide.component === 'em_home-col-2-medium'"
            v-editable="slide"
            :flippedCardUid="flippedCardUid"
            v-bind="slide"
            @flip="handleFlip" />
        </SwiperSlide>
        <SwiperSlide :style="{ width: `${footerWidth}px` }">
          <EMHomeFooter
            :headline="blok.footer_headline"
            :copyrightExtension="blok.footer_copyright_extension"
            :blok="footerBlok"
            :activeBreakpoint="activeBreakpoint" />
        </SwiperSlide>
      </Swiper>
      <div ref="$scrollbar" class="swiper-scrollbar"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDebounceFn } from '@vueuse/core';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Scrollbar } from 'swiper/modules';
import type { Swiper as SwiperInstance } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useTransition } from '~/composables/useTransition';
import type { BmFooterStoryblok, EmHomeStoryblok } from '~/types/storyblok-generated';
import type { ModuleWrapperState } from '~/types/utils';
import EMHome1Large2Small from './EMHome1Large2Small.vue';
import EMHome1Medium1Small from './EMHome1Medium1Small.vue';
import EMHome1Medium2Small from './EMHome1Medium2Small.vue';
import EMHome2Medium from './EMHome2Medium.vue';
import EMHomeCard from './EMHomeCard.vue';
import EMHomeFooter from './EMHomeFooter.vue';
import type { EMHomeSliderBreakpoint } from './EMHome.types';
import { nextTick } from 'process';

const props = defineProps<{
  blok: EmHomeStoryblok;
  footerBlok: BmFooterStoryblok;
  state: ModuleWrapperState;
}>();

const $scrollbar = ref<HTMLElement>();
const $container = ref<HTMLElement>();
const containerWidth = ref<number | null>(null);
const activeBreakpoint = ref<EMHomeSliderBreakpoint>('bl-l');
const totalColumns = ref<2 | 4>(4);
const xLargeWidth = ref<number | null>(null);
const footerWidth = ref<number | null>(null);
const gap = ref<number>(24);
const swiperIsInitialized = ref(false);
const swiperRef = ref<SwiperInstance | null>(null);
const flippedCardUid = ref<string | null>(null);

const { state: breakpointState } = useBreakpoint();
const { fadeSlide } = useTransition();

function handleResize() {
  if (breakpointState.isLaptop) {
    activeBreakpoint.value = 'bl-l';
    gap.value = 24;
  } else if (breakpointState.isTablet) {
    activeBreakpoint.value = 'bt-l';
    gap.value = 24;
    totalColumns.value = 2;
  } else if (breakpointState.isPhoneLarge) {
    activeBreakpoint.value = 'bt';
    gap.value = 16;
    totalColumns.value = 2;
  } else if (window.innerWidth >= 480) {
    activeBreakpoint.value = 'bt';
    gap.value = 8;
    totalColumns.value = 2;
  } else {
    activeBreakpoint.value = 'base';
    gap.value = 8;
    totalColumns.value = 2;
  }

  const ratio = window.innerWidth / window.innerHeight;
  if (breakpointState.isLaptop) {
    totalColumns.value = 4;
  } else {
    /**
     * Special case very wide screens.
     * Based on the viewport **width**, we would actually be in desktop mode,
     * but due to the the cards being very shallow / stretched, we want to switch to a mobile-like view to properly display the cards.
     */
    if (ratio >= 2 / 3) {
      totalColumns.value = 4;
    } else {
      totalColumns.value = 2;
    }
  }

  containerWidth.value = $container.value?.clientWidth ?? 0;
  swiperRef.value?.update();
}
const debouncedHandleResize = useDebounceFn(handleResize, 500) as () => void;

function showContents() {
  swiperIsInitialized.value = true;

  // Filter only visible items
  const cards = $container.value?.querySelectorAll('.em_home-card');
  if (!cards) {
    return;
  }

  const visibleCards = Array.from(cards).filter(card => card.getBoundingClientRect().x <= window.innerWidth);

  fadeSlide(visibleCards, {
    direction: 'x',
    offsetMultiplier: 5,
    staggerEach: 0.1,
  });
}

function handleFlip(id: string) {
  flippedCardUid.value = flippedCardUid.value === id ? null : id;
}

onMounted(() => {
  handleResize();
  if (typeof window === 'undefined') {
    return;
  }
  window.addEventListener('resize', debouncedHandleResize);

  // Add mouse up event to scrollbar
  $scrollbar.value?.addEventListener('mousedown', onScrollBarDragEnd);

  // We somehow need to listen to swiper events in here and can't add them in the template
  swiperRef.value?.on('scrollbarDragEnd', onScrollBarDragEnd);

  // This needs a bit of time to finalize the DOM rendering
  nextTick(showContents);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debouncedHandleResize);
  $scrollbar.value?.removeEventListener('mousedown', onScrollBarDragEnd);
});

// Sync manual scrolling through the scrollbar with the GSAP scroll trigger
function onScrollBarDragEnd() {
  const pinSpacer = $container.value?.closest<HTMLElement>('.pin-spacer');

  if (!pinSpacer || !swiperRef.value) {
    return;
  }

  const progress = swiperRef.value.progress;
  const pinSpacerOffsetTop = pinSpacer.getBoundingClientRect().top + window.scrollY;
  const targetScrollTop = pinSpacerOffsetTop + progress * (pinSpacer.scrollHeight - window.innerHeight);

  window.scrollTo({
    behavior: 'instant',
    top: targetScrollTop,
  });
}

// The width of each column based on the total number of columns
const colTypeToColCountMap: Record<
  NonNullable<EmHomeStoryblok['other_cards']>[number]['component'] | 'xLarge' | 'footer',
  Record<EMHomeSliderBreakpoint, number>
> = {
  xLarge: {
    base: 2,
    bp: 2,
    bt: 3,
    'bt-l': 3,
    'bl-l': 3,
  },
  footer: {
    base: 4,
    bp: 2,
    bt: 2,
    'bt-l': 2,
    'bl-l': 3,
  },
  'em_home-col-1-large-2-small': {
    base: 2,
    bp: 2,
    bt: 2,
    'bt-l': 2,
    'bl-l': 2,
  },
  'em_home-col-1-medium-1-small': {
    base: 2,
    bp: 2,
    bt: 1,
    'bt-l': 1,
    'bl-l': 1,
  },
  'em_home-col-2-medium': {
    base: 2,
    bp: 2,
    bt: 1,
    'bt-l': 1,
    'bl-l': 1,
  },
  'em_home-col-1-medium-2-small': {
    base: 2,
    bp: 2,
    bt: 1,
    'bt-l': 1,
    'bl-l': 1,
  },
};

/**
 * Calculate the width of each column based on the total number of columns (includes the gap between columns)
 */
const slides = computed(() => {
  if (!containerWidth.value) {
    return [];
  }
  const widthPerCol = (containerWidth.value - gap.value * (totalColumns.value - 1)) / totalColumns.value;
  return props.blok.other_cards?.map(slide => {
    const colCount = colTypeToColCountMap[slide.component][activeBreakpoint.value];
    return {
      ...slide,
      colWidth: `${widthPerCol * colCount + gap.value * (colCount - 1)}px`,
    };
  });
});
watch(slides, () => {
  if (!containerWidth.value) {
    return [];
  }
  const widthPerCol = (containerWidth.value - gap.value * (totalColumns.value - 1)) / totalColumns.value;
  const xLargeColCount = colTypeToColCountMap['xLarge'][activeBreakpoint.value];
  xLargeWidth.value = widthPerCol * xLargeColCount + gap.value * (xLargeColCount - 1);
  const footerColCount = colTypeToColCountMap['footer'][activeBreakpoint.value];
  footerWidth.value = widthPerCol * footerColCount + gap.value * (xLargeColCount - 1);
});

function setSwiperRef(swiper: SwiperInstance) {
  swiperRef.value = swiper;
}
function updateSwiperProgress() {
  if (typeof window === 'undefined') {
    return;
  }
  if (swiperRef.value && window.innerWidth >= 1024) {
    swiperRef.value.setProgress(props.state.progress);
  }
}

watch(() => props.state.progress, updateSwiperProgress);
</script>

<style src="./EMHome.scss" lang="scss" scoped />
