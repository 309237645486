import { useStoryblokApi, type ISbResult, type StoryblokClient } from '@storyblok/vue';
import ms from 'ms';
import { createError, useAsyncData, useNuxtData, useRuntimeConfig, useState, type NuxtApp } from 'nuxt/app';
import type { ISbStoriesParams, ISbStoryData } from 'storyblok-js-client';
import { nextTick, type Ref } from 'vue';
import type { Locale } from '~/types/locales';
import type { BmHeaderRichStoryblok, CorePageLayerStoryblok, CoreSettingsStoryblok } from '~/types/storyblok-generated';

interface MeResponse {
  space: {
    id: number;
    name: string;
    domain: string;
    version: number;
    language_codes: string[];
  };
}

interface DatasourceEntry {
  name: string;
  value: string;
  dimension_value: string | null;
}

// We need to use our own type because the Storyblok client doesn't have good types
interface StoryResponse<T> extends ISbResult {
  data: {
    link_uuids: string[];
    links: string[];
    rel_uuids: string[];
    rels: ISbStoryData<CorePageLayerStoryblok>[];
    story: ISbStoryData<T>;
    stories: Array<ISbStoryData<T>>;
  };
}

export interface FetchedStory<T> {
  story: ISbStoryData<T>;
  pageLayers: ISbStoryData<CorePageLayerStoryblok>[];
}

export const defaultStoryParams: ISbStoriesParams = {
  resolve_relations: [
    'core_page.data_product',
    'core_page.header_alternative',
    'lm_slider.products',
    'util_button.item_overlay',
    'util_link-item-icon.item_overlay',
    'em_product-widget.data_product',
  ],
  resolve_links: 'url',
};

export const defaultSettingsParams: ISbStoriesParams = {
  resolve_relations: [
    // 'core_settings.header_rich_default', This gets resolved separately
    'core_settings.footer_default',
    'core_settings.shopping_cart',
    'bm_header-rich.top_product_defaults',
    'bm_header-rich--quick-links.data_products',
    'data_product.page',
  ],
  resolve_links: 'url',
};

/**
 * Make sure the path is cleaned up and doesn't lead to duplicate cache keys for the same story.
 */
function getCleanedPath(path: string) {
  return path.replace(/^\//, '').replace(/\/$/, '');
}

let cvFetchedAt = 0;

export async function useStoryblokClient() {
  const config = useRuntimeConfig();
  const version = config.public.storyblok.apiOptions.version as 'draft' | 'published';
  const currentCv = useState<number | null>(() => null); // Same on server and client
  const serverCv = useState<number | null>(() => null); // The cv on the server
  const storyblokApi = useStoryblokApi();

  /**
   * Get the CV (initial on server, updated on client)
   */
  if (cvFetchedAt < Date.now() - ms('10m')) {
    cvFetchedAt = Date.now();
    if (import.meta.server) {
      // We immediately fetch the CV on the server (it is only null there)
      const newCV = await getCurrentCv();
      currentCv.value = newCV;
      serverCv.value = newCV;
    }
    if (import.meta.client) {
      // We wait for the watchers to be set up on the client
      nextTick(async () => {
        const newCV = await getCurrentCv();
        setTimeout(() => {
          currentCv.value = newCV;
        }, 10);
      });
    }
  }

  /*
   * Gets the current CV of the Storyblok space
   */
  async function getCurrentCv() {
    const { data } = (await storyblokApi.get('cdn/spaces/me/', {
      version,
      cv: new Date().getTime(),
    })) as { data: MeResponse };
    return data.space.version;
  }

  /**
   * Gets the cached data from the Nuxt payload or static data.
   */
  function getCachedData(key: string, nuxtApp: NuxtApp) {
    if (serverCv.value !== null && currentCv.value !== serverCv.value) {
      // We return the client cache if the CV has changed (is empty on the client and will therefore be fetched again)
      return nuxtApp.payload.data[key];
    }
    return nuxtApp.payload.data[key] || nuxtApp.static.data[key];
  }

  /**
   * Returns the Storyblok settings for the given locale.
   */
  async function getSettings(locale: Locale) {
    const params: ISbStoriesParams = {
      ...defaultSettingsParams,
      version,
      cv: currentCv.value || undefined,
    };

    async function fetchSettings(locale: Locale): Promise<ISbStoryData<CoreSettingsStoryblok>> {
      try {
        const { data: settingsData }: StoryResponse<CoreSettingsStoryblok> = await storyblokApi.get(
          `cdn/stories/${locale}/_settings`,
          params
        );
        const { data: headerData }: StoryResponse<BmHeaderRichStoryblok> = await storyblokApi.get(
          `cdn/stories/${settingsData.story.content.header_rich_default}`,
          {
            ...params,
            // @ts-expect-error - The types here a wrong
            find_by: 'uuid',
          }
        );
        if (headerData?.story.content && typeof headerData.story.content === 'object') {
          settingsData.story.content.header_rich_default = headerData.story;
          return settingsData.story;
        }
        console.error('Header not found');
        throw createError({
          statusCode: 404,
          statusMessage: 'Not Found',
          message: 'Not Found',
        });
      } catch (error) {
        console.error('Error while fetching settings', error);
        throw createError({
          statusCode: 404,
          statusMessage: 'Not Found',
          message: 'Not Found',
        });
      }
    }

    const { data: settings } = await useAsyncData<ISbStoryData<CoreSettingsStoryblok>>(
      `settings__${locale}`,
      () => fetchSettings(locale),
      {
        getCachedData: getCachedData,
        watch: [currentCv, () => locale],
      }
    );

    // We know that settings.value can't be null here
    return settings as Ref<ISbStoryData<CoreSettingsStoryblok>>;
  }

  /**
   * Returns the Storyblok story for the given path.
   */
  async function getStory<T>(path: string, params?: ISbStoriesParams) {
    const cleanedPath = getCleanedPath(path);

    const { data: getStoryRes } = await useAsyncData<FetchedStory<T>>(
      cleanedPath,
      () => fetchStory<T>({ storyblokApi, version, cv: currentCv.value, path: cleanedPath, params }),
      {
        getCachedData: getCachedData,
        watch: [currentCv],
      }
    );

    // We know that story.value can't be null here
    return getStoryRes as Ref<FetchedStory<T>>;
  }

  /**
   * Returns all stories
   */
  async function getStories<T>(params: ISbStoriesParams) {
    const defaultParams: ISbStoriesParams = {
      ...defaultStoryParams,
      version,
      cv: currentCv.value || undefined,
    };

    async function fetchStories(params: ISbStoriesParams) {
      try {
        const data: ISbStoryData<T>[] = await storyblokApi.getAll(`cdn/stories`, { ...defaultParams, ...params });
        return data;
      } catch (error) {
        console.error('Error while fetching story', error);
        throw createError({
          statusCode: 404,
          statusMessage: 'Not Found',
          message: 'Not Found',
        });
      }
    }

    const paramsAsString = JSON.stringify(params);

    const { data: stories } = await useAsyncData<ISbStoryData<T>[]>(paramsAsString, () => fetchStories(params), {
      getCachedData: getCachedData,
      watch: [currentCv],
    });

    // We know that stories.value can't be null here
    return stories as Ref<ISbStoryData<T>[]>;
  }

  /**
   * Returns all entries of a datasource.
   */
  async function getDatasourceEntries(datasource: string, dimension?: string) {
    async function fetchDatasourceEntries(datasource: string, dimension?: string) {
      try {
        const newData = (await storyblokApi.getAll('cdn/datasource_entries', {
          datasource,
          dimension,
          cv: currentCv.value || undefined,
        })) as DatasourceEntry[];
        return newData;
      } catch (error) {
        console.error('Datasource not found', error);
        throw createError({
          statusCode: 404,
          statusMessage: 'Not Found',
          message: 'Not Found',
        });
      }
    }

    const paramsAsString = `${datasource}__${dimension}`;

    const { data } = await useAsyncData<DatasourceEntry[]>(
      paramsAsString,
      () => fetchDatasourceEntries(datasource, dimension),
      {
        getCachedData: getCachedData,
        watch: [currentCv, () => paramsAsString],
      }
    );

    // We know that data.value can't be null here
    return data as Ref<DatasourceEntry[]>;
  }

  return {
    getSettings,
    getStory,
    getStories,
    getDatasourceEntries,
  };
}

/**
 * Fetch a story from Storyblok. This functiion is separated from the useStoryblokClient because
 * we also use it in the prefetch hook.
 */
interface FetchStoryParams {
  storyblokApi: StoryblokClient;
  version: 'draft' | 'published';
  cv?: number | null;
  path: string;
  params?: ISbStoriesParams;
}
async function fetchStory<T>({ storyblokApi, version, cv, path, params }: FetchStoryParams) {
  const defaultParams: ISbStoriesParams = {
    ...defaultStoryParams,
    version,
    cv: cv ?? undefined,
  };
  try {
    const { data }: StoryResponse<T> = await storyblokApi.get(`cdn/stories/${path}`, {
      ...defaultParams,
      ...params,
    });
    return { story: data.story, pageLayers: data.rels.filter(rel => rel.content.component === 'core_page_layer') };
  } catch (error) {
    console.error('Error while fetching story', error);
    throw createError({
      statusCode: 404,
      statusMessage: 'Not Found',
      message: 'Not Found',
    });
  }
}

/**
 * Hook used to prefetch a story. It is "lighter" than the useStoryblokClient hook and synchronous.
 */
export function useStoryblokPrefetch() {
  const storyblokApi = useStoryblokApi();
  const config = useRuntimeConfig();
  const version = config.public.storyblok.apiOptions.version as 'draft' | 'published';

  async function prefetchStory<T>(path?: string, params?: ISbStoriesParams) {
    if (!path) {
      return;
    }
    const cleanedPath = getCleanedPath(path);
    const { data } = useNuxtData(cleanedPath);
    if (data.value) {
      return;
    }
    const story = await fetchStory<T>({ storyblokApi, version, path: cleanedPath, params });
    data.value = story;
  }

  return { prefetchStory };
}
